import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Modal, Grid, Card, Image } from 'semantic-ui-react'
import { loadResources } from '../../reducks/resources'
import WCLoader from './Loader';

class Library extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
        this.onClickItem.bind(this)
    }

    componentDidMount() {

        const { resource, dispatch } = this.props

        if ( this.props.hasOwnProperty(resource) && this.props[resource].length === 0 ) {

            this.setState({ loading:true })

            dispatch( loadResources( resource, () => {
                this.setState({ loading:false })
            } ) )

        }

    }

    onClickItem(item) {
        this.props.onSelectItem(item)
    }

    render() {

        const { loading } = this.state
        const { open, onClose, trigger, resource } = this.props
        const resources = this.props[resource]
        const title = this.props.title || 'Library'

        return (
            <Modal open={open} trigger={trigger} onClose={onClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content scrolling>

                    { loading ?
                        <WCLoader />
                        :
                        <Grid columns={6}>
                            { resources.map( item =>
                                <Grid.Column key={item.id}>
                                    <Card fluid onClick={ () => { this.onClickItem(item) } }>

                                        { resource === 'graphics' &&
                                            <Image src={item.url} />
                                        }

                                        { resource === 'cutArt' &&
                                            <svg
                                                width={item.width} height={item.height}
                                                viewBox={`0 0 ${item.width} ${item.height}`}
                                                style={{maxWidth:'100%', height:'auto', padding: '1rem', margin: '0 auto'}}
                                                >
                                                <path d={item.d} fill="rgba(0,0,0,0.3)" />
                                            </svg>
                                        }

                                    </Card>
                                </Grid.Column> )
                            }
                        </Grid>
                    }

                </Modal.Content>
            </Modal>
        )
    }
}

Library.propTypes = {
    graphics: PropTypes.array.isRequired,
    cutArt: PropTypes.array.isRequired,
    resource: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        graphics: state.resources.graphics,
        cutArt: state.resources.cutArt
    }
}

export default connect(mapStateToProps)(Library)
