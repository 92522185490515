import React, { useState } from 'react'
import Currency from '../core/Currency'
import { generatePreview, getPrintPPI, saveProject } from '../core/utilities'
import { Label, Message, Input, Icon, Button, Modal, Grid, List, Header, Progress, Table, Checkbox, Segment } from 'semantic-ui-react'
import { PPI, QUARTER_IN_PX } from '../../data/constants'
import { toggleConfirmCartMode, addSnack, setUserFormMode } from '../../reducks/app'
import { updateCart } from '../../reducks/cart'
import { useSelector, useDispatch } from 'react-redux'
import CloudinaryApi from '../../api/cloudinaryApi'
import ProjectPreview from '../core/ProjectPreview'
import ShopApi from '../../api/shopApi'
import { setPaper, setPrinting } from '../../reducks/project'
import ProductSelector from '../core/ProductSelector'
// import UserActions from '../core/UserActions'
// import ProductSelector from '../core/ProductSelector'
// import { setGlass, setPaper, setPrinting, setObjectsDescription } from '../../reducks/project'
// import { sanitizeHTML } from '../../helpers'
// import { useEffect } from 'react'

const CartConfirm = props => {

    const user = useSelector(state => state.user.meta)
    const {
        // requireShipping, 
        confirmCartMode,
        frameShop
    } = useSelector(state => state.app)
    const project = useSelector(state => state.project.present)
    const lineItems = useSelector(state => state.cart.lineItems)
    const projectPrice = useSelector(state => state.price)
    const dispatch = useDispatch()

    const [cartError, setCartError] = useState(false)
    const [cartSuccess, setCartSuccess] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [loadingMessage, setLoadingMessage] = useState('')
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [showPricing, setShowPricing] = useState(false)
    // const [localObjectsDescription, setLocalObjectsDescription] = useState(project.objectsDescription)

    const projectsInCart = lineItems.map(item => {
        return parseInt(item.snapshot.options.projectId)
    }).filter(item => item) // filter out falsy values
    const lineItemKey = projectsInCart.indexOf(project.id)
    const inCart = project.id && lineItemKey > -1

    // useEffect(() => {
    //     setLocalObjectsDescription(project.objectsDescription)
    // }, [project.objectsDescription])

    function handleAddToCart() {
        setLoadingMessage('Saving project...')
        // delay progress a bit so that the user can see it
        setTimeout(() => {
            setLoadingProgress(1 / 3 * 100)
        }, 500)
        saveProject(project, project.id, user && user.id).then(({ projectData, response }) => {
            if (response.success) {
                sendToCart(projectData)
            } else {
                dispatch(addSnack({
                    id: 'cart-error',
                    message: 'Could not add project to cart',
                    icon: 'exclamation circle',
                    color: 'red',
                }))
            }
        })
    }

    async function sendToCart(projectData) {
        setLoadingMessage('Adding to cart...')
        setLoadingProgress(2 / 3 * 100)

        const options = await assembleProjectOptions(projectData)
        let params = {
            options,
            qty: parseInt(quantity)
        }
        if (inCart) {
            params.lineItemId = lineItems[lineItemKey].id
        } else {
            params.purchasableId = window.Craft.productVariants.customProject
        }

        return ShopApi.updateCart(params)
            .then(response => {
                if (response.success) {
                    setLoadingMessage('Complete!')
                    setLoadingProgress(3 / 3 * 100)
                    setTimeout(() => {
                        // delay so user can see it
                        handleUpdateCartSuccess(response.cart);
                    }, 1000)
                }
                if (response.error) {
                    handleUpdateCartFailure(response.error);
                    setLoadingMessage('')
                    setLoadingProgress(0)
                    dispatch(toggleConfirmCartMode(false))
                }
            })
    }

    async function assembleProjectOptions(projectData) {
        const { activeFrame, artboardwidth, artboardheight, selectedGlass, selectedPaper } = projectData
        const dpi = getPrintPPI(artboardwidth / PPI, artboardheight / PPI)
        const printSheetDataUrl = await generatePreview({ excludeFrame: true, scale: dpi / PPI, dpi, cropMarks: true })
        const rabbet = activeFrame ? 0.25 : 0
        const faceWidth = activeFrame ? activeFrame.faceWidth : 0
        const outsideFace = activeFrame ? faceWidth - rabbet : 0
        const outsideWidth = (artboardwidth / PPI) + outsideFace * 2
        const outsideHeight = (artboardheight / PPI) + outsideFace * 2

        const packagingBuffer = 2 // a buffer to add to each dimension for packaging.
        const cubicInchesPerPound = 207 // an average calculated from real world tests
        const projectWidth = outsideWidth + packagingBuffer
        const projectLength = outsideHeight + packagingBuffer
        const projectHeight = 1 + packagingBuffer
        const volume = projectWidth * projectLength * projectHeight

        let cloudinaryPrintSheet = await CloudinaryApi.upload({
            file: printSheetDataUrl,
            folder: CloudinaryApi.getFolder(user && user.id, projectData.id),
            public_id: `project_${projectData.id}_printsheet`
        })

        return {
            projectAssembly: projectData.assembly,
            projectFrame: activeFrame ? activeFrame.id : 0,
            projectFrameBaseCost: projectPrice.framingBaseCost,
            projectGlass: selectedGlass ? selectedGlass.id : 0,
            projectId: projectData.id,
            projectImages: projectData.elements.reduce((acc, elem) => {
                if (elem.image instanceof Object && elem.depth === projectData.projectMats.length) {
                    acc += 1
                }
                return acc
            }, 0),
            projectImagePrinting: projectData.imagePrinting,
            projectMats: projectData.projectMats.map(mat => mat.id).join(),
            projectMatWidth: (artboardwidth / PPI).toFixed(3),
            projectMatHeight: (artboardheight / PPI).toFixed(3),
            projectMounting: projectData.mounting,
            projectObjectsDescription: projectData.objectsDescription ? projectData.objectsDescription : '',
            projectObjectsProvided: projectData.objectsProvided,
            projectPaper: selectedPaper ? selectedPaper.id : 0,
            projectPodImages: projectData.elements.reduce((acc, elem) => {
                if (elem.image instanceof Object && elem.image.source === 'pod') {
                    acc = [
                        ...acc,
                        `Image ID: ${elem.image.id} - Image size: ${(elem.width + QUARTER_IN_PX) / PPI} x ${(elem.height + QUARTER_IN_PX) / PPI}" - Border width: 2"`
                    ]
                }
                return acc
            }, []),
            projectPodCost: projectPrice.pod,
            projectPrice: projectPrice.total,
            projectPrintSheetUrl: cloudinaryPrintSheet && cloudinaryPrintSheet.data ? cloudinaryPrintSheet.data.secure_url : '',
            projectPaperBaseCost: projectPrice.paperBaseCost,
            projectThumbnail: projectData.thumbnail,
            projectTitle: projectData.title,
            projectWidth,
            projectLength,
            projectHeight,
            projectWeight: volume / cubicInchesPerPound,
        }
    }

    function handleUpdateCartSuccess(cart) {
        setLoadingMessage('')
        setLoadingProgress(0)
        setCartSuccess(true)
        dispatch(updateCart(cart))
    }

    function handleUpdateCartFailure(error) {
        setCartError(error)
    }

    function closeAndResetModal() {
        setCartSuccess(false)
        dispatch(toggleConfirmCartMode(false))
    }

    return (<>
        <Modal
            open={confirmCartMode}
            onClose={closeAndResetModal}
        >

            <Modal.Content>

                <Segment loading={loadingMessage} basic>

                    <Grid stackable relaxed columns="equal">

                        <Grid.Column align="center">
                            <ProjectPreview fluid style={{ boxShadow: '0 2px 10px rgba(0,0,0,0.3)' }} />
                            <p className='mt_1'><strong>{project.artboardwidth / PPI} &times; {project.artboardheight / PPI} in.</strong></p>
                        </Grid.Column>

                        {!loadingMessage && cartSuccess ?
                            <Header size='large' className='icon' icon={<Icon name="check circle" color='green' />} content="Item has been added to your cart" />
                            :
                            <Grid.Column>
                                <Header size='large'>Your order includes:</Header>
                                <Segment attached="top" padded>
                                    <p>
                                        <Icon name="check icon" style={{ width: '1.9rem' }} />
                                        <strong>Hi-res digital download</strong>
                                    </p>
                                </Segment>
                                <Segment attached={project.imagePrinting ? true : "bottom"} padded>
                                    <Checkbox name="imagePrinting"
                                        checked={project.imagePrinting}
                                        label={<label><strong>Add high-quality printing</strong> <span class="ui green circular small label ml_half">Shipped for FREE</span></label>}
                                        onChange={(e, { checked }) => {
                                            if (!checked) {
                                                setQuantity(1)
                                            }
                                            dispatch(setPrinting(checked))
                                        }}
                                    />
                                    {project.imagePrinting &&
                                        <ProductSelector basic fluid clearable selection
                                            className="mt_1"
                                            loadingText="Loading paper"
                                            activeItem={project.selectedPaper}
                                            productHandle="paper"
                                            selectText="Select paper"
                                            onSelect={paper => dispatch(setPaper(paper))}
                                        />
                                    }
                                </Segment>
                                {project.imagePrinting &&
                                    <Segment attached="bottom" padded>
                                        <Grid columns={2} verticalAlign="middle">
                                            <Grid.Column>
                                                <p className='ui tiny header'>Quantity:</p>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Input type="number" fluid step={1} min={1} value={quantity}
                                                    input={<input style={{ fontSize: '1.5rem', padding: '0.5em 1em' }} />}
                                                    onChange={(e, { value }) => setQuantity(value)}
                                                />
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                }
                            </Grid.Column>
                        }
                    </Grid>


                    {user && user.admin && showPricing &&

                        <Table compact="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell><strong>Total:</strong></Table.HeaderCell>
                                    <Table.HeaderCell textAlign='right'><Currency value={projectPrice.total} /></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(projectPrice).map(key => key !== 'total' && (
                                    <Table.Row key={`price-table-${key}`} positive={projectPrice[key] > 0} negative={projectPrice[key] < 0} disabled={projectPrice[key] === 0}>
                                        <Table.Cell>{key}:</Table.Cell>
                                        <Table.Cell textAlign='right'><Currency value={projectPrice[key]} /></Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                    }

                </Segment>

            </Modal.Content>

            {loadingMessage &&
                <Modal.Actions>
                    <Progress label={loadingMessage}
                        color={loadingProgress === 100 ? "green" : "blue"}
                        active={loadingProgress < 100}
                        size="small"
                        percent={loadingProgress}
                    />
                </Modal.Actions>
            }

            {!loadingMessage && cartSuccess &&
                <Modal.Actions>
                    <Button basic
                        onClick={closeAndResetModal} >
                        Make Changes
                    </Button>
                    <Button primary as="a" href={window.Craft.cart.url + (frameShop ? '?framer=' + frameShop.id : '')}>View cart</Button>
                </Modal.Actions>
            }

            {!loadingMessage && !cartSuccess &&
                <Modal.Actions>
                    {user && user.admin &&
                        <Button basic content={showPricing ? 'Hide Pricing' : 'Show Pricing'} onClick={e => setShowPricing(!showPricing)} />
                    }
                    <Button basic onClick={closeAndResetModal}>Cancel</Button>
                    {user ?
                        <Button primary onClick={() => handleAddToCart()}>
                            <Icon name="cart" />
                            {'Add to cart - '}
                            <Currency value={projectPrice.total * quantity} />
                        </Button>
                        :
                        <Button primary
                            content="Sign in"
                            icon="user circle"
                            onClick={() => dispatch(setUserFormMode('login'))}
                        />
                    }
                </Modal.Actions>
            }

        </Modal>

        {/* <Modal size="mini" open={cartSuccess}>
            <Modal.Content>
                <Image centered size="small" src={project.thumbnail} />
                <Header color="green" className="icon mb_0" size="medium" textAlign="center">
                    <Icon name="check" />
                    Project added to your cart!
                </Header>
            </Modal.Content>
            <Modal.Actions>
                <Button primary fluid as="a" href={window.Craft.cart.url + ( frameShop ? '?framer=' + frameShop.id : '' ) } className="mx_0 mb_nudge">View cart</Button>
                <Button fluid basic onClick={ () => setCartSuccess(false) } className="mx_0">Make Changes</Button>
            </Modal.Actions>
        </Modal> */}

        <Modal size="mini" open={!!cartError}>
            <Modal.Content>
                <Label attached="top" color="red" size="large">Error adding to cart</Label>
                <Header size="small" textAlign="center" >
                    Oops, something went wrong...
                </Header>
                <Message negative>{cartError}</Message>
                <p className="text_center">
                    {'Try'} <strong>saving</strong> {'(File > Save As) your project and reloading the page.'}
                    <br />{'If the problem persists, please contact us for help.'}
                    <br /><a href={`mailto:info@mydiywall.com?subject=${encodeURIComponent('User Error: Add to cart')}&body=${encodeURIComponent('cartError: "' + cartError + '"')}`}>Contact email support</a>
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button icon="delete" content="Close" onClick={() => setCartError(false)} />
            </Modal.Actions>
        </Modal>

    </>)
}

export default CartConfirm
