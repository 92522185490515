export const black 	= '#212B36'
export const blue 	= '#0085DE'
export const brown 	= '#82674c'
export const olive 	= '#6A7B56'
export const red 	= '#ED6347'
export const white 	= '#FFFFFF'
export const yellow = '#E0AF25'
export const gold = '#AF9128'

export const green 	= '#79A843'
export const orange = '#ef9929'
export const pink 	= '#CF2E95'
export const teal 	= '#008571'
export const purple = '#824AC5'
export const violet = '#ac7398'

export const darkestGrey = '#1e1e1f'
export const darkerGrey = '#323233'
export const darkGrey = '#4a4b4d'
export const grey = '#636466'
export const lightGrey = '#a1a2a3'
export const lighterGrey = '#d0d1d1'
export const lightestGrey = '#eff0f0'

// MyDIYWall Brand
export const primary	= blue;
export const secondary	= yellow;
export const positive	= green;
export const negative = red