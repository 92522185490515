import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Modal, Button, Icon, Image } from 'semantic-ui-react'
import { updateElementProps } from '../../reducks/project'
import { setMediaModal } from '../../reducks/app'
import MediaLibrary from './MediaLibrary'
import { getCenteredImageCrop } from '../core/utilities'

class LibraryModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedImage: null
        }
        this.closeMediaModal.bind(this)
        this.placeImage.bind(this)
        this.handleSelection.bind(this)
    }

    closeMediaModal() {
        this.props.dispatch( setMediaModal(false) )
    }

    placeImage() {
        const { activeElement, activeElementObject, dispatch } = this.props
        const { selectedImage } = this.state
        const crop = getCenteredImageCrop(selectedImage, activeElementObject.width, activeElementObject.height)
        dispatch( updateElementProps( activeElement, { 
            image: {...selectedImage, crop}
        } ) )
        this.setState({ selectedImage: null })
        this.closeMediaModal()
    }

    handleSelection(images) {
        this.setState({ selectedImage: images.length ? images[0] : null })
    }

    render() {

        const {mediaModalOpen} = this.props
        const {selectedImage} = this.state

        return (
            <Modal open={mediaModalOpen} onClose={() => this.closeMediaModal()}>
                <Modal.Header>
                    <div className="display_flex align_items_center">
                        <span>Select an image</span>
                        <div className="ml_auto">
                            { selectedImage && <>
                                <Image size="tiny" src={selectedImage.thumbnail || selectedImage.src} verticalAlign="middle" className="mr_1 box_shadow"/>
                                <Button positive onClick={() => this.placeImage()}>Use this image <Icon name="arrow right"/></Button>
                            </>}
                            <Button basic content="Cancel" icon="delete" onClick={() => this.closeMediaModal()} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Content scrolling>
                    <MediaLibrary
                        showSelection={false}
                        maxSelection={1}
                        onChangeSelection={ this.handleSelection.bind(this) }
                        />
                </Modal.Content>
            </Modal>
        )
    }
}

LibraryModal.propTypes = {
    mediaModalOpen: PropTypes.bool,
    activeElement: PropTypes.string,
}

function mapStateToProps(state) {
    const { activeElement } = state.tools
    const activeElementObject = state.project.present.elements.find( el => el.id === activeElement )

    return {
        mediaModalOpen: state.app.mediaModalOpen,
        activeElement,
        activeElementObject
    }
}

export default connect(mapStateToProps)(LibraryModal)
