import React, {useState, useEffect} from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import Editor from './Editor'
import LoginRegister from './core/LoginRegister'
import Setup from './Setup'
import ShopArt from './ShopArt'
import '../style/main.scss'
import { getBrowserFeatures } from '../helpers'
import Masthead from './Masthead'
import Snackbar from './core/Snackbar'
import { DndProvider } from 'react-dnd'
import MouseBackEnd from 'react-dnd-mouse-backend'
// import { Responsive } from 'semantic-ui-react'
// import MobileDisclaimer from './core/MobileDisclaimer'
// import { useSelector } from 'react-redux'
// import { HTML5Backend } from 'react-dnd-html5-backend'
require('../polyfills.js')

const App = () => {

	const [appClasses, setAppClasses] = useState('')
	// const [ mobileDisclaimer, setMobileDisclaimer ] = useState(false)

	// const user = useSelector( state => state.user.meta )
	// const dispatch = store.dispatch
	
	// // check user session
	// useEffect(() => {
	// 	setInterval(() => {
	// 		UserApi.getSessionInfo().then( json => {
	// 			if ( user && json.isGuest ) {
	// 				dispatch(setUserFormMode('login'))
	// 			}
	// 		})
	// 	}, 1000 * 60)
	// }, [])

	// useEffect(() => {
    //     // mobile disclaimer
    //     if ( window.innerWidth < Responsive.onlyComputer.minWidth && getBrowserFeatures('touchevents') ) {
    //         setMobileDisclaimer(true)
    //     }
	// }, [])
	
	useEffect(() => {
		// check browser features on mount
		setAppClasses( getBrowserFeatures().join(' ') )
	}, []) // empty array tells React that your effect doesn’t depend on any values from props or state, so it never needs to re-run
	
	return (
		<BrowserRouter>
			<DndProvider backend={MouseBackEnd}>

			<div className={`App ${appClasses}`}>
				
				<Switch>
					<Route path="/designer/setup/:stepId" render={ routeProps => {
						return <>
							<Masthead {...routeProps} navigation userActions />
							<Setup {...routeProps} />
						</>
					} } />
					<Route exact path="/designer/editor/:entryId" render={ routeProps => {
						return <>
							<Masthead {...routeProps} navigation userActions addToCart />
							<Editor {...routeProps} storedProject={undefined} />
						</>
					} } />
					<Route path="/designer/shop-art" render={ routeProps => {
						return <>
							<Masthead {...routeProps} navigation userActions/>
							<ShopArt {...routeProps} />
						</>
					} } />
					{/* Default when none of the above match */}
					<Redirect to={`/designer/setup/category${window.location.search}`} />
				</Switch>

				<LoginRegister />
				<Snackbar/>
				{/* <MobileDisclaimer open={mobileDisclaimer} onClose={ () => setMobileDisclaimer(false) } /> */}

			</div>

			</DndProvider>
		</BrowserRouter>
	)

}

export default App