import {store} from './_index'

export const getState = () => {
    if ( ! store || typeof store.getState !== 'function' ) {
        return {}
    }
    return store.getState()
}

export const dispatch = (actionCreator) => store.dispatch(actionCreator)
