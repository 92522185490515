import initialState from './_initialState';
import {deepCopyObject} from '../helpers'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function setActiveElement( elementId ) {
    return { type: 'SET_ACTIVE_ELEMENT', elementId }
}

export function setMultiSelect( multiSelect ) {
    return { type: 'SET_MULTI_SELECT', multiSelect }
}

export function setActiveDrawTool( tool: String ) {
    return { type: 'SET_ACTIVE_DRAW_TOOL', tool }
}

export function setDrawToolActiveOn( elementId: String ) {
    return { type: 'SET_DRAW_TOOL_ACTIVE_ON', elementId }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function toolsReducer( state = initialState.tools, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )

    switch(action.type) {

        case 'SET_ACTIVE_ELEMENT':
            newState.activeElement = action.elementId
            break

        case 'SET_MULTI_SELECT':
            newState.multiSelect = action.multiSelect
            break

        case 'SET_ACTIVE_DRAW_TOOL':
            newState.activeDrawTool = action.tool
            break

        case 'SET_DRAW_TOOL_ACTIVE_ON':
            newState.drawToolActiveOn = action.elementId
            break

        default:
            return newState

    }

    return newState;

}
