import React, { useEffect } from 'react'
import { Header, Form, Grid, Checkbox, Input, Button } from 'semantic-ui-react'
import { setTemplateCategories, setTemplateMaxPrice } from '../../reducks/setup'
import { useDispatch, useSelector } from 'react-redux'
import Section from '../sidebar/Section'
import { doScrollRef } from '../../helpers'

const TemplateFilters = props => {
	
	// partition template types into two groups for columns
	const templateTypes = window.Craft.templateTypes.reduce( (acc, type, index) => {
		if ( index < window.Craft.templateTypes.length / 2 ) {
			acc[0].push(type)
		} else {
			acc[1].push(type)
		}
		return acc
	}, [[],[]] )
	const defaultMinPrice = 20
	const defaultMaxPrice = 300

	const {onChangeView} = props

	const templateCategories = useSelector( state => state.setup.templateCategories )
	const templateMaxPrice = useSelector( state => state.setup.templateMaxPrice )
	const dispatch = useDispatch()

	// component mounted effect
	useEffect(() => {
		// scroll up
		doScrollRef( window.document.body.parentElement, 0, 0 )
	}, [])

	const onToggleUnlimitedPrice = event => {
		if ( templateMaxPrice === 'unlimited' ) {
			dispatch( setTemplateMaxPrice(defaultMaxPrice) )
		}
			else {
				dispatch( setTemplateMaxPrice('unlimited') )
			}
	}
	
	const onToggleCategory = (category, check) => {
		if ( check ) {
			dispatch( setTemplateCategories( [category] ) )
		}
		else {
			const defaultCats = window.Craft.defaultTemplateCategories
			dispatch( setTemplateCategories( defaultCats.length ? defaultCats : [] ) )
		}
	}

	return ( 
		<Section title="Find a template">
			<Section.Content>
				<div className="mb_3">
					<Button fluid compact basic color="purple" icon="arrow left" 
						content="Back to mat borders" 
						onClick={ () => onChangeView('custom') } />
				</div>
				<Header>Filter templates:</Header>
				<Form>
					<Header size="tiny">By Category</Header>
					<Grid columns={2}>
						{ templateTypes.map( (column, col) => (
							<Grid.Column key={col}>
								{ column.map( (cat, c) => {
									const isChecked = templateCategories.find(_c => _c.id === cat.id) instanceof Object
									return (
										<Checkbox key={c} as="p" className="mb_half" label={cat.title} style={{display:'block'}}
											checked={isChecked}
											onChange={ (event, {checked}) => onToggleCategory(cat, checked) }
											/>
									)
								} ) }
							</Grid.Column>
						) ) }
					</Grid>
					<Header size="tiny">By Max. Price</Header>
					<Checkbox id="unlimitedPrice" label="Unlimited"
						className="mb_nudge"
						checked={templateMaxPrice === 'unlimited'}
						onChange={onToggleUnlimitedPrice}
						/>
					<Form.Field disabled={templateMaxPrice === 'unlimited'}>
						<div className="display_flex align_items_center">
							<span>${defaultMinPrice}&nbsp;</span>
							<Input id="templateMaxPrice" 
								className="flex_grow_1" 
								type="range" 
								name="templateMaxPrice" 
								value={templateMaxPrice}
								min={defaultMinPrice} max={defaultMaxPrice} step={20} 
								onChange={(event, {value}) => dispatch( setTemplateMaxPrice(value) )}
								/>
							<span>&nbsp;${defaultMaxPrice}</span>
						</div>
						{ templateMaxPrice !== 'unlimited' &&
							<div className="ui center aligned small header" style={{margin:0}}>${templateMaxPrice}</div>
						}
					</Form.Field>
				</Form>
				<Button 
					fluid basic					
					as="a" href="/templates"
					content="Explore All Templates" 
					icon="search" 
					className="mt_1"
					/>
			</Section.Content>
		</Section>
	)
}

export default TemplateFilters