import React, { useEffect, useState } from 'react'
import { Header, Grid, Icon, Button, Responsive } from 'semantic-ui-react'
import OpeningEditor from './OpeningEditor'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedImages } from '../../reducks/app'
import { makeOpeningFromImage } from './helpers'
import { setOpenings } from '../../reducks/setup'
import { defaultOpening } from './vars'
import { getQueryObject } from '../../helpers'
import { lighterGrey } from '../core/colors'
import WCIcon from '../core/Icon'

const Openings = props => {

	const {location} = props

	const [display, setDisplay] = useState('none')
	const [columns, setColumns] = useState(1)

	const openings = useSelector( state => state.setup.openings )
	const selectedImages = useSelector( state => state.app.selectedImages )
	const categoryId = useSelector( state => state.setup.category && state.setup.category.id )
	const dispatch = useDispatch()

	useEffect(() => {
		const { art } = getQueryObject(location.search)
		if ( art ) {
			dispatch( setSelectedImages([ JSON.parse(decodeURIComponent(art)) ]) )
		}
	}, [])

	useEffect(() => {
		let newOpenings = []
		if ( selectedImages.length > openings.length ) {
			// ADDING openings
			newOpenings = [...openings]
			const openingImageIds = openings.map( o => o.image && o.image.id ).filter(o => !!o)
			selectedImages.forEach( selectedImage => {
				if ( openingImageIds.indexOf(selectedImage.id) < 0 ) {
					newOpenings.push( makeOpeningFromImage(selectedImage) )
				}
			} )
		} 
		else {
			// REMOVING openings
			const selectedImageIds = selectedImages.map( img => img.id )
			newOpenings = openings.filter( opening => opening.image && selectedImageIds.indexOf(opening.image.id) > -1 )
		}

		dispatch(setOpenings(newOpenings))
	}, [selectedImages.length])

	useEffect(() => {
		// set display of the component
		if ( openings.length === 0 && window.innerWidth <= Responsive.onlyTablet.maxWidth ) {
			setDisplay('none')
		} else {
			setDisplay('')
		}

		// set the columns for the selected images
		setColumns(getColumnCount(openings.length, window.innerWidth))
	}, [openings.length])

	useEffect(() => {
		if ( categoryId === 'existing' && openings.length === 0 ) {
			dispatch(setOpenings([defaultOpening]))
		}
	}, [categoryId])

	function addOpening(openingData){
		let newOpening = defaultOpening
		const lastOpening = openings[openings.length - 1]
		if (openingData instanceof Object) {
			newOpening = { ...defaultOpening, ...openingData }
		} 
		else if ( lastOpening ) { 
			newOpening.width = lastOpening.width
			newOpening.height = lastOpening.height
		}
        dispatch(setOpenings(openings.concat([ newOpening ])))
    }

    function removeOpening(key){
		if ( openings[key].image ) {
			const newImages = selectedImages.filter( (image, i) => {
				return i !== key
			} )
			dispatch(setSelectedImages(newImages))
		} else {
			const newOpenings = openings.filter( (opening, o) => {
				return o !== key
			} )
			dispatch( setOpenings(newOpenings) )
		}
    }

    function updateOpening( index, newValues ) {
		const newOpenings = openings.map( (opening, o) => {
			if ( index === o ) {
                return {...opening, ...newValues}
            } else {
                return opening
            }
		} )
        dispatch(setOpenings(newOpenings))
	}

	function handleViewportUpdate(event, {width}) {
		setColumns(getColumnCount(openings.length, width))
		setDisplay( 
			width > Responsive.onlyTablet.maxWidth || openings.length > 0 ? 
			'' : 'none'
		)
	}

	function getColumnCount(numberOfOpenings, viewportWidth) {
		const columnRules = {
			"default": 4,
			"1" : 3,
			"2" : 3,
			"3" : 3,
		}
		
		let columns = columnRules.hasOwnProperty(numberOfOpenings) ? columnRules[numberOfOpenings] : columnRules.default;
		if ( viewportWidth > Responsive.onlyTablet.maxWidth ) {
			columns = Math.min(4, columns)
		} else if ( viewportWidth > Responsive.onlyMobile.maxWidth ) {
			columns = Math.min(3, columns)
		} else if ( viewportWidth > 500 ) {
			columns = Math.min(2, columns)
		} else {
			columns = Math.min(1, columns)
		}

		return columns
	}

	return (
		<Responsive className="Openings"
			fireOnMount
			onUpdate={ handleViewportUpdate }
			style={{ display }}
			>

			{ categoryId === 'upload' &&
				<Header as="h1" size="large" 
					color={ selectedImages.length === 0 ? "grey" : "blue" }
					textAlign="center" 
					className="my_3"
					content={ selectedImages.length === 0 ? 
						`Your selected images will appear here` 
						: `Choose a size for your ${selectedImages.length > 1 ? 'images' : 'image'}` 
					}
					/>
			}
			{ categoryId === 'existing' && <>
				<Header as="h1" size="large" color="blue" textAlign="center" className="mt_3 mb_0">
					<Header.Content>Select the dimensions for your item</Header.Content>
				</Header>
				<p className="mt_0 mb_3 text_center"><small>
					Choose from common document sizes or enter your own.
					<br/>Add more openings with the <Icon name="add circle" color="blue" className="mx_nudge"/> button if you have more than 1 item.
				</small></p>
			</>}

			<Grid columns={columns} relaxed centered>
				{ openings.length === 0 && 
					<Grid.Column textAlign="center">
						<WCIcon fluid name="picture" size={100} fill={lighterGrey} />
					</Grid.Column>
				}
				{openings.map( (opening, o) => (
					<Grid.Column key={o} textAlign="center">
						<OpeningEditor
							id={`opening-${o}`}
							opening={opening}
							onUpdate={ updatedProps => updateOpening( o, updatedProps ) }
							canRemove={true}
							onRemove={ () => removeOpening(o) }
							/>
						{ categoryId === 'existing' && o === openings.length - 1 &&
							<Button basic 
								size="mini" 
								color="grey" 
								className="mt_1"
								onClick={ () => addOpening() }
								>
								<Icon name="add circle" color="blue" />
								{`Add Opening`}
							</Button>
						}
					</Grid.Column>
				) )}
			</Grid>
		</Responsive>
	)
}

export default Openings