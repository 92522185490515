import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Element from './Element'
import {getItemBy} from '../../helpers'
import GraphicToolset from './GraphicToolset'

class Graphic extends Component {

    render() {

        const {
            id,
            image,
            component,
            dispatch,
            editable,
            isActive,
            lockedMode,
            parentWidth,
            parentHeight,
            projectMats,
            x,
            y,
            width,
            height,
            zoom,
            depth
        } = this.props

        console.log('graphic props', this.props)

        return  <Element
                    component={component}
                    depth={depth}
                    dispatch={dispatch}
                    editable={editable}
                    id={id}
                    isActive={isActive}
                    lockedMode={lockedMode}
                    parentHeight={parentHeight}
                    parentWidth={parentWidth}
                    projectMats={projectMats}
                    tools={<GraphicToolset/>}
                    x={x} y={y} width={width} height={height}
                    zoom={zoom}
                    >
                    <image
                        x={0}
                        y={0}
                        width="100%"
                        height="100%"
                        pointerEvents="none"
                        href={image ? image.url : ''}
                        />
                    <rect width="100%" height="100%" fill="rgba(0,0,0,0)" />
    			</Element>

    }
}

Graphic.propTypes = {

    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    image: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    editable: PropTypes.bool,
    parent: PropTypes.string,

}

function mapStateToProps(state, ownProps) {
    const parentObject = getItemBy('id', ownProps.parent, state.project.present.elements)
    return {
        parentWidth: parentObject ? parentObject.width : 0,
        parentHeight: parentObject ? parentObject.height : 0,
        projectMats: state.project.present.projectMats,
        isActive: ownProps.id === state.tools.activeElement,
        lockedMode: state.app.lockedMode
    }
}

export default connect(mapStateToProps)(Graphic)
