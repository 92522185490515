import React, { useState } from 'react'
import { Header, Button, Input, Icon, Checkbox, Form, Popup } from 'semantic-ui-react'
import LayoutSVG from './LayoutSVG'
import { useSelector, useDispatch } from 'react-redux'
import { blue, lightGrey } from '../core/colors'
import { setLayout, setBorders, setGutters, setOpenings } from '../../reducks/setup'
import Fraction from '../core/Fraction'
import Section from '../sidebar/Section'
import { useEffect } from 'react'
import { doScrollRef, nearestDivisor } from '../../helpers'

const LayoutCustomizer = props => {

	// const { onChangeView } = props

	const [allowFineTuneBorders, setAllowFineTuneBorders] = useState(false)
	const [bordersEnabled, setBordersEnabled] = useState(true)
	const [openingsAligned, setOpeningsAligned] = useState(false)
	const [previousOpenings, setPreviousOpenings] = useState(null)

	const {borders, openings, layout, gutters} = useSelector( state => state.setup )
	const dispatch = useDispatch()

	// component mounted effect
	useEffect(() => {
		// scroll up
		doScrollRef( window.document.body.parentElement, 0, 0 )
	}, [])

	const onChangeBorder = (event, data) => {
		const newBorders = {[data.name]: data.value}
		dispatch(
			setBorders({ ...borders, ...newBorders })
		)
	}

	const onChangeBorders = (event, data) => {
		dispatch(
			setBorders({ top: data.value, side: data.value, bottom: data.value })
		)
	}
		
	const disableBorders = () => {
		dispatch(
			setBorders({ top: 0, side: 0, bottom: 0 })
		)
	}
	
	const enableBorders = () => {
		dispatch(
			setBorders({ top: 3, side: 3, bottom: 3 })
		)
	}

	const onToggleBorders = (event, data) => {
		if ( data.checked ) {
			setBordersEnabled(true)
			enableBorders()
		} else {
			setBordersEnabled(false)
			disableBorders()
		}
	}

	// const templateButton = (
	// 	<Button content="Design using a template"
	// 		fluid basic
	// 		color="purple" 
	// 		icon="lightbulb" 
	// 		onClick={ () => onChangeView('template') } 
	// 		/>
	// )

	const alignOpenings = () => {
		setOpeningsAligned(true)
		setPreviousOpenings(openings)
		
		const minHeight = openings.map( o => o.height ).sort((a,b) => a - b)[0]
		const minWidth = openings.map( o => o.width ).sort((a,b) => a - b)[0]

		const newOpenings = openings.map( o => {
			const ratio = o.height / o.width
			let newWidth, newHeight
			if ( layout === 'horizontal' ) {
				newWidth = minHeight / ratio
				newHeight = minHeight
			}
			else if ( layout === 'vertical' ) {
				newWidth = minWidth
				newHeight = minWidth * ratio
			} else {
				newWidth = openings[0].width
				newHeight = openings[0].height
			}
			return {
				...o, 
				width: nearestDivisor( newWidth, 0.125 ),
				height: nearestDivisor( newHeight, 0.125 )
			}
		} )
		
		dispatch( setOpenings(newOpenings) )
	}
	
	const unAlignOpenings = () => {
		setOpeningsAligned(false)
		dispatch( setOpenings(previousOpenings) )
		setPreviousOpenings(null)
	}

	const changeLayout = (layoutOption) => {
		if ( openingsAligned ) {
			unAlignOpenings()
		}
		dispatch( setLayout(layoutOption) ) 
	}

	return ( 
		<Section title={ openings.length > 1 ? "Choose layout & mat options" : "Choose mat options" }>
			<Section.Content>
				{ openings.length > 1 && <>
					<Header>Layout</Header>
					<Button.Group fluid basic widths={openings.length < 3 ? 2 : 3}>
						<Button
							active={layout === 'horizontal'}
							onClick={ () => changeLayout('horizontal') } >
							<LayoutSVG.Horz className="ui tiny centered image mb_half" fill={ layout === 'horizontal' ? blue : lightGrey } />
							<br/>{`Horiz.`}
						</Button>
						<Button
							active={layout === 'vertical'}
							onClick={ () => changeLayout('vertical') } >
							<LayoutSVG.Vert className="ui tiny centered image mb_half" fill={ layout === 'vertical' ? blue : lightGrey } />
							<br/>{`Vert.`}
						</Button>
						{ openings.length > 2 &&
							<Button
								active={layout === 'grid'}
								onClick={ () => changeLayout('grid') } >
								<LayoutSVG.Grid className="ui tiny centered image mb_half" fill={ layout === 'grid' ? blue : lightGrey } />
								<br/>{`Grid`}
							</Button>
						}
					</Button.Group>
					<Popup inverted
						trigger={
							<Button basic size="tiny" className="mt_half mb_3" 
								color={ openingsAligned ? "red" : undefined }
								icon={ openingsAligned ? "undo" : "th" }
								content={ openingsAligned ? "Undo align openings" : "Align openings" }
								onClick={ openingsAligned ? unAlignOpenings : alignOpenings }
								/>
						}
						content={ openingsAligned ? "Reset openings back to their original size" : "Make all openings the same size so they align together neatly."}
						/>
				</>}

				<Form className="mb_3">
					<Header className="mb_0">
						{ bordersEnabled ? 
							( allowFineTuneBorders ? 
								`Mat Borders: custom`
								: <>{`Mat Borders: `}<Fraction float={borders.top}/>&rdquo;</>
							)
							:
							`No matboard`
						}
					</Header>
					<Input fluid type="range" 
						min={2} max={8} step={0.5} 
						value={borders.top}
						disabled={ ! bordersEnabled || allowFineTuneBorders }
						onChange={onChangeBorders}
						/>
					
					<p className={`mt_0 ${ ! bordersEnabled || allowFineTuneBorders ? 'opacity_3' : 'faded'}`}><small><Icon name="caret left" /><span>drag to adjust</span><Icon name="caret right" /></small></p>
					
					{ openings.length > 1 && <>
						<Header className="mb_0">
							Space between images:{' '}<Fraction float={gutters}/>&rdquo;
						</Header>
						<Input fluid type="range" 
							min={1} max={4} step={0.5} 
							value={gutters}
							onChange={(event, data) => dispatch(setGutters(data.value))}
							/>
						<p className={`mt_0 faded`}><small><Icon name="caret left" /><span>drag to adjust</span><Icon name="caret right" /></small></p>
					</> }


					<Form.Group widths={2} className="mt_3">
						<Form.Field>
							<Checkbox checked={bordersEnabled} 
								label="Include matboard" 
								onChange={onToggleBorders} 
								disabled={openings.length > 1}
								/>
						</Form.Field>
						<Form.Field>
							<Checkbox checked={allowFineTuneBorders} 
								label="Custom borders" 
								onChange={ (event, {checked}) => setAllowFineTuneBorders(checked) } 
								disabled={ ! bordersEnabled}
								/>
						</Form.Field>
					</Form.Group>

					{ allowFineTuneBorders && 
					<Form.Group widths={3}>
						<Form.Field disabled={ ! bordersEnabled}>
							<Input id="border-top-width" 
								type="number"
								min={2} max={10} step={0.125}
								name="top"
								value={borders.top}
								onChange={onChangeBorder}
								/>
							<label htmlFor="border-top-width">Top</label>
						</Form.Field>
						<Form.Field  disabled={ ! bordersEnabled}>
							<Input id="border-sides-width" 
								type="number"
								min={2} max={10} step={0.125}
								name="side"
								value={borders.side}
								onChange={onChangeBorder}
								/>
							<label htmlFor="border-sides-width">Sides</label>
						</Form.Field>
						<Form.Field disabled={ ! bordersEnabled}>
							<Input id="border-bottom-width" 
								type="number"
								min={2} max={10} step={0.125}
								name="bottom"
								value={borders.bottom}
								onChange={onChangeBorder}
								/>
							<label htmlFor="border-bottom-width">Bottom</label>
						</Form.Field>
					</Form.Group>
					}
				</Form>

				{/* <div className="my_3">
					<Popup trigger={templateButton} size="small">
						<p><strong className="color_purple">Templates</strong> are professionally designed mats that come in a variety of layouts and themes. <strong>Click to see more!</strong></p>
						<Image fluid src={`${window.Craft.baseUrl}/assets/images/templates.jpg`} />
					</Popup>
				</div> */}

			</Section.Content>
		</Section>
	)
}

export default LayoutCustomizer