import React from 'react';
import LibraryImage from './LibraryImage';
import { Button } from 'semantic-ui-react';
import { getItemBy } from '../../helpers';

const MediaList = ({media, columns, inline, visibleCount, onLoadMore, onSelectImage, draggable, selectedImages, manageMode}) => {

	return (
		<div className={`display_flex align_items_center ${ inline ? 'flex_no_wrap overflow_auto' : 'flex_wrap' }`}>
			{ media.map( (upload, i) => {
				
				const isSelected = getItemBy( 'id', upload.id, selectedImages ) instanceof Object
				// control how many images are visible, helps with load times
				
				return i < visibleCount && (
					<LibraryImage
						key={upload.id +'-'+ i}
						image={upload}
						draggable={draggable}
						style={{ width: `calc(${100 / columns}% - 1rem)`, margin: '0.5rem', minWidth: inline ? 150 : 0 }}
						showCheckbox={manageMode || isSelected }
						selected={ isSelected }
						onSelect={onSelectImage} />
				)
				
			} ) }
			{ visibleCount < media.length &&
				<Button basic className={ inline ? "ma_1" : "my_1 mx_auto"} icon="plus" content="Load More"
					style={{minWidth:150}}
					onClick={onLoadMore} />
			}
		</div>
	);

}

export default MediaList