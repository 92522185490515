import React from 'react'

export default (props) => {
    const {className} = props

    return (
        <svg className={"MatGraphic position_relative " + className}
            style={props.style}
            
            viewBox="0 0 330 129"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            >
            <defs>
                <polygon id={`mat-${props.id}-path`} points="176 20 167 0 248.753906 0 292.273438 89 8.52651283e-14 89 8.52651283e-14 20"></polygon>
                <filter x="-12.0%" y="-28.1%" width="124.0%" height="178.7%" filterUnits="objectBoundingBox" id={`mat-${props.id}-filter`}>
                    <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g>
                <use fill="black" fillOpacity="1" filter={`url(#mat-${props.id}-filter)`} xlinkHref={`#mat-${props.id}-path`}></use>
                <use fill={props.color} fillRule="evenodd" xlinkHref={`#mat-${props.id}-path`}></use>
            </g>
            <rect fill={props.core} x="0" y="89" width="292" height="5"></rect>
            <polygon fill={props.core} points="167 7.0033769 167 0 176 20 173.002035 20"></polygon>
            <path d="M167,0 L176,20" strokeOpacity="0.1" stroke="#000000"></path>
            <path d="M0,89.5 L292,89.5" strokeOpacity="0.1" stroke="#000000"></path>
        </svg>
    )
}
