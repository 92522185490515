import initialState from './_initialState';
import {deepCopyObject} from '../helpers'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function setCategory( category ) {
    return { type: 'SET_CATEGORY', category }
}

export function setOpenings( openings ) {
    return { type: 'SET_OPENINGS', openings }
}

export function setFrameShops( frameShops ) {
    return { type: 'SET_FRAME_SHOPS', frameShops }
}

export function setLayoutView( view ) {
    return { type: 'SET_LAYOUT_VIEW', view }
}

export function setLayout( layout ) {
    return { type: 'SET_LAYOUT', layout }
}

export function setBorders( borders ) {
    return { type: 'SET_BORDERS', borders }
}

export function setGutters( gutters ) {
    return { type: 'SET_GUTTERS', gutters }
}

export function setTemplates( templates ) {
    return { type: 'SET_TEMPLATES', templates }
}

export function setSelectedTemplate( template ) {
    return { type: 'SET_SELECTED_TEMPLATE', template }
}

export function setTemplatesPagination( data ) {
    return { type: 'SET_TEMPLATES_PAGINATION', data }
}

export function setTemplateCategories( cats ) {
    return { type: 'SET_TEMPLATE_CATEGORIES', cats }
}

export function setTemplateMaxPrice( price ) {
    return { type: 'SET_TEMPLATE_MAX_PRICE', price }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function setupReducer( state = initialState.setup, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )

    switch(action.type) {

        case 'SET_CATEGORY':
            newState.category = action.category
            break

        case 'SET_OPENINGS':
            newState.openings = action.openings
            break
        
        case 'SET_FRAME_SHOPS':
            newState.frameShops = action.frameShops
            break
        
        case 'SET_LAYOUT_VIEW':
            newState.layoutView = action.view
            break
        
        case 'SET_LAYOUT':
            newState.layout = action.layout
            break
        
        case 'SET_BORDERS':
            newState.borders = action.borders
            break
        
        case 'SET_GUTTERS':
            newState.gutters = action.gutters
            break
        
        case 'SET_TEMPLATES':
            newState.templates = action.templates
            break
        
        case 'SET_SELECTED_TEMPLATE':
            newState.selectedTemplate = action.template
            break
        
        case 'SET_TEMPLATES_PAGINATION':
            newState.templatesPagination = action.data
            break
                
        case 'SET_TEMPLATE_CATEGORIES':
            newState.templateCategories = action.cats
            break
                    
        case 'SET_TEMPLATE_MAX_PRICE':
            newState.templateMaxPrice = action.price
            break
        
        default:
            return newState

    }

    return newState;
}
