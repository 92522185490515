import React from 'react'
import { Image, Header, Icon } from 'semantic-ui-react'
import Fraction from '../core/Fraction'

const ImageCropPreview = (props) => {

	const {imageSrc, lockAspectRatio, openingRatio, opening, label, className, warning} = props

	return (<>
		<Image fluid className={`ImageCropPreview position_relative bg_primary color_primary box_shadow overflow_hidden ${className}`} 
			style={{ paddingTop: openingRatio * 100 + '%' }}
			>
			{/* <img src={imageSrc} alt="opening preview" className="position_absolute top_0 left_0"/> */}
			{ ! lockAspectRatio &&
				<div className="position_absolute top_0 right_0 bottom_0 left_0 display_flex align_items_center justify_content_center"
						style={{backgroundColor:"rgba(0,0,0,0.6)"}}>
					<svg viewBox={`0 0 100 ${openingRatio * 100}`}
							className="bg_cover bg_center color_white"
							style={{backgroundImage:`url(${imageSrc})`, width:'100%', height:'100%'}}>
							<rect x="0" y="0" width="100" height={openingRatio * 100} fill="none"/>
						</svg>
				</div>
			}
			{ label &&
				<Header className={`${ warning ? 'red' : 'black' } bg_white position_absolute transform_center white_space_no_wrap mt_0 pa_nudge border_rounded`} 
					>
					{ warning && <span><Icon name="exclamation circle"/></span> }
					<Fraction float={opening.width} /> &times; <Fraction float={opening.height} /> &rdquo;
				</Header>
			}
		</Image>
	</>)

}

export default ImageCropPreview