import React from 'react'

const Sidebar = props => {
	const { className, children } = props
	return (
		<div className={`Sidebar ${className || ''}`}>
			{children}
		</div>
	)
}

Sidebar.Content = props => {
	const { className, children } = props
	return (
		<div className={`StepContent ${className || ''}`}>
			{children}
		</div>
	)
}

Sidebar.Steps = props => {
	const { className, children } = props
	return (
		<div className={`Steps ${className || ''}`}>{children}</div>
	)
}

Sidebar.Navigation = props => {
	const { className, children } = props
	return (
		<div className={`Sidebar__navigation ${className || ''}`}>{children}</div>
	)
}

export default Sidebar


