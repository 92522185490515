import initialState from './_initialState';
import {deepCopyObject} from '../helpers'
import UserApi from '../api/userApi'
import {getItemBy} from '../helpers'
// import { normalizeImageData } from '../components/core/utilities'

/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function updateUser( user ) {
    return { type: 'UPDATE_USER', user }
}

export function setUserLocation( location ) {
    return { type: 'SET_USER_LOCATION', location }
}

export function loadUserProjects(page, callback) {
    return function(dispatch) {
        dispatch( setLoadingProjects(true) )
        return UserApi.getUserProjects(page)
            .then( json => {
                if ( json.error ) {
                    console.log(json.error.message)
                    console.trace()
                }
                else {
                    dispatch( loadUserProjectsSuccess(json.data) )
                }
                if ( typeof callback === 'function' ) {
                    callback( json )
                }
                dispatch( setLoadingProjects(false) )
            } )
    }
}

export function loadUserTemplates(page, callback) {
    return function(dispatch) {
        return UserApi.getUserTemplates(page)
            .then( json => {
                if ( json.error ) {
                    console.log(json.error.message)
                    console.trace()
                }
                else {
                    dispatch( loadUserTemplatesSuccess(json.data) );
                }
                if ( typeof callback === 'function' ) {
                    callback( json )
                }
            } )
    }
}

export function setLoadingProjects( value ) {
    return { type: 'SET_LOADING_PROJECTS', value }
}

export function loadUserProjectsSuccess( projects ) {
    return { type: 'LOAD_USER_PROJECTS_SUCCESS', projects }
}

export function loadUserTemplatesSuccess( templates ) {
    return { type: 'LOAD_USER_TEMPLATES_SUCCESS', templates }
}

export function updateUserEntry(entryType, data) {
    return { type: 'SAVE_USER_ENTRY', entryType, data }
}

export function removeUserEntry( entryType, id) {
    return { type: 'REMOVE_USER_ENTRY', entryType, id }
}

export function updateUserMedia( items ) {
    return { type: 'UPDATE_MEDIA', items }
}

export function updateUserArt( items ) {
    return { type: 'UPDATE_ART', items }
}

export function loadUserMedia(user, callback) {
    return function(dispatch) {
        return UserApi.getUserMedia(user)
            .then( json => {
                if ( json.error ) {
                    console.error(json.error.message)
                    console.trace()
                }
                else {
                    dispatch( updateUserMedia(json.resources) )
                }
                if ( typeof callback === 'function' ) {
                    callback( json.error && json.error.message )
                }
            } )
    }
}

export function loadUserArt(user, callback) {
    return function(dispatch) {
        return UserApi.getUserArt(user)
            .then( json => {
                if ( json.error ) {
                    console.error(json.error.message)
                    console.trace()
                }
                else {
                    dispatch( updateUserArt(json.art) )
                }
                if ( typeof callback === 'function' ) {
                    callback( json.error && json.error.message )
                }
            } )
    }
}

export function addUserMedia( items ) {
    return { type: 'ADD_MEDIA', items }
}

export function addArtImage( image ) {
    return { type: 'ADD_ART_IMAGE', image }
}

export function removeArtImage( idsToDelete ) {
    return { type: 'REMOVE_ART_IMAGE', idsToDelete }
}

export function setFetchUploads( fetch ) {
    return { type: 'SET_FETCH_UPLOADS', fetch }
}

export function setFetchArt( fetch ) {
    return { type: 'SET_FETCH_ART', fetch }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function userReducer( state = initialState.user, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state ),
        newArtImages

    switch(action.type) {

        case 'UPDATE_USER':
            newState.meta = action.user
            break
        
        case 'SET_USER_LOCATION':
            newState.location = action.location
            break

        case 'SET_LOADING_PROJECTS':
            newState.loadingProjects = action.value
            break;

        case 'LOAD_USER_PROJECTS_SUCCESS':
            newState.projects = newState.projects.concat(action.projects)
            break

        case 'LOAD_USER_TEMPLATES_SUCCESS':
            newState.projectTemplates = newState.projectTemplates.concat(action.templates)
            break

        case 'SAVE_USER_ENTRY':
            let newEntries = deepCopyObject( newState[action.entryType] )
            let existingEntry = getItemBy( 'id', action.data.id, newEntries, 'withKey' )
            if ( existingEntry.item ) {
                newEntries[existingEntry.key] = action.data
            }
            else {
                newEntries.push( action.data )
            }
            newState[action.entryType] = newEntries
            break

        case 'REMOVE_USER_ENTRY':
            newState[action.entryType] = newState[action.entryType].filter( entry => {
                return entry.id !== action.id
            } )
            break

        case 'UPDATE_MEDIA':
            newState.uploads = action.items
            break

        case 'UPDATE_ART':
            newState.art = action.items
            break

        case 'ADD_MEDIA':
            var newArray = deepCopyObject( newState.uploads )
            newArray = newArray.concat( action.items )
            newState.uploads = newArray
            break

        case 'ADD_ART_IMAGE':
            newArtImages = deepCopyObject( newState.art )
            newArtImages.push( action.image )
            newState.art = newArtImages
            break

        case 'REMOVE_ART_IMAGE':
            newArtImages = deepCopyObject( newState.art )
            var idsToDeleteArray = typeof action.idsToDelete === 'object' ? action.idsToDelete : [action.idsToDelete]
            newArtImages = newArtImages.filter( image => idsToDeleteArray.indexOf( image.image_id ) < 0 )
            newState.art = newArtImages
            break
            
        case 'SET_FETCH_UPLOADS':
            newState.fetchUploads = action.fetch
            break
        
        case 'SET_FETCH_ART':
            newState.fetchArt = action.fetch
            break

        default:
            return newState

    }

    return newState;

}
