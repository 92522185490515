import initialState from './_initialState'
import ResourcesApi from '../api/resourcesApi'
import {storageAvailable, getItemIndexBy, deepCopyObject} from '../helpers'
import { generatePreview } from '../components/core/utilities'

/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function updateProject(data) {
    return { type: 'UPDATE_PROJECT', data }
}

export function setProjectThumbnail(url) {
    return { type: 'SET_PROJECT_THUMBNAIL', url }
}

export function setAsTemplate(isTemplate) {
    return { type: 'SET_AS_TEMPLATE', isTemplate }
}

export function setFromTemplate(fromTemplate) {
    return { type: 'SET_FROM_TEMPLATE', fromTemplate }
}

export function setProjectTitle( title ) {
    return { type: 'SET_PROJECT_TITLE', title }
}

export function setProjectDescription( description ) {
    return { type: 'SET_PROJECT_DESCRIPTION', description }
}

export function addElement( element ) {
    return { type: 'ADD_ELEMENT', element }
}

export function addElements( elements ) {
    return { type: 'ADD_ELEMENTS', elements }
}

export function removeElement( elementId ) {
    return { type: 'REMOVE_ELEMENT', elementId }
}

export function removeAllElements() {
    return { type: 'REMOVE_ALL_ELEMENTS' }
}

export function updateElementProps( elementId, newValues ) {
    return { type: 'UPDATE_ELEMENT_PROPS', elementId, newValues, undoable: true }
}

export function addMat(mat, newElements) {
    return { type: 'ADD_MAT', mat, newElements }
}

export function setMats(matCount) {
    return { type: 'SET_MATS', matCount }
}

export function setMatsArray(matsArray) {
    return { type: 'SET_MATS_ARRAY', matsArray }
}

export function removeMat(key) {
    return { type: 'REMOVE_MAT', key }
}

export function removeAllMats() {
    return { type: 'REMOVE_ALL_MATS' }
}

export function updateMat(matKey, matObject) {
    return { type: 'UPDATE_MAT', matKey, matObject }
}

export function setGlass(glass) {
    return { type: 'SET_GLASS', glass }
}

export function setPaper(paper) {
    return { type: 'SET_PAPER', paper }
}

export function setAssembly(assembly) {
    return { type: 'SET_ASSEMBLY', assembly }
}

export function setMounting(mounting) {
    return { type: 'SET_MOUNTING', mounting }
}

export function setPrinting(printing) {
    return { type: 'SET_PRINTING', printing }
}

export function setObjectsProvided(objectsProvided) {
    return { type: 'SET_OBJECTS_PROVIDED', objectsProvided }
}

export function setObjectsDescription(objectsDescription) {
    return { type: 'SET_OBJECTS_DESCRIPTION', objectsDescription }
}

export function updateDefaultReveal(defaultReveal) {
    return { type: 'UPDATE_DEFAULT_REVEAL', defaultReveal }
}

export function loadDefaultFrame(callback) {
    return function(dispatch) {
        return ResourcesApi.getDefaultFrame()
            .then( json => {
                if ( json.error ) {
                    console.error(json.error.message)
                    console.trace()
                }
                else {
                    dispatch( updateFrame(json) )
                }
                if ( typeof callback === 'function' ) {
                    callback( json, json.error && json.error.message )
                }
            } )
    }
}

export function updateFrame(frame) {
    return { type: 'UPDATE_FRAME', frame, undoable: true }
}

export function updateDimensions( {width, height} ) {
    return { type: 'UPDATE_DIMENSIONS', width, height }
}

export function getProjectPreview(callback) {
    return async function(dispatch){ 

        const dataUrl = await generatePreview()
        dispatch( setProjectThumbnail( dataUrl ) )

        if ( typeof callback === 'function' ) {
            callback();
        }

        return dataUrl

    }
}



/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function projectReducer( state = initialState.project, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )
    let newElements

    switch(action.type) {

        case 'UPDATE_PROJECT':
            newState = Object.assign({}, newState, action.data)
            break;

        case 'SET_PROJECT_THUMBNAIL':
            newState.thumbnail = action.url
            break;

        case 'SET_AS_TEMPLATE':
            newState.type = action.isTemplate ? 'projectTemplates' : 'projects'
            break;
        
        case 'SET_FROM_TEMPLATE':
            newState.fromTemplate = action.fromTemplate
            break;

        case 'SET_PROJECT_TITLE':
            newState.title = action.title
            break

        case 'SET_PROJECT_DESCRIPTION':
            newState.description = action.description
            break

        case 'ADD_ELEMENT':
            newElements = deepCopyObject( newState.elements )
            newElements.push( action.element )
            newState.elements = newElements
            break

        case 'ADD_ELEMENTS':
            newState.elements = newState.elements.concat(action.elements)
            break

        case 'REMOVE_ELEMENT':
            newElements = deepCopyObject( newState.elements )
            newElements = newElements.filter( (el) => {
                return el.id !== action.elementId
            } )
            newState.elements = newElements
            break

        case 'REMOVE_ALL_ELEMENTS':
            newElements = deepCopyObject( newState.elements )
            newElements = newElements.filter( (el) => {
                return el.id === 'source-opening'
            } )
            newState.elements = newElements
            break

        case 'UPDATE_ELEMENT_PROPS':
            // get index of element
            var index = getItemIndexBy('id', action.elementId, newState.elements)
            if ( newState.elements[index] ) {
                // make copies
                newElements = [...newState.elements]
                var newElement = {...newElements[index]}
                // update values for object at [index]
                newElements[index] = {...newElement, ...action.newValues}
                // update elements array
                newState.elements = newElements
            }
            // also update artboard dimensions if this is the source-opening and specifying a dimension property
            if ( action.elementId === 'source-opening' ) {
                if ( action.newValues.hasOwnProperty('width') ) {
                    newState.artboardwidth = action.newValues.width
                }
                if ( action.newValues.hasOwnProperty('height') ) {
                    newState.artboardheight = action.newValues.height
                }
            }
            break;

        case 'ADD_MAT':
            let matObject = typeof action.mat === 'object' ? action.mat : newState.projectMats[0]
            newState.projectMats = [matObject, ...newState.projectMats]
            newState.elements = action.newElements
            break

        case 'SET_MATS':
            var matsArray = [],
                matCount = parseInt(action.matCount)

            for ( let i = 0; i < matCount; i++ ) {
                matsArray[i] = newState.projectMats[0]
            }
            newState.projectMats = matsArray
            break

        case 'SET_MATS_ARRAY':
            newState.projectMats = action.matsArray
            break

        case 'REMOVE_MAT':
            newState.projectMats = newState.projectMats.filter( (mat,i) => i !== action.key )
            break

        case 'REMOVE_ALL_MATS':
            newState.projectMats = []
            break

        case 'UPDATE_MAT':
            var newMats = deepCopyObject( newState.projectMats )
            newMats[action.matKey] = action.matObject
            newState.projectMats = newMats
            break

        case 'SET_GLASS':
            newState.selectedGlass = action.glass
            break

        case 'SET_PAPER':
            newState.selectedPaper = action.paper
            break

        case 'SET_ASSEMBLY':
            newState.assembly = action.assembly
            break

        case 'SET_MOUNTING':
            newState.mounting = action.mounting
            break

        case 'SET_PRINTING':
            newState.imagePrinting = action.printing
            break
        
        case 'SET_OBJECTS_PROVIDED':
            newState.objectsProvided = action.objectsProvided
            break

        case 'SET_OBJECTS_DESCRIPTION':
            newState.objectsDescription = action.objectsDescription
            break

        case 'UPDATE_DEFAULT_REVEAL':
            newState.defaultReveal = action.defaultReveal
            break

        case 'UPDATE_FRAME':
            newState.activeFrame = action.frame
            break;

        case 'UPDATE_DIMENSIONS':
            newState.artboardwidth = action.width
            newState.artboardheight = action.height
            break;

        default:
            return newState

    }

    newState.dateUpdated = new Date().toISOString()

    if ( storageAvailable ) {
        window.localStorage.setItem(`wallcore_project`, JSON.stringify(newState))
    }

    return newState

}

// const undoableProjectReducer = undoable(projectReducer, {
//     limit: 5,
//     debug: true
// })
//
// export default undoableProjectReducer
