import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import ComposedElements from './ComposedElements'
import Frame from './Frame'
import Grid from './Grid'
import {PPI} from '../../data/constants'
import RulersBasic from './RulersBasic'
import RulersSides from './RulersSides'

const Canvas = props => {

    const { 
        artboardwidth, 
        artboardheight, 
        zoom, 
        showGrid, 
        showFrame, 
        showRulers, 
        activeFrame, 
        updating,
        lockedMode
    } = props
    const rabbet = activeFrame ? 0.25 * PPI : 0
    const faceWidth = activeFrame ? activeFrame.faceWidth * PPI : 0
    const outsideFace = showFrame ? faceWidth - rabbet : 0
    const totalWidth = artboardwidth + outsideFace*2
    const totalHeight = artboardheight + outsideFace*2
    const style = {
        width: totalWidth * zoom,
        height: totalHeight * zoom,
        pointerEvents: updating ? 'none' : 'auto',
    }

    return ( <div id="Canvas" style={style} className="position_relative"
        onKeyDown={ props.onKeyDown }
        onKeyUp={ props.onKeyUp }
        onBlur={ props.onBlur }
        onFocus={ props.onFocus }
        tabIndex="0">

        <svg id="Canvas_SVG" version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox={`0 0 ${totalWidth} ${totalHeight}`}
            width={totalWidth * zoom} height={totalHeight * zoom}
            style={{display:'block'}}
            >
            <rect
                x={outsideFace} y={outsideFace}
                width={artboardwidth}
                height={artboardheight}
                fill="white"
            />
            <svg x={outsideFace} y={outsideFace} width={artboardwidth} height={artboardheight}>
                <ComposedElements zoom={zoom} />
            </svg>
            <Frame width={totalWidth} height={totalHeight} data={lockedMode || showFrame ? activeFrame : null} />
        </svg>

        { ! lockedMode && showGrid &&
            <svg id="Grid" version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox={`0 0 ${totalWidth} ${totalHeight}`}
                width={totalWidth * zoom} height={totalHeight * zoom}
                className="position_absolute"
                pointerEvents="none"
                style={{ pointerEvents:'none', top: outsideFace * zoom, left: outsideFace * zoom }}
                >
                <foreignObject x={0} y={0} width={artboardwidth} height={artboardheight} pointerEvents="none" style={{overflow:'visible'}} >
                    <Grid />
                </foreignObject>
            </svg>
        }

        <Dimmer active={updating} inverted>
            <Loader size="massive"/>
        </Dimmer>

        { ! lockedMode && showRulers ?
            <RulersSides
                artboardwidth={artboardwidth}
                artboardheight={artboardheight}
                zoom={zoom}
                offset={outsideFace + 50}
                />
            :
            <RulersBasic
                dimensions={{
                    inner: { width: artboardwidth, height: artboardheight},
                    outer: { width: totalWidth, height: totalHeight }
                }}
                />
        }

    </div>)
    
}

export default Canvas
