import {get} from './fetchKit'

/**
 * API for querying entries from Craft via Element API routes
 */

const endpoints = {
    cutArt:         "/api/cutart-products",
    frameProducts:  "/api/frame-products",
    glass:          "/api/glass-products",
    matProducts:    "/api/mat-products",
    paper:          "/api/paper-products",
    graphics:       "/api/graphics-products",
    fonts:          "/assets/mockFontsData",
}

export default class ResourcesApi {

    static getResource( handle, id ) {

        let endpoint = endpoints[ handle ]
        if ( id ) {
            endpoint += '/' + id
        }
        return get( `${endpoint}.json` )

    }

}
