import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import logger from './middleware/logger'
import app from './app'
import cart from './cart'
import project from './project'
import resources from './resources'
import tools from './tools'
import user from './user'
import setup from './setup'
import price from './price'
import undoable from './undoable'

const rootReducer = combineReducers({
    app,
    cart,
    project: undoable(project, {limit: 20}),
    resources,
    tools,
    user,
    setup,
    price
});

export const store = createStore(
    rootReducer,
    applyMiddleware( thunk, logger )
);
