import React from 'react'
import {PPI} from '../../data/constants'
import Fraction from '../core/Fraction'
import { Popup, Header, Divider, Icon } from 'semantic-ui-react'

const Rulers = ({dimensions}) => {

    const widthInches = dimensions.outer.width / PPI
    const heightInches = dimensions.outer.height / PPI
    const rulersStyle = {
        position: 'absolute',
        top: -40,
        left: 0,
        width: '100%',
    }

    return (
        <div id="RulersBasic" style={rulersStyle}>
            <div className="ui small grey center aligned header my_0">
                <Fraction float={ widthInches } /> &times; <Fraction float={ heightInches } />
                <Popup inverted 
                    trigger={<span><Icon name="info circle" className="ml_nudge mr_0" /></span>} 
                    on="hover" 
                    position="bottom center"
                    >
                    <Header inverted>Project Dimensions:</Header>
                    <Divider inverted />
                    <p><strong>Outer: <Fraction float={dimensions.outer.width/PPI} /> &times; <Fraction float={dimensions.outer.height/PPI} /></strong>
                    <br/>Refers to the size of the project around the <em>outside</em> of the frame.</p>
                    <p><strong>Inner: <Fraction float={dimensions.inner.width/PPI} /> &times; <Fraction float={dimensions.inner.height/PPI} /></strong>
                    <br/>Refers to the size of the mat or artwork that fits <em>inside</em> the frame.</p>
                </Popup>
            </div>
            <div className="border_light_grey" style={{height:8, borderBottom:0}}></div>
        </div>
    )

}

export default Rulers
