import { deepCopyObject, sortDESC, nearestDivisor } from  '../../helpers'
import {PPI, LOW_RES_PRINT_PPI, MED_RES_PRINT_PPI, HI_RES_PRINT_PPI, CLOUDINARY_BASE_URL, EIGHTH_IN_PX} from '../../data/constants'
import { dispatch, getState } from '../../reducks/_utilities'
import canvg from 'canvg'
import CloudinaryApi from '../../api/cloudinaryApi'
import { createOpening, getTextNodes, getShape } from '../elements/element-helpers'
import { storageAvailable } from '../../helpers'
import { addSnack, dismissSnack } from '../../reducks/app'
import EntriesApi from '../../api/entriesApi'
import { updateUserEntry } from '../../reducks/user'
import { updateProject } from '../../reducks/project'
import {changeDpiDataUrl} from 'changedpi'

export const layerHighlightColors = ["#00d9e1", "#0068e1", "#0800e1", "#3200a2"]

export const matLabels = {
    0: {
        0: "No Mats"
    },
    1: {
        0: "Single Mat"
    },
    2: {
        0: "Top Mat",
        1: "Bottom Mat",
    },
    3: {
        0: "Top Mat",
        1: "Middle Mat",
        2: "Bottom Mat",
    },
}

export const setPublished = (value) => {
    
    const project = getState().project.present
    const sectionId = window.Craft.sectionIds[project.type]

    dispatch( addSnack({
        id : 'publishing-snack',
        message: value ? "Publishing template..." : "UN-Publishing template",
        loading: true,
    }) )

    EntriesApi.saveEntry( sectionId, { 
            entryId: project.id,
            "fields[published]": value
        })
        .then( json => {
            dispatch( dismissSnack('publishing-snack') )
            if ( json.success ) {
                dispatch( updateProject({ published: value }) )
            }
        })
}

/**
 * Get the bottom openings from an array of project elements
 * @param {Object} project | the project object
 */
export const getBottomOpenings = (project) => {
    return project.elements.filter( elem => elem.depth === project.projectMats.length )
}

/**
 * Get the existing top & left borders from a given set of elements
 * @param {Array} elements | a project elements array
 */
export const getCurrentBorders = (elements, artboardwidth, artboardheight) => {
    // We want to grab the TOP, RIGHT, BOTTOM, and LEFT -most elements on the top mat 
    // to use as a reference for the current border widths.
    
    // First, only get the elements on the top mat
    let topMatElements = elements.filter( el => el.depth === 1 )
    
    // loop through the elements and accumulate the border measurements
    const borders = topMatElements.reduce( (acc,elem) => {
        const top = elem.y
        const right = artboardwidth - (elem.x + elem.width)
        const bottom = artboardheight - (elem.y + elem.height)
        const left = elem.x
        acc.top = acc.top ? Math.min(acc.top, top) : top
        acc.right = acc.right ? Math.min(acc.right, right) : right
        acc.bottom = acc.bottom ? Math.min(acc.bottom, bottom) : bottom
        acc.left = acc.left ? Math.min(acc.left, left) : left
        return acc
    }, {})

    // // Next, sort the top mat elements by their x and y values
    // topMatElements.sort( (a,b) => {
    //     // first, sort by x value
    //     if ( a.x > b.x ) return 1; 
    //     if ( a.x < b.x ) return -1; 
    //     // then, sort by y value
    //     if ( a.y > b.y ) return 1; 
    //     if ( a.y < b.y ) return -1; 

    //     return 0
    // } )

    // return the x and y values from the first element in the array
    return {
        // top: topMatElements.length ? topMatElements[0].y : 0,
        // right: 0,
        // bottom: 0,
        // left: topMatElements.length ? topMatElements[0].x : 0,
        ...borders,
        topMatElements
    }
}

/**
 * Get the maximum dimesions (in inches) that an image can be printed
 * @param {Image} image - an Image object with pixel width and height properties
 */
export const getImageMaxPrintSize = image => {
    if ( ! image || ! image.width || ! image.height ) {
        return { width: 40, height: 40  }
    }
    return {
        width: nearestDivisor( image.width / MED_RES_PRINT_PPI, 1/8 ),
        height: nearestDivisor( image.height / MED_RES_PRINT_PPI, 1/8 ),
    }
}

/**
 * Check if an image has sufficient resolution to be printed for a given opening size
 * @param image {object} - opening image object
 * @param opening {object} - opening object
 */
export const hasSufficientImageSize = (image, opening) => {
	if ( ! image || ! opening || image.source === 'pod' ) {
		return true
	}
    
	const printedImage = {
		width: nearestDivisor( image.width / MED_RES_PRINT_PPI, 1/8 ),
        height: nearestDivisor( image.height / MED_RES_PRINT_PPI, 1/8 ),
	}

	return printedImage.width >= opening.width && printedImage.height >= opening.height
}

/**
 * Get project data from localStorage
 * @param id {integer|string} - ID of a project. (optional)
 */
export const getStoredProject = (id) => {
    let storedVersion

    // check for localStorage support
    if ( storageAvailable() ) {

        // get the stored version and check it
        let storedVersion = window.localStorage.getItem('wallcore_project')

        if ( storedVersion ) {
            // try to parse the string into JSON
            try {
                storedVersion = JSON.parse(storedVersion)
                return storedVersion
            } catch (e) {
                return
            }
        }
    }

    return storedVersion

}

/**
 * Get the zoom level which would allow an object to fill a specified area
 * @param {Number} areaWidth | @required | the width of the area containing the object to be fitted
 * @param {Number} areaHeight | @required | the height of the area containing the object to be fitted
 * @param {Number} width | @required | the width of the object to be fitted
 * @param {Number} height | @required | the height of the object to be fitted
 * @param {Array|Number} padding | padding, in pixels, around the object to be fitted. Single number for even padding on all sides OR array of two numbers [$horizontal, $vertical]
 */
export const getFittedZoom = (areaWidth, areaHeight, width, height, padding = [150, 100]) => {
    padding = padding instanceof Object ? padding : [padding, padding]
    
    const areaRatio = areaWidth / areaHeight
    const objectRatio = width / height
    const prioritySide = areaRatio < objectRatio ? 'width' : 'height'
    const paddingX = padding[0] * 2
    const paddingY = padding[1] * 2
    const maxWidth = areaWidth - paddingX
    const maxHeight = areaHeight - paddingY

    switch(prioritySide) {
        case 'width' :
            return Math.round((maxWidth/width) * 100)/100;
        default: // 'height'
            return Math.round((maxHeight/height) * 100)/100;
    }

}

export const getCenteredImageCrop = ( image, openingWidth, openingHeight ) => {
    const openingRatio = openingWidth / openingHeight
    const imageRatio = image.width / image.height
    const fitWidth = openingRatio > imageRatio
    const fitHeight = openingRatio < imageRatio
    const imageWidth = fitHeight ? openingHeight * imageRatio : openingWidth
    const imageHeight = fitWidth ? openingWidth / imageRatio : openingHeight
    const crop = {
        width: imageWidth,
        height: imageHeight,
        x: fitHeight ? imageWidth / -2 - openingWidth / -2 : 0,
        y: fitWidth ? imageHeight / -2 - openingHeight / -2 : 0
    }
    return crop
}

/**
 * Get the width and height (in pixels) for an image in a given opening. Accounts for 1/8 inch bleed.
 * @param {Object} image | a plain object containing the image's width and height in pixels
 * @param {Number} openingWidth | the opening width in pixels
 * @param {Number} openingHeight | the opening height in pixels
 */
export const getOpeningImageProps = (image, openingWidth, openingHeight ) => {
    if ( ! image || ! openingWidth || ! openingHeight ) 
        return { width: 0, height: 0 }

    const openingRatio = openingWidth / openingHeight
    const imageRatio = image.width / image.height
    const imageReferenceSide = openingRatio < imageRatio ? 'width' : 'height'
    const imageProps = {
        width: imageReferenceSide === 'width' ? openingHeight * imageRatio : openingWidth,
        height: imageReferenceSide === 'height' ? openingWidth / imageRatio : openingHeight
    }

    // account for 1/8 inch bleed
    // imageProps.width += QUARTER_IN_PX
    // imageProps.height += QUARTER_IN_PX

    return imageProps
}

export const getPrintPPI = (widthInches, heightInches) => {
    let ppi
    if ( widthInches > 32 || heightInches > 32 ) {
        ppi = LOW_RES_PRINT_PPI
    } else if ( widthInches > 24 || heightInches > 24 ) {
        ppi = MED_RES_PRINT_PPI
    } else {
        ppi = HI_RES_PRINT_PPI
    }
    return ppi
}

/**
 * Calculate the x/y offset for a given nested element from the top left corner of a project
 * @param {String} key | the property whose value to accumulate
 * @param {Object} item | the data object
 * @param {Array} collection | the array of objects
 */
export const accumulateOffset = (key, item, collection) => {
    collection = [...collection].sort(sortDESC('depth'))
    return collection.reduce((acc, elem) => {
        if ( elem.id === acc.id ){
            acc.offset += elem[key]
            acc.id = elem.parent
        }
        return acc
    }, { id: item.id, offset: 0 })
}

/**
 * Get the printable elements for a project
 * @param {Object} project | the project object
 */
export const getPrintableElements = project => {
    const { elements } = project
    const printableElements = elements.filter( el => {
        const onBottomOrLower = el.depth >= project.projectMats.length
        const colorHexRegex = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
        return onBottomOrLower 
            && (
                ( el.component === 'Opening'
                    && ( 
                        (el.image instanceof Object && el.image.source !== 'pod')
                        || ( typeof el.backgroundColor === 'string' && !!el.backgroundColor.match(colorHexRegex) )
                    )
                )
                || ( el.component === 'Text' && el.content.length > 0 )
                || ( el.component === 'Graphic' )
            )
    })
    
    return printableElements;
}

/**
 * Build the URL for the print sheet
 * @param {Object} project | the project object
 */
export const getPrintSheet = project => {

    const { artboardwidth, artboardheight, elements } = project
    const widthInches = artboardwidth/PPI
    const heightInches = artboardheight/PPI
    const PRINT_PPI = getPrintPPI(widthInches, heightInches)
    const scale = PRINT_PPI/PPI
    
    // calculate printed document dimensions
    const scaledWidth = parseInt(artboardwidth * scale)
    const scaledHeight = parseInt(artboardheight * scale)
    
    const printableElements = getPrintableElements(project)

    let canvas = document.createElement("canvas"),
        svgNodes = []

    // set the canvas dimensions
    canvas.width = parseInt(scaledWidth)
    canvas.height = parseInt(scaledHeight)

    // set a white background
    svgNodes.push(`<rect x="0" y="0" width="100%" height="100%" fill="white" />`)
    
    // add SVG elements for each project element
    printableElements.forEach( el => {
        const 
            posX = accumulateOffset('x',el,elements),
            posY = accumulateOffset('y',el,elements),
            w = Math.round(el.width),
            h = Math.round(el.height),
            x = Math.round(posX.offset),
            y = Math.round(posY.offset),
            shapeEl = getShape(el.width, el.height, el.id, el.shape)

        // set the image element
        let elImage = ''
        if ( el.image instanceof Object ) {
            const imageProps = {
                ...el.image,
                crop: el.image.crop || getCenteredImageCrop(el.image, el.width, el.height)
            }
            elImage = `<image href="${imageProps.src}" x="${imageProps.crop.x}" y="${imageProps.crop.y}" width="${imageProps.crop.width}" height="${imageProps.crop.height}" />`
        }

        // set text children
        let textChildren = elements.filter( __el => __el.component === 'Text' && __el.parent === el.id )
        if ( textChildren.length ) {
            textChildren = textChildren.map( child => {
                // parse out the individual text nodes for each new line in the text content
                const textNodes = getTextNodes(child).map( node => {
                    const styleStr = Object.keys(node.style).map( prop => {
                        const propName = prop.replace( /([A-Z])/g, "-$1" ).toLowerCase()
                        return `${propName}: ${node.style[prop]}`
                    } ).join(';')
                    return `<text x="${node.x}" y="${node.y}" text-anchor="${node.textAnchor}" fill="${node.color}" style="${styleStr}">${node.lineText}</text>`
                } ).join('')
                // wrap the text nodes in an svg with proper sizing/positioning
                return `<svg x="${child.x}" y="${child.y}" width="${child.width}" height="${child.height}">${textNodes}</svg>`
            } ).join('')
        }

        // set the basic shape attributes for this element, accounting for 1/8" bleed
        const elAttrs = `x="${x - EIGHTH_IN_PX}" y="${y - EIGHTH_IN_PX}" width="${w + EIGHTH_IN_PX * 2}" height="${h + EIGHTH_IN_PX * 2}"`
        // set a background color for this element
        const elBgRect = `<rect x="0" y="0" width="100%" height="100%" fill="${ el.backgroundColor || 'white' }" />`
        
        // put it all together
        const elNode = [
            `<svg ${elAttrs}>`,
                `<defs>`,
                    `<clipPath id="${el.id}-clip">`,
                        `<${shapeEl.path.type} ${Object.keys(shapeEl.path.props).map( k => `${k}="${shapeEl.path.props[k]}"` ).join(' ')} />`,
                    `</clipPath>`,
                `</defs>`,
                `<svg x="${EIGHTH_IN_PX}" y="${EIGHTH_IN_PX}" width="${w}" height="${h}" transform="translate(${w/2} ${h/2}) scale(${ 1 + EIGHTH_IN_PX / w} ${ 1 + EIGHTH_IN_PX / h}) translate(-${w/2} -${h/2})">`,
                    `<g clip-path="url(#${el.id}-clip)">`,
                        elBgRect,
                        elImage,
                        textChildren,
                    `</g>`,
                `</svg>`,
            `</svg>`
        ]
        // add to the collection
        svgNodes.push(elNode.join(''))
        
        // // !!-- FOR DEBUGGING -----------------------------------------!!
        // const windowNode = [
        //     `<svg x="${x}" y="${y}" width="${w}" height="${h}">`,
        //         `<defs>`,
        //             `<clipPath id="${el.id}-window-clip">`,
        //                 `<${shapeEl.path.type} ${Object.keys(shapeEl.path.props).map( k => `${k}="${shapeEl.path.props[k]}"` ).join(' ')} />`,
        //             `</clipPath>`,
        //         `</defs>`,
        //         `<g clip-path="url(#${el.id}-window-clip)">`,
        //             `<rect x="0" y="0" height="100%" width="100%" fill="orange" opacity="0.3" />`,
        //         `</g>`,
        //     `</svg>`
        // ]
        // svgNodes.push(windowNode.join(''))
        // // ---------------------------------------------------------- !!
    })

    // set attributes for the overall SVG
    const svgAttrs = [
        `version="1.1"`,
        `xmlns="http://www.w3.org/2000/svg"`,
        `xmlnsXlink="http://www.w3.org/1999/xlink"`,
        `viewBox="0 0 ${artboardwidth} ${artboardheight}"`,
        `width="${scaledWidth}"`,
        `height="${scaledHeight}"`
    ]
    const cropMarks = `<rect width="100%" height="100%" x="0" y="0" fill="none" stroke="red" stroke-width="${scale}" stroke-dasharray="${3 * scale}" />`

    // wrap all the nodes in an svg
    svgNodes = [
        `<svg ${svgAttrs.join(' ')}>`, 
            ...svgNodes,
            cropMarks,
        `</svg>`
    ]

    // use canvg() to send the SVG string to canvas and return a data URL
    return new Promise((resolve, reject) => {
        try {
            const svgMarkup = svgNodes.join('')
            canvg( canvas, svgMarkup, {
                log: true,
                useCORS: true,
                renderCallback: () => {
                    const dataUrl = canvas.toDataURL('image/jpeg')
                    resolve({
                        hasPrintableElements: printableElements.length > 0,
                        url: changeDpiDataUrl( dataUrl, PRINT_PPI ),
                        width: scaledWidth,
                        height: scaledHeight,
                        svg: svgMarkup
                    })
                }
            } )
        }
        catch(err) {
            reject(err)
            console.error('There was an error retrieving the data url for this print sheet', err)
        }
    })
}


/**
 * Build URL for a Cloudinary-generated print sheet
 * @param {Object} project | the project object
 */
export const getCloudinaryPrintSheetUrl = (project) => {
    const { artboardwidth, artboardheight, elements } = project
    const widthInches = artboardwidth/PPI
    const heightInches = artboardheight/PPI
    const PRINT_PPI = getPrintPPI(widthInches, heightInches)
    const scale = PRINT_PPI/PPI
    const scaledWidth = parseInt(artboardwidth * scale)
    const scaledHeight = parseInt(artboardheight * scale)

    const specialChars = /[^\w\s]/gi
    const multipleSpaces = /\s\s+/g
    const imageTitle = project.title.replace(specialChars, '').replace(multipleSpaces, ' ') + ' print sheet'
    let printSheetUrl = [
        `${CLOUDINARY_BASE_URL}/image/upload`,
        `w_${scaledWidth},h_${scaledHeight},bo_1px_solid_black,fl_attachment:${imageTitle}`
    ]

    elements.forEach( (el,i,elementsArray) => {
        if ( 
            el.image 
            && el.image.source !== 'pod' 
            && el.depth === project.projectMats.length 
        ) {
            
            // determine opening size and position
            const posX = accumulateOffset('x',el,elements)
            const posY = accumulateOffset('y',el,elements)
            let scaledElementWidth  = Math.round(el.width * scale)
            let scaledElementHeight = Math.round(el.height * scale)
            let scaledXOffset       = Math.round(posX.offset * scale)
            let scaledYOffset       = Math.round(posY.offset * scale)

            // account for 1/8 inch bleed
            const print_eighth = Math.round(PRINT_PPI / 8)
            scaledElementWidth += print_eighth * 2
            scaledElementHeight += print_eighth * 2
            scaledXOffset -= print_eighth
            scaledYOffset -= print_eighth
            
            // determine image cropping
            const imageProps    = getOpeningImageProps(el.image, el.width, el.height)
            const widthRatio    = el.width / imageProps.width
            const heightRatio   = el.height / imageProps.height
            const xRatio        = typeof el.image.x === 'number' ? Math.abs(el.image.x) / imageProps.width : 0
            const yRatio        = typeof el.image.y === 'number' ? Math.abs(el.image.y) / imageProps.height : 0
            const crop          = {
                                    w: Math.round(el.image.width * widthRatio),
                                    h: Math.round(el.image.height * heightRatio),
                                    x: Math.round(el.image.width * xRatio),
                                    y: Math.round(el.image.height * yRatio)
                                }

            const str = `l_${el.image.id.replace(/\//g,':')},c_crop,w_${crop.w},h_${crop.h},x_${crop.x},y_${crop.y},fl_region_relative/fl_layer_apply,w_${scaledElementWidth},h_${scaledElementHeight},x_${scaledXOffset},y_${scaledYOffset},g_north_west`
            printSheetUrl.push(str)
        }
    })

    printSheetUrl.push('print-sheet.jpg')

    return printSheetUrl.join('/')
}

/**
 * Calculate total price of a project
 */
export const getTotalPrice = (project, options) => {
    const defaultOptions = {
        requireShipping: false,
        requireMaterials: false,
    }
    options = {...defaultOptions, ...options}
    const { projectMats, activeFrame, elements, artboardwidth, artboardheight, selectedGlass, selectedPaper, assembly, mounting, imagePrinting, fromTemplate } = project
    let widthInches = artboardwidth / PPI
    let heightInches = artboardheight / PPI
    const {
        frames: framesPricing,
        templates: templatesPricing,
        matboard: matboardPricing,
        glass: glassPricing,
        paper: paperPricing,
        pod: podPricing,
        openings: openingsPricing,
        debossing: debossingPricing,
        assembly: assemblyPricing,
        mounting: mountingPricing,
        inkLine: inkLinePricing,
        vGroove: vGroovePricing,
        shipping,
        materialsShipping,
        insurance,
        orderProcessing,
        spoilage,
    } = window.Craft.pricing

    let finalCost = { total: 0 }

    // framing
    finalCost.framing = 0
    finalCost.framingBaseCost = 0
    if ( activeFrame ) {
        // calculate outside dimensions of frame
        const frameWidth = widthInches + (activeFrame.faceWidth * 2)
        const frameHeight = heightInches + (activeFrame.faceWidth * 2)
        finalCost.framing = getItemPrice(activeFrame.price, frameWidth, frameHeight, framesPricing)
        finalCost.framingBaseCost = getItemPrice(activeFrame.price, frameWidth, frameHeight, {...framesPricing, markup: 1})
    }
    finalCost.total += finalCost.framing

    // matting
    finalCost.matting = 0
    projectMats.forEach( mat => {
        finalCost.matting += getItemPrice(mat.price, widthInches, heightInches, matboardPricing)
    } )
    finalCost.total += finalCost.matting

    // glass
    finalCost.glass = selectedGlass ? getItemPrice(selectedGlass.price, widthInches, heightInches, glassPricing) : 0
    finalCost.total += finalCost.glass

    // paper
    finalCost.paper = 0
    finalCost.paperBaseCost = 0
    if ( imagePrinting ) {
        const paperBasePrice = selectedPaper ? selectedPaper.price : 0
        const printableElements = elements.filter( el => {
            return el.depth === projectMats.length && (el.image || el.component === 'Text' || el.component === 'Graphic')
        } )
        if ( printableElements.length ) {
            finalCost.paper += getItemPrice(paperBasePrice, widthInches, heightInches, paperPricing)
            finalCost.paperBaseCost += getItemPrice(paperBasePrice, widthInches, heightInches, { ...paperPricing, markup:1 })
        }
    }
    finalCost.total += finalCost.paper

    // POD images
    finalCost.pod = 0
    finalCost.podBaseCost = 0
    if ( imagePrinting ) {
        const podElements = elements.filter( el => {
            return el.depth === projectMats.length && el.image instanceof Object && el.image.source === 'pod'
        } )
        podElements.forEach( el => {
            // convert to inches
            let elPodWidth = el.width/PPI
            let elPodHeight = el.height/PPI
            // add 1/8" bleed
            elPodWidth += 0.25
            elPodHeight += 0.25
            // add 2" border
            elPodWidth += 4
            elPodHeight += 4
            finalCost.pod += getItemPrice(podPricing.price, elPodWidth, elPodHeight, podPricing)
            finalCost.podBaseCost += getItemPrice(podPricing.price, elPodWidth, elPodHeight, { ...podPricing, markup:1 })
        } )
    }
    finalCost.total += finalCost.pod

    // openings
    finalCost.openings = 0
    elements.forEach( el => {
        if ( el.component === 'Opening' ) {
            finalCost.openings += getItemPrice(openingsPricing.price, widthInches, heightInches, openingsPricing)
            // inkLines
            if ( el.inkline ) {
                finalCost.openings += getItemPrice(inkLinePricing.price, widthInches, heightInches, inkLinePricing)
            }
        }
    } )
    finalCost.total += finalCost.openings

    // debossing
    finalCost.debossing = 0
    elements.forEach( el => {
        if ( el.component === 'Deboss' ) {
            finalCost.debossing += getItemPrice(debossingPricing.price, widthInches, heightInches, debossingPricing)
        }
    } )
    finalCost.total += finalCost.debossing

    // vGroove
    finalCost.vGroove = 0
    elements.forEach( el => {
        if ( el.component === 'VGroove' ) {
            finalCost.vGroove += getItemPrice(vGroovePricing.price, widthInches, heightInches, vGroovePricing)
        }
    } )
    finalCost.total += finalCost.vGroove

    // assembly
    finalCost.assembly = assembly ? getItemPrice(assemblyPricing.price, widthInches, heightInches, assemblyPricing) : 0
    finalCost.total += finalCost.assembly

    // mounting
    finalCost.mounting = mounting ? getItemPrice(mountingPricing.price, widthInches, heightInches, mountingPricing) : 0
    finalCost.total += finalCost.mounting

    // template adjustment
    if ( fromTemplate ) {
        finalCost.templateAdjustment = (finalCost.total * templatesPricing.markup) - finalCost.total
        finalCost.total = finalCost.total * templatesPricing.markup
    }
        else {
            finalCost.templateAdjustment = 0
        }

    // set the pre-order-handling total
    finalCost.preOrderHandlingTotal = finalCost.total

    // Customer shipping
    if ( options.requireShipping ) {
        finalCost.shipping = shipping
        finalCost.total += shipping
    }
    
    // Materials shipping
    if ( options.requireMaterials ) {
        finalCost.materialsShipping = materialsShipping
        finalCost.total += materialsShipping
    }

    // Set order handling costs
    finalCost = {
        ...finalCost,
        insurance: finalCost.total * insurance,
        orderProcessing: finalCost.total * orderProcessing,
        spoilage: finalCost.total * spoilage,
    }

    // Add handling costs
    finalCost.total += finalCost.insurance + finalCost.orderProcessing + finalCost.spoilage

    // Return final cost ================= //
    return finalCost
}

/**
 * 
 * @param {Number} basePrice | The base price per unit
 * @param {Number} width | project width in inches
 * @param {Number} height | project height in inches
 * @param {Object} pricingModel | an object containing 'markup' and 'units' properties
 */
export const getItemPrice = (basePrice, width, height, pricingModel) => {

    if ( ! basePrice || ! width || ! height )
        return 0

    const markup = (pricingModel && pricingModel.markup) || 1
    const units = pricingModel && pricingModel.units
    const unitCount = __getUnits(width, height, units)

    return Math.round(unitCount * basePrice * markup * 100) / 100

    function __getUnits(width, height, units) {
        switch(units) {
            case 'squareFoot':
                return (width / 12) * (height / 12)
            case 'squareInch':
                return width * height
            case 'perItem':
                return 1
            case 'linealInch':
                return (width + height) * 2
            default:
                // united inch
                return width + height
        }
    }
}

/**
 * Returns a formatted number with commas
 * @param props.value {number} - Number to format
 */
export const Number = (props) => {
    return typeof props.value === 'number' ?
        props.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        : '0'

}

/**
 * Returns a number as in currency format
 * @param {Number} value | the number to convert
 */
export const getCurrency = value => {
    return typeof value === 'number' ?
        '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        : '$0'
}

/**
 * Return a project object stripped of its identifying data
 * @param project {object} - The project object
 */
export const cleanProjectData = (project) => {
    project = {...project}
    project.id = null
    project.dateCreated = new Date().toISOString()
    project.dateUpdated = null
    
    return project
}

/**
 * Returns a single object tree of elements
 * @param elements {array} - flat source array of elements
 */
export const getComposedElements = (elements) => {

    const reducer = (acc, item, _, arr) => {

        // if item has a parent
        if ( item.parent ) {
            var parentKey

            // find parent key
            arr.forEach( (o,j) => {
                if ( o.id === item.parent ) { parentKey = j }
            })

            // if parentKey exists
            if ( parentKey !== undefined && arr[parentKey] ) {

                // check for 'descendants' property, make sure its an array, and push item to it
                if ( arr[parentKey].hasOwnProperty('descendants') && arr[parentKey].descendants.filter ) {
                    arr[parentKey].descendants.push(item)
                }
                // otherwise create the 'descendants' property and populate it
                else {
                    arr[parentKey].descendants = [item]
                }

            }
            // if parentKey does no exist, just push item to top level
            else {
                acc.push(item)
            }
        }
        else {
            acc.push(item)
        }
        return acc
    }

    return deepCopyObject( elements ).sort( sortDESC('parent') ).reduce( reducer, [] )
}

/**
 * Generate an SVG preview of composed project elements.
 * @param elements {array} - An array of project elements
 */
export const generatePreview = (options) => {

    const defaultOptions = {
        excludeFrame: false,
        scale: 1,
        dpi: 72,
        cropMarks: false
    }
    options = {...defaultOptions, ...options}

    let svg, viewBoxWidth, viewBoxHeight
    if ( options.excludeFrame ) {
        svg = document.getElementById('source-opening')
        viewBoxWidth = svg.parentElement.getAttribute('width')
        viewBoxHeight = svg.parentElement.getAttribute('height')
    } else {
        svg = document.getElementById('Canvas_SVG')
        viewBoxWidth = svg.viewBox.baseVal.width
        viewBoxHeight = svg.viewBox.baseVal.height
    }
    if ( ! svg )
        return

    let clonedSvg = svg.cloneNode(true)
    clonedSvg.setAttribute('version', '1.1')
    clonedSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    clonedSvg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    clonedSvg.setAttribute('viewBox', `0 0 ${viewBoxWidth} ${viewBoxHeight}`)
    clonedSvg.setAttribute('width', viewBoxWidth * options.scale)
    clonedSvg.setAttribute('height', viewBoxHeight * options.scale)
    
    clonedSvg.querySelectorAll('.hide_in_preview').forEach( node => node.remove() )
    clonedSvg.querySelectorAll('.show_in_preview').forEach( node => node.style.display = 'block' )
    
    // redraw strokes
    clonedSvg.querySelectorAll('svg[id*="-opening-"]').forEach( node => {
        const id = node.id
        const nodeX = node.getAttribute('x')
        const nodeY = node.getAttribute('y')
        const coreStrokeInner = node.querySelector(`.core-stroke-inner[href="#${id}-path"]`)
        const strokeWidth = coreStrokeInner.getAttribute('stroke-width')
        const strokeColor = coreStrokeInner.getAttribute('stroke')
        const path = node.querySelector(`#${id}-path`)
        const stroke = path.cloneNode(true)
        const strokeD = stroke.getAttribute('d')

        // translate handles the stroke position
        let transforms = [`translate(${nodeX} ${nodeY})`]

        // if there's a previous transform value:
        if ( stroke.transform.baseVal[0] ) {
            const { a: scaleX, d: scaleY } = stroke.transform.baseVal[0].matrix
            // include the scale transform values
            transforms.push(`scale(${scaleX} ${scaleY})`)
        }
        stroke.setAttribute('transform', transforms.join(' '))

        stroke.setAttribute('fill', 'none')
        stroke.setAttribute('stroke', strokeColor)
        stroke.setAttribute('stroke-width', strokeWidth)

        // if the path is not closed (with a 'z' on the end), add that 'z' now
        if ( strokeD && strokeD.split('').reverse()[0].toLowerCase() !== 'z' ) {
            stroke.setAttribute('d', strokeD + ' z')
        }
        
        // look for the nearest parent svg
        let parentSvg = node.parentNode
        while (parentSvg.tagName !== 'svg') {
            // check a level higher if the previous parent was not an svg element
            parentSvg = parentSvg.parentNode
        }

        // clone stroke to make an outer stroke
        const outerStroke = stroke.cloneNode(true)
        outerStroke.setAttribute('stroke', 'rgba(128,128,128,0.3)')
        outerStroke.setAttribute('stroke-width', strokeWidth * 1.5)

        // append strokes
        parentSvg.appendChild(outerStroke)
        parentSvg.appendChild(stroke)

    } )
    // remove old strokes
    clonedSvg.querySelectorAll('[class^="core-stroke-"]').forEach( node => node.remove() )

    if ( options.cropMarks ) {
        const attrs = {
            width: "100%",
            height: "100%",
            x: "0",
            y: "0",
            fill: "none",
            stroke: "red",
            "stroke-width": "3",
            "stroke-dasharray": "9"
        }
        const cropMarks = document.createElement('rect')
        Object.keys(attrs).forEach( key => cropMarks.setAttribute(key, attrs[key]) )
        clonedSvg.appendChild(cropMarks)
    }

    return new Promise( (resolve, reject) => {
        var canvas = document.createElement("canvas");
        canvas.width = parseInt(viewBoxWidth)
        canvas.height = parseInt(viewBoxHeight) 
        canvg( canvas, clonedSvg.outerHTML, {
            log: true,
            useCORS: true,
            renderCallback: () => {
                let dataUrl = canvas.toDataURL('image/jpeg')
                if ( options.dpi !== 72 ) {
                    dataUrl = changeDpiDataUrl( dataUrl, options.dpi )
                }
                resolve( dataUrl )
            }
        } )
    })
}

/**
 * Save a project preview to Cloudinary
 * @param url {string} - The url for the image. Can accept a base64 encoded url.
 */
export const saveProjectPreview = async (userId, projectId) => {

    // get a data url from the Canvas SVG
    const dataUrl = await generatePreview({ excludeFrame: true })
    let params = {
        file: dataUrl
    }
    // build the location for this image to be stored
    params.folder = CloudinaryApi.getFolder(userId, projectId)
    // specify a file name
    if ( userId && projectId ) {
        params.public_id = `project_${projectId}`
    }
    // upload to Cloudinary
    const json = await CloudinaryApi.upload(params)

    return json
}

/**
 * Calculate the width, height, and openings for a given set of project data
 * @param openings {array} - An array of opening objects
 * @param data {object} - An object of data for the project
 */
export const calculateLayout = (openings, data) => {

    // if no openings, bail out
    if ( ! openings ) return

    const defaultLayout = { columns: openings.length, rows: 1 }
    const validLayoutObject = data.layout && data.layout.hasOwnProperty('columns') && data.layout.hasOwnProperty('rows')
    const defaultBorders = { top: 2, side: 2, bottom: 2 }
    const validBordersObject = data.borders && data.borders.hasOwnProperty('top') && data.borders.hasOwnProperty('side') && data.borders.hasOwnProperty('bottom')

    // grab data and set defaults
    const layout = validLayoutObject ? data.layout : defaultLayout
    const matCount = data.matCount || 1
    const matReveal = data.matReveal || 0.25
    const borders = validBordersObject ? data.borders : defaultBorders
    const insideHorzMargin = data.insideHorzMargin || 1
    const insideVertMargin = data.insideVertMargin || 1

    // calculate pixel values
    const insideHorzMarginPX = insideHorzMargin * PPI
    const insideVertMarginPX = insideVertMargin * PPI
    const matRevealPX = matReveal * PPI
    const topBorderPX = borders.top * PPI
    const sideBorderPX = borders.side * PPI
    const bottomBorderPX = borders.bottom * PPI
    const totalHorzMarginsPX = insideHorzMargin * (layout.columns - 1) * PPI
    const totalVertMarginsPX = insideVertMargin * (layout.rows - 1) * PPI

    // setup a row matrix from which opening sizes can be compared
    let rowMatrix = []
    openings.forEach( (opening,o) => {
        const openingPosition = __getPosition(o, layout.rows, layout.columns)
        const openingRow = openingPosition[0]
        const openingCol = openingPosition[1]

        const outsideOpeningWidthPX = __getOutsideWidthPX(opening)
        const outsideOpeningHeightPX = __getOutsideHeightPX(opening)

        if ( typeof rowMatrix[openingRow] !== 'object' ) {
            rowMatrix[openingRow] = []
        }
        rowMatrix[openingRow][openingCol] = [ outsideOpeningWidthPX, outsideOpeningHeightPX ]
    })

    // calculate horizontal offsets for each opening
    const xOffsets = rowMatrix.map( row => {
        let xAccum = 0
        return row.map( (item, i) => {
            if (i === 0) return 0
            return xAccum += row[i - 1][0]
        } )
    } )

    // calculate vertical offsets for each opening
    let yAccum = 0
    const yOffsets = rowMatrix.map( (row, i) => {
        if (i === 0) return 0
        return yAccum += rowMatrix[i - 1].reduce( (a,item,j,src) => Math.max(a,item[1]), 0 )
    } )

    // create the array of new openings
    let newOpenings = []
    openings.forEach( (opening,o) => {
        const openingPosition = __getPosition(o, layout.rows, layout.columns)
        const openingRow = openingPosition[0]
        const openingCol = openingPosition[1]
        const openingWidthPX = opening.width * PPI
        const openingHeightPX = opening.height * PPI

        for ( let m = matCount; m > 0; m--) {
            var rectConfig = {
                image: m === 1 ? opening.image : null,
                id: `element-opening-${o}-${m}`,
                parent: m === matCount ? 'source-opening' : `element-opening-${o}-${m + 1}`,
                depth: (matCount - m) + 1,
                width: openingWidthPX + (matRevealPX * 2 * (m - 1)),
                height: openingHeightPX + (matRevealPX * 2 * (m - 1)),
                x: m === matCount ? sideBorderPX + (xOffsets[openingRow][openingCol]) + (insideHorzMarginPX * openingCol) : matRevealPX,
                y: m === matCount ? topBorderPX + (yOffsets[openingRow]) + (insideVertMarginPX * openingRow) : matRevealPX,
            }
            newOpenings.push( createOpening('rect', rectConfig) )
        }

    } )

    // calculate the overall width and height of the project
    var allOpeningsWidthPX = 0
    var allOpeningsHeightPX = 0
    rowMatrix.forEach( row => {
        var rowWidth = row.reduce( (a,col) => a += col[0], 0 )
        var maxHeight = row.reduce( (a,col) => Math.max(a,col[1]), 0 )
        allOpeningsWidthPX = Math.max(allOpeningsWidthPX, rowWidth)
        allOpeningsHeightPX += maxHeight
    } )
    const newWidthPX =  sideBorderPX + allOpeningsWidthPX + totalHorzMarginsPX + sideBorderPX
    const newHeightPX = topBorderPX + allOpeningsHeightPX + totalVertMarginsPX + bottomBorderPX

    // return object of openings and dimensions
    const result = { openings: newOpenings, width: newWidthPX, height: newHeightPX}
    return result

    // helper functions
    function __getPosition(x, rows, columns) {
        let count = 0
        for ( let rowIndex = 0; rowIndex < rows; rowIndex++ ) {
            for ( let colIndex = 0; colIndex < columns; colIndex++ ) {
                if ( count === x ) { return [rowIndex, colIndex] }
                count++
            }
        }
    }
    function __getOutsideHeightPX(opening) {
        return (opening.height + (matReveal * 2 * (matCount - 1))) * PPI
    }
    function __getOutsideWidthPX(opening) {
        return (opening.width + (matReveal * 2 * (matCount - 1))) * PPI
    }
}

/**
 * Get a normalized object of image data which has universally recognizable props
 * @param sourceImage {object} - The source object of image data
 * @param propMap {object} - A map pointing to sourceImage props. Common maps are stored in `imageMaps`
 */
export const normalizeImageData = (sourceImage, propMap) => {
    if ( ! propMap ) {
        return sourceImage
    }

    propMap = propMap instanceof Object ? propMap : imageMaps[propMap]
    
    let normalizedData = {
        alt: propMap.alt ? sourceImage[propMap.alt] : sourceImage.alt,
        height: propMap.height ? sourceImage[propMap.height] : sourceImage.height,
        id: propMap.id ? sourceImage[propMap.id] : sourceImage.id,
        src: propMap.src ? sourceImage[propMap.src] : sourceImage.src,
        thumbnail: propMap.thumbnail ? sourceImage[propMap.thumbnail] : sourceImage.thumbnail,
        width: propMap.width ? sourceImage[propMap.width] : sourceImage.width,
        author: propMap.author ? sourceImage[propMap.author] : sourceImage.author,
        dateCreated: propMap.dateCreated ? sourceImage[propMap.dateCreated] : new Date().toISOString(),
        source: propMap.source || 'upload',
    }

    // populate a thumbnail if one doesnt exist
    if ( ! normalizedData.thumbnail && normalizedData.source === 'upload' ) {
        normalizedData.thumbnail = __getCloudinaryThumbnail(normalizedData.src)
    }

    function __getCloudinaryThumbnail(url, width) {
        width = width || '150'
        if ( url ) {
            return url.split('/upload/').join(`/upload/w_${width}/`)
        } else {
            return url
        }
    }

    return normalizedData
}

/**
 * A map of image props for various sources
 */
export const imageMaps = {
    // Cloudinary images
    cloudinary: { 
        id: 'public_id', 
        src: 'secure_url', 
        thumbnail: 'thumbnail', 
        width: 'width', 
        height: 'height', 
        alt: 'original_filename',
        dateCreated: 'created_at'
    },
    // POD Exchange images
    pod: { 
        id: 'image_id', 
        src: 'lowres', 
        thumbnail: 'thumbnail', 
        width: 'width', 
        height: 'height', 
        alt: 'item_name', 
        author: 'artist_name',
        source: 'pod'
    }
}


/**
 * Save a new project or update an existing one
 * @param {Object} projectData | The project model
 */
export const saveProject = async (projectData, entryId, userId) => {    
    // add saving notification
    dispatch( addSnack({ 
        id: 'saving-snack',
        message: `Saving ${projectData.title}...`, 
        loading: true,
    }) )

    // save preview to Cloudinary
    const thumbnailJson = await saveProjectPreview(userId, projectData.id)
    projectData.thumbnail = thumbnailJson && !thumbnailJson.error ? thumbnailJson.data.secure_url : ""
    
    // setup entry data
    let entry = {
        "title": projectData.title,
        "fields[pixelHeight]": projectData.artboardheight,
        "fields[pixelWidth]": projectData.artboardwidth,
        "fields[assembly]": projectData.assembly ? "1" : "0",
        "fields[mounting]": projectData.mounting ? "1" : "0",
        "fields[imagePrinting]": projectData.imagePrinting ? "1" : "0",
        "fields[objectsProvided]": projectData.objectsProvided ? "1" : "0",
        "fields[objectsDescription]": projectData.objectsDescription,
        "fields[defaultReveal]": projectData.defaultReveal,
        "fields[description]": projectData.type === 'projectTemplates' ? projectData.description : "",
        "fields[elementsJson]": JSON.stringify(projectData.elements),
        "fields[frame][]": projectData.activeFrame ? projectData.activeFrame.id : "",
        "fields[glass][]": projectData.selectedGlass ? projectData.selectedGlass.id : "",
        "fields[paper][]": projectData.selectedPaper ? projectData.selectedPaper.id : "",
        "fields[price]": getTotalPrice(projectData).total,
        "fields[primaryImageUrl]": projectData.thumbnail,
    }
    projectData.projectMats.forEach( (mat,i) => {
        entry["fields[mats][mat-"+i+"][type]"] = "matboard"
        entry["fields[mats][mat-"+i+"][enabled]"] = "1"
        entry["fields[mats][mat-"+i+"][fields][matProduct]"] = ""
        entry["fields[mats][mat-"+i+"][fields][matProduct][]"] = mat.id
    })
    if ( entryId ) {
        entry.entryId = entryId
    }
    
    // save entry
    const sectionId = window.Craft.sectionIds[projectData.type]
    const response = await EntriesApi.saveEntry( sectionId, entry )
    
    // handle response
    if ( response.success ) {
        // add new meta data
        projectData = {
            ...projectData,
            id: response.id,
            dateCreated: new Date(response.dateCreated).getTime(),
            dateUpdated: new Date(response.dateUpdated).getTime(),
        }
        // update project
        dispatch( updateProject(projectData) )
        // update the user entries we already fetched
        dispatch( updateUserEntry( projectData.type, projectData ) )
        // update url
        window.history.pushState({},'',`/designer/editor/${projectData.id}`)
        // notify
        dispatch( addSnack({ 
            id: 'project-saved',
            message: `${projectData.title} was saved!`, 
            icon: 'check circle', 
            color: 'olive',
            canDismiss: false,
            dismissAfter: 3000
        }) )
    }

    if ( response.error || response.errors ) {
        // handle error(s)
        const saveError = response.error || response.errors
        if ( typeof saveError !== 'string' ) {
            console.error(saveError)
        }
        dispatch( addSnack({
            id: 'saving-error',
            message: 'There was a problem saving your project',
            description: typeof saveError === 'string' ? saveError : 'Please check your console for errors',
            icon: 'exclamation circle',
            color: 'red'
        }) )
    }

    // remove saving notification
    dispatch( dismissSnack("saving-snack") )

    return { projectData, response }
}