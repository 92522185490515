import React, { useEffect } from 'react'
import {categories} from './vars'
import { Card, Dimmer, Header, Divider, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { setCategory } from '../../reducks/setup'
import { setUserEntriesModal } from '../../reducks/app'
import ProjectsModal from '../files/ProjectsModal'
import { updateProject } from '../../reducks/project'
import { getQueryObject } from '../../helpers'

const Category = props => {

	const { history, location, onSelect } = props
	const { category } = useSelector(state => state.setup)
	const userEntriesModal = useSelector( state => state.app.userEntriesModal )
	const user = useSelector( state => state.user.meta )
	const userTemplates = useSelector( state => state.user.projectTemplates )
	const userProjects = useSelector( state => state.user.projects )
	const project = useSelector( state => state.project.present )
	const dispatch = useDispatch()

	useEffect(() => {
		const {art} = getQueryObject(location.search)
		if ( category && art ) {
			history.push('/designer/setup/openings' + location.search)
		}
	}, [])

	let recentProject
	try {
		recentProject = JSON.parse(window.localStorage.getItem('wallcore_project'))
	} catch(err) {
		console.error(`Could't fetch local storage item 'wallcore_project'`, err)
	}

	function selectCategory(cat) {
		dispatch( setCategory(cat) )
		if ( cat.to ) {
			history.push(cat.to || cat.href)
		}
		else if ( cat.href ) {
			window.location.href = cat.href
		}
		else if ( typeof onSelect === 'function' ) {
			onSelect(cat)
		}
	}

	function formatDate(date) {
		const d = new Date(date)
		return d.toLocaleString()
	}

	function selectRecent() {
		dispatch( updateProject(recentProject) )
		history.push('/designer/editor/new')
	}

	return (
		<div id="Categories">

			<div className="fade_left px_1 pb_4 mx_auto" style={{maxWidth:1100}}>	
				{ recentProject && ! recentProject.id && <>
					<Header size="large" textAlign="center" className="mt_4">Continue Editing...</Header>
					<Card centered onClick={() => selectRecent()}>
						<Card.Content>
							<Header className="mb_0">{recentProject.title}</Header>
							<Card.Meta>Created: {formatDate(recentProject.dateCreated)}</Card.Meta>
						</Card.Content>
					</Card>
				</>}

				
				<Header as="h1" size="large" textAlign="center" className="mt_4">
					{ recentProject && ! recentProject.id && 
						`...or start fresh.`
					}
					<br/>What are you framing?
				</Header>
				<Divider horizontal className="mt_3">Choose one</Divider>

				<Card.Group itemsPerRow={3} doubling centered>

					{ categories.map( cat => {
						// const selected = category instanceof Object && category.id === cat.id
						return (
							<Card raised key={cat.id} 
								onClick={ cat.disabled ? undefined : () => selectCategory(cat) }
								>
								{/* <div className="ui image">
									<Dimmer active={ cat.id === '3d' }>
										<Header size="medium" inverted>Coming Soon!</Header>
									</Dimmer>
									<div className={`bg_cover bg_center`} style={{paddingTop:"75%", backgroundImage: cat.image ? "url("+cat.image+")" : ""}}></div>
								</div> */}
								<Card.Content>
									<Header textAlign='center' size="medium" color={ cat.disabled ? "grey" : "blue"}>
										{ cat.name }
									</Header>
									{/* <Responsive as={Card.Description} minWidth={Responsive.onlyTablet.minWidth}
										>
										{ cat.description } 
									</Responsive> */}
								</Card.Content>
							</Card>
						)
					} ) }

					{ user && 
						<Card raised onClick={ () => dispatch(setUserEntriesModal(true)) }>
							<div className="ui image">
								<Dimmer active={ true }>
									<Icon size="huge" inverted name="folder open" className="fade_up" />
								</Dimmer>
								<div style={{paddingTop:"75%"}}></div>
							</div>
							<Card.Content>
								<Header size="medium">
									Open a saved project
								</Header>
							</Card.Content>
						</Card>
					}

				</Card.Group>
			</div>

			<ProjectsModal
                open={userEntriesModal}
                userTemplates={userTemplates}
                userProjects={userProjects}
                project={project}
                onClose={ () => dispatch( setUserEntriesModal(false) ) }
                dispatch={dispatch}
                />

        </div>
	)
}

export default Category