// import { nearestDivisor } from "../../helpers";
import { commonSizesPortrait, commonSizesSquare, commonSizesLandscape, defaultOpening } from "./vars";
import { getFraction } from "../core/Fraction";
import { nearestDivisor } from "../../helpers";

export const getSizeOptions = ({ ratio, image }) => {
	let sizeOptions

	if ( image instanceof Object ) {
		let small = {}, medium = {}, large = {};

		if ( ratio > 1 ) {
			// portrait
			small.height = 7; 	small.width = nearestDivisor( 7 / ratio, 0.125 );
			medium.height = 10; medium.width = nearestDivisor( 10 / ratio, 0.125 );
			large.height = 20; 	large.width = nearestDivisor( 20 / ratio, 0.125 );
		} else {
			// landscape
			small.width = 7; 	small.height = nearestDivisor( 7 * ratio, 0.125 );
			medium.width = 10; medium.height = nearestDivisor( 10 * ratio, 0.125 );
			large.width = 20; 	large.height = nearestDivisor( 20 * ratio, 0.125 );
		}

		sizeOptions = [
			{ label: "Small",  width: small.width,  height: small.height,  dimensions: `${getFraction(small.width)} x ${getFraction(small.height)}`   },
			{ label: "Medium", width: medium.width, height: medium.height, dimensions: `${getFraction(medium.width)} x ${getFraction(medium.height)}` },
			{ label: "Large",  width: large.width,  height: large.height,  dimensions: `${getFraction(large.width)} x ${getFraction(large.height)}`   },
		] 
	}
	else {
		// no pre-existing image, combine all common sizes
		sizeOptions = [
			...commonSizesPortrait, 
			...commonSizesSquare, 
			...commonSizesLandscape
		]
	}
	
	return sizeOptions.sort( (a,b) => a.width > b.width )
}

export const makeOpeningFromImage = image => {
	let width, height

	// // respect POD artwork dimensions
	// if ( image.source === 'pod' ) {
		const ratio = image.height/image.width
		if ( image.width > image.height ) {
			width = 10
			height = nearestDivisor(10 * ratio, 0.125)
		} else {
			width = nearestDivisor(10 / ratio, 0.125)
			height = 10
		}
	// } 
	// // just use standard 7x5/5x7 for everything else
	// else {
	// 	width = image.width > image.height ? 7 : 5
	// 	height = image.width > image.height ? 5 : 7
	// }

	return {
		...defaultOpening,
		width,
		height,
		image
	}
}