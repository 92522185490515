export default {
    activeFrame: null,
    artboardheight: 12 * 72,
    artboardwidth: 10 * 72,
    assembly: true,
    mounting: true,
    dateCreated: null,
    dateUpdated: null,
    defaultReveal: 0.25 * 72,
    description: "",
    elements: [ { "id": "source-opening", "editable": true, "component": "Opening", "shape": "rect", "depth": 0, "x": 0, "y": 0, "width": 10 * 72, "height": 12 * 72 }, { "id": "main-opening", "parent": "source-opening", "editable": true, "component": "Opening", "shape": "rect", "depth": 1, "x": 2.5 * 72, "y": 2.5 * 72, "width": 5 * 72, "height": 7 * 72 }, ],
    fromTemplate: false,
    id: null,
    imagePrinting: false,
    objectsProvided: false,
    objectsDescription: "",
    projectMats: [],
    selectedGlass: null,
    selectedPaper: null,
    thumbnail: '',
    title: "My Project",
    type: "projects",
}
