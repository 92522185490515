import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { 
    // Table, 
    Button, 
    // Popup, 
    // Icon, 
    // Divider, 
    // Header 
} from 'semantic-ui-react'
import { toggleConfirmCartMode } from '../../reducks/app'
import Currency from '../core/Currency'

class AddToCart extends React.Component {

    showConfirm(event, data) {
        this.props.dispatch(toggleConfirmCartMode(true))
    }

    render() {
        const { 
            // user, 
            basic, size, fluid, inverted, className, compact, positive, negative, primary, secondary, color, style, project, price, id, projectsInCart 
        } = this.props
        // const { type } = project
        const inCart = project.id && projectsInCart.indexOf(project.id) > -1

        return (
            <div id={id} className="AddToCart" style={style}>
                <Button
                    basic={basic}
                    compact={compact}
                    inverted={inverted}
                    color={color}
                    positive={positive}
                    negative={negative}
                    secondary={secondary}
                    primary={primary}
                    fluid={fluid}
                    size={size || 'medium'}
                    className={className}
                    onClick={this.showConfirm.bind(this)}
                >

                    { ! basic && <>
                        <i className="cart icon" aria-hidden="true"></i>
                        <span className="mx_nudge">
                            {inCart ? "Update Cart" : "Add to cart"}
                        </span>
                        &nbsp;&nbsp;
                    </> }
                    <Currency value={price.total} />

                </Button>
            </div>
        )

    }
}

AddToCart.propTypes = {
    size: PropTypes.string,
    fluid: PropTypes.bool,
    project: PropTypes.object,
    user: PropTypes.object,
    price: PropTypes.object
}

function mapStateToProps(state) {
    return {
        project: state.project.present,
        price: state.price,
        user: state.user ? state.user.meta : null,
        projectsInCart: state.cart.lineItems.map(item => {
            return parseInt(item.snapshot.options.projectId);
        })
    }
}

export default connect(mapStateToProps)(AddToCart)
