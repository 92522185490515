import {post} from './fetchKit'

export default class ShopApi {

    static updateCart(params) {

        const { purchasableId, qty, options, lineItemId } = params

        let requestOptions = {}

        // adding a new item to cart
        if ( purchasableId !== undefined ) {
            requestOptions.purchasableId = purchasableId
            requestOptions.qty = qty
            if ( options instanceof Object ) {
                Object.keys(options).forEach( option => {
                    requestOptions[`options[${option}]`] = options[option]
                } )
            }
        }

        // update an existing line item in cart
        if ( lineItemId !== undefined ) {
            if ( qty !== undefined ) {
                requestOptions[`lineItems[${lineItemId}][qty]`] = qty
            }
            if ( options instanceof Object ) {
                Object.keys(options).forEach( option => {
                    requestOptions[`lineItems[${lineItemId}][options][${option}]`] = options[option]
                } )
            }
        }

        return post('/actions/commerce/cart/update-cart', requestOptions )

    }

}
