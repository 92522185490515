import {post} from './fetchKit'

// interface UploadParams {
//     public_id: String,
//     file: String,
//     folder: String
// }

// interface RetrieveParams {
//     prefix: String,
//     max_results: Number,
//     next_cursor: String,
// }

export default class CloudinaryApi {

    static getFolder(userId, projectId) {
        let folder = ['user_uploads']

        if ( userId ) {
            folder.push(`user_${userId}`)

            if ( projectId ) {
                folder.push(`project_${projectId}`)
            }
                else {
                    folder.push(`unsaved_projects`)
                }
        }
            else {
                folder.push('guests/project_previews')
            }

        return folder.join('/')
    }

    static assets( params ) {
        return post('/actions/wallcore-custom-module/cloudinary/assets', params)
    }

    static deleteAssets( publicIds ) {
        return post('/actions/wallcore-custom-module/cloudinary/delete-assets', publicIds)
    }

    static upload( params ) {
        return post('/actions/wallcore-custom-module/cloudinary/upload', params);
    }
    
    static rename( params ) {
        return post('/actions/wallcore-custom-module/cloudinary/rename', params);
    }

}
