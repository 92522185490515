import React from 'react'
import { Input } from 'semantic-ui-react';
import ErrorMessage from './ErrorMessage';

const LoginForm = props => {
	
	const {data: formData, notice} = props
	
    return (
        <form className="ui form" method="post" acceptCharset="UTF-8" onSubmit={ e => { e.preventDefault(); props.onSubmit() } }>

            { notice }
            
			<div className="field">
                <label htmlFor="loginName">Username or email</label>
                <input id="loginName" type="text" name="loginName" value={formData.loginName} 
                    onChange={ event => props.onChange({ name: 'loginName', type: 'text', value: event.target.value }) } 
                    />
            </div>
            <div className="field">
                <label htmlFor="password">Password</label>
                <Input fluid id="password" type="password" name="password" value={formData.password} 
                    onChange={ event => props.onChange({ name: 'password', type: 'password', value: event.target.value }) } 
                    />
            </div>
			<div className="two fields">
				<div className="field">
					<div className="ui checkbox">
						<input type="checkbox" label="Remember me" name="rememberMe" id="rememberMe" checked={formData.rememberMe} 
							onChange={ event => props.onChange({ name: 'rememberMe', type: 'checkbox', checked: event.target.checked  }) } 
							/>
						<label htmlFor="rememberMe">Remember me</label>
					</div>
				</div>
				<div className="field" style={{textAlign:'right'}}>
                    <a href="/user/forgot-password" onClick={ event => { event.preventDefault(); props.onForgotPassword() } }>
						{`Forgot your password?`}
					</a>
				</div>
			</div>

            { props.error &&
                <ErrorMessage error={props.error} />
            }

            <input type="submit" value="Log in" style={{display:"none"}} />

        </form>
    )
}

export default LoginForm