import initialState from './_initialState';
import {deepCopyObject} from '../helpers'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function updatePrice( price ) {
    return { type: 'UPDATE_PRICE', price }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function priceReducer( state = initialState.price, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )

    switch(action.type) {

        case 'UPDATE_PRICE':
            newState = action.price
            break

        default:
            return newState

    }

    return newState;
}
