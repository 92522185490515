export const getFraction = float => {
    const base = Math.floor(float)
    const remainder = float - base
    let fractionEntity = ''

    if ( remainder !== 0 ) {
        const charCodes = {
            '0.125' : 8539,
            '0.25'  : 188,
            '0.375' : 8540,
            '0.5'   : 189,
            '0.625' : 8541,
            '0.75'  : 190,
            '0.875' : 8542,
        }
        fractionEntity = charCodes[remainder.toString()]
    }

    return fractionEntity ? 
        `${base !== 0 ? base + ' ' : ''}${String.fromCharCode(fractionEntity)}`
        : Math.round(float * 1000)/1000
}

const Fraction = ({float}) => {
    return getFraction(float)
}

export default Fraction
