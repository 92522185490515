import React, { useState } from 'react'
import { getSizeOptions } from './helpers'
import ImageCropPreview from './ImageCropPreview'
import { getImageMaxPrintSize } from '../core/utilities'
import { Icon, Dropdown, Input, Header, Button, Responsive } from 'semantic-ui-react'
import { blue } from '../core/colors'
import { nearestDivisor } from '../../helpers'
import { MAX_SIZE } from '../../data/constants'
import { getFraction } from '../core/Fraction'

const OpeningEditor = props => {

	const thickInput = (color) => <input style={{ fontWeight: 'bold', borderColor: color, padding: '0.5em', color, textAlign: 'center' }} />

	// Props
	const { id, opening, canRemove, onRemove, onUpdate } = props

	// Derived vars
	const hasImage = opening.image instanceof Object

	// State
	// const [lockAspectRatio, setLockAspectRatio] = useState(hasImage)
	const [allowCustomSize, setAllowCustomSize] = useState(false)
	// const [imageSizeOK, setImageSizeOK] = useState(hasSufficientImageSize( opening.image, opening ))
	// const [openingSizeOK, setOpeningSizeOK] = useState(true)
	const [isMobile, setIsMobile] = useState(true)

	// Derived vars
	const openingRatio = opening.height / opening.width
	const maxImagePrint = getImageMaxPrintSize(opening.image)
	const sizeOptions = getSizeOptions({ ratio: openingRatio, image: opening.image }).filter( option => {
		return option.width <= maxImagePrint.width && option.height <= maxImagePrint.height
	} )
	if ( ! sizeOptions.length && hasImage ) {
		sizeOptions.push({ 
			label: "Tiny", 
			width: maxImagePrint.width, 
			height: maxImagePrint.height, 
			dimensions: `${getFraction(maxImagePrint.width)} x ${getFraction(maxImagePrint.height)}` 
		})
	}
	const activeSize = sizeOptions.find( size => size.width === opening.width && size.height === opening.height )

	// useEffect(() => {
	// 	setImageSizeOK(hasSufficientImageSize( opening.image, opening ))
	// 	// setOpeningSizeOK( opening.width <= MAX_SIZE && opening.height <= MAX_SIZE )
	// }, [ opening.width, opening.height ])

	function selectCommon({width, height}) {
		// setLockAspectRatio(false)
		setAllowCustomSize(false)
		if ( typeof onUpdate === 'function' ){
			onUpdate({ width, height })
		}
	}

	function getProportionalHeight(width, ratio) {
		return nearestDivisor( width * ratio, 0.125 )
	}
	
	function getProportionalWidth(height, ratio) {
		return nearestDivisor( height / ratio, 0.125 )
	}

	function onSizeInputChange(prop, value) {
		if ( typeof onUpdate !== 'function' )
			return;
		let updatedData

		// if ( (prop === 'width' || prop === 'height') && (lockAspectRatio || hasImage) ) {
		if ( prop === 'width' || prop === 'height' ) {
			let width, height
			if ( prop === 'width' ) {
				width = Math.min( MAX_SIZE, parseFloat(value))
				height = hasImage ? 
					getProportionalHeight(value, opening.image.height / opening.image.width)
					: opening.height
				updatedData = { width, height }
			}
			if ( prop === 'height' ) {
				height = Math.min( MAX_SIZE, parseFloat(value))
				width = hasImage ? 
					getProportionalWidth(value, opening.image.height / opening.image.width)
					: opening.width
				updatedData = { height, width }
			}
		}
		else {
			updatedData = { [prop]: parseFloat(value) }
		}

		onUpdate(updatedData)
	}

	const handleResponsiveUpdate = (event, {width}) => {
		const isLargeScreen = width > Responsive.onlyTablet.maxWidth
		if ( isLargeScreen ) {
			setIsMobile(false)
		} else {
			setIsMobile(true)
		}
	}

	return (
		<Responsive 
			fireOnMount
			onUpdate={handleResponsiveUpdate}
			className="OpeningEditor bg_white position_relative display_flex flex_column"
			>
			
			<div className="pa_half">

				<Dropdown fluid 	
					trigger={(
						<strong className="color_blue">
							{ allowCustomSize ? 
								"Custom size" 
								: activeSize ? 
									<>{activeSize.label} - {activeSize.dimensions}&rdquo;</> 
									: <>{getFraction(opening.width)} &times; {getFraction(opening.height)}</>
							}
							<Icon name="dropdown"/>
						</strong>
					)}
					icon={null}
					className="selection" 
					selectOnBlur={false}
					style={{borderColor: blue, paddingRight:0, fontSize: '1rem'}}
					>
					<Dropdown.Menu>
						<Dropdown.Item
							content="Custom size"
							onClick={() => setAllowCustomSize(true) }
							/>
						{ sizeOptions.map( (size, s) => {
							const isActive = opening.width === size.width && opening.height === size.height
							return (
								<Dropdown.Item key={s} active={isActive} 
									onClick={() => selectCommon(size) }
									>
									{size.label.length > 0 && `${size.label} - `}{size.dimensions}&rdquo;
								</Dropdown.Item>
							)
						} ) }
					</Dropdown.Menu>
				</Dropdown>

				{/* { allowCustomSize &&
					<p  className={`text_center`}
						style={{lineHeight:1.2}}
						>
						<small>
							<Popup
								on="hover"
								inverted
								trigger={ <Icon name={imageSizeOK ? "info circle" : "exclamation triangle"} color={imageSizeOK ? "grey" : "red"} /> }
								>
								Based on the resolution of this image, <strong><Fraction float={maxImagePrint.width} /> &times; <Fraction float={maxImagePrint.height} /></strong> is the largest size at which it can be printed while maintaining good print quality.
							</Popup>
							<strong>Max size: <Fraction float={maxImagePrint.width} /> &times; <Fraction float={maxImagePrint.height} /> &rdquo;</strong>
							{ ! imageSizeOK &&
								<><br/><br/>Image does not have sufficient resolution for this size. It may appear blurry when printed.</>
							}
						</small>
					</p>
				} */}

				{ allowCustomSize && 
					<div className="mt_nudge display_flex">
						<div className="flex_grow_1">
							<Input fluid id={`${id}-width`} 
								className='mx_auto'
								input={thickInput(blue)}
								value={opening.width.toString()} type="number" step={0.125} min={1} 
								style={{maxWidth:130}}
								onChange={ (e, {value}) => onSizeInputChange('width', value ? parseFloat(value) : 0) }
								/>
							<label className="display_block faded" htmlFor={`${id}-width`}>
								<small><strong>Width</strong></small>
							</label>
						</div>
						<Header className="faded mx_half mt_nudge" style={{fontSize: '2rem', alignSelf: 'flex-start', marginTop: '0.6em'}}>&times;</Header>
						<div className="flex_grow_1">
							<Input fluid id={`${id}-height`} 
								className='mx_auto'
								input={thickInput(blue)}
								value={opening.height.toString()} type="number" step={0.125} min={1} 
								style={{maxWidth:130}}
								disabled={false}
								onChange={ (e, {value}) => onSizeInputChange('height', value ? parseFloat(value) : 0) }
								/>
							<label className="display_block faded" htmlFor={`${id}-height`}>
								<small><strong>Height</strong></small>
							</label>
						</div>
					</div>
				}

				{/* { ! allowCustomSize &&
					<Button compact fluid basic 
						size="tiny" 
						className="mt_nudge"
						content="Custom Size"
						onClick={ () => setAllowCustomSize(true) }
						/>
				} */}

			</div>

			{ canRemove &&
				<Button negative circular compact size="mini" icon="delete" title="Remove opening"
					className="position_absolute top_0 right_0"
					style={{margin:'-1rem'}}
					onClick={ onRemove }/>
			}

			{/* <Image fluid src={opening.image.src} style={{ order: isMobile ? 1 : 0 }}/> */}
			{ opening.image instanceof Object && opening.image.hasOwnProperty('src') ?
				<ImageCropPreview 
					imageSrc={opening.image.src}
					lockAspectRatio={false}
					openingRatio={openingRatio}
					opening={opening}
					className=""
					warning={false}
					/>
				: 
				<div className="bg_light_grey bg_confetti position_relative" 
					style={{
						paddingTop: `${opening.height/opening.width * 100}%`,
						order: isMobile ? 1 : 0
					}}>
						<strong className="position_absolute transform_center color_white">
							Placeholder
							<br/>{getFraction(opening.width)} &times; {getFraction(opening.height)}
						</strong>
				</div>
			}

		</Responsive>
	)
}

export default OpeningEditor