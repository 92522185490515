import React, { useEffect } from 'react'
import Images from '../sidebar/Images'
import CommonDocuments from './CommonDocuments'
import { useSelector } from 'react-redux'
import { getQueryObject, doScrollRef } from '../../helpers'

const OpeningSources = props => {

	const {location} = props
	const categoryId = useSelector( state => state.setup.category && state.setup.category.id )

	const {art} = getQueryObject(location.search) 

	// component mounted effect
	useEffect(() => {
		// scroll up
		doScrollRef( window.document.body.parentElement, 0, 0 )
	}, [])

	switch(categoryId) {
		case 'existing':
			return <CommonDocuments />
		default:
			return <Images tab={art && 'art'} title="Select your images" />
	}
}

export default OpeningSources