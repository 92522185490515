import React from 'react'
import { Link } from 'react-router-dom'
import UserActions from './core/UserActions'
import { useSelector } from 'react-redux'
import AddToCart from './cart/AddToCart'

const Masthead = (props) => {

    const { addToCart } = props

    const cart = useSelector(state => state.cart)
    const project = useSelector(state => state.project.present)

    return (
        <nav className="main_nav py_1 px_2 border_b_lightest_grey">

            <div className="ui secondary large sharp-grotesk menu">
                <div className="header fitted item" aria-hidden="true">
                    <a href={window.Craft.baseUrl} className="logo">MyDIYWall</a>
                </div>
                <div id="main_nav__menu" className="right menu display_flex">

                    <Link as="a" to="/designer" className="item" >
                        <span className="color_blue"><i className="asterisk icon" aria-hidden="true"></i> Create New</span>
                    </Link>

                    <a href="/templates" className="item" >
                        <i className="search icon" aria-hidden="true"></i> Browse Templates
                    </a>

                    <UserActions item />

                    { addToCart &&
                        <div className="item pa_0">
                            <AddToCart primary={project.type === 'projects'} basic={project.type === 'projectTemplates'} />
                        </div>
                    }

                    <a href="/user/cart" className="item" title="View shopping cart">
                        <i className="shopping cart fitted icon"></i>
                        {cart.lineItems.length > 0 &&
                            <div className="ui circular ml_nudge label">{cart.lineItems.length}</div>
                        }
                    </a>
                </div>
            </div>

        </nav>
    )
}

export default Masthead
