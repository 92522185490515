import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Header, Icon, Modal, Image, Button, Message } from 'semantic-ui-react'
import Currency from '../core/Currency'
import {get} from '../../api/fetchKit'
import { useDebounce } from '../core/hooks'
import { setTemplates } from '../../reducks/setup'

const TemplateGallery = props => {

	const {history, loading, onSetLoading} = props
	
	const [selectedTemplate, setSelectedTemplate] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [pagination, setPagination] = useState({})

	const templates = useSelector( state => state.setup.templates )
	const openings = useSelector( state => state.setup.openings )
	const templateCategories = useSelector( state => state.setup.templateCategories )
	const templateMaxPrice = useSelector( state => state.setup.templateMaxPrice )
	const dispatch = useDispatch()

	const debouncedMaxPrice = useDebounce(templateMaxPrice, 500)

	const getTemplates = page => {
		const opengingCount = openings.length > 12 ? '13+' : openings.length.toString()
		const openingCategories = window.Craft.openingCounts.filter( cat => cat.title === opengingCount )
		let catIds = [...templateCategories, ...openingCategories].map( cat => cat instanceof Object ? cat.id : cat )
		onSetLoading(true)
		setErrorMessage('')
		return get(`/api/project-templates/gallery/${catIds.join()}/and/${templateMaxPrice}.json?page=${page || 1}`)
			.then( json => {
				if ( ! json.error && ! json.errors && json.data ) {
					if ( page ) {
						dispatch( setTemplates([...templates, ...json.data]) )
					} else {
						dispatch( setTemplates(json.data) )
					}
					setPagination(json.meta.pagination)
				} 
				else {
					setErrorMessage("There was an error finding templates. See the console for details")
					console.log(json.error || json.errors)
				}
			} )
			.finally( () => onSetLoading(false) )

	}

	useEffect( () => { 
		getTemplates() 
	}, [ JSON.stringify(templateCategories), debouncedMaxPrice] )

	return ( 
		<div className="TemplateGallery pa_3">

			{ ! loading &&
				( templates.length > 0 ?
					<Header textAlign="center">
						<Header.Content>Found {pagination.total || 0} {pagination.total > 1 ? 'templates' : 'template'} with {openings.length} {openings.length > 1 ? `openings` : `opening`}</Header.Content>
						<Header.Subheader><Icon name="info circle"/> A "Template" is a professional mat design that you can use to start a new project.</Header.Subheader>
					</Header>
					:
					<Header textAlign="center">
						<Header.Content>No templates were found.</Header.Content>
						<Header.Subheader>Try removing some filters</Header.Subheader>
					</Header>
				)
			}

			{ errorMessage &&
				<Message negative>{errorMessage}</Message>
			}

			<Grid doubling centered columns={3} className="mt_3">

				{ templates.map( template => (
					<Grid.Column key={template.id}>
						<button className="naked_button bg_none border_none width_100 mb_2 hover_raise px_nudge pt_nudge pb_half" 
							style={{borderBottomLeftRadius:5,borderBottomRightRadius:5}}
							onClick={() => setSelectedTemplate(template) }
							>
							<div className="ui fluid image">
								<div className={`position_relative bg_center bg_contain ${ !template.image ? 'bg_lighter_grey' : '' }`} style={{paddingTop:'100%', backgroundImage:`url("${template.image}")`}}>
									{ ! template.image && 
										<div className="position_absolute top_50 left_50 transform_center">
											<Icon name="crop" color="grey" size="big" />
										</div>
									}
								</div>
							</div> 
							<Header color="blue" size="tiny" textAlign="center" className="mt_half mb_0">{template.title}</Header> 
							<small className="faded">from</small>
							<Header textAlign="center" size="large" className="mt_0">{template.priceFormatted}</Header>
						</button>
					</Grid.Column>
				) ) }

				{ !!selectedTemplate && 
					<Modal open size="small" onClose={() => setSelectedTemplate(null)}>
						<Modal.Content>
							<Grid stackable columns={2}>
								<Grid.Column>
									<Image fluid src={selectedTemplate.image} />
								</Grid.Column>
								<Grid.Column>
									<ul className="ui bulleted list">
										<li className="item"><strong>{selectedTemplate.openingCount} {selectedTemplate.openingCount > 1 ? 'Openings' : 'Opening'}</strong> 
											<ul className="list">
												{ Object.keys(selectedTemplate.openings).map( key =>
													<li className="item"><strong>{selectedTemplate.openings[key]}</strong> &mdash; {key}</li>
												) }
											</ul>
										</li> 
										<li className="item"><strong>{selectedTemplate.matLabel} mat</strong></li> 
										<li className="item"><strong>Frame size: {selectedTemplate.width} × {selectedTemplate.height} in.</strong></li>
									</ul> 
									<p className="ui small header"><small>Available from</small> <Currency value={selectedTemplate.price} /></p> 
								</Grid.Column>
							</Grid>
						</Modal.Content>
						<Modal.Actions>
							<Button basic onClick={() => setSelectedTemplate(null)}>Close</Button>
							<Button positive onClick={() => history.push(`/designer/editor/new?template=${selectedTemplate.id}&placeImages=true`)}>Use this template</Button>
						</Modal.Actions>
					</Modal>
				}
			</Grid>

			{ pagination.current_page < pagination.total_pages &&
				<p className="text_center">
					<Button basic size="huge" className="my_3" loading={loading}
						onClick={() => getTemplates(pagination.current_page + 1)}
						>
						{pagination.current_page}/{pagination.total_pages} <span className="mx_half faded">|</span> Load More
					</Button>
				</p>
			}

		</div>
	)
}

export default TemplateGallery