import React, {useState} from 'react'
import { Input, Dropdown, Menu, Header, Modal, Image, Icon, Button, Card } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from '../../api/fetchKit'
import { setFrameShops } from '../../reducks/setup'
import { debounce, nl2br, storageAvailable } from '../../helpers'
import { setUserLocation } from '../../reducks/user'

const FramerSearch = props => {

	const { open, onContinue } = props

	const [address, setAddress] = useState('')
	const [distance, setDistance] = useState('25')
	const [errorMessage, setErrorMessage] = useState('')
	const [searching, setSearching] = useState(false)
	const [hasSearched, setHasSearched] = useState(false)
	const [selectedFrameShopId, setSelectedFrameShopId] = useState(null)

	const {frameShops} = useSelector( state => state.setup )
	const {objectsProvided} = useSelector( state => state.project.present )
	const dispatch = useDispatch()

	const getFrameShops = (location, distance) => {
		setSearching(true)
		setHasSearched(false)
		setErrorMessage('')
		return get(`/api/frame-shops/${encodeURIComponent(location)}/${distance}.json`)
			.then( json => {
				if ( json.data ) {
					dispatch( setFrameShops(json.data) )
					if ( ! json.data.length) {
						setSelectedFrameShopId(window.Craft.fulfillmentFramerId)
					}
				}
				if ( json.error ) {
					setErrorMessage(`${json.error}. See console for details.`)
				}
			} )
			.finally(() => {
				setSearching(false)
				setHasSearched(true)
			} )
	}

	const handleAddressChange = debounce((event, {value}) => {
		setAddress(value)
		setErrorMessage('')
		if ( value.match(/[0-9]{5}/) ) {
			getFrameShops(value, distance)
		} else {
			setErrorMessage('Please enter a valid U.S. zip code.')
		}
	}, 1000)

	const handleDistanceChange = (event, {value}) => {
		setDistance(value)
		if ( address ) {
			getFrameShops(address, value)
		}
	}

	const handleContinue = (event, data) => {
		if ( storageAvailable('localStorage') ) {
			window.localStorage.setItem('wallcoreUserLocation', address)
			window.localStorage.setItem('wallcoreSelectedFrameShopId', selectedFrameShopId)
		}
		dispatch( setUserLocation(address) )
		onContinue(selectedFrameShopId)
	}

	return (
		<Modal size="tiny" open={open}>
			<Modal.Header>Let's find a framer in your area:</Modal.Header>
			<Modal.Content>
				<Menu fluid secondary style={{position:'relative', zIndex:1001}}>
					<Menu.Item fitted>
						<Input type="text" 
							maxlength="5"
							inputmode="numeric"
							disabled={searching}
							loading={searching}
							size="huge"
							icon='map marker alternate'
							placeholder="Enter your zip code"
							onChange={handleAddressChange}
							/>
					</Menu.Item>
					<Dropdown item
						disabled={searching}
						value={distance}
						text={`within ${distance} miles`}
						options={[
							{ text:'25 miles', value:25 },
							{ text:'50 miles', value:50 },
							{ text:'100 miles', value:100 },
						]}
						onChange={handleDistanceChange}
						/>
				</Menu>
				{ errorMessage && ! searching &&
					<p className="color_red">
						<Icon name="exclamation circle" />
						{errorMessage}
					</p>
				}
			</Modal.Content>
			
			{ ! hasSearched && 
				<Image fluid src="https://res.cloudinary.com/wallcore/image/upload/w_1000,ar_16:9,c_fill,e_auto_brightness/v1598258747/marketing/DSC_1456_qn9bsa.jpg" />
			}

			{ hasSearched && ! errorMessage && 
				<Modal.Content>
					{ frameShops.length > 0 ? 
						<>
							<Header
								style={{fontWeight:'normal'}}
								>
								{`Found`} <strong>{frameShops.length}</strong> frame {frameShops.length > 1 ? 'shops' : 'shop'} within <strong>{distance} miles</strong> of <strong>{address}</strong>.
							</Header>
							{ frameShops.map( shop => 
								<Card fluid key={shop.id} color={ selectedFrameShopId === shop.id ? 'green' : undefined }>
									<Card.Content>
										<h2>{shop.title} <small className="faded ml_half">{shop.distance} miles</small></h2>
										<h4 className="my_0">
											{shop.customerTelephone && <span className="mr_half"><Icon name="phone" size="small" /> {shop.customerTelephone}</span>}
											{shop.customerEmail 	&& <span className="mr_half"><Icon name="paper plane" size="small" /> {shop.customerEmail}</span>}
											{shop.website 			&& <><br/><a href={shop.website} className="mr_half"><Icon name="mouse pointer" size="small" /> {shop.website.substr(shop.website.indexOf('://') + 3)}</a></> }
										</h4>
										<Card.Description className="display_flex align_items_center">
											<div>
												{shop.address1}, {shop.address2}
												<br/>{ nl2br(shop.hoursOfOperation) }
											</div>
											{ selectedFrameShopId === shop.id ?
												<Icon name="check" color="green" size="big" className="ml_auto"/>
												:
												<Button 
													content="Select"
													positive compact
													size="tiny"
													className="ml_auto"
													onClick={ event => setSelectedFrameShopId(shop.id) } 
													/>
											}
										</Card.Description>
									</Card.Content>
								</Card>
							) }
						</>
						
						: 	
						
						<>
							<Header size="large" className="icon width_100">
								<Icon name="shipping fast" color="green" />
								We're still building our network near {address}.
								<br/>For now, <u class="color_positive">we'll ship your items for free!</u>
							</Header>
							{ objectsProvided && 
								<Header size="medium" color="orange" textAlign="center" className="width_100">
									<Header.Content>
										<Icon name="info circle" />
										Please read:
									</Header.Content>
									<Header.Subheader>If you are framing an existing item, MyDIYWall will only be sending you the assembled project. You will be responsible for fitting your items into the frame.</Header.Subheader>
								</Header>
							}
						</>
					}
				</Modal.Content>
			}

			{ hasSearched && ! errorMessage &&
				<Modal.Actions>
					<Button 
						size="huge" 
						className="mx_0"
						positive fluid 
						onClick={handleContinue}
						disabled={!selectedFrameShopId}
						>
						Continue <Icon name="arrow right" />
					</Button>
				</Modal.Actions>
			}
		</Modal>
	)
}

export default FramerSearch