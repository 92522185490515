import React, { useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Button, Icon, Popup, Header } from 'semantic-ui-react'
import {updateElementProps} from '../../reducks/project'
import Section from './Section'
import CloudinaryApi from '../../api/cloudinaryApi'
import { storageAvailable } from '../../helpers'
import { updateUserMedia } from '../../reducks/user'
import UserUploads from '../library/UserUploads'
import { setSelectedImages, addSnack } from '../../reducks/app'
import { getCenteredImageCrop } from '../core/utilities'

const Images = props => {

    const { onClose, enableTaskbar, title, subtitle, draggable } = props
    const [deleting, setDeleting] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [loading, setLoading] = useState(false)

    const elements = useSelector( state => state.project.present.elements )
    const projectMats = useSelector( state => state.project.present.projectMats )
    const user = useSelector( state => state.user.meta )
    const userUploads = useSelector( state => state.user.uploads )
    const selectedImages = useSelector( state => state.app.selectedImages )
    const dispatch = useDispatch()

    const selectedImageIds = selectedImages.map(image => image.id)
    const bottomOpenings = elements.filter( elem => elem.component === 'Opening' && elem.depth === projectMats.length )

    function placeSelection() {
        bottomOpenings.forEach( (opening, o) => {
            if ( opening.editable && selectedImages[o] ) {
                const image = selectedImages[o]
                const crop = getCenteredImageCrop(image, opening.width, opening.height)
                dispatch( updateElementProps(opening.id, { 
                    image: {...image, crop}
                }) )
            }
        } )
        dispatch( setSelectedImages([]) )
    }

    async function deleteSelectedImages() {
        setDeleting(true)
        setDeleteConfirm(false)

        await deleteSelectedUploads()
        
        dispatch( setSelectedImages([]) )
        setDeleting(false)
    }

    async function deleteSelectedUploads() {
        
        const newUploads = userUploads.filter( upload => {
            return selectedImageIds.indexOf( upload.id ) < 0
        } )

        if ( user ) {
            // delete from Cloudinary
            const response = await CloudinaryApi.deleteAssets({
                public_ids: selectedImageIds.join()
            })
            if ( response.deleted ) {
                dispatch( updateUserMedia( newUploads ) )
            }
            if ( response.error ) {
                dispatch( addSnack({
                    id: 'delete-image-error',
                    message: `Couldn't delete image(s)`, 
                    description: response.error,
                    icon: 'exclamation circle', 
                    color: 'red',
                    dismissAfter: 3000
                }) )
            }
        }
        else {
            // update localStorage
            if ( storageAvailable('localStorage') ) {
                window.localStorage.setItem('wallcoreAnonymousUploads', JSON.stringify({resources: newUploads}))
                dispatch( updateUserMedia( newUploads ) )
            }
        }

        setDeleting(false)
    }

    return (
        <Section 
            title={title} 
            subtitle={subtitle} 
            onClose={onClose} 
            loading={loading}
            >
            <Section.Content>

                { subtitle && <p className="mb_1"><small>{subtitle}</small></p> }

                <UserUploads draggable={draggable} 
                    onLoadingStart={ message => setLoading(message) } 
                    onLoadingEnd={() => setLoading(false) } 
                    />
            </Section.Content>
                        
            <Section.Taskbar visible={ enableTaskbar && selectedImages.length > 0}>
                <Header size="tiny" className="my_0">{`(${selectedImages.length}) selected`}</Header>
                <Popup 
                    open={deleteConfirm} 
                    on="click"
                    trigger={<Button title="Delete selected images" basic size="mini" icon="trash" className="ml_1" loading={deleting} />}
                    onOpen={ () => setDeleteConfirm(true) }
                    onClose={ () => setDeleteConfirm(false) }
                    >
                    <Header size="tiny" color="red">{`Are you sure you want to delete the selected images?`}</Header>
                    <Button negative content="Yes, delete" onClick={ deleteSelectedImages } />
                </Popup>
                <Popup content="Deselect images"
                    inverted 
                    trigger={ 
                        <Button basic size="mini" icon="delete" className="ml_nudge" 
                            onClick={ () => dispatch(setSelectedImages([])) }
                        /> 
                    } />
                <Popup content="Place images in openings"
                    inverted 
                    trigger={ 
                        <Button color="yellow" size="mini" className="ml_auto mr_0"
                            onClick={() => placeSelection()}>
                            Place <Icon name="images" />
                        </Button>
                    } />
            </Section.Taskbar>
            
        </Section>
    )

}

export default Images
