import React, { useEffect, useState } from 'react'
import { Dropdown, Image, Header } from 'semantic-ui-react'
import { loadResources } from '../../reducks/resources'
import { useDispatch, useSelector } from 'react-redux'
import { getItemPrice, getCurrency } from './utilities'
import Currency from './Currency'
import { PPI } from '../../data/constants'

const ProductSelector = props => {
	const { 
        activeItem, 
        basic,
        className,
        clearable,
        filter,
        fluid,
        item,
        loadingText, 
        onSelect, 
        productHandle, 
        selection,
        selectText, 
        simple,
    } = props
    const _loadingText = loadingText || "Loading..."
	const _selectText = selectText || "Select..."
	
	const [loading, setLoading] = useState(false)

    let products = useSelector( state => state.resources[productHandle] )
	const {artboardwidth, artboardheight} = useSelector( state => state.project.present )
	const price = useSelector( state => state.price )
    const dispatch = useDispatch()
    
    if ( typeof filter === 'function' ) {
        products = products.filter(filter)
    }
	
	useEffect(() => {
		if ( ! products ) {
			setLoading(true)
			dispatch( loadResources( productHandle, (json, error) => {
				setLoading(false)
			} ) )
		}
    }, [products])
    
    return (
        <Dropdown 
            basic={basic}
            fluid={fluid}
            className={`${className} ${selection ? 'selection' : ''}`}
            clearable={clearable}
            simple={simple}
            item={item}
            loading={loading}
            disabled={loading}
            text={ loading ? _loadingText : ( activeItem ? `${activeItem.title} — ${getCurrency(price[productHandle])}` : _selectText) }
            >
            <Dropdown.Menu>
                { products && products.map( (product, i) => {
                    const isActive = activeItem && activeItem.sku === product.sku;
                    const price = getItemPrice(product.price, artboardwidth/PPI, artboardheight/PPI, window.Craft.pricing[productHandle])
                    return (
                        <Dropdown.Item key={i} active={isActive} onClick={ () => { onSelect(product) } }>
                            <div className="display_flex width_100 align_items_center">
                                { product.image && 
                                    <Image src={product.image} className="mr_half" width={60} height={60} />
                                }
                                <div>
                                    <Header size="tiny" color={isActive ? 'blue' : undefined} className="ma_0">
                                        {product.title} <Currency value={price} className="ml_half"/>
                                    </Header>
                                    { product.description &&
                                        <p className="ma_0 faded">{product.description}</p>
                                    }
                                </div>
                            </div>
                        </Dropdown.Item>
                    )
                } ) }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProductSelector