import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Button, Input, Icon, TextArea, Popup, Dropdown, Message } from 'semantic-ui-react'
import { updateElementProps } from '../../reducks/project'
import { CompactPicker } from 'react-color'
import {throttle, getItemBy, sortASC} from '../../helpers'
import { addFontFamily, addFontPreviews } from '../../reducks/app'
import ResourcesApi from '../../api/resourcesApi'
import { loadResourceSuccess } from '../../reducks/resources'

const TextToolset = (props) => {

    const [errorMessage, setErrorMessage] = useState(null)
    const [loadingFonts, setLoadingFonts] = useState(false)

    const { activeElement } = useSelector( state => state.tools )
    const { elements } = useSelector( state => state.project.present )
    const fonts = useSelector( state => state.resources.fonts )
    const fontFamilies = useSelector( state => state.app.fontFamilies )
    const activeElementObject = getItemBy('id', activeElement, elements) || {}
    const dispatch = useDispatch()

    const {fontFamily, fontSize, fontWeight, fontStyle, textAlign, color, content, lineHeight} = activeElementObject
    const fontButton        = <Button fluid basic loading={loadingFonts} disabled={loadingFonts}>{fontFamily}, {fontSize}pt <Icon name="dropdown" className="mr_0 ml_half"/></Button>
    const weightButton      = <Button icon="bold" active={fontWeight === 'bold'} tool="fontWeight" onClick={ handleSelectTool } />
    const italicButton      = <Button icon="italic" active={fontStyle === 'italic'} tool="fontStyle" onClick={ handleSelectTool } />
    const alignLeftButton   = <Button icon="align left" active={textAlign === 'left'} tool="textAlign_left" onClick={ handleSelectTool } />
    const alignCenterButton = <Button icon="align center" active={textAlign === 'center'} tool="textAlign_center" onClick={ handleSelectTool } />
    const alignRightButton  = <Button icon="align right" active={textAlign === 'right'} tool="textAlign_right" onClick={ handleSelectTool } />
    const colorButton       = <Button fluid icon="tint" content="Color" className="mr_0 mt_nudge" style={{backgroundColor: color || ''}}/>
    
    const fontOptions = fonts.map( font => {
        return {
            key: font.title,
            value: font.title + '&&' + font.importUrl,
            text: font.title,
            content: (
                <span style={{fontFamily: font.title}}>{font.title}</span>
            )
        }
    } )
    fontOptions.sort( sortASC('text') )

    useEffect( () => {
        if ( fonts.length === 0 ) {
            setLoadingFonts(true)
            ResourcesApi.getResource('fonts')
                .then( json => {
                    const { error, data: fonts } = json
                    if ( error ) {
                        setErrorMessage(error)
                        console.error(error)
                        console.trace()
                    }
                    else {
                        const fontPreviews = fonts.map( font => font.previewImportUrl )
                        dispatch( addFontPreviews(fontPreviews) )
                        dispatch( loadResourceSuccess('fonts', fonts) );
                        setLoadingFonts(false)
                    }
                } )
        }
    }, [] )

    function handleSelectTool(event, { tool, value }) {

        switch( tool ){
            case 'color':
                dispatch( updateElementProps( activeElement, { color: value } ) )
                break
            case 'fontFamily':
                let font = value.split('&&')[0]
                let fontUrl = value.split('&&')[1]
                dispatch( updateElementProps( activeElement, { fontFamily: font, fontUrl: fontUrl } ) )
                if ( fontUrl && fontFamilies.indexOf( fontUrl ) < 0 ) {
                    dispatch( addFontFamily(fontUrl) )
                }
                break
            case 'fontSize':
                dispatch( updateElementProps( activeElement, { fontSize: value } ) )
                break
            case 'fontWeight':
                var weight = ! fontWeight || fontWeight === 'normal' ? 'bold' : 'normal'
                dispatch( updateElementProps( activeElement, { fontWeight: weight} ) )
                break
            case 'fontStyle':
                var style = ! fontStyle || fontStyle === 'normal' ? 'italic' : 'normal'
                dispatch( updateElementProps( activeElement, { fontStyle: style} ) )
                break
            case 'textAlign_left':
                dispatch( updateElementProps( activeElement, { textAlign: tool.substr(10) } ) )
                break
            case 'textAlign_center':
                dispatch( updateElementProps( activeElement, { textAlign: tool.substr(10) } ) )
                break
            case 'textAlign_right':
                dispatch( updateElementProps( activeElement, { textAlign: tool.substr(10) } ) )
                break
            case 'lineHeight':
                dispatch( updateElementProps( activeElement, { lineHeight: value } ) )
                break
            case 'editTextContent':
                dispatch( updateElementProps( activeElement, { content: value } ) )
                break
            default:
                return
        }
    }

    function onChangeColor(color) {
        handleSelectTool(null, {tool: 'color', value: color.hex})
    }

    return (
    <div className="TextToolset"
        onClick={ event => event.stopPropagation() }
        onKeyUp={ event => event.stopPropagation() }
        onKeyDown={ event => event.stopPropagation() }
        onDragStart={ event => event.stopPropagation() }
        onDrag={ event => event.stopPropagation() }
        onDragEnd={ event => event.stopPropagation() }
        onMouseDown={ event => event.stopPropagation() }
        onMouseMove={ event => event.stopPropagation() }
        onMouseUp={ event => event.stopPropagation() }
        >

        <div className="ui form mb_half">
            <TextArea rows="1" value={content}
                tool="editTextContent"
                style={{textAlign}}
                onChange={ throttle( handleSelectTool, 100 ) }
                onFocus={ e => e.target.select() }
                />
        </div>

        <Popup trigger={fontButton} on="click" position="bottom center" style={{minWidth:220}}>
            <label htmlFor="fontFamily" className="display_block"><strong>Font Family</strong></label>
            <Dropdown
                placeholder='Select a font'
                fluid
                selection
                options={fontOptions}
                id="fontFamily" 
                tool="fontFamily" 
                onChange={ handleSelectTool } 
            />
            <Grid columns={2}>
                <Grid.Column>
                    <label htmlFor="fontSize" className="display_block mt_nudge"><strong>Size</strong></label>
                    <Input fluid id="fontSize" value={fontSize} type="number" step={10} tool="fontSize" onChange={ handleSelectTool } />
                </Grid.Column>
                <Grid.Column>
                    <label htmlFor="lineHeight" className="display_block mt_nudge"><strong>Line Height</strong></label>
                    <Input fluid id="lineHeight" value={lineHeight} type="number" min={1} max={3} step={0.1} tool="lineHeight" onChange={ handleSelectTool } />
                </Grid.Column>
            </Grid>
        </Popup>

        { errorMessage && 
            <Message negative>{errorMessage}</Message>
        }

        <Button.Group fluid basic className="mt_nudge">
            {weightButton}
            {italicButton}
        </Button.Group>

        <Button.Group fluid basic className="mt_nudge">
            {alignLeftButton}
            {alignCenterButton}
            {alignRightButton}
        </Button.Group>

        <Popup inverted trigger={colorButton} on='click' wide position="bottom center">
            <CompactPicker color={color} onChange={onChangeColor} />
        </Popup>

    </div>)
}

export default TextToolset
