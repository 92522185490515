import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Portal, Card, Button, Header, Icon, Responsive, Label, Divider } from "semantic-ui-react"
import { Redirect, useHistory } from 'react-router-dom'
import { getBottomOpenings, getCenteredImageCrop } from '../core/utilities'
import { updateElementProps } from '../../reducks/project'

const GalleryCardPreview = (props) => {

	const [redirect, setRedirect] = useState('')

	const {
		open,
		card,
		onClose,
		onSelectImage,
		onDeselectImage,
		onToggle,
	} = props

	const {image, inLibrary, syncing, canFeature, featured} = card

	const project = useSelector( state => state.project.present )
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {

		// control scrolling when preview is open
		if ( open ) {
			window.document.body.classList.add('overflow_hidden')
		}
		else {
			window.document.body.classList.remove('overflow_hidden')
		}

		return () => {
			// allow scrolling when dismounted
			window.document.body.classList.remove('overflow_hidden')
		}
	})

	const onAddToProject = (event, data) => {
		event.stopPropagation()
		onSelectImage(event)
		const emptyOpening = getBottomOpenings(project).find( opening => opening.editable === true && ! opening.image )
		if ( emptyOpening ) {
			const crop = getCenteredImageCrop(image, emptyOpening.width, emptyOpening.height)
			dispatch( updateElementProps(emptyOpening.id, { 
				image: {...image, crop} 
			}) )
		}
		setRedirect(`/designer/editor/${project.id || 'new'}?imagesView=art` )
	}

	const onFrameImage = (event, data) => {
		event.stopPropagation()
		onSelectImage(event)
		history.push(`/designer/setup/openings?cat=upload&art=${encodeURIComponent(JSON.stringify(image))}`)
	}

	function handleClose(event) {
		event.stopPropagation()
		onClose()
	}
	
	function handleOutsideClick(event) {
		event.stopPropagation()
		if ( event.target.classList.contains('GalleryCardPreview') ) {
			handleClose(event)
		}
	}

	const cardContent = <>
		<Card.Content>
			<Button negative circular compact size="mini" icon="delete" 
				style={{position:'absolute',top:'-1rem',right:'-1rem'}}
				onClick={handleClose}
				/>
			<Header size="small" className="mt_0">
				<Header.Content>
					{image.alt}
					<Header.Subheader>{image.author}</Header.Subheader>
				</Header.Content>
			</Header>
			<Label 
				color="green"
				basic={ ! inLibrary } 
				className="cursor_pointer"
				onClick={ inLibrary ? onDeselectImage : onSelectImage }
				>
				{ syncing && 
					<Icon name="cog" loading={syncing} fitted={syncing} />
				}
				{ ! syncing &&
					( inLibrary ? 
						<>
							<Icon name="check" />
							{`In Library`}
							<Icon name="delete"/>
						</>
						: 
						<>
							<Icon name="add circle" />
							{`Add to library`}
						</>
					)
				}
			</Label>
		</Card.Content>
		<Card.Content>
			{/* { ! inLibrary && 
				<Button content="Add to Library"
					fluid 
					color='yellow'
					icon="plus"
					loading={syncing}
					onClick={ onSelectImage }
					/>
			} */}
			{/* { inLibrary && <> */}
				<Button content="Frame this image" fluid
					color="yellow"
					icon="image outline"
					onClick={ onFrameImage }
					/>
				{ project.dateCreated && <>
					<Divider horizontal>Or</Divider>
					<Button content="Add to project" fluid
						color="yellow"
						icon="plus"
						onClick={ onAddToProject }
						/>
				</>}
			{/* </>} */}
		</Card.Content>
		{ canFeature &&
			<Card.Content>
				<Card.Description as="p"><Icon name="lock"/> <strong>Admin Only:</strong></Card.Description>
				<Button 
					fluid basic
					color={ featured ? "blue" : undefined }
					icon={ featured ? "star" : "star outline"}
					loading={syncing}
					content={ featured ? "Featured" : "Feature image"}
					onClick={ onToggle }
					/>
			</Card.Content>
		}
	</>

	return redirect ? 
		<Redirect to={redirect} />
		: (
			<Portal open={open} onClose={ handleClose }>
				<div className="GalleryCardPreview bg_dimmer position_fixed top_0 right_0 left_0 bottom_0 pa_1"
					onClick={ handleOutsideClick }
					>
					<Responsive as={Card} fluid maxWidth={Responsive.onlyTablet.maxWidth}>
						{cardContent}
					</Responsive>
					<Responsive as={Card} minWidth={Responsive.onlyComputer.minWidth}>
						{cardContent}
					</Responsive>
					<img className="box_shadow_strong" src={image.src.replace( / /g, encodeURIComponent(' ') )} alt={image.alt} />
				</div>
			</Portal>
		)

}

export default GalleryCardPreview