import React from 'react'
import { useState } from 'react'
import { Button, Header, Segment } from 'semantic-ui-react'
import { getColorData, sortDESC } from '../../helpers'
import MatSwatch from './MatSwatch'

const MatPalette = (props) => {

	const { 
		projectMats, 
		activeMat, 
		onSelectMat, 
	} = props
	
	const matProducts = sortMats(props.matProducts)

	const [showMoreDark, setShowMoreDark] = useState(false)
	const [showMoreMedium, setShowMoreMedium] = useState(false)
	const [showMoreLight, setShowMoreLight] = useState(false)

	function sortMats(matCollection) {

        matCollection = matCollection.map( mat => (
			{ 
				...mat, 
				...getColorData(mat.hex) 
			}
        ) )

        const dark = []
        const colorful = []
        const light = []

        matCollection.forEach( mat => {
			const { sat, val } = mat
            if ( sat > 0.2 && (val > 0.25 || val < 0.75)  ) {
				colorful.push(mat)
			}
			else {
				if ( val < 0.6 ) {
					dark.push(mat)
				}
				else {
					light.push(mat)
				}
			}
        } )

		return {
			dark: dark.sort( sortDESC('val') ),
			colorful: __organize(colorful),
			light: light.sort( sortDESC('val') ) 
		}

        function __organize(collection) {
			const redRange = collection
				.filter( c => c.hue > 300 || c.hue <= 60 )
				.sort( sortDESC('val') )
			const greenRange = collection
				.filter( c => c.hue > 60 && c.hue <= 180 )
				.sort( sortDESC('val') )
			const blueRange = collection
				.filter( c => c.hue > 180 && c.hue <= 300 )
				.sort( sortDESC('val') )
			
			return [...redRange, ...greenRange, ...blueRange]
        }
    }

    if ( ! projectMats.length )
        return ''

    return (
		<Segment basic vertical className="MatPalette">
			<Header size="tiny">Light Neutrals</Header>
			<div className="display_flex flex_wrap">
				{ matProducts.light.map( (mat, m) => {
					return (showMoreLight || mat.isFeatured) &&
						<MatSwatch 
							key={m} 
							mat={mat} 
							isCurrent={ projectMats[activeMat] && projectMats[activeMat].sku === mat.sku } 
							onClick={ () => onSelectMat(mat) } 
							/> 
				} ) }
				<Button basic icon={ showMoreLight ? "chevron up" : "chevron down"} style={{margin:'0 0 0.5rem'}} 
					onClick={ () => setShowMoreLight( ! showMoreLight ) }/>
			</div>

			<Header size="tiny">Dark Neutrals</Header>
			<div className="display_flex flex_wrap">
				{ matProducts.dark.map( (mat, m) => {
					return (showMoreDark || mat.isFeatured) &&
						<MatSwatch 
							key={m} 
							mat={mat} 
							isCurrent={ projectMats[activeMat] && projectMats[activeMat].sku === mat.sku } 
							onClick={ () => onSelectMat(mat) } 
							/> 
				} ) }
				<Button basic icon={ showMoreDark ? "chevron up" : "chevron down"} style={{margin:'0 0 0.5rem'}} 
					onClick={ () => setShowMoreDark( ! showMoreDark ) }/>
			</div>

			<Header size="tiny">Colorful</Header>
			<div className="display_flex flex_wrap">
				{ matProducts.colorful.map( (mat, m) => {
					return (showMoreMedium || mat.isFeatured) &&
						<MatSwatch 
							key={m} 
							mat={mat} 
							isCurrent={ projectMats[activeMat] && projectMats[activeMat].sku === mat.sku } 
							onClick={ () => onSelectMat(mat) } 
							/> 
				} ) }
				<Button basic icon={ showMoreMedium ? "chevron up" : "chevron down"} style={{margin:'0 0 0.5rem'}} 
					onClick={ () => setShowMoreMedium( ! showMoreMedium ) }/>
			</div>
		</Segment>
	)
}

export default MatPalette