import React, { useEffect, useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Button, Dimmer, Loader, Icon } from 'semantic-ui-react'
import { cleanProjectData, calculateLayout } from './core/utilities'
import { generateUID, getQueryObject } from '../helpers'
import { get } from '../api/fetchKit'
import { setSelectedImages } from '../reducks/app'
import { setCategory } from '../reducks/setup'
import { updateProject, removeAllElements, setMats, updateElementProps, addElements, setGlass } from '../reducks/project'
import { useSelector, useDispatch } from 'react-redux'
import defaultProjectData from '../data/defaultProjectData'
import Category from './setup/Category'
import LayoutCustomizer from './setup/LayoutCustomizer'
import LayoutPreview from './setup/LayoutPreview'
import Openings from './setup/Openings'
import OpeningSources from './setup/OpeningSources'
import Sidebar from './sidebar/Sidebar'
import TemplateFilters from './setup/TemplateFilters'
import TemplateGallery from './setup/TemplateGallery'
import { categories } from './setup/vars'
import { PPI } from '../data/constants'

const Setup = props => {

	const { history, location, match: {params: {stepId}} } = props
	const steps = ['category', 'openings', 'layout']
	const prevStep = steps[steps.indexOf(stepId) - 1]
	const nextStep = steps[steps.indexOf(stepId) + 1]

	const [creating, setCreating] = useState(false)
	const [loadingTemplates, setLoadingTemplates] = useState(false)
	const [layoutView, setLayoutView] = useState('customize')
	const [tooLarge, setTooLarge] = useState(false)

	const { requireShipping } = useSelector( state => state.app )
	const { category, openings, layout, borders, gutters } = useSelector( state => state.setup )
	const dispatch = useDispatch()

	useEffect(redirect, [])

	useEffect(() => {
		if ( stepId === 'layout' && layoutView !== 'customize' ) {
			setLayoutView('customize')
		}
	}, [stepId])

	useEffect(() => {
		window.document.title = 'Create New Project | Designer | MyDIYWall'
	}, [])

	function redirect() {

		const { cat } = getQueryObject(location.search)
		if ( cat ) {
			const catObject = categories.find( _c => _c.id === cat )
			if ( catObject ) {
				dispatch(setCategory( catObject ))
			}
		}

		// if ( ! selectedFrameShop && ! window.Craft.frameShopId ) {
		// 	history.push('/designer/choose-framer' + location.search)
		// 	return
		// }
		if ( ! category ) {
			history.push('/designer/setup/category' + location.search)
			return
		}
		if ( ! openings || ! openings.length ) {
			history.push('/designer/setup/openings' + location.search)
			return
		}
	}

	function canMoveOn() {
		switch (stepId) {
			// case 'framer':
			// 	return selectedFrameShop instanceof Object
			case 'category':
				return category instanceof Object
			case 'openings':
				return openings instanceof Object && openings.length > 0
			default:
				return true
		}
	}
	
	function nextButtonText() {
		// switch (stepId) {
		// 	// case 'framer':
		// 	// 	return selectedFrameShop instanceof Object ? "Next" : "Select a framer"
		// 	case 'category':
		// 		return category instanceof Object ? "Next" : "Select a category"
		// 	case 'openings':
		// 		return openings instanceof Object && openings.length > 0 ? "Next" : "Select images/openings"
		// 	default:
				return "Next"
		// }
	}

	const onUpdateLayoutPreview = tooLarge => {
		setTooLarge(tooLarge)
	}

	const createProject = async () => {
        let columns, rows

        setCreating(true)

        switch (layout) {
            case 'grid':
                columns = Math.ceil(Math.sqrt(openings.length))
                rows = Math.ceil(openings.length/columns)
                break;
            case 'vertical':
                columns = 1
                rows = openings.length
                break;
            default:
                columns = openings.length
                rows = 1
        }

		const isMatlessSingle = openings.length === 1 && (borders.top === 0 || borders.side === 0 || borders.bottom === 0)
        let projectLayout = calculateLayout(openings, {
			borders,
			insideHorzMargin: gutters,
			insideVertMargin: gutters,
			layout: { columns, rows },
		})

        // setup default data
        let defaultData = defaultProjectData

        // check for a designated default template
        if ( window.Craft.defaultTemplateId ) {
            defaultData = await get(`/api/project-templates/${window.Craft.defaultTemplateId}.json`)
            defaultData = cleanProjectData( defaultData )
        }

		// Set properties based on user input
		const projectTitle = `${projectLayout.width/PPI} x ${projectLayout.height/PPI} - ${generateUID()}`
        let newProjectData = {
			...defaultData,
            title: isMatlessSingle ? projectTitle : `${projectLayout.openings.length} Opening ${projectTitle}`,
            artboardwidth: projectLayout.width,
            artboardheight: projectLayout.height,
			type: 'projects',
			fromTemplate: false,
			objectsProvided: category.id === 'existing',
			objectsDescription: category.id === 'existing' ? 'My own artwork or document' : '',
			imagePrinting: category.id !== 'existing',
            dateCreated: new Date().toISOString()
        }

        dispatch( updateProject(newProjectData) )

        // Remove all exisiting project elements
        dispatch( removeAllElements() )

        // set mats
        dispatch( setMats( isMatlessSingle ? 0 : 1 ) )

		// set source opening width/height
		if ( isMatlessSingle ) {
			dispatch( updateElementProps( 'source-opening', {
				...projectLayout.openings[0],
				id: 'source-opening',
				parent: undefined,
				depth: 0
			} ) )
		}
		else {
			dispatch( updateElementProps( 'source-opening', {
				width: projectLayout.width,
				height: projectLayout.height
			} ) )
			dispatch( addElements(projectLayout.openings) )
		}

		dispatch( setSelectedImages([]) )

		// if require shipping
		if ( requireShipping ) {
			dispatch( setGlass(null) )
		}

        props.history.push('/designer/editor/new')
	}
	
	return (
		<div id="Setup" className="Editor">

			<Sidebar>

				{ stepId !== 'category' && 
					<Sidebar.Content>

						<Route path="/designer/setup/openings" component={OpeningSources} />
						<Route path="/designer/setup/layout" render={ routeProps => {
							return layoutView === 'template' ? 
								<TemplateFilters {...routeProps} 
									onChangeView={setLayoutView}
									/> 
								: <LayoutCustomizer 
									onChangeView={setLayoutView}
									{...routeProps} 
									/>
						} } />

						<Sidebar.Navigation>
							<div className="display_flex align_items_center width_100">
								{ prevStep && 
									<Button as={Link} to={`/designer/setup/${prevStep}${location.search}`} 
										basic inverted
										icon="arrow left" 
										content="Back"
										className="flex_shrink_0 mr_half"
										/>
								}
								{ nextStep && 
									<Button as={Link} to={`/designer/setup/${nextStep}${location.search}`}
										primary
										disabled={ ! canMoveOn() }
										className="flex_grow_1"
										>
										{nextButtonText()}
										<Icon name="arrow right" />
									</Button>
									}
								{ ! nextStep && 
									<Button primary onClick={ () => createProject() }
										disabled={ ! canMoveOn() || tooLarge || creating }
										loading={creating}
										className="flex_grow_1"
										>
										{nextButtonText()}
										<Icon name="arrow right" />
									</Button>
								}
							</div>
						</Sidebar.Navigation>
					</Sidebar.Content>
				}
			</Sidebar>
			
			<div className="Workspace display_flex align_items_center justify_content_center">
				<Dimmer.Dimmable dimmed={loadingTemplates} className="overflow_x_hidden width_100 height_100">
					<Switch>
						<Route path="/designer/setup/category" render={ routeProps => 
							<Category {...routeProps} onSelect={ cat => history.push(`/designer/setup/${nextStep}`) } />
						} />
						<Route path="/designer/setup/openings" component={Openings} />
						<Route path="/designer/setup/layout" render={ routeProps => {
							return layoutView === 'template' ? 
								<TemplateGallery 
									onSetLoading={setLoadingTemplates} 
									loading={loadingTemplates} 
									{...routeProps}
									/>
								: <LayoutPreview {...routeProps} onUpdate={onUpdateLayoutPreview}/> 
						} } 
						/>
					</Switch>
					<Dimmer active={loadingTemplates} inverted>
						<Loader />
					</Dimmer>
				</Dimmer.Dimmable>
			</div>

		</div>
	)

}

export default Setup