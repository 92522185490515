import { getFraction } from "../core/Fraction"

export const categories = [
	{
		id: 'upload',
		name: 'My Digital Photos/Art',
		description: 'From your computer, social media, phone or any digital file. If you have it, you can frame it!',
		image: window.Craft.baseUrl + '/assets/images/digital-art@2x.jpg'
	}, 
	{
		id: 'existing',
		name: 'My Art, Posters, or Documents',
		description: 'Already have your art or need a new frame for an older piece? Just tell us the dimensions.',
		image: 'https://res.cloudinary.com/wallcore/image/upload/w_700/v1598274785/rooms/shutterstock_1079967491_wedypa.jpg'
	}, 
	{
		id: 'template',
		name: 'Photo Collages and Templates',
		href: window.Craft.baseUrl + "/templates",
		description: 'We have 100s of pre-designed mats, ready for your photos and special touch.',
		image: 'https://res.cloudinary.com/wallcore/image/upload/w_660,e_auto_brightness/v1598258754/marketing/DSC_1538_tdpojl.jpg'
	}, 
	// {
	// 	id: 'shop-art',
	// 	name: 'Shop Our Art Library',
	// 	description: 'Browse our huge selection of art, prints, & photographs – more than 150,000 pieces.',
	// 	image: window.Craft.baseUrl + '/assets/images/shop-art-mosaic@2x.png',
	// 	icon: "shopping cart",
	// 	to: "/designer/shop-art",
	// },
	// {
	// 	id: '3d',
	// 	name: 'Objects & Memorabilia',
	// 	description: 'Coming soon!', //'Not all memories are flat & that\'s okay! You can custom frame anything your heart desires – professionally.',
	// 	// href: "/templates",
	// 	disabled: true,
	// 	image: window.Craft.baseUrl + '/assets/images/objects.jpg'
	// }, 
]

export const commonSizesPortrait = [
	{ dimensions: "5 x 7",        label:"Small Image",        width: 5,       height: 7 },
	{ dimensions: "8 x 10",       label:"Large Image",        width: 8,       height: 10 },
	{ dimensions: "11 x 14",      label:"Small Poster",       width: 11,      height: 14 },
	{ dimensions: "18 x 24",      label:"Poster",             width: 18,      height: 24 },
	{ dimensions: "20 x 24",      label:"Large Poster",       width: 20,      height: 24 },
	{ dimensions: "24 x 36",      label:"Huge Poster",        width: 24,      height: 36 },
]
export const commonSizesSquare = [
	{ dimensions: "5 x 5",        label:"5\" Square",         width: 5,       height: 5 },
	{ dimensions: "10 x 10",      label:"10\" Square",        width: 10,      height: 10 },
	{ dimensions: "12 x 12",      label:"Vinyl Record",       width: 12,      height: 12 },
	{ dimensions: "14 x 14",      label:"14\" Square",        width: 14,      height: 14 },
	{ dimensions: "18 x 18",      label:"18\" Square",        width: 18,      height: 18 },
	{ dimensions: "24 x 24",      label:"24\" Square",        width: 24,      height: 24 },
]
export const commonSizesLandscape = [
	{ dimensions: "7 x 5",        				label:"Small Image",        width: 7,       height: 5 },
	{ dimensions: "14 x 11",      				label:"Small Poster",       width: 14,      height: 11 },
	{ dimensions: "24 x 18",      				label:"Large Poster",       width: 24,      height: 18 },
	{ dimensions: `11 x ${getFraction(8.5)}`, 	label:"Diploma",            width: 11,      height: 8.5 },
	{ dimensions: "14 x 11",      				label:"PhD Diploma",        width: 14,      height: 11 },
	{ dimensions: `22 x ${getFraction(15.75)}`,	label:"Doctoral Diploma",   width: 22,      height: 15.75 },
]

export const defaultOpening = { width: 8, height: 10, depth: 0, image: null }