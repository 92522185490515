import initialState from './_initialState';
import {deepCopyObject} from '../helpers'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function updateCart( cart ) {
    return { type: 'UPDATE_CART', cart }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function cartReducer( state = initialState.cart, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )

    switch(action.type) {

        case 'UPDATE_CART':
            newState.lineItems = action.cart.lineItems
            newState.totalQty = action.cart.totalQty
            break

        default:
            return newState

    }

    return newState;
}
