import {post, get} from './fetchKit'

export default class EntriesApi {

    /**
     * Get a 'projects' or 'projectTemplates' entry
     * @param {String} id | the entry ID
     */
    static getProjectEntry(id) {
        return get(`/api/project-entries/${id}.json`)
    }
    
    /**
     * Get a template from an Element API endpoint
     * @param {String} id | the entry ID
     */
    static getTemplate(id) {
        return get(`/api/project-templates/${id}.json`)
    }

    /**
     * Save an entry to Craft. Posts to the "entries/save-entry" action.
     * @param {String} sectionId | the Craft section ID
     * @param {Object} data | a plain object of data to pass to the entry
     */
    static saveEntry(sectionId, data) {

        const staticData = {
            sectionId,
            enabled: "1",
        }
        data = {...data, ...staticData}

        return post('/actions/entries/save-entry', data)
    }

    /**
     * Delete an entry from Craft. Posts to the "entries/delete-entry" action.
     * @param {String} entryId | the ID of the entry to delete
     */
    static deleteEntry(entryId) {
        return post('/actions/entries/delete-entry', {entryId})
    }

}
