import React from 'react'
import ErrorMessage from './ErrorMessage';

const RegisterForm = (props) => {
    const { data: formData } = props

    return (
        <form className="ui equal width form" method="post" acceptCharset="UTF-8" 
            onSubmit={ e => { e.preventDefault(); props.onSubmit() } }
            >
            <div className="field">
                <label htmlFor="email">Email Address *</label>
                <input id="email" type="text" name="email" value={formData.email} 
                    onChange={ event => props.onChange({ name: 'email', value: event.target.value, type: 'text' }) } 
                    />
            </div>                
            <div className="two fields">
                <div className="field">
                    <label htmlFor="firstName">First Name</label>
                    <input id="firstName" type="text" name="firstName" value={formData.firstName} 
                        onChange={ event => props.onChange({ name: 'firstName', value: event.target.value, type: 'text' }) } 
                        />
                </div>            
                <div className="field">
                    <label htmlFor="lastName">Last Name</label>
                    <input id="lastName" type="text" name="lastName" value={formData.lastName} 
                        onChange={ event => props.onChange({ name: 'lastName', value: event.target.value, type: 'text' }) } 
                        />
                </div>                
            </div>
            <div className="two fields">
                <div className="field">
                    <label htmlFor="username">Create a username *</label>
                    <input id="username" type="text" name="username" value={formData.username} 
                        onChange={ event => props.onChange({ name: 'username', value: event.target.value, type: 'text' }) } 
                        />
                </div>                
                <div className="field">
                    <label htmlFor="password">Choose a password *</label>
                    <input id="password" type="password" name="password" value={formData.password} 
                        onChange={ event => props.onChange({ name: 'password', value: event.target.value, type: 'password' }) } 
                        />
                </div>                
            </div>

            { props.error &&
                <ErrorMessage error={props.error} />
            }

            <input type="submit" value="Register" style={{display:"none"}} />

        </form>
    )
}

export default RegisterForm