import React from 'react'
import {connect} from 'react-redux'
import { setProjectThumbnail } from '../../reducks/project'
import { Image, Loader, Placeholder, Button } from 'semantic-ui-react'
import { 
    generatePreview, 
    // getPrintPPI 
} from './utilities'

class ProjectPreview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            generatingPreview: false,
            error: false
        }
        this.getImage = this.getImage.bind(this)
    }

    componentDidMount() {
        this.getImage()
    }
    
    async getImage() {
        
        if ( typeof this.props.loadingStart === 'function' ) {
            this.props.loadingStart();
        }
        
        this.setState({ generatingPreview: true, error: false })

        const dataUrl = await generatePreview({ excludeFrame: true })
        this.props.dispatch( setProjectThumbnail( dataUrl ) )
        
        this.setState({ generatingPreview: false })
        if ( typeof this.props.loadingComplete === 'function' ) {
            this.props.loadingComplete();
        }

    }

    render() {
        const { generatingPreview, error } = this.state
        const { projectThumbnail, fluid, centered, size, style } = this.props
        const inlineStyle = {
            minHeight: 150, 
            minWidth: 150, 
            ...style
        }

        return (
            <Image fluid={fluid} centered={centered} style={inlineStyle} size={ fluid ? undefined : size || 'medium' }>
                { generatingPreview ?
                    <>
                        <Placeholder inverted fluid style={{ height: 150 }}>
                            <Placeholder.Image />
                        </Placeholder>
                        <Loader active>Generating Preview</Loader>
                    </>
                    :
                    ( error ?
                        <div className="pa_1" style={{border:'2px dashed lightgray'}}>
                            <p>Could not load the preview</p>
                            <Button content="retry" size="mini" onClick={() => this.getImage()} />
                        </div>
                        :
                        <img src={projectThumbnail} alt="project preview" />
                    )
                }
            </Image>
        )
    }

}

function mapStateToProps(state) {
    return {
        projectThumbnail: state.project.present.thumbnail,
        artboardwidth: state.project.present.artboardwidth,
        artboardheight: state.project.present.artboardheight,
    }
}

export default connect(mapStateToProps)(ProjectPreview)
