import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { 
    setGlass, 
    setPaper, 
    // setAssembly, 
    // setMounting, 
    // setPrinting, 
    // setObjectsProvided, 
    // setObjectsDescription, 
    setProjectTitle 
} from '../../reducks/project'
import { 
    // Checkbox, 
    Segment, 
    // Form, 
    Input, 
    Header,
    // Button, 
    // Accordion 
} from 'semantic-ui-react'
import Section from './Section'
// import { sanitizeHTML } from '../../helpers'
// import ProductSelector from '../core/ProductSelector'

class Options extends Component {

    constructor(props) {
        super(props)

        this.state = {
            includeGlass: true,
            localProjectTitle: props.projectTitle,
            localObjectsDescription: props.objectsDescription,
            showAssemblyDesc: false,
            showMountingDesc: false
        }

        this.selectPaper = this.selectPaper.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if ( 
            ! this.state.includeGlass
            && this.props.selectedGlass instanceof Object 
            && ! prevProps.selectedGlass 
        ) {
            this.setState({ includeGlass: true })
        }

        if ( prevProps.objectsDescription !== this.props.objectsDescription ){
            this.setState({ localObjectsDescription: this.props.objectsDescription })
        }
    }

    selectPaper(paper) {
        this.props.dispatch( setPaper(paper) )
    }
    
    toggleGlass() {
        this.setState({ includeGlass: ! this.state.includeGlass })
        this.props.dispatch( setGlass(null) )
    }

    render() {

        const { 
            // includeGlass, 
            localProjectTitle, 
            // localObjectsDescription, 
            // showAssemblyDesc, 
            // showMountingDesc 
        } = this.state
        const { 
            dispatch, 
            // assembly, 
            // mounting, 
            // imagePrinting, 
            // selectedPaper, 
            // selectedGlass, 
            // objectsProvided, 
            // requireShipping, 
            onClose, 
            title, 
            subtitle 
        } = this.props

        return ( 
        <Section title={title} onClose={onClose}>

            <Section.Content>

                { subtitle && <p className="mb_1"><small>{subtitle}</small></p> }

                <Segment basic vertical>
                    <Header>Project Title</Header>
                    <Input fluid
                        id="projectTitleInput"
                        value={localProjectTitle}
                        icon="text cursor"
                        onClick={ (event, data) => event.stopPropagation() }
                        onChange={ (event, data) => this.setState({ localProjectTitle: data.value}) }
                        onBlur={ (event, data) => {
                            dispatch( setProjectTitle(localProjectTitle) )
                        } }
                        onFocus={ (event) => { event.target.select() }}
                        />
                </Segment>
                
                {/* <Segment basic vertical color="grey">
                    <Checkbox name="objectsProvided" checked={ objectsProvided } label={<label><strong>I will provide my item(s) to be framed.</strong></label>} onChange={ (e, {checked}) => dispatch( setObjectsProvided(checked) ) } />
                    { objectsProvided && 
                        <Form>
                            <Input fluid icon="text cursor"
                                placeholder="My own artwork or document" 
                                value={localObjectsDescription} 
                                className="mt_nudge"
                                onChange={ (e, {value}) => this.setState({ localObjectsDescription: sanitizeHTML(value) }) } 
                                onBlur={ event => dispatch( setObjectsDescription(localObjectsDescription) ) } 
                                onFocus={ (event) => { event.target.select() }}
                                />
                        </Form>
                    }
                </Segment>*/}
                
                {/* <Segment basic vertical color="grey" className='my_3'>
                    <Header>High-quality Printing <span class="ui green circular small label ">Shipped for FREE</span></Header>
                    <Checkbox name="imagePrinting"
                        checked={ imagePrinting }
                        label={<label><strong>Print my design</strong></label>}
                        onChange={ (e, {checked}) => dispatch( setPrinting(checked) ) } 
                        />
                    { imagePrinting && 
                        <ProductSelector basic fluid clearable selection
                            className="mt_half"
                            loadingText="Loading paper"
                            activeItem={selectedPaper}
                            productHandle="paper"
                            selectText="Select paper"
                            onSelect={ product => this.selectPaper(product) }
                            />
                    }
                </Segment> */}

                {/*<Segment basic vertical color="grey">
                    <Checkbox name="includeGlass"
                        checked={ includeGlass }
                        label={
                            <label>
                                <strong>Include glass</strong>
                                { requireShipping && <small className="faded">&nbsp;(due to shipping, only Acrylic is available)</small>}
                            </label>
                        }
                        onChange={ this.toggleGlass.bind(this) } 
                        />
                    { includeGlass &&
                        <ProductSelector basic fluid clearable selection
                            className="mt_half"
                            loadingText="Loading glass"
                            activeItem={selectedGlass}
                            productHandle="glass"
                            selectText="Select glass"
                            onSelect={ product => dispatch( setGlass(product) ) }
                            filter={ requireShipping ? 
                                item => item.canShip
                                : undefined 
                            }
                            />
                    }
                </Segment>

                <Segment basic vertical color="grey">
                    <Checkbox
                        name="assembly" 
                        checked={ assembly } 
                        label={<label><strong>Include Assembly</strong></label>} 
                        onChange={ (e, {checked}) => dispatch( setAssembly(checked) ) } 
                        />
                    <Accordion>
                        <Accordion.Title 
                            active={showAssemblyDesc}
                            onClick={() => this.setState({showAssemblyDesc: ! showAssemblyDesc})}
                            >
                                <small>
                                    {'Putting all the components together. '} 
                                    <strong>{ showAssemblyDesc ? 'Close' : 'Read more'}</strong>
                                </small>
                        </Accordion.Title>
                        <Accordion.Content active={showAssemblyDesc}>
                            <Segment className="ma_0">
                                {'Assembly refers to putting all the framing components (glass or acrylic, matboards, artwork, and backing boards) together and securing them inside the frame. It is then finished with a dustcover and hanging hardware on the back. Proper hanging systems will be selected by the professional framer assembling the piece.'}
                            </Segment>
                        </Accordion.Content>
                    </Accordion>
                </Segment>
                
                <Segment basic vertical color="grey">
                    <Checkbox 
                        name="mounting" 
                        checked={ mounting } 
                        label={<label><strong>Include Mounting</strong></label>} 
                        onChange={ (e, {checked}) => dispatch( setMounting(checked) ) } 
                        />
                    <Accordion>
                        <Accordion.Title 
                            active={showMountingDesc}
                            onClick={() => this.setState({showMountingDesc: ! showMountingDesc})}
                            >
                                <small>
                                    {'Adhering the framed item to different types of boards. '} 
                                    <strong>{ showMountingDesc ? 'Close' : 'Read more'}</strong>
                                </small>
                        </Accordion.Title>
                        <Accordion.Content active={showMountingDesc}>
                            <Segment className="ma_0">
                                {'Mounting is a way to adhere a variety of papers, substrates, and objects referred to as “artwork” to different types of boards. There are several methods of mounting; “dry mounting”, or heat mounting, is the most popular using an adhesive, heat, and pressure to bond the artwork to the board (substrate) to create full adhesion and flatness of the artwork. These items will remain flat and bubble free. “Archival mounting” or “conservation mounting” comprises numerous methods where preservation of the artwork is paramount. This maintains the condition of the artwork, and insures the artwork is properly preserved for years to come. These methods are used when artwork is valuable to the customer or irreplaceable. Proper mounting systems will be chosen by the professional framer assembling the framed piece.'}
                            </Segment>
                        </Accordion.Content>
                    </Accordion>
                </Segment> */}

            </Section.Content>

        </Section> )
    }
}

Options.propTypes = {
    assembly: PropTypes.bool,
    mounting: PropTypes.bool,
    imagePrinting: PropTypes.bool,
    selectedPaper: PropTypes.object,
    selectedGlass: PropTypes.object,
    requireShipping: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        assembly: state.project.present.assembly,
        mounting: state.project.present.mounting,
        imagePrinting: state.project.present.imagePrinting,
        selectedPaper: state.project.present.selectedPaper,
        selectedGlass: state.project.present.selectedGlass,
        projectTitle: state.project.present.title,
        objectsProvided: state.project.present.objectsProvided,
        objectsDescription: state.project.present.objectsDescription,
        requireShipping: state.app.requireShipping
    }
}

export default connect(mapStateToProps)(Options)