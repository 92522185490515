import React from 'react';
import { calculateLayout, getCenteredImageCrop } from '../core/utilities';

const LayoutSVG = props => {
    const { layout, openings, borders, gutters, className, style, onCreate } = props
    let columns, rows;
    switch (layout) {
        case 'grid':
            columns = Math.ceil(Math.sqrt(openings.length))
            rows = Math.ceil(openings.length/columns)
            break;
        case 'vertical':
            columns = 1
            rows = openings.length
            break;
        default:
            columns = openings.length
            rows = 1
    }

    const calculated = calculateLayout(openings, {
        borders,
        insideHorzMargin: gutters,
        insideVertMargin: gutters,
        layout: { columns, rows }
    })

    if ( typeof onCreate === 'function' ) {
        onCreate(calculated)
    }
    
    return (
        <svg viewBox={`0 0 ${calculated.width} ${calculated.height}`} 
            className={className}
            style={style}
            > 
            {/* background */}
            <rect x={0} y={0} width="100%" height="100%" fill="#fff"/>

            {/* openings */}
            { calculated.openings.map( (opening, i) => (
                <LayoutSVG.Opening key={i} opening={opening} /> 
            ) ) }
        </svg>
    )
}

LayoutSVG.Horz = (props) => <svg viewBox="0 0 225 225" className={props.className} width="225" height="225">
    <rect x={0} y="50%" transform="translate(0 -50)" width={100} height={100} fill={props.fill || "#677783"} />
    <rect x={125} y="50%" transform="translate(0 -50)" width={100} height={100} fill={props.fill || "#677783"} />
</svg>

LayoutSVG.Vert = (props) => <svg viewBox="0 0 225 225" className={props.className} width="225" height="225">
    <rect x="50%" transform="translate(-50 0)" y={0} width={100} height={100} fill={props.fill || "#677783"} />
    <rect x="50%" transform="translate(-50 0)" y={125} width={100} height={100} fill={props.fill || "#677783"} />
</svg>

LayoutSVG.Grid = (props) => <svg viewBox="0 0 225 225" className={props.className} width="225" height="225">
    <rect x={0} y={0} width={100} height={100} fill={props.fill || "#677783"} />
    <rect x={125} y={0} width={100} height={100} fill={props.fill || "#677783"} />
    <rect x={0} y={125} width={100} height={100} fill={props.fill || "#677783"} />
    <rect x={125} y={125} width={100} height={100} fill={props.fill || "#677783"} />
</svg>

LayoutSVG.Opening = ({opening}) => {
    const { width, height, x, y, image, id } = opening
    
    let crop = { x:0, y:0, width:0, height:0 }
    if ( image ) {
        crop = getCenteredImageCrop(image, width, height)
    }


    return (
        <svg x={x} y={y} width={width} height={height}>

            { image ? 
                <>
                    <defs>
                        <clipPath id={`opening-${id}-clip`}>
                            <rect width={width} height={height} />
                        </clipPath>
                    </defs>
                    <image x={crop.x} y={crop.y} 
                        width={crop.width} 
                        height={crop.height} 
                        clipPath={`opening-${id}-clip`} 
                        xlinkHref={image.src}
                        />
                </>
                : 
                <svg width="100%" height="100%" x="0" y="0">
                    <rect x="0" y="0" width="100%" height="100%" fill="#eee" />
                    <line x1="10%" y1="10%" x2="90%" y2="90%" strokeWidth={10} stroke="#ddd" />
                    <line x1="90%" y1="10%" x2="10%" y2="90%" strokeWidth={10} stroke="#ddd" />
                </svg>
            }

            <rect width="100%" height="100%" stroke="#ddd" strokeWidth={10} x="0" y="0" fill="none" />

        </svg>
    )
}

export default LayoutSVG
