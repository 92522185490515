import React, { useState } from 'react';
import LibraryImage from './LibraryImage';
import { Button, Segment } from 'semantic-ui-react';
import { sortDESC } from '../../helpers';

const MediaList2 = (props) => {

	const {media, onSelectImage, draggable, selectedImageIds} = props
	
	const [visibleCount, setVisibleCount] = useState(12)
	
	const columns = props.columns || 3
	const sortedMedia = media.sort( sortDESC('dateCreated') )
	
	function onLoadMore() {
		setVisibleCount(visibleCount + 12)
	}

	return (
		<Segment basic vertical className={`display_flex align_items_center flex_wrap`}>
			{ sortedMedia.map( (image, i) => {
				const isVisible = i < visibleCount
				const isSelected = selectedImageIds.indexOf(image.id) > -1
				return isVisible && (
					<LibraryImage
						key={image.id +'-'+ i}
						image={image}
						draggable={draggable}
						style={{ width: `calc(${100 / columns}% - 1rem)`, margin: '0.5rem', minWidth: 0 }}
						selected={ isSelected }
						onSelect={onSelectImage} />
				)
				
			} ) }
			{ visibleCount < media.length &&
				<Button basic fluid content="Load More"
					icon="plus" 
					className="my_1"
					onClick={onLoadMore}
					/>
			}
		</Segment>
	);

}

export default MediaList2