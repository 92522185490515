import React, { Component } from 'react'
import { Icon, Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class WCLoader extends Component {

    render() {

        const textAlign = this.props.textAlign || 'center'
        const {message, className, style, inverted} = this.props
        const icon = <Icon name="cog" circular loading inverted={inverted} />

        return message ?
            <Header inverted={inverted} className={`${className} icon`} textAlign={textAlign} size="tiny" style={style}>
                {icon}
                <Header.Content>{message}</Header.Content>
            </Header>
            :
            icon
    }

}

WCLoader.propTypes = {
    message: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

export default WCLoader
