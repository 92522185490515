import React from 'react';
import { Header, Dimmer, Loader, Responsive } from 'semantic-ui-react';

const Section = ({title, loading, dimmerContent, children, style}) => {

	return (
		<Dimmer.Dimmable dimmed={!!loading || !!dimmerContent} className="Section" style={style}>

			{ title &&
				<div className="Section__header">
					{ title && <Responsive as={Header} size="large" color="blue" dividing>{title}</Responsive> }
				</div>
			}

			{children}
			
			<Dimmer inverted active={ !! dimmerContent }>
				{dimmerContent}
			</Dimmer>

			<Dimmer inverted active={ !! loading && ! dimmerContent }>
				<Loader>{loading}</Loader>
			</Dimmer>

		</Dimmer.Dimmable>
	);

}

Section.Content = ({children}) => {
	return (
		<div className="Section__content">
			{children}
			<div style={{height:100}}></div>
		</div>
	)
}

Section.Taskbar = ({children, visible}) => {
	return (
		<div className={`Section__taskbar ${visible ? 'visible' : '' }`}>{children}</div>
	)
}

export default Section;
