import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { dismissSnack } from '../../reducks/app'
import { Transition } from 'semantic-ui-react';
import Snack from './Snack';

const Snackbar = props => {

	const snacks = useSelector( state => state.app.snacks )
	const dispatch = useDispatch()

	return (
		<Transition visible={snacks.length > 0} animation="fade left" duration={300} >
			<div className="Snackbar position_absolute z_index_1" style={{ maxWidth: 450, right: 30, bottom: 20 }}>
				<Transition.Group as="div" animation="fade left" duration={300}>
					{ snacks.map( snack => (
						<Snack key={snack.id}
							message={snack.message} 
							icon={snack.icon}
							loading={snack.loading}
							color={snack.color}
							description={snack.description}
							image={snack.image}
							canDismiss={snack.canDismiss}
							dismissAfter={ snack.dismissAfter }
							onDismiss={ () => { 
								if ( typeof snack.onDismiss === 'function' ) {
									snack.onDismiss()
								}
								dispatch(dismissSnack(snack.id))
							} }
							/>
					) ) }
				</Transition.Group >
			</div>
		</Transition>
	);

}

export default Snackbar