import initialState from './_initialState';
import {deepCopyObject} from '../helpers'
import ResourcesApi from '../api/resourcesApi'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
 export function loadResources(handle, id, callback) {

    if ( typeof id === 'function' ) {
        callback = id
        id = undefined
    }

    return function(dispatch) {
        return ResourcesApi.getResource(handle, id)
            .then( json => {
                if ( json.error ) {
                    console.error(json.error)
                    console.trace()
                }
                else {
                    dispatch( loadResourceSuccess(handle, json.data) );
                }
                if ( typeof callback === 'function' ) {
                    callback( json, json.error && json.error.message )
                }

            } )
    }
 }

 export function loadResourceSuccess(handle, data) {
     return { type: 'LOAD_RESOURCE_SUCCESS', handle, data }
 }


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
export default function resourcesReducer( state = initialState.resources, action ) {

    // You MUST return a NEW state object for changes to be recognized
    let newState = deepCopyObject( state )

    switch(action.type) {

        case 'LOAD_RESOURCE_SUCCESS':
            newState[action.handle] = action.data
            break;

        default:
            return newState

    }

    return newState;

}
