import React from 'react';
import Handle from './ElementTransformerHandle'

const ElementTransformer = (props) => {

    const { canTransform, isActive, zoom } = props
    const color = props.color || 'black'

    return (
        <svg
            x={0} y={0}
            width="100%"
            height="100%"
            className="hide_in_preview"
            >
            <rect
                fill="none"
                width="100%"
                height="100%"
                stroke="white"
                strokeWidth={4 / zoom}
                />
            <rect
                fill="none"
                width="100%"
                height="100%"
                stroke={color}
                strokeWidth={2 / zoom}
                strokeDasharray={ isActive ? 'none' : `${5/zoom} ${5/zoom}` }
                />

            { canTransform && <>

                <Handle
                    onDragStart={ props.onDragStart }
                    onDrag={ props.onDrag }
                    onDragStop={ props.onDragStop }
                    >
                    <g style={{ cursor: "move" }}>
                        <circle
                            cx="50%"
                            cy="50%"
                            r={12 / zoom}
                            fill={color}
                            stroke="white"
                            strokeWidth={2 / zoom}
                            />
                        <svg x="50%" y="50%" width={ 16 / zoom } height={ 16 / zoom } viewBox="0 0 768 768">
                            <path 
                                fill="#ffffff" 
                                transform="translate(-384 -384)"
                                d="M352 141.248v210.752h-210.752l41.376-41.376c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0l-96 96c-3.072 3.072-5.376 6.592-6.944 10.368-1.632 3.904-2.432 8.096-2.432 12.256 0 8.192 3.136 16.384 9.376 22.624l96 96c12.512 12.512 32.768 12.512 45.248 0s12.512-32.768 0-45.248l-41.376-41.376h210.752v210.752l-41.376-41.376c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l96 96c3.072 3.072 6.592 5.376 10.368 6.944s7.904 2.432 12.256 2.432c4.16 0 8.352-0.8 12.256-2.432 3.776-1.568 7.296-3.872 10.368-6.944l96-96c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0l-41.376 41.376v-210.752h210.752l-41.376 41.376c-12.512 12.512-12.512 32.768 0 45.248s32.768 12.512 45.248 0l96-96c3.072-3.072 5.376-6.592 6.944-10.368 4.8-11.584 2.496-25.44-6.944-34.88l-96-96c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l41.376 41.376h-210.752v-210.752l41.376 41.376c12.512 12.512 32.768 12.512 45.248 0s12.512-32.768 0-45.248l-96-96c-3.072-3.072-6.592-5.376-10.368-6.944s-7.904-2.432-12.256-2.432c-4.16 0-8.352 0.8-12.256 2.432-3.776 1.568-7.296 3.872-10.368 6.944l-96 96c-12.512 12.512-12.512 32.768 0 45.248s32.768 12.512 45.248 0z"></path>
                        </svg>
                    </g>
                </Handle>
                <Handle x="0" y="0" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="nwse-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'NW')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="50%" y="0" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="ns-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'N')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="100%" y="0" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="nesw-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'NE')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="100%" y="50%" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="ew-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'E')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="100%" y="100%" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="nwse-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'SE')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="50%" y="100%" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="ns-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'S')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="0" y="100%" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="nesw-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'SW')}
                    onDragStop={ props.onResizeStop }
                    />
                <Handle x="0%" y="50%" transform={`translate(${-5 / zoom} ${-5 / zoom})`} cursor="ew-resize"
                    color={color}
                    zoom={zoom}
                    onDragStart={ props.onResizeStart }
                    onDrag={ (event, data) => props.onResize(event, data, 'W')}
                    onDragStop={ props.onResizeStop }
                    />

            </>}

        </svg>
    )

}

export default ElementTransformer
