import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import {CLOUDINARY_NAME} from '../../data/constants'
import {normalizeImageData, imageMaps} from './utilities'
import {updateUserMedia} from '../../reducks/user'
// import {storageAvailable} from '../../helpers'

class CloudinaryUploader extends Component {

    constructor(props) {
        super(props)

        const uploaderStyles = {
            palette: {
                window: "#ffffff",
                sourceBg: "#f4f4f5",
                windowBorder: "#90a0b3",
                tabIcon: "#4B4E52",
                inactiveTabIcon: "#555a5f",
                menuIcons: "#555a5f",
                link: "#00a1f3",
                action: "#00a1f3",
                inProgress: "#f2c200",
                complete: "#00a1f3",
                error: "#F95C5C",
                textDark: "#4B4E52",
                textLight: "#fcfffd"
            },
            fonts: {
                "'Source Sans Pro', sans-serif": "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&subset=latin&display=swap"
            }
        }
        this.config = {
            clientAllowedFormats: ["png","jpeg"],
            cloudName: CLOUDINARY_NAME,
            cropping: false,
            defaultSource: "local",
            folder: `user_uploads/${props.userId ? 'user_'+props.userId : 'guests'}/uploads`,
            maxFiles: 40,
            multiple: true,
            resourceType: 'image',
            showAdvancedOptions: false,
            showCompletedButton: true,
            sources: ["local", "url", "camera", "facebook", "dropbox", "instagram", "shutterstock"],
            styles: uploaderStyles,
            uploadPreset: "vfgaqcko",
        }
        this.onUploadSuccess.bind(this)
        this.uploads = []
    }

    componentDidMount() {
        this.widget = window.cloudinary.createUploadWidget( this.config, this.initWidget.bind(this))
    }

    componentDidUpdate(prevProps) {
        if ( prevProps.allowMultiple !== this.props.allowMultiple ) {
            this.widget.update({
                multiple: this.props.allowMultiple,
                cropping: ! this.props.allowMultiple,
            })
        }
        if ( prevProps.userId !== this.props.userId ) {
            this.widget.update({
                folder: `user_uploads/${this.props.userId ? 'user_'+this.props.userId : 'guests'}/uploads`,
            })
        }
    }

    initWidget(error, result) {

        if ( error ) {
            console.log("Upload Widget error - ", error, result);
        }

        switch( result.event ) {

            case 'success':
                this.uploads.push(result.info)
                break

            case 'close':
                this.onUploadSuccess(this.uploads)
                this.uploads = []
                break

            default:
                return

        }

    }

    onClickTrigger() {
        this.widget.open()
    }

    onUploadSuccess(uploads) {

        const { userUploads, onUploadComplete } = this.props

        let normalizedUploads = uploads.map( upload => normalizeImageData(upload, imageMaps.cloudinary) )
        let newUserUploads = normalizedUploads.concat(userUploads)

        /** @deprecated Store user images locally when not logged in  */
        // if ( ! user && storageAvailable('localStorage') ) {
        //     window.localStorage.setItem('wallcoreAnonymousUploads', JSON.stringify({resources: newUserUploads}))
        // }

        this.props.dispatch( updateUserMedia( newUserUploads ) )

        if ( typeof onUploadComplete === 'function' ) {
            onUploadComplete(normalizedUploads)
        }

    }

    render() {
        const {
            basic,
            children,
            className,
            color,
            content,
            disabled,
            fluid,
            icon,
            loading,
            negative,
            positive,
            primary,
            secondary,
            size,
        } = this.props

        return (
            <Button
                basic={basic}
                className={className}
                color={color}
                content={content}
                disabled={disabled}
                fluid={fluid}
                icon={icon}
                loading={loading}
                negative={negative}
                positive={positive}
                primary={primary}
                secondary={secondary}
                size={size}
                onClick={this.onClickTrigger.bind(this)} >
                { children }
            </Button>
        )
    }
}

CloudinaryUploader.propTypes = {
    onSuccess: PropTypes.func,
    user: PropTypes.object,
    userUploads: PropTypes.array,
    userId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
}

function mapStateToProps(state) {
    return {
        user: state.user.meta,
        userId: state.user.meta ? state.user.meta.id : null,
        userUploads: state.user.uploads,
    }
}



export default connect(mapStateToProps)(CloudinaryUploader)
