import React, { Component } from 'react'
import {EIGHTH_IN_PX} from '../../data/constants'

export default class DrawArea extends Component {

    constructor(props) {
        super(props)
        this.state ={
            showSelection: false,
            firstX: null,
            firstY: null,
            lastX: null,
            lastY: null,
        }
    }

    onMouseDown(event) {
        const x = Math.round( event.nativeEvent.offsetX / EIGHTH_IN_PX ) * EIGHTH_IN_PX
        const y = Math.round( event.nativeEvent.offsetY / EIGHTH_IN_PX ) * EIGHTH_IN_PX

        this.setState({
            showSelection: true,
            firstX: x,
            firstY: y,
        })

        event.stopPropagation()
    }

    onMouseMove(event) {
        const x = Math.round( event.nativeEvent.offsetX / EIGHTH_IN_PX ) * EIGHTH_IN_PX
        const y = Math.round( event.nativeEvent.offsetY / EIGHTH_IN_PX ) * EIGHTH_IN_PX

        this.setState({
            lastX: x,
            lastY: y,
        })

        event.stopPropagation()
    }

    onMouseUp(event) {
        const { firstX, firstY, lastX, lastY } = this.state
        this.props.onSelectArea({
            width: lastX - firstX,
            height: lastY - firstY,
            x: firstX,
            y: firstY,
        })
        this.setState({
            showSelection: false
        })

        event.stopPropagation()
    }

    render() {

        const { firstX, firstY, lastX, lastY, showSelection } = this.state
        const style = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            // backgroundImage: `url(${gridNodeImage})`,
            // backgroundPosition: '0 0',
            // backgroundSize: '9px auto',
            cursor: 'crosshair'
        }
        const selectionStyle = {
            position: 'absolute',
            left: firstX,
            top: firstY,
            width: lastX - firstX,
            height: lastY - firstY,
            backgroundColor: 'rgba(56, 185, 255, 0.6)'
        }

        return (
            <div className="DrawArea"
            style={style}
            onMouseDown={ this.onMouseDown.bind(this) }
            onMouseMove={ this.onMouseMove.bind(this) }
            onMouseUp={ this.onMouseUp.bind(this) }
            >

                { showSelection &&
                    <div className="DrawArea_Selection" style={selectionStyle} />
                }

            </div>
        )
    }
}
