import React from 'react'
import {getItemBy, generateUID, range} from '../../helpers'
import { getState } from '../../reducks/_utilities'

/**
 * Returns the SVG path element and scale for give shape data
 * @param {Number} width | element pixel width
 * @param {Number} height | element pixel height
 * @param {String} id | ID of the element
 * @param {Object|String} shape | shape data for the element
 */
export const getShape = ( width, height, id, shape ) => {

    let scale = 1,
        path;

    if (typeof shape === "object") {
        scale = width / shape.width;
        path = (
            <path
                id={`${id}-path`}
                d={shape.d}
                transform={`scale(${width / shape.width} ${height /
                    shape.height})`}
            />
        );
    } else if (shape === "oval") {
        path = <rect id={`${id}-path`} width={width} height={height} rx="50%" ry="50%" />

    } else {
        path = <rect id={`${id}-path`} width={width} height={height} />
    }

    return {path, scale}
}

/**
 * Get the background color for an opening based on depth and project mats.
 * @param {Array} projectMats | array of mats in a project
 * @param {Number} depth | The 0-based depth index, or mat layer, from which to check core color
 */
export const getBackgroundColor = (projectMats, depth) => {
    let projectMatsCopy = [...projectMats]
    const backgroundReferenceMat = projectMatsCopy.splice(depth, 1)
    return backgroundReferenceMat.length ? backgroundReferenceMat[0].hex : '#eaeaea'
}

/**
 * Get the color color for a mat layer
 * @param {Array} projectMats | array of mats in a project
 * @param {Number} depth | The 0-based depth index, or mat layer, from which to check core color
 */
export const getCoreColor = (projectMats, depth) => {
    let projectMatsCopy = [...projectMats]
    let coreColor = 'white'

    if ( depth !== 0 ) {
        const coreReferenceMat = projectMatsCopy.splice(depth - 1, 1)
        if ( coreReferenceMat[0] ) {
            coreColor = coreReferenceMat[0].core
        }
    }

    return coreColor
}

/**
 * default text settings
 */
export const textDefaults = {
    fontFamily: 'Palatino',
    fontSize: '48',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'center',
    color: '#000000',
    lineHeight: "1",
}

/**
 * generate SVG text nodes from a multi-line text string
 * @param {Object} props | id, content, color, textAlign, lineHeight, fontSize, fontFamily, fontWeight, fontStyle
 */
export const getTextNodes = (props) => {
    const {id, content, color, textAlign, lineHeight, fontSize, fontFamily, fontWeight, fontStyle} = {textDefaults, ...props}
    return content.split('\n').map( (lineText, i) => {
        let x, textAnchor
        const y = fontSize * lineHeight * (i + 1)
        switch (textAlign) {
            case 'center':
                x = '50%'
                textAnchor = 'middle'
                break;
            case 'right':
                x = '100%'
                textAnchor = 'end'
                break;
            default:
                x = '0'
                textAnchor = 'start'
        }
        return { id, x, y, textAnchor, color, lineText, style: {fontFamily, fontWeight, fontStyle, fontSize: fontSize+'px'} }
    } )
}

/**
 * Check if an element has children
 * @param {Object} element | the target element
 * @param {Array} elements | the collection of elements
 */
export const elementHasChildren = (element, elements) => {
    if ( typeof elements !== "object" || ! elements.filter )
        return false

    const children = elements.filter( elem => elem.parent === element.id )
    return children.length > 0
}

/**
 * Create an opening
 * @param shape {enum} - 'rect'|'oval'|'cutart'
 * @param data {object} - an Element properties object for the opening
 */
export const createOpening = ( shape = '', data = {} ) => {
    const defaults = {
        editable: true,
        image: null,
        backgroundColor: ''
    }
    const id = `element-opening-${ generateUID() }`
    const required = {
        component: 'Opening',
        tool: '45bevel',
        shape: data.shape || shape,
        id: data.id || id,
        parent: data.parent,
        depth: data.depth
    }

    return Object.assign({}, defaults, data, required)
}

/**
 * Get the lowest (deepest) opening among a set of elements
 * @param depth {number} - the maximum depth to search for openings
 * @param elements {array} - array of project elements
 */
export const getLowestOpening = (depth, elements) => {
    let opening = getItemBy('depth', depth, elements)
    if ( ! opening ) {
        opening = getLowestOpening(depth - 1, elements)
    }
    return opening
}

/**
 * Create openings which drill down through each mat layer
 * @param shape {enum} - 'rect'|'oval'|'cutart'
 * @param layersToCut {number} - number of mat layers to create openings for
 * @param outsideDimensions {object} - an Element properties object of the outermost opening
 * @param reveal {number} - the reveal (in pixels) between each mat layer
 */
export const createDeepOpenings = (shape, layersToCut, outsideDimensions) => {
    const { project } = getState()
    const { defaultReveal } = project
    const { x, y, width, height, parent, depth } = outsideDimensions

    return range(layersToCut).reduce( (accumulator, index) => {
        let params = {
            x:      index === 0 ? x : defaultReveal,
            y:      index === 0 ? y : defaultReveal,
            width:  index === 0 ? width : accumulator[index - 1].width - (defaultReveal * 2),
            height: index === 0 ? height : accumulator[index - 1].height - (defaultReveal * 2),
            parent: index === 0 ? parent : accumulator[index - 1].id,
            depth: depth + index + 1,
        }

        accumulator.push(createOpening( shape, params ))
        return accumulator

    }, [])
}

/**
 * Create a text element
 * @param content {string} - the text content
 * @param data {object} - an Element properties object
 */
export const createText = ( content = '', data = {} ) => {
    const defaults = Object.assign({}, textDefaults, {
        editable: true,
    })
    const id = `element-text-${ generateUID() }`
    const required = {
        component: 'Text',
        content,
        id
    }
    return Object.assign({}, defaults, data, required)
}

/**
 * Create a graphic element
 * @param url {string} - url of the graphic image
 * @param data {object} - an Element properties object
 */
export const createGraphic = ( url = '', data = {} ) => {
    const defaults = {
        editable: true,
    }
    const id = `element-graphic-${ generateUID() }`
    const required = {
        id,
        component: 'Graphic',
        image: {url, width: data.width, height: data.height},
    }
    return Object.assign({}, defaults, data, required)
}
