import React, { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import {CLOUDINARY_BASE_URL} from '../../data/constants'
import DragPreview from '../core/DragPreview'
import { updateElementProps } from '../../reducks/project'
import { useDispatch } from 'react-redux'
import { getCenteredImageCrop } from '../core/utilities'
import classNames from 'classnames'

const OpeningImage = props => {

    const { 
        image,
        opening,
        isDraggedOver
    } = props
    let imageSrc = image.src
    if ( image.src && image.src.indexOf("cloudinary.com") < 0 ) {
        imageSrc = `${CLOUDINARY_BASE_URL}/image/fetch/${image.src}`
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if ( image && ! image.crop ) {
            dispatch( updateElementProps( opening.id, {
                image: {
                    ...image,
                    crop: getCenteredImageCrop(image, opening.width, opening.height)
                }
            } ) )
        }
    })

    const [{isDragging}, dragRef] = useDrag({
        item: { 
            type: 'IMAGE', 
            image: props.image,
            opening
        },
        end: (item, monitor) => {
            console.log('drag end', item, 'did drop:', monitor.didDrop())
        },
        collect: (monitor, props) => {
            return {
                isDragging: monitor.isDragging()
            }
        }
    })

    const className = classNames(
        'transition_all',
        { 'opacity_0': isDragging || isDraggedOver }
    )

    return (
        <svg 
            x={0} 
            y={0} 
            width={opening.width}
            height={opening.height}
            >
            { image.crop && 
                <image
                    x={image.crop.x}
                    y={image.crop.y}
                    width={image.crop.width}
                    height={image.crop.height}
                    xlinkHref={imageSrc}
                    className={className}
                    />
            }
            <foreignObject x={0} y={0} width={opening.width} height={opening.height}>
                <DragPreview visible={isDragging} image={props.image} />
                <div ref={dragRef} className="image_drag_ref width_100 height_100" />
            </foreignObject>
        </svg>
    )
}

export default OpeningImage