import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Button} from 'semantic-ui-react'
import Library from '../core/Library'
import { updateElementProps } from '../../reducks/project'
import { setActiveElement } from '../../reducks/tools'
import {getItemBy} from '../../helpers'

class GraphicToolset extends Component {

    constructor(props) {
        super(props)
        this.state = {
            libraryOpen: false
        }
    }

    selectGraphic(graphic) {
        const { activeElementObject, dispatch } = this.props
        const { id } = activeElementObject
        this.setState({ libraryOpen: false })
        dispatch( updateElementProps( id, { image: graphic } ) )
        dispatch( setActiveElement( id ) )
    }

    onClickTrigger() {
        this.setState({ libraryOpen: true })
    }

    render() {

        const trigger = (
            <Button icon="sync" content="Change" title="Change graphic"
                className="mr_0"
                onClick={this.onClickTrigger.bind(this)} />
        )

        return (
            <Button.Group vertical basic>
                <Library resource="graphics"
                    trigger={trigger}
                    open={this.state.libraryOpen}
                    title="Select a graphic"
                    onSelectItem={this.selectGraphic.bind(this)} />
            </Button.Group>
        )
    }
}

function mapStateToProps(state) {

    const { elements } = state.project.present
    const { activeElement } = state.tools
    let activeElementObject = getItemBy('id', activeElement, elements) || {}

    return {
        activeElement,
        activeElementObject,
        activeIsSource: activeElement === 'source-opening',
        defaultReveal: state.project.present.defaultReveal,
    }
}

export default connect(mapStateToProps)(GraphicToolset)
