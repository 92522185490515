import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Pagination, Responsive } from 'semantic-ui-react'
import UserApi from '../api/userApi'
import { updateUserArt, loadUserArt } from '../reducks/user'
import PodApi from '../api/podApi'
import {normalizeImageData} from './core/utilities'
import {storageAvailable, getQueryObject} from '../helpers'
import ShopArtHeader from './shop-art/ShopArtHeader'
import ShopArtList from './shop-art/ShopArtList';
import EntriesApi from '../api/entriesApi';

const addToCollection = (item, collectionName) => {
    return state => {
        return {
            [collectionName]: [...state[collectionName], item]
        }
    }
}

const removeFromCollection = (item, collectionName) => {
    return state => {
        return {
            [collectionName]: state[collectionName].filter( i => i.id !== item.id )
        }
    }
}

class ShopArt extends Component {

    constructor(props) {
        super(props)
        this.query  = getQueryObject()
        
        const featuredImages = window.Craft.podImages.map(img => normalizeImageData(img, 'pod'))
        this.state  = {
            loading: false,
            currentPage: 0,
            totalPages: 1,
            totalImages: 0,
            perpage: 48,
            images: [],
            filters: {},
            loadingArt: true,
            loadingArtError: false,
            mode: this.query.mode === 'myLibrary' ? 'myLibrary' : 'shop', // 'myLibrary'
            confirmModal: false,
            allowConfirm: true,
            lastSelectedImage: null,
            syncingImages: [],
            featuredImages
        }
                            
        this.storageAvailable = storageAvailable('localStorage')
        this.getImages        = this.getImages.bind(this)
        this.applyFilters     = this.applyFilters.bind(this)
        this.getUserArt       = this.getUserArt.bind(this)
        this.updateUserMedia  = this.updateUserMedia.bind(this)
        this.listRef          = React.createRef()
    }

    componentDidMount() {

        window.document.title = 'Shop for Art | Designer | MyDIYWall'

        this.getUserArt()

        if ( this.query.categories ) {
            let params = {
                page_no: 0,
                perpage: this.state.perpage
            }
            let cats = this.query.categories.split(',').map(cat => cat.trim())
            cats.forEach( (cat,i) => {
                params['cat' + i] = cat
            } )
            this.applyFilters(params)
        }

    }

    getUserArt(){
        this.setState({
            loadingArt: true,
            loadingArtError: false
        })
        this.props.dispatch( loadUserArt( this.props.user, error => {
            if ( error ) {
                this.setState({
                    loadingArtError: error
                })
            }
            this.setState({loadingArt: false})
        } ) )
    }

    async getImages(params) {
        this.setState({ loading: true })

        params = params instanceof Object ? params : {}

        // check and load stored results
        let paramsKey = ['pod_images__']
        Object.keys(params).forEach( k => {
            paramsKey.push( k + '_' + params[k] )
        })
        paramsKey = paramsKey.join('__')
        if ( this.storageAvailable ) {
            const pod_images = window.localStorage.getItem(paramsKey)
            const pod_images_exp = window.localStorage.getItem(paramsKey + '__EXPIRES')
            const currentTime = new Date().getTime()

            if ( pod_images && pod_images_exp && currentTime < pod_images_exp ) {

                const pod_images_json = JSON.parse(pod_images)
                this.setState({
                    loading: false,
                    images: pod_images_json.images,
                    currentPage: pod_images_json.currentPage,
                    totalPages: pod_images_json.totalPages,
                    totalImages: pod_images_json.totalImages,
                })

                return pod_images_json
            }

        }

        // fetch new images
        const imagesJson = await PodApi.getData('images', params)
        
        // handle data
        let imageData = {}
        if ( imagesJson && imagesJson.data ){

            // update state with results
            imageData = {
                images: imagesJson.data.map(img => normalizeImageData(img, 'pod')),
                currentPage: imagesJson.current_page,
                totalPages: imagesJson.total_pages,
                totalImages: imagesJson.total_images,
            }
            this.setState(imageData)

            // update stored results
            if ( this.storageAvailable ) {
                window.localStorage.setItem(paramsKey, JSON.stringify(imageData))
                window.localStorage.setItem(paramsKey + '__EXPIRES', new Date().getTime() + 60 * 60 * 24 * 1000)
            }
        }
        
        // handle errors
        if ( imagesJson && imagesJson.error ) {
            console.log(imagesJson.error.message)
        }
        
        // update state
        this.setState({loading: false})

        // return json
        return imageData
    }

    handlePaginationChange( event, {activePage} ) {
        const { filters, perpage } = this.state

        if ( this.listRef.current ) {
            window.scrollTo( { left: 0, top: this.listRef.current.offsetTop, behavior: 'smooth'} )
        }

        this.setState({
            currentPage: activePage
        })
        this.getImages({
            ...filters,
            page_no: activePage - 1,
            perpage
        })
    }

    handlePerPageChange( event, {value} ) {
        const { filters, perpage } = this.state

        // bail out if no change
        if ( perpage === value )
            return

        this.setState({
            perpage: value
        })
        this.getImages({
            ...filters,
            page_no: 0,
            perpage: value
        })
    }

    applyFilters(filters) {
        this.setState({
            currentPage: 1,
            filters
        })
        this.getImages({
            ...filters,
            page_no: 0,
            perpage: this.state.perpage
        })
    }

    addLibraryImage(normalizedImage) {
        const { userArt } = this.props
        const { allowConfirm } = this.state

        // check for duplicate
        if ( userArt.find( art => art.id === normalizedImage.id ) ) {
            return;
        }

        // add image to syncing list
        this.setState(addToCollection(normalizedImage, 'syncingImages'))

        // add image to collection
        const newUserArt = [...userArt]
        newUserArt.unshift(normalizedImage)

        // update user media
        this.updateUserMedia(newUserArt, () => {
            this.setState({
                lastSelectedImage: normalizedImage,
                confirmModal: allowConfirm,                
            })
            this.setState(removeFromCollection(normalizedImage, 'syncingImages'))
        })

    }

    removeLibraryImage(normalizedImage) {
        const { userArt } = this.props

        // add image to syncing list
        this.setState(addToCollection(normalizedImage, 'syncingImages'))
        
        // remove image from collection
        const newUserArt = [...userArt].filter( image => image.id !== normalizedImage.id )

        // update user media
        this.updateUserMedia(newUserArt, () => {
            this.setState(removeFromCollection(normalizedImage, 'syncingImages'))
        })
    }
    
    updateUserMedia(newMedia, callback) {
        const { user } = this.props

        // update user media
        if ( user ) {

            UserApi.saveUser({
                "userId": user.id,
                "fields[savedMediaJson]": JSON.stringify(newMedia)
            })
            .then( json => {
                if ( json.success ) {
                    this.props.dispatch(updateUserArt( newMedia ))
                }
                if ( json.errors || json.error ) {
                    console.log(json.errors || json.error)
                    console.trace()
                }
            })
            .finally( () => {
                if ( typeof callback === 'function' ) {
                    callback()
                }
            } )

        }
            // OR update storage media
            else {

                // save to localStorage
                if ( storageAvailable('localStorage') ) {
                    window.localStorage.setItem('wallcoreLocalArt', JSON.stringify({ art: newMedia }))
                    this.props.dispatch(updateUserArt( newMedia ))
                }

                if ( typeof callback === 'function' ) {
                    callback()
                }

            }
    }
	
	toggleFeaturedImage(image, isFeatured) {

        this.setState(addToCollection(image, 'syncingImages'))
        
        if ( isFeatured ) {
            EntriesApi.deleteEntry(image.id)
                .then( response => {
                    if ( response.success ) {
                        this.setState(removeFromCollection(image, 'featuredImages'))
                    }
                })
                .finally(() => {
                    this.setState(removeFromCollection(image, 'syncingImages'))
                })
        }
        else {
            EntriesApi.saveEntry(window.Craft.sectionIds['podExchange'], {
                    type: 'images',
                    title: image.alt,
                    'fields[uniqueId]': image.id,
                    'fields[description]': image.author,
                    'fields[pixelWidth]': image.width,
                    'fields[pixelHeight]': image.height,
                    'fields[primaryImageUrl]': image.src
                })
                .then( response => {
                    if ( response.success ) {
                        this.setState(addToCollection(image, 'featuredImages'))
                    }
                })
                .finally(() => {
                    this.setState(removeFromCollection(image, 'syncingImages'))
                })
        }
    }

    render() {

        const { userArt, userArtIds, user } = this.props
        const {
            loading,
            currentPage,
            totalPages,
            totalImages,
            images,
            perpage,
            mode,
            syncingImages,
            featuredImages,
            filters
        } = this.state

        const syncingImageIds = syncingImages.map( img => img.id )
        const featuredImageIds = featuredImages.map( img => img.id )
        const hasFilters = Object.keys(filters).length !== 0
        const pagination = (<>
            <Responsive as={Pagination} maxWidth={Responsive.onlyTablet.maxWidth}
                activePage={currentPage}
                totalPages={totalPages}
                siblingRange={0} 
                boundaryRange={1}
                firstItem={null}
                lastItem={null}
                prevItem={ currentPage > 1 && { 'aria-label': 'Previous page', content: 'Prev' }}
                nextItem={ currentPage < totalPages && { 'aria-label': 'Next page', content: 'Next' }}
                onPageChange={this.handlePaginationChange.bind(this)}
              />
            <Responsive as={Pagination} minWidth={Responsive.onlyComputer.minWidth}
                activePage={currentPage}
                totalPages={totalPages}
                firstItem={null}
                lastItem={null}
                prevItem={{ 'aria-label': 'Previous page', content: 'Previous Page' }}
                nextItem={{ 'aria-label': 'Next page', content: 'Next Page' }}
                onPageChange={this.handlePaginationChange.bind(this)}
              />
        </>)

        return (
        <div className="ShopArt flex_grow_1 flex_shrink_0">

            <ShopArtHeader 
                query={this.query}
                loading={loading}
                mode={mode}
                onApplyFilters={ filters => this.applyFilters(filters) }
                libraryCount={userArt.length}
                onChangeMode={mode => this.setState({ mode })}
                />

            <div ref={ this.listRef }>

                { mode === 'shop' &&
                    <ShopArtList 
                        className="my_3 mx_1"
                        loading={loading}
                        totalImages={totalImages}
                        totalPages={totalPages}
                        filters={filters}
                        images={ hasFilters ? images : featuredImages }
                        pagination={pagination}
                        perpage={perpage}
                        canFeature={ user && user.admin }
                        userArtIds={userArtIds}
                        syncingImageIds={syncingImageIds}
                        featuredImageIds={featuredImageIds}
                        onToggleFeature={ this.toggleFeaturedImage.bind(this) }
                        onChangePerpage={ this.handlePerPageChange.bind(this) }
                        onSelectImage={ this.addLibraryImage.bind(this) }
                        onDeselectImage={ this.removeLibraryImage.bind(this) }
                        />
                }
                
                { mode === 'myLibrary' &&
                    <ShopArtList 
                        className="my_3 mx_1"
                        loading={loading}
                        totalImages={totalImages}
                        images={userArt}                        
                        canFeature={ user && user.admin }
                        userArtIds={userArtIds}
                        syncingImageIds={syncingImageIds}
                        featuredImageIds={featuredImageIds}
                        onToggleFeature={ this.toggleFeaturedImage.bind(this) }
                        onSelectImage={ this.addLibraryImage.bind(this) }
                        onDeselectImage={ this.removeLibraryImage.bind(this) }
                        />
                }

            </div>

            {/* <Modal size="small" open={confirmModal} onClose={ () => this.setState({ confirmModal: false }) }>
                <Modal.Content className="text_center">
                    <Responsive fluid as={Button.Group} maxWidth={Responsive.onlyTablet.maxWidth}>
                        {frameArtButton}
                        {keepBrowsingButton}
                    </Responsive>
                    <Responsive as={React.Fragment} minWidth={Responsive.onlyComputer.minWidth}>
                        {frameArtButton}
                        <strong className="mx_1">— or —</strong>
                        {keepBrowsingButton}
                    </Responsive>
                    <Header as="h2" size="medium" color="yellow" textAlign="center" className="my_2">
                        <Icon name="check" />
                        {`Image added to your library!`}
                    </Header>
                    { lastSelectedImage &&
                        <Image centered src={lastSelectedImage.src} size="small"/>
                    }
                    <p className="font_size_small my_2">You'll now find it under the <Icon name="folder" color="yellow" /><strong className="color_secondary">My Art</strong> tab when creating a new project.</p>
                    <Divider />
                    <Checkbox label="Don't show this message again" onChange={ (e, {checked}) => this.setState({ allowConfirm: !checked }) }/>
                </Modal.Content>
            </Modal> */}

        </div>
        )
    }
}

ShopArt.propTypes = {
    dispatch: PropTypes.func,
    activeElement: PropTypes.string,
    user: PropTypes.object,
    userArt: PropTypes.array
}

function mapStateToProps(state) {
    return {
        user: state.user.meta,
        userArt: state.user.art,
        userArtIds: state.user.art.map( img => img.id ),
        activeElement: state.tools.activeElement,
    }
}

export default connect(mapStateToProps)(ShopArt)
