import React from 'react';
import {DraggableCore} from 'react-draggable'
import {EIGHTH_IN_PX} from '../../data/constants'

export default class Handle extends React.Component {

    onStart(event, data) {
        event.stopPropagation()
        this.props.onDragStart( event, data )
    }

    onDrag(event, data) {
        event.stopPropagation()
        this.props.onDrag( event, data )
    }

    onStop(event, data) {
        event.stopPropagation()
        this.props.onDragStop( event, data )
    }


    render() {
        const { x, y, cursor, color, zoom, children, transform } = this.props

        return (
            <DraggableCore
                grid={ [EIGHTH_IN_PX, EIGHTH_IN_PX] }
                onStart={ this.onStart.bind(this) }
                onDrag={ this.onDrag.bind(this) }
                onStop={ this.onStop.bind(this) }
                >
                {
                    children
                    ||
                    <rect
                        x={x}
                        y={y}
                        transform={transform}
                        width={10 / zoom}
                        height={10 / zoom}
                        rx={2 / zoom}
                        fill={color}
                        stroke="white"
                        strokeWidth={2 / zoom}
                        style={{ cursor }}
                        />
                }
            </DraggableCore>
        )
    }
}
