export const PPI = 72 // POINTS PER INCH FOR SCREEN
export const LOW_RES_PRINT_PPI = 120 // POINTS PER INCH FOR PRINT
export const MED_RES_PRINT_PPI = 150 // POINTS PER INCH FOR PRINT
export const HI_RES_PRINT_PPI = 200 // POINTS PER INCH FOR PRINT
export const EIGHTH_IN_PX = PPI/8
export const QUARTER_IN_PX = PPI/4
export const HALF_IN_PX = PPI/2
export const MIN_WIDTH_IN = 6
export const MAX_WIDTH_IN = 40
export const MAX_SIZE = 9999
export const MIN_WIDTH_PX = MIN_WIDTH_IN * PPI
export const MAX_WIDTH_PX = MAX_WIDTH_IN * PPI
export const MIN_HEIGHT_IN = 6
export const MAX_HEIGHT_IN = 40
export const MIN_HEIGHT_PX = MIN_HEIGHT_IN * PPI
export const MAX_HEIGHT_PX = MAX_HEIGHT_IN * PPI
export const MIN_ZOOM = 0.25
export const MAX_ZOOM = 1.5
export const MIN_MAT_COUNT = 1
export const MAX_MAT_COUNT = 3
export const CLOUDINARY_NAME = 'wallcore'
export const CLOUDINARY_BASE_URL = `https://res.cloudinary.com/${CLOUDINARY_NAME}`
export const MAPBOX_API_ACCESS_TOKEN = "pk.eyJ1Ijoid2FsbGNvcmUiLCJhIjoiY2s4ZTN3Nm5zMTIwdDNvbHZpaGgyMnEybiJ9.D-Cnso7rB8Tp_Il_bO38ow"