import React from 'react'
import {PPI} from '../../data/constants'

export default (props) => {

    const {data, width, height} = props

    if ( ! data ) {
        return <g id="Frame"></g>
    }

    const faceWidth = data ? data.faceWidth * PPI : 0
    const renderImage = data ? data.renderImage : ''
    const renderImageRatio = data ? data.renderWidth / data.renderHeight : 0
    const horzRail = {
        v0: [0,0],
        v1: [width,0],
        v2: [width - faceWidth,faceWidth],
        v3: [faceWidth,faceWidth],
    }
    const vertRail = {
        v0: [0,0],
        v1: [height,0],
        v2: [height - faceWidth,faceWidth],
        v3: [faceWidth,faceWidth],
    }
    const horzRailPath = `M ${horzRail.v0.join()} ${horzRail.v1.join()} ${horzRail.v2.join()} ${horzRail.v3.join()} Z`
    const vertRailPath = `M ${vertRail.v0.join()} ${vertRail.v1.join()} ${vertRail.v2.join()} ${vertRail.v3.join()} Z`

    return (
        <g id="Frame">
            <defs>
                <pattern id="frame-fill" patternUnits="userSpaceOnUse" width={parseInt(faceWidth*renderImageRatio)} height={faceWidth}>
                    <rect fill="none" width="100%" height="100%" />
                    <image xlinkHref={renderImage} width={parseInt(faceWidth*renderImageRatio)} height={faceWidth} x="0" y="0"/>
                </pattern>
                <filter id="frame-blur">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter>
            </defs>
            <rect
                className="frame-shadow hide_in_preview"
                x={faceWidth} y={faceWidth}
                width={(width - faceWidth*2) + 10} height={(height - faceWidth*2) + 10}
                fill="none"
                stroke="black"
                strokeWidth="20"
                opacity="0.25"
                filter="url(#frame-blur)"
            />
            <path d={horzRailPath} fill="url(#frame-fill)" />
            <path d={horzRailPath} fill="url(#frame-fill)" transform={`rotate(180) translate(${-width} ${-height})`} />
            <path d={vertRailPath} fill="url(#frame-fill)" transform={`rotate(-90) translate(${-height} 0)`} x={-height} y={0} />
            <path d={vertRailPath} fill="url(#frame-fill)" transform={`rotate(90) translate(0 ${-width})`} x={-height} y={0} />
        </g>
    )
}
