import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Element from './Element'
import {getItemBy} from '../../helpers'

class Deboss extends Component {

    getShape( width, height, id, shape ) {

        let scale = width / shape.width,
            path = (
                <path
                    id={`${id}-path`}
                    d={shape.d}
                    transform={`scale(${width / shape.width} ${height /
                        shape.height})`}
                />
            );

        return {path, scale}
    }

    render() {

        const { id, activeElement, shape, width, height } = this.props
        const isActive = activeElement === id
        const shapeEl = this.getShape( width, height, id, shape )

        return  <Element {...this.props} isActive={isActive}>
    				<defs>
    					{shapeEl.path}
    				</defs>
                    <use
                        href={`#${id}-path`}
                        fill="rgba(0,0,0,0.1)"
                        />
    			</Element>

    }
}

Deboss.propTypes = {

    id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
    shape: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    editable: PropTypes.bool,
    parent: PropTypes.string,
    activeElement: PropTypes.string,
}

function mapStateToProps(state, ownProps) {
    const parentObject = getItemBy('id', ownProps.parent, state.project.present.elements)
    const activeElementObject = getItemBy('id', state.tools.activeElement, state.project.present.elements)
    return {
        activeElement: state.tools.activeElement,
        parentObject,
        activeElementObject,
        lockedMode: state.app.lockedMode
    }
}

export default connect(mapStateToProps)(Deboss)
