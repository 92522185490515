import React from 'react'
import { Message } from 'semantic-ui-react'

const ErrorMessage = ({error}) => {
    return (
        <Message negative>
            { error instanceof Object ?
                <ul className="ui list">
                    { Object.keys(error).map( key => <li><strong>{key}:</strong> {error[key]}</li> ) }
                </ul> 
                :
                <p>{ error }</p>
            }
        </Message>
    )
}

export default ErrorMessage