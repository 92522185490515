import React, { useEffect, useState } from 'react'
import { Modal, Image, Button, Checkbox } from 'semantic-ui-react'
import { generatePreview, getPrintPPI, getPrintSheet } from '../core/utilities'
import WCLoader from '../core/Loader'
import { PPI } from '../../data/constants'

const PrintSheetModal = (props) => {

	const { open, onClose, project } = props
	const [loading, setLoading] = useState(false)
	const [printSheet, setPrintSheet] = useState(null)
	const [excludeMatboard, setExcludeMatboard] = useState(false)

	useEffect(() => {
		loadPrintsheet()
	}, [open, excludeMatboard])

	async function loadPrintsheet() {
		if (open === true) {
			setLoading(true)
			let dataUrl = ''
			if (excludeMatboard) {
				const { url } = await getPrintSheet(project)
				dataUrl = url
			} else {
				const dpi = getPrintPPI(project.artboardwidth / PPI, project.artboardheight / PPI)
				dataUrl = await generatePreview({ excludeFrame: true, scale: dpi / PPI, dpi, cropMarks: true })
			}
			setPrintSheet(dataUrl)
			setLoading(false)
		}
	}

	return (
		<Modal size="small" basic dimmer='inverted' centered={false} {...{ open, onClose }}>
			<Modal.Content className="text_center">
				<p>
					<Button content="Download Print Sheet"
						disabled={loading || !printSheet}
						loading={loading}
						icon="download"
						download={project.title + ' print sheet.jpg'}
						primary
						as="a"
						target="_blank"
						href={printSheet}
					/>
				</p>
				{ project.projectMats.length > 0 && 
					<p>
						<Checkbox
							checked={excludeMatboard}
							label="Exclude matboard"
							disabled={loading || !printSheet}
							onChange={(e, { checked }) => setExcludeMatboard(checked)}
						/>
					</p>
				}
				<p className='mt_3'>
					{loading ?
						<WCLoader message="Loading print sheet" />
						:
						<Image src={printSheet} fluid />
					}
				</p>
			</Modal.Content>
		</Modal>
	)
}

export default PrintSheetModal