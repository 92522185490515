/**
* Fetch API
* https://github.com/github/fetch
*/
import 'whatwg-fetch';

/**
 * ES6 Promises
 * https://github.com/stefanpenner/es6-promise
 */
require('es6-promise').polyfill();
