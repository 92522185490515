import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {setSetupMode, setSelectedLibraryImage, setMediaModal} from '../../reducks/app'
import { addElements, removeAllElements, updateElementProps, setMats } from '../../reducks/project'
import {Divider, Modal, Grid, Button, Input, Transition, Icon, Header, Popup} from 'semantic-ui-react'
import {nearestDivisor, range} from '../../helpers'
import {calculateLayout} from './utilities'

const defaultWidth = 11
const defaultHeight = 14
const thickInput = <input style={{ fontWeight: 'bold', padding: '0.25em', textAlign: 'center' }} />

class SetupWizard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openingWidth: defaultWidth,
            openingHeight: defaultHeight,
            topBorder: 3,
            bottomBorder: 3,
            sideBorders: 3,
            insideHorzMargin: 0.5,
            insideVertMargin: 0.5,
            matCount: 1,
            matReveal: 0.25,
            openingCount: 1,
            layoutRows: 1,
            layoutColumns: 1,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {}
        if ( props.selectedLibraryImage ) {
            const imageRatio = props.selectedLibraryImage.width / props.selectedLibraryImage.height
            newState.openingHeight = nearestDivisor( state.openingWidth / imageRatio, 0.125 )
            newState.openingCount = 1
            newState.layoutRows = 1
            newState.layoutColumns = 1
        }
        return newState
    }

    handleInputChange(event, data) {
        let newState = {}
        newState[data.name] = parseFloat(data.value)
        this.setState(newState)
    }

    submit(event, data) {

        const { dispatch, selectedLibraryImage } = this.props
        const { matCount, matReveal, topBorder, sideBorders, bottomBorder, insideHorzMargin, insideVertMargin, layoutColumns, layoutRows, openingWidth, openingHeight } = this.state
        const openings = range(this.state.openingCount).map( (opening, i) => {
            return {
                width: openingWidth,
                height: openingHeight,
                depth: 0,
                image: i === 0 ? selectedLibraryImage : null
            }
        } )
        const layout = calculateLayout( openings, {
                borders: { top: topBorder, side: sideBorders, bottom: bottomBorder },
                layout: { columns: layoutColumns, rows: layoutRows },
                insideHorzMargin,
                insideVertMargin,
                matCount,
                matReveal,
            })

        // Reset to a blank project
        dispatch( removeAllElements() )

        // set mats
        dispatch( setMats(matCount) )

        // update overall dimensions
        dispatch( updateElementProps( 'source-opening', {
            width: layout.width,
            height: layout.height
        } ) )

        dispatch( addElements( layout.openings ) )

        // this.processInputData(this.state)
        this.close()
    }

    close() {
        this.props.dispatch( setSetupMode(false) )
        this.props.dispatch( setSelectedLibraryImage(null) )
    }

    removeSelectedImage() {
        this.props.dispatch( setSelectedLibraryImage(null) )
    }

    changeSelectedImage() {
        this.props.dispatch( setSetupMode(false) )
        this.props.dispatch( setMediaModal(true) )
    }

    render() {

        const { setupMode, selectedLibraryImage } = this.props
        const { openingWidth, openingHeight, openingCount, topBorder, bottomBorder, sideBorders, matCount, matReveal, insideHorzMargin, insideVertMargin, layoutColumns, layoutRows } = this.state
        const elementText = selectedLibraryImage ? 'Print' : 'Opening'
        const elementWidthInput = (
            <Input fluid
                type="number"
                name="openingWidth"
                min={2}
                max={38}
                step={0.125}
                value={openingWidth}
                input={thickInput}
                size="massive"
                onChange={ this.handleInputChange.bind(this) }
                />
        )
        const elementHeightInput = (
            <Input fluid
                type="number"
                name="openingHeight"
                min={2}
                max={38}
                step={0.125}
                value={openingHeight}
                input={thickInput}
                size="massive"
                disabled={ selectedLibraryImage ? true : false}
                onChange={ this.handleInputChange.bind(this) }
                />
        )

        return (
            <Transition visible={setupMode} animation="fade down" duration={500}>
                <Modal open={setupMode}>
                    <Modal.Header><Icon name="table" /> Create a NEW layout</Modal.Header>
                    <Modal.Content>

                        <Header color="blue" size="tiny"><Icon name="info circle"/>All sizes are in inches.</Header>

                        <Grid divided>

                            <Grid.Column width={8}>
                                <Grid>
                                    { ! selectedLibraryImage &&
                                        <Grid.Row columns="equal">
                                                <Grid.Column>
                                                    <label className="label" htmlFor="openingCount">Number of Openings</label>
                                                    <Input fluid type="number" name="openingCount" min={1} max={25} step={1} value={openingCount} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                                </Grid.Column>
                                        </Grid.Row>
                                    }
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <label className="label" htmlFor="width">{elementText} width</label>
                                            { selectedLibraryImage ?
                                                <Popup trigger={elementWidthInput} size="large" wide>
                                                    <Icon name="info circle" color="blue"/> {'Set the desired width (in inches) and the height will adjust automatically to fit the proportions of your image.'}
                                                </Popup>
                                                :
                                                elementWidthInput
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <label className={`label ${ selectedLibraryImage ? 'faded' : '' }`} htmlFor="height">
                                                {elementText} height { selectedLibraryImage && '(automatic)' }
                                            </label>
                                            { elementHeightInput }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <label className="label" htmlFor="topBorder">Top mat border</label>
                                            <Input fluid type="number" name="topBorder" min={0.5} max={6} step={0.125} value={topBorder} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <label className="label" htmlFor="bottomBorder">Bottom mat border</label>
                                            <Input fluid type="number" name="bottomBorder" min={0.5} max={6} step={0.125} value={bottomBorder} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <label className="label" htmlFor="sideBorders">Side mat borders</label>
                                            <Input fluid type="number" name="sideBorders" min={0.5} max={6} step={0.125} value={sideBorders} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <p><Icon name="flag" color="blue" /><strong className="color_primary">Pro Tip:</strong> {'We suggest 3"- 4" Mat Borders on larger sizes. You can always change your mat board borders later.'}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <label className="label" htmlFor="matCount">Mat layers</label>
                                            <Button.Group fluid basic>
                                                <Button active={matCount === 1} content="1" onClick={ () => this.setState({ matCount: 1 }) } />
                                                <Button active={matCount === 2} content="2" onClick={ () => this.setState({ matCount: 2 }) } />
                                                <Button active={matCount === 3} content="3" onClick={ () => this.setState({ matCount: 3 }) } />
                                            </Button.Group>
                                        </Grid.Column>
                                        { matCount > 1 &&
                                            <Grid.Column>
                                                <label className={`label ${ matCount === 1 ? 'faded' : '' }`} htmlFor="matReveal">Reveal</label>
                                                <Input fluid type="number" name="matReveal" min={0.125} max={1} step={0.125} value={matReveal} onChange={ this.handleInputChange.bind(this) } />
                                            </Grid.Column>
                                        }
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                { ! selectedLibraryImage &&
                                    <Grid>
                                        <Grid.Row columns="equal">
                                            <Grid.Column>
                                                <label className="label" htmlFor="layoutColumns">Columns</label>
                                                <Input fluid type="number" name="layoutColumns" min={1} max={openingCount} step={1} value={layoutColumns} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <label className="label" htmlFor="layoutRows">Rows</label>
                                                <Input fluid type="number" name="layoutRows" min={1} max={openingCount} step={1} value={layoutRows} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns="equal">
                                            { layoutColumns > 1 &&
                                                <Grid.Column>
                                                    <label className={`label ${ layoutColumns === 1 ? 'faded' : '' }`} htmlFor="insideHorzMargin">Horizontal inside margins</label>
                                                    <Input fluid type="number" name="insideHorzMargin" min={0.5} max={3} step={0.125} value={insideHorzMargin} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                                </Grid.Column>
                                            }
                                            { layoutRows > 1 &&
                                                <Grid.Column>
                                                    <label className={`label ${ layoutRows === 1 ? 'faded' : '' }`} htmlFor="insideVertMargin">Vertical inside margins</label>
                                                    <Input fluid type="number" name="insideVertMargin" min={0.5} max={3} step={0.125} value={insideVertMargin} input={thickInput} size="massive" onChange={ this.handleInputChange.bind(this) } />
                                                </Grid.Column>
                                            }
                                        </Grid.Row>
                                    </Grid>
                                }
                                { selectedLibraryImage && <>
                                    <img className="ui fluid image my_1" src={selectedLibraryImage.src} alt={selectedLibraryImage.alt} />
                                    <Button basic size="mini" content="Remove Image" icon="delete" onClick={this.removeSelectedImage.bind(this)} />
                                    <Button basic size="mini" content="Change Image" icon="sync" onClick={this.changeSelectedImage.bind(this)} />
                                </>}
                            </Grid.Column>

                        </Grid>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.close.bind(this)}>Cancel</Button>
                        <Popup wide trigger={<Button primary>Create</Button>} on="click" position="top right">
                            <p><Icon name="exclamation triangle" color="orange"/> <strong>Warning</strong></p>
                            <Divider />
                            <p><strong>This will <u>discard</u> your current layout and replace it with a new one!</strong></p>
                            <Button fluid positive content="Proceed with new layout" onClick={this.submit.bind(this)} />
                        </Popup>
                    </Modal.Actions>
                </Modal>
            </Transition>
        )
    }
}

SetupWizard.propTypes = {
    setupMode: PropTypes.bool.isRequired,
    elements: PropTypes.array.isRequired,
    selectedLibraryImage: PropTypes.object
}

function mapStateToProps(state) {
    return {
        setupMode: state.app.setupMode,
        elements: state.project.present.elements,
        selectedLibraryImage: state.app.selectedLibraryImage,
    }
}

export default connect(mapStateToProps)(SetupWizard)
