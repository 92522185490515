import React, { useState, useEffect } from 'react'
import {useSelector} from 'react-redux'
import Opening from '../elements/Opening'
import { getComposedElements } from '../core/utilities'

const ComposedElements = props => {

    const {zoom} = props
    const [sourceOpening, setSourceOpening] = useState({})
    const elements = useSelector( state => state.project.present.elements )

    useEffect(() => {
        const composedElements = getComposedElements( elements )
        setSourceOpening(composedElements[0])
    }, [elements])

    return <Opening {...sourceOpening} zoom={zoom} />
}

export default ComposedElements
