import React from 'react';
import { Header, Dropdown, Placeholder, Responsive } from 'semantic-ui-react';
import { Number } from '../core/utilities';
import GalleryCard from './GalleryCard';
import { range } from '../../helpers';
import Masonry from 'react-masonry-component';

export default class ShopArtList extends React.Component {

	perpageOptions = [
		{ text: '48', value: 48 },
		{ text: '72', value: 72 },
		{ text: '96', value: 96 }
	]

	render() {
		const { filters, featuredImageIds, syncingImageIds, canFeature, onToggleFeature, totalPages, onSelectImage, onDeselectImage, className, loading, totalImages, pagination, perpage, onChangePerpage, images, userArtIds } = this.props
		const hasFilters = filters && Object.keys(filters).length > 0
		// const _mobile = mobile || 6, 
		// 	  _tablet = tablet || 3, 
		// 	  _computer = computer || 2
		const loadingMessage = `Searching, please wait a moment...`
		const resultsMessage = hasFilters && totalImages > 0 ?
			<>Found <Number value={ totalImages } /> images</>
			:
			'No images were found.'
		const headerContent = loading ? loadingMessage : resultsMessage
		const perPageSelect = (
			<div className="display_inline_block ml_auto">
				<strong>Results per page: </strong>
				<Dropdown compact selection
					value={perpage}
					options={this.perpageOptions}
					onChange={ onChangePerpage }
					/>
			</div>
		)

		const brickWidth = images.length < 6 ? 'three wide' : 'two wide'

		return (
			<div className={`ShopArtList ${className}`}>

				{ (loading || hasFilters) && <>

					<Responsive maxWidth={Responsive.onlyTablet.maxWidth}
						className="display_flex align_items_center flex_column my_2">
						<Header textAlign="center" className="mb_1">
							{ headerContent }
						</Header>
						{ totalPages > 1 && ! loading && pagination &&
							pagination
						}
					</Responsive>

					<Responsive minWidth={Responsive.onlyComputer.minWidth}
						className="display_flex align_items_center my_2"
						>
						<Header className="mb_0 mr_1">
							{ headerContent }
						</Header>
						{ totalPages > 1 && ! loading && pagination && <>
							{pagination}
							{perPageSelect}
						</> }
					</Responsive>

				</>}

				<Masonry className="masonry" style={{ minHeight: 300 }}>
					{ loading ?
						range(perpage).map( i => (
							<div key={`GalleryPlaceholder-${i}`} className={`${brickWidth} brick mb_3`}>
								<Placeholder>
									<Placeholder.Image square />
								</Placeholder>
							</div>
						) )
						:
						images.map( (image,i) => {
							const inLibrary = userArtIds.indexOf( image.id ) > -1
							const isSyncing = syncingImageIds.indexOf( image.id ) > -1
							const isFeatured = featuredImageIds.indexOf( image.id ) > -1
							return (
								<div key={i} className={`${brickWidth} brick`}>
									<GalleryCard
										image={image}
										inLibrary={inLibrary}
										syncing={isSyncing}
										featured={isFeatured}
										canFeature={canFeature}
										onToggleFeature={onToggleFeature}
										onSelectImage={ onSelectImage }
										onDeselectImage={ onDeselectImage } 
										/>
								</div>
							)
						} )
					}

				</Masonry>

				{ totalPages > 1 && ! loading && 
					<div className="display_flex align_items_center flex_column my_2">
						{pagination}
					</div>
				}	

			</div>

		);
	}

}