import React from 'react';
import { Header, Responsive, Menu, Icon } from 'semantic-ui-react';
import FilterBar from './FilterBar';

const ShopArtHeader = props => {

	const {
		query, 
		onApplyFilters, 
		loading,
		mode,
		libraryCount,
		onChangeMode
	} = props

	const menuProps = {
		secondary: true,
		pointing: true,
		widths: 2,
		style: { maxWidth: 600, margin:0 }
	}
	const menuItems = <>
		<Menu.Item active={mode === 'shop'}
			onClick={() => onChangeMode('shop')}
			>
			<Icon name="search"/> Find Art
		</Menu.Item>
		<Menu.Item active={mode === 'myLibrary'} 
			onClick={() => onChangeMode('myLibrary')}
			>
			<Icon name="folder"/> {`My Library`}
		</Menu.Item>
	</>

	return (
		<div 
			className="bg_center bg_cover bg_yellow width_100 py_3 px_1 display_flex flex_column justify_content_center align_items_center" 
			style={{backgroundImage:'url('+window.Craft.baseUrl+'/assets/images/confetti@2x.png)'}}
			>

			<Responsive as={Menu} {...menuProps} 
				maxWidth={Responsive.onlyTablet.maxWidth} 
				>
				{menuItems}
			</Responsive>
			<Responsive as={Menu} size="huge" {...menuProps}
				minWidth={Responsive.onlyComputer.minWidth}
				>
				{menuItems}
			</Responsive>
			
			{ mode === 'myLibrary' && 
				<Header as="h2" textAlign="center" className="mx_1 my_2">
					{libraryCount > 0 ?
						`${libraryCount} ${ libraryCount < 2 ? 'image' : 'images' } saved in your library.`
						:
						'You have not saved any images yet.'
					}
				</Header>
			}
			
			{ mode === 'shop' && <>
				<Header as="h2" textAlign="center" className="mx_1 my_2">
					Browse Art, Prints, Photographs, &amp; more...
				</Header>
				<FilterBar
					query={ query }
					searching={ loading }
					onApplyFilters={ (filters) => onApplyFilters(filters) }
					className="mx_1" 
					/>
			</>}
		</div>
	)
	
}
export default ShopArtHeader