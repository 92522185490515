import React, { useEffect, useState, useRef } from 'react'
import { getFittedZoom, cleanProjectData, getTotalPrice, getPrintableElements } from './core/utilities'
import { debounce, getQueryObject } from '../helpers'
import { Loader, Dimmer, Button, Modal, Header, Responsive, Icon } from 'semantic-ui-react'
import { PPI } from '../data/constants'
import { doScrollRef } from '../helpers'
import { setLockedMode, clearSnacks, addSnack, setSelectedImages, setFrameShop, setRequireShipping, setFindFramerModal, setUserFormMode } from '../reducks/app'
import { updateProject, setGlass } from '../reducks/project'
import CartConfirm from './cart/CartConfirm'
import EntriesApi from '../api/entriesApi'
import LibraryModal from './library/LibraryModal'
import SetupWizard from './core/SetupWizard'
import AppSidebar from './AppSidebar'
import Snackbar from './core/Snackbar'
import Workspace from './Workspace'
import { useSelector, useDispatch } from 'react-redux'
import { updatePrice } from '../reducks/price'
import FramerSearch from './core/FramerSearch'
import { get } from '../api/fetchKit'

const Editor = props => {

    const { history, match: { params: routeParams } } = props

    const project = useSelector(state => state.project.present)
    const {
        fontFamilies,
        fontPreviews,
        selectedImages, frameShop:
        selectedFrameShop,
        findFramerModal,
        requireShipping
    } = useSelector(state => state.app)
    const { meta: user } = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [zoom, setZoom] = useState(1)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorCode, setErrorCode] = useState(null)
    // const [showOnboard, setShowOnboard] = useState(false)

    const workspaceRef = useRef(null)

    // useEffect(() => {
    //     if ( window.localStorage.getItem('wallcoreDismissOnboard') !== 'true' ) {
    //         setTimeout(() => {
    //             setShowOnboard(true)
    //         }, 1000)
    //     }
    // }, [])

    useEffect(() => {
        window.document.title = project.title + ' | Designer | MyDIYWall'
    }, [project.title])

    async function getAndSetFrameShop(id) {
        const frameShop = await get(`/api/frame-shop/${id}.json`);

        if (!frameShop.error) {
            // set the frame shop
            dispatch(setFrameShop(frameShop))

            // if frame shop is delivery only...
            if (frameShop.deliveryOnly) {
                // set global require shipping
                dispatch(setRequireShipping(true))
                // set glass to plexi
                if (window.Craft.defaultAcrylicId) {
                    const defaultAcrylic = await get(`/api/glass-products/${window.Craft.defaultAcrylicId}.json`);
                    if (!defaultAcrylic.error) {
                        dispatch(setGlass(defaultAcrylic))
                    } else {
                        console.error(`Couldn't get default acrylic -`, 'code: ' + defaultAcrylic.error.code + ' -', defaultAcrylic.error.message)
                    }
                }
            }
            else {
                dispatch(setRequireShipping(false))
            }
        }
        else {
            dispatch(setFindFramerModal(true)) // give the user another try
            console.error(`Couldn't get framer -`, 'code: ' + frameShop.error.code + ' -', frameShop.error.message)
        }
    }

    useEffect(() => {
        // Look for a stored frame shop ID
        const frameShopId = (
            window.Craft.frameShopId // prioritize the value grabbed from a cookie -> templates/designer/index.twig
            || window.localStorage.getItem('wallcoreSelectedFrameShopId') // fallback to value stored from last time the user selected a framer
        );

        // If there is no selected framer...
        if (!selectedFrameShop) {
            // ...and we have an ID, get the frame shop
            if (frameShopId) {
                getAndSetFrameShop(frameShopId)
            }
            // // ...otherwise, prompt user to search for framer
            // else {
            //     dispatch( setFindFramerModal(true) )
            // }
        }

    }, [])

    useEffect(() => {
        // scroll up when component initializes
        doScrollRef(window.document.body.parentElement, 0, 0)
    }, [])

    useEffect(() => {
        // listen for window resize
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    // initialize project from `entryId` routeParams
    useEffect(() => {
        if (parseInt(routeParams.entryId) !== parseInt(project.id)) {
            initProject()
        }
    }, [routeParams.entryId])

    // update project price
    useEffect(() => {
        dispatch(updatePrice(getTotalPrice(project, {
            requireShipping,
            requireMaterials: getPrintableElements(project).length > 0 && selectedFrameShop && !selectedFrameShop.selfPrint
        })))
    }, [requireShipping, JSON.stringify(selectedFrameShop), JSON.stringify(project)])

    function handleWindowResize() {
        return debounce(function () {
            zoomAndCenter()
        }, 500)
    }

    function initProject() {
        const { template, placeImages } = getQueryObject()
        let entryId = routeParams.entryId

        // Reset locked mode
        dispatch(setLockedMode(true))
        // Reset snacks
        dispatch(clearSnacks())

        if (template) {
            setLoading(true)
            setErrorMessage('')
            setErrorCode(null)
            EntriesApi.getTemplate(template)
                .then(json => {
                    // special processes if this is from a template:
                    if (!json.error) {
                        // reset as a new project
                        json = cleanProjectData(json)
                        json.fromTemplate = true
                        json.type = 'projects'
                        // if images were pre-selected, place them
                        if (placeImages && selectedImages.length) {
                            let imageKey = 0
                            json.elements = json.elements.map(elem => {
                                if (elem.depth === json.projectMats.length
                                    && elem.editable
                                    && elem.component === 'Opening') {
                                    elem.image = selectedImages[imageKey]
                                    imageKey++
                                }
                                return elem
                            })
                            dispatch(setSelectedImages([]))
                        }
                    }
                    // handle json update
                    handleGetEntryJson(json)
                })
                .finally(() => setLoading(false))
        }
        else if (parseInt(entryId)) {
            setLoading(true)
            EntriesApi.getProjectEntry(entryId)
                .then(handleGetEntryJson)
                .finally(() => setLoading(false))
        }
        else {
            // record initial project settings for reset option
            window.Craft.initialProjectState = project

            if (!project.dateCreated) {
                // redirect to setup if project doesnt have a dateCreated value
                history.push('/designer/setup')
            }
        }
    }

    function handleGetEntryJson(json) {
        if (!json.error) {

            // record initial project settings for reset option
            window.Craft.initialProjectState = json

            dispatch(updateProject(json))
            dispatch(addSnack({
                id: 'now-editing',
                message: `Now editing "${json.title}"`,
                icon: 'pencil',
                color: 'green',
                dismissAfter: 3000
            }))
            return
        }
        setErrorCode(json.error.code)
        switch (json.error.code) {
            case 403:
                setErrorMessage(`You don't have permission to use this project.`)
                break;
            case 404:
                setErrorMessage(`Sorry, that project can't be found.`)
                break;
            default:
                setErrorMessage(`Sorry, we couldn't load this project.`)
        }
    }

    function zoomFit(ref, offset) {
        if (!ref || !ref.current || !ref.current.getBoundingClientRect)
            return

        const { artboardwidth, artboardheight, activeFrame } = project
        let { width, height } = ref.current.getBoundingClientRect()
        if (offset) {
            width = width + offset.left
            height = height + offset.top
        }
        const faceWidth = activeFrame ? activeFrame.faceWidth : 0
        const artboardWithFrameW = artboardwidth + (faceWidth * PPI * 2)
        const artboardWithFrameH = artboardheight + (faceWidth * PPI * 2)

        let fittedZoom
        if (window.innerWidth > Responsive.onlyTablet.maxWidth) {
            fittedZoom = getFittedZoom(width, height, artboardWithFrameW, artboardWithFrameH, [150, 100])
        } else {
            fittedZoom = getFittedZoom(width, height, artboardWithFrameW, artboardWithFrameH, [100, 75])
        }

        setZoom(fittedZoom)
    }

    function centerScroll(ref, offset) {
        if (!ref || !ref.current || !ref.current.getBoundingClientRect)
            return

        offset = { top: 0, left: 0, ...offset }
        const top = (ref.current.scrollHeight - (ref.current.clientHeight + offset.top)) / 2
        const left = (ref.current.scrollWidth - (ref.current.clientWidth + offset.left)) / 2
        doScrollRef(ref.current, top, left)
    }

    function zoomAndCenter() {
        zoomFit(workspaceRef)
        centerScroll(workspaceRef)
    }

    function handleZoom(action) {
        let newZoom
        switch (action) {

            case 'zoom in':
                newZoom = Number(Math.round((zoom + 0.1) * 10) / 10).toFixed(1)
                setZoom(Math.min(2, newZoom))
                break

            case 'zoom out':
                newZoom = Number(Math.round((zoom - 0.1) * 10) / 10).toFixed(1)
                setZoom(Math.max(0.1, newZoom))
                break

            case 'zoom 1':
                setZoom(1)
                break

            default:
                zoomAndCenter()

        }
    }

    return (
        <div className="Editor">
            {fontPreviews.length > 0 &&
                <style>
                    {fontPreviews.map(importUrl => importUrl ? `@import url('${importUrl}');` : ``)}
                </style>
            }
            {fontFamilies.length > 0 &&
                <style>
                    {fontFamilies.map(importUrl => importUrl ? `@import url('${importUrl}');` : ``)}
                </style>
            }
            <AppSidebar
                visible={true}
            />
            <Workspace
                ref={workspaceRef}
                zoom={zoom}
                onZoom={handleZoom}
                resetViewMethod={() => zoomAndCenter()}
            />

            <Dimmer active={loading}><Loader size="huge">Loading</Loader></Dimmer>

            <SetupWizard />

            <CartConfirm />

            <LibraryModal />

            {/* <Onboard visible={showOnboard} onClose={() => { 
                setShowOnboard(false); 
                window.localStorage.setItem('wallcoreDismissOnboard', 'true'); 
            }} /> */}

            {/* <OnboardVideos open={showOnboard} onClose={() => { 
                setShowOnboard(false); 
                window.localStorage.setItem('wallcoreDismissOnboard', 'true'); 
            }} /> */}

            <Snackbar />

            <FramerSearch
                open={!errorMessage && findFramerModal}
                onContinue={shopId => {
                    getAndSetFrameShop(shopId)
                    dispatch(setFindFramerModal(false))
                }}
            />

            <Modal size="tiny" open={!!errorMessage}>
                <Modal.Content>
                    <Header as="p" size="small" textAlign="center">{errorMessage}</Header>
                    <Button.Group fluid basic>
                        {errorCode === 403 &&
                            (!user ?
                                <Button content="Sign in" icon="user circle" onClick={e => dispatch(setUserFormMode('login'))} />
                                :
                                (() => { window.location.href = `${window.Craft.baseUrl}/designer/editor/${routeParams.entryId}` })()
                            )
                        }
                        <Button icon="magic" content="Start a new project" as="a" href="/designer/" />
                    </Button.Group>
                </Modal.Content>
            </Modal>

        </div>
    )
}

export default Editor