import React, { useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Element from './Element'
import {getItemBy} from '../../helpers'
import TextToolset from './TextToolset'
import { addFontFamily } from '../../reducks/app'
import { getTextNodes } from './element-helpers'

const Text = (props) => {

    const {
        component,
        editable,
        fontUrl,
        height,
        id,
        parent,
        width,
        x,
        y,
        zoom,
    } = props
    
    const lockedMode = useSelector( state => state.app.lockedMode )
    const projectMats = useSelector( state => state.project.present.projectMats )
    const elements = useSelector( state => state.project.present.elements )
    const activeElement = useSelector( state => state.tools.activeElement )
    const fontFamilies = useSelector( state => state.app.fontFamilies )
    const dispatch = useDispatch()
    
    let parentObject = elements.find( elem => elem.id === parent )

    if ( ! parentObject ) {
        parentObject = elements.find( elem => elem.id === 'source-opening' )
    }

    const parentWidth = parentObject.width
    const parentHeight = parentObject.height
    const isActive = activeElement === id
    const textNodes = getTextNodes(props)

    useEffect( () => {
        // load this Element's fonts
        if ( fontUrl && fontFamilies.indexOf( fontUrl ) < 0 ) {
            dispatch( addFontFamily(fontUrl) )
        }
    } )

    return (
        <Element
            boundaries={null}
            component={component}
            dispatch={dispatch}
            editable={editable}
            grid={null}
            id={id}
            isActive={isActive}
            lockedMode={lockedMode}
            onDragStop={null}
            parentHeight={parentHeight}
            parentWidth={parentWidth}
            projectMats={projectMats}
            tools={<TextToolset />}
            x={x} y={y} width={width} height={height}
            zoom={zoom}
            >
            {/* <style>
                {`.${id}-style { font-weight:${fontWeight}; font-style:${fontStyle}; font-family:${fontFamily}; font-size:${fontSize}px; }`}
            </style> */}
            <rect width={width} height={height} fill="none"/>
            {textNodes.map( (node, i) => (
                <text key={i} 
                    x={node.x} y={node.y} 
                    textAnchor={node.textAnchor} 
                    fill={node.color}
                    style={node.style} 
                    >
                    {node.lineText}
                </text>
            ) )}
        </Element>

    )

}

export default Text
