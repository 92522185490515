import React from 'react';
import {connect} from 'react-redux'
import { Modal, Image, Header, Button, Popup, Dimmer, Loader, Responsive } from 'semantic-ui-react';
import { PPI } from '../../data/constants';
import { getProjectPreview } from '../../reducks/project';

class ProductPreviews extends React.Component {
	
	defaultPreviews = [
		{ 
			projectSize: 'small',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598273644/rooms/shutterstock_1242864781_whdtqc.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598273644/rooms/shutterstock_1242864781_whdtqc.jpg',
			width: 1000,
			height: 732,
			widthInches: 42,
			centerPointX: 490,
			centerPointY: 220,
			overlayProject: true
		},
		{ 
			projectSize: 'small',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598274783/rooms/shutterstock_1401947102_qgexuf.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598274783/rooms/shutterstock_1401947102_qgexuf.jpg',
			width: 1000,
			height: 734,
			widthInches: 32,
			centerPointX: 752,
			centerPointY: 272,
			overlayProject: true
		},
		{ 
			projectSize: 'small',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598274777/rooms/shutterstock_1499849840_t4zmxo.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598274777/rooms/shutterstock_1499849840_t4zmxo.jpg',
			width: 1000,
			height: 734,
			widthInches: 62,
			centerPointX: 695,
			centerPointY: 272,
			overlayProject: true
		},
		{ 
			projectSize: 'medium',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598274796/rooms/shutterstock_1395960716_nh7ohp.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598274796/rooms/shutterstock_1395960716_nh7ohp.jpg',
			width: 1000,
			height: 734,
			widthInches: 62,
			centerPointX: 536,
			centerPointY: 226,
			overlayProject: true
		},
		{ 
			projectSize: 'medium',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598273628/rooms/shutterstock_604054934_nrj5mo.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598273628/rooms/shutterstock_604054934_nrj5mo.jpg',
			width: 1000,
			height: 734,
			widthInches: 70,
			centerPointX: 594,
			centerPointY: 238,
			overlayProject: true
		},
		{ 
			projectSize: 'medium',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000/v1598273638/rooms/shutterstock_1137379070_iym5ko.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100/v1598273638/rooms/shutterstock_1137379070_iym5ko.jpg',
			width: 1000,
			height: 734,
			widthInches: 60,
			centerPointX: 690,
			centerPointY: 285,
			overlayProject: true
		},
		{ 
			projectSize: 'large',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000,h_667/rooms/Living-Room-And-Dining-Room_twsgcb.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100,h_67/rooms/Living-Room-And-Dining-Room_twsgcb.jpg',
			width: 1000,
			height: 667,
			widthInches: 120,
			centerPointX: 745,
			centerPointY: 225,
			overlayProject: true
		},
		{ 
			projectSize: 'large',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_691,h_750/rooms/Bedroom_damzjz.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_74,h_80/rooms/Bedroom_damzjz.jpg',
			width: 691,
			height: 750,
			widthInches: 96,
			centerPointX: 420,
			centerPointY: 180,
			overlayProject: true
		},
		{ 
			projectSize: 'large',
			url: 'https://res.cloudinary.com/wallcore/image/upload/w_1000,h_667/frame_wall_template.jpg',
			thumb: 'https://res.cloudinary.com/wallcore/image/upload/w_100,h_68/frame_wall_template.jpg',
			width: 1000,
			height: 667,
			widthInches: 120,
			centerPointX: 500,
			centerPointY: 200,
			overlayProject: true
		},
	]

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			showModal: false,
			generatingPreview: false,
			isMobile: true,
		}
		this.getPreviews = this.getPreviews.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if ( ! prevState.showModal && this.state.showModal ) {
			this.setState({ generatingPreview: true })			
			this.props.dispatch( getProjectPreview( dataUrl => {
				this.setState({ generatingPreview: false })			
			}) )		
		}
	}
		
	getPreviews() {
		let previews = [...this.defaultPreviews]
		const { projectWidth, projectHeight } = this.props

		if ( projectWidth/PPI <= 14 && projectHeight/PPI <= 14 ) {
			return previews.filter( preview => preview.projectSize === 'small' )
		}
		if ( projectWidth/PPI <= 24 && projectHeight/PPI <= 24 ) {
			return previews.filter( preview => preview.projectSize === 'medium' )
		}
		else {
			return previews.filter( preview => preview.projectSize === 'large' )
		}

	}

	handleResponsiveUpdate(event, {width}) {
		this.setState({
			isMobile: width < Responsive.onlyComputer.minWidth
		})
	}

	render() {

		const { activeIndex, showModal, generatingPreview, isMobile } = this.state
		const { className, projectThumbnail, projectWidth, projectHeight } = this.props
		const previews = this.getPreviews()
		const activePreview = previews[activeIndex]
		const prevIndex = previews[activeIndex - 1] ? activeIndex - 1 : previews.length - 1
		const nextIndex = previews[activeIndex + 1] ? activeIndex + 1 : 0

		return (
			<Responsive 
				className={`ProductPreviewList ${className} display_flex flex_column`}
				fireOnMount
				onUpdate={ this.handleResponsiveUpdate.bind(this) }
				>
				
				{ previews.map( (preview, p) => {
					const style = {...preview.style, backgroundImage: `url(${preview.thumb})`, width: isMobile ? '3rem' : '4rem', height: isMobile ? '3rem' : `4rem` }
					const button = (
						<button key={p}
							className="ProductPreview naked_button bg_center bg_cover border_rounded border_none box_shadow hover_raise transition_all mb_half bg_white" 
							style={style}
							onClick={ () => this.setState({ activeIndex: p, showModal: true }) }
							/>
					)

					if ( isMobile && p > 0 ) {
						// only return FIRST preview in mobile
						return ''

					} else {
						if ( preview.popup ) {
							return (	
								<Popup key={p} trigger={button} on='hover' position="right center">
									<p className="weight_bold">{ preview.popup }</p>
								</Popup>
							)
						} else {
							return button
						}
					}

				} ) }

				{ activePreview && 
					<Modal basic open={showModal} onClose={() => this.setState({ showModal: false })}>
						<Modal.Content>
							<div className="text_center" style={{padding: isMobile ? '0 5px' : '0 55px' }}>
								{ activePreview.overlayProject ?
									<PreviewOverlay 
										overlay={{url: projectThumbnail, width: projectWidth, height: projectHeight}}
										background={activePreview}
										loading={generatingPreview}
										/>
									: <Image centered src={activePreview.url} />
								}
							</div>
							{ activePreview.title && 
								<Header inverted as="p" textAlign="center">{activePreview.title}</Header>
							}
							{ previews.length > 1 && <>
								<Button circular inverted active={isMobile} size={ isMobile ? "tiny" : "huge"} icon="arrow left" title="previous image" 
									style={{ 
										position:'absolute', 
										top: `calc(50% - ${ isMobile ? '18px' : '30px' })`,
										left: 0,
										margin: 0
									}}
									onClick={() => this.setState({ activeIndex: prevIndex })}
									/>
								<Button circular inverted active={isMobile} size={ isMobile ? "tiny" : "huge"} icon="arrow right" title="next image" 
									style={{ 
										position:'absolute', 
										top: `calc(50% - ${ isMobile ? '18px' : '30px' })`,
										right: 0,
										margin: 0
									}}
									onClick={() => this.setState({ activeIndex: nextIndex })}
									/>
							</>}
						</Modal.Content>
					</Modal>
				}

			</Responsive>			
		);
	}

}


function mapStateToProps(state) {
	return {
		projectThumbnail: state.project.present.thumbnail,
		activeFrame: state.project.present.activeFrame, 
		projectWidth: state.project.present.artboardwidth,
		projectHeight: state.project.present.artboardheight,
    }
}

export default connect(mapStateToProps)(ProductPreviews)


	
function PreviewOverlay({ overlay, background, loading }) {

	// calculate ratio of overlay relative to background
	const overlayToBackgroundRatio = (overlay.width/PPI) / background.widthInches
	const overlayDisplayWidth = background.width * overlayToBackgroundRatio
	const overlayRatio = overlay.height / overlay.width
	const overlayDisplayHeight = overlayDisplayWidth * overlayRatio
	const overlayPosition = {
		x: background.centerPointX - overlayDisplayWidth / 2,
		y: background.centerPointY - overlayDisplayHeight / 2
	}

	return (
		<Dimmer.Dimmable dimmed={loading}>
			<svg version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				viewBox={`0 0 ${background.width} ${background.height}`}
				width={background.width} height={background.height}
				className="display_block max_width_100 height_auto mx_auto"
				>
				<defs>
					<filter id="preview-blur-2"><feGaussianBlur in="SourceGraphic" stdDeviation="2" /></filter>
					<filter id="preview-blur-4"><feGaussianBlur in="SourceGraphic" stdDeviation="4" /></filter>
					<filter id="preview-blur-8"><feGaussianBlur in="SourceGraphic" stdDeviation="8" /></filter>
				</defs>
				<image xlinkHref={background.url}
					x={0} y={0}	
					width="100%"
					height="100%"
					/>
				<g>
					<rect x={overlayPosition.x + 5} y={overlayPosition.y + 10}	
						width={overlayDisplayWidth - 10}
						height={overlayDisplayHeight - 10}
						filter="url(#preview-blur-8)" 
						fill="rgba(0,0,0,0.4)"
						/>
					<rect x={overlayPosition.x + 5} y={overlayPosition.y + 10}	
						width={overlayDisplayWidth - 10}
						height={overlayDisplayHeight - 10}
						filter="url(#preview-blur-4)" 
						fill="rgba(0,0,0,0.4)"
						/>
					<rect x={overlayPosition.x + 5} y={overlayPosition.y + 10}	
						width={overlayDisplayWidth - 10}
						height={overlayDisplayHeight - 10}
						filter="url(#preview-blur-2)" 
						fill="rgba(0,0,0,0.4)"
						/>
					<image xlinkHref={overlay.url}
						x={overlayPosition.x} y={overlayPosition.y}	
						width={overlayDisplayWidth}
						height={overlayDisplayHeight}
						/>
				</g>
			</svg>
			<Dimmer inverted active={loading}>
				<Loader>Loading...</Loader>
			</Dimmer>
		</Dimmer.Dimmable>
	)

}
