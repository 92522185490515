import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateProject } from '../../reducks/project'
import { Dropdown, Menu, Popup, Icon, Modal, Header, Button, Responsive } from 'semantic-ui-react'
import { setPrintSheetModal, setUserEntriesModal, setSaveModal, setLockedMode, setRulers, setGrid, setFrame, setSetupMode } from '../../reducks/app'
import { saveProject, setPublished } from '../core/utilities'

const Controls = props => {

	const { onZoom } = props

	const user = useSelector(state => state.user.meta)
	const project = useSelector(state => state.project.present)
	const { showFrame, showGrid, showRulers, lockedMode } = useSelector(state => state.app)
	const canUndo = useSelector(state => state.project.past.length > 0)
	const canRedo = useSelector(state => state.project.future.length > 0)
	const dispatch = useDispatch()

	const [resetConfirm, setResetConfirm] = useState(false)
	const [menuSize, setMenuSize] = useState('small')
	const [allowUnlock, setAllowUnlock] = useState(false)

	// compare initial project state to current
	const atInitialProjectState = (
		!window.Craft.initialProjectState instanceof Object
		// compare the stringified versions. Note the setting of `dateUpdated` to the same value.
		|| JSON.stringify({ ...project, dateUpdated: '' }) === JSON.stringify({ ...window.Craft.initialProjectState, dateUpdated: '' })
	)

	const downloadTxtFile = (text) => {
		const element = document.createElement("a");
		const file = new Blob([text], { type: 'text/plain;charset=utf-8' });
		element.href = URL.createObjectURL(file);
		element.download = project.title + ".json";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	const getCutFileJson = () => {
		const formatted_elements = project.elements.map(element => {
			let __element = { ...element }

			// Convert the y value to a bottom-left origin
			// First, get the parent element's height
			let parentHeight = project.artboardheight;
			if (__element.parent) {
				project.elements.forEach(_el => {
					if (__element.parent === _el.id) {
						parentHeight = _el.height;
					}
				})
			}
			// divide values by 72 in order to get inch value
			__element.y = (parentHeight - (__element.y + __element.height)) / 72;
			__element.x = element.x / 72;
			__element.width = element.width / 72;
			__element.height = element.height / 72;

			return __element;

		})
		const json = {
			"elements": formatted_elements,
			"heightInches": project.artboardheight / 72,
			"mats": project.projectMats.length ? project.projectMats.map(mat => {
				return { "title": mat.title, "sku": mat.sku }
			}) : null,
			"title": project.title,
			"units": "inches",
			"version": "1.0",
			"widthInches": project.artboardwidth / 72,
		}
		downloadTxtFile(JSON.stringify(json));
	}

	const handleAction = (action) => {
		switch (action) {
			case 'get print sheet':
				dispatch(setPrintSheetModal(true))
				break
			case 'get cut file json':
				getCutFileJson()
				break
			case 'publish':
				setPublished(1)
				break
			case 'unpublish':
				setPublished(0)
				break
			default:
				return
		}
	}

	const resetProject = () => {
		setResetConfirm(false)
		if (window.Craft.initialProjectState instanceof Object) {
			dispatch(updateProject(window.Craft.initialProjectState))
		}
	}

	const handleResponsiveUpdate = (event, { width }) => {
		const isLargeScreen = width > Responsive.onlyTablet.maxWidth
		if (isLargeScreen) {
			setMenuSize('huge')
			setAllowUnlock(true)
		} else {
			setMenuSize('mini')
			setAllowUnlock(false)
		}
	}

	return (
		<Responsive fireOnMount
			onUpdate={handleResponsiveUpdate}
			className="Controls display_flex flex_column align_items_start"
		>

			{user && (user.admin || user.partner) &&
				<Menu size={menuSize} compact vertical color="blue" className="icon mb_nudge">
					<Dropdown item icon={null} trigger={<Icon name="bars" />} pointing="left">
						<Dropdown.Menu direction="left">
							<Dropdown.Item content="Get print sheet" icon="print"
								onClick={() => handleAction('get print sheet')}
							/>
							<Dropdown.Item content="Get cut file JSON" icon="code"
								onClick={() => handleAction('get cut file json')}
							/>
							{user.admin && project.id && project.type === 'projectTemplates' &&
								(project.published ?
									<Dropdown.Item onClick={() => handleAction('unpublish')}>
										<Icon name="delete" color="red" />
										<strong className="color_red text">Un-publish</strong>
									</Dropdown.Item>
									:
									<Dropdown.Item onClick={() => handleAction('publish')}>
										<Icon name="star outline" color="blue" />
										<strong className="color_blue text">Publish template</strong>
									</Dropdown.Item>
								)
							}
							{user.admin && project.id &&
								<Dropdown.Item content="Edit in backend"
									icon="pencil"
									as="a"
									target="_blank"
									href={`${window.Craft.baseUrl}/admin/entries/${project.type}/${project.id}`}
								/>
							}
						</Dropdown.Menu>
					</Dropdown>
				</Menu>
			}

			{user &&
				<Menu size={menuSize} compact vertical className="icon mb_nudge">
					<Popup content="Open" inverted position="left center" trigger={
						<Menu.Item icon="folder open" onClick={() => dispatch(setUserEntriesModal(true))} />
					} />
					<Dropdown item icon={null} trigger={<Icon name="save" />} pointing="left">
						<Dropdown.Menu direction="left">
							{project.id &&
								<Dropdown.Item content="Save" onClick={() => saveProject(project, project.id, user.id)} />
							}
							<Dropdown.Item content={project.id ? "Save New" : "Save"} onClick={() => dispatch(setSaveModal(true))} />
						</Dropdown.Menu>
					</Dropdown>
					{/* <Popup content="Save" inverted position="left center" trigger={
							<Menu.Item icon="save" onClick={ 
								user && project.id ?
									() => saveProject(project, project.id, user.id)
									: () => dispatch( setSaveModal(true) ) 
							} />
						} /> */}
				</Menu>
			}

			{allowUnlock &&
				<Menu size={menuSize} compact vertical color="blue" className="icon mb_nudge">

					<Popup inverted position="left center"
						trigger={
							<Menu.Item id="LockToggleButton"
								active={!lockedMode}
								icon="object ungroup outline"
								onClick={() => dispatch(setLockedMode(!lockedMode))}
							/>
						}
						content={<>
							<strong>Pro Tools <span className="color_yellow">[beta]</span></strong><br />
							{`Unleash your creativity with these tools intended for the framing professional. Click any element on your project to manipulate its properties and add new elements.`}
						</>}
					/>

					{/* <Dropdown item icon={null} trigger={<Icon name="eye" />} pointing="left">
						<Dropdown.Menu direction="left">
							<Dropdown.Item content="Rulers" icon={showRulers ? 'toggle on' : 'toggle off'}
								onClick={event => { event.stopPropagation(); dispatch(setRulers(!showRulers)); }}
							/>
							<Dropdown.Item content="Grid" icon={showGrid ? 'toggle on' : 'toggle off'}
								onClick={event => { event.stopPropagation(); dispatch(setGrid(!showGrid)); }}
							/>
							<Dropdown.Item content="Frame" icon={showFrame ? 'toggle on' : 'toggle off'}
								onClick={event => { event.stopPropagation(); dispatch(setFrame(!showFrame)); }}
							/>
						</Dropdown.Menu>
					</Dropdown> */}

					{/* {user && user.admin &&
						<Popup inverted position="left center"
							content={<>
								<strong>Layout Wizard</strong><br />
								{`Use this tool to add more openings, create a collage, or easily change your layout dimensions.`}
							</>}
							trigger={
								<Menu.Item icon="table" onClick={() => dispatch(setSetupMode(true))} />
							}
						/>
					} */}
				</Menu>
			}

			<Menu size={menuSize} compact vertical className="icon mb_nudge">
				<Popup
					inverted position="left center"
					content={
						<><strong>Start over</strong><Icon name="exclamation triangle" color="red" className="ml_half" /><br />Remove all images and reset any options you've selected during this session.</>
					}
					trigger={
						<Menu.Item disabled={atInitialProjectState} icon={<Icon flipped="horizontally" name="sync alternate" color={atInitialProjectState ? undefined : "red"} />} onClick={() => setResetConfirm(true)} />
					}
				/>
				<Popup inverted content="Undo" position="left center"
					trigger={
						<Menu.Item icon="undo alternate" disabled={!canUndo}
							onClick={() => dispatch({ type: 'UNDO' })}
						/>
					}
				/>
				<Popup inverted content="Redo" position="left center"
					trigger={
						<Menu.Item icon="redo alternate" disabled={!canRedo}
							onClick={() => dispatch({ type: 'REDO' })}
						/>
					}
				/>
			</Menu>

			<Menu size={menuSize} compact vertical className="icon mb_nudge">
				<Popup content="Zoom in" inverted position="left center"
					trigger={
						<Menu.Item icon="zoom in" onClick={() => onZoom("zoom in")} />
					}
				/>
				<Popup content="Zoom out" inverted position="left center"
					trigger={
						<Menu.Item icon="zoom out" onClick={() => onZoom("zoom out")} />
					}
				/>
			</Menu>

			<Modal open={resetConfirm} size="mini" onClose={() => setResetConfirm(false)}>
				<Modal.Content>
					<Header>Are you sure you want to start over?</Header>
					<p>All of the updates you've made during this session will be removed.</p>
				</Modal.Content>
				<Modal.Actions>
					<Button basic onClick={() => setResetConfirm(false)}>Cancel</Button>
					<Button negative onClick={() => resetProject()}>Yes, reset project</Button>
				</Modal.Actions>
			</Modal>

		</Responsive>
	)

}

export default Controls