export default (reducer, options: {}) => {
    // Call the reducer with empty action to populate the initial state
    const initialState = {
        past: [],
        present: reducer(undefined, {}),
        future: []
    }

    // Return a reducer that handles undo and redo
    return function(state = initialState, action) {
        let previous, newPast, next, newFuture
        const {
            past,
            present,
            future
        } = state
        // ^^^ these guys are immutable, so make copies if you need to mutate them

        switch (action.type) {
            case 'UNDO':
                previous = past[past.length - 1]
                newPast = past.slice(0, past.length - 1)
                return {
                    past: newPast,
                    present: previous,
                    future: [present, ...future]
                }
            case 'REDO':
                next = future[0]
                newFuture = future.slice(1)
                return {
                    past: [...past, present],
                    present: next,
                    future: newFuture
                }
            default:
                // Delegate handling the action to the passed reducer
                const newPresent = reducer(present, action)

                if (present === newPresent) {
                    return state
                }

                // make a copy of `past` (remember, function arguments are IMMUTABLE!)
                newPast = Object.assign([], past)

                // handle undoable action
                if (action.undoable) {
                    // remove first entry if limit as been reached
                    if ( options && options.limit && past.length === options.limit ) {
                        newPast.shift()
                    }
                    // add `present` to the end of `past`
                    newPast = [...newPast, present]
                }

                // return new state
                return {
                    past: newPast,
                    present: newPresent,
                    future: []
                }
        }
    }
}
