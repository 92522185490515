import {post} from './fetchKit'

const endpoints = {
    images: "default/get-images",
    artists: "default/get-artists",
    categories: "default/get-categories",
    orientations: "default/get-orientations",
    publishers: "default/get-publishers",
    imageTypes: "default/get-image-types",
}

export default class PodApi {

    static getData( handle, params ) {
        return post(`/actions/pod-exchange-module/${endpoints[ handle ]}`, params)
    }

}
