import React, { useState, useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import LayoutSVG from './LayoutSVG'
import { PPI, MAX_SIZE } from '../../data/constants'
import { addSnack, dismissSnack } from '../../reducks/app'
import Fraction, { getFraction } from '../core/Fraction'
import { Header, Icon, Image } from 'semantic-ui-react'

const LayoutPreview = props => {

	const { onUpdate } = props

	const [widthInches, setWidthInches] = useState(0)
	const [heightInches, setHeightInches] = useState(0)
	const [tooLarge, setTooLarge] = useState(false)
	
	const { openings, layout, borders, gutters } = useSelector( state => state.setup )
	const requireShipping = useSelector( state => state.app.requireShipping )
	const dispatch = useDispatch()
	
	const onCreateLayoutSVG = calcLayout => {
		const { width, height } = calcLayout
		const tooLarge = requireShipping && (width/PPI > MAX_SIZE || height/PPI > MAX_SIZE)
		setWidthInches(width/PPI)
		setHeightInches(height/PPI)
		setTooLarge(tooLarge)
		if ( typeof onUpdate === 'function' ) {
			onUpdate(tooLarge)
		}
	}

	useEffect(() => {
		if ( tooLarge ) {
			dispatch( addSnack({
				id: 'layout-size',
				message: `Sorry, ${getFraction(widthInches)} × ${getFraction(heightInches)} inches is too large to ship!`,
				description: `The maximum size for shipped items is ${MAX_SIZE} inches. Try decreasing your border width or choosing a different image size.`,
				color: 'red',
				icon: 'exclamation circle',
				canDismiss: false
			}) )
		}
		else {
			dispatch( dismissSnack('layout-size') )
		}
	}, [tooLarge, widthInches, heightInches])

	return (
		<div className="LayoutPreview py_3 px_6 height_100 display_flex align_items_center justify_content_center flex_column">
			<Header textAlign="center" color={tooLarge ? 'red' : undefined}>
				{ tooLarge && <span><Icon name="exclamation circle" /></span> }
				<Fraction float={widthInches} /> &times; <Fraction float={heightInches} /> &rdquo;
			</Header>
			<Image fluid style={{maxWidth:'70%', maxHeight: '100%'}} className='box_shadow'>
				<LayoutSVG 
					{...{layout, openings, borders, gutters} }
					onCreate={onCreateLayoutSVG}
					/>
			</Image>
		</div>
	)
}

export default LayoutPreview