import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transition, Button, Icon, Grid, Message, Dropdown, Popup, Menu, Header, Label, Segment } from 'semantic-ui-react'
import { PPI } from '../../data/constants'
import { loadResources } from '../../reducks/resources'
import { setActiveElement } from '../../reducks/tools'
import { updateDefaultReveal, addMat, removeMat, removeAllMats, updateMat, updateElementProps, updateDimensions, updateProject } from '../../reducks/project'
import { createOpening } from '../elements/element-helpers'
import MatGraphic from './MatGraphic'
import {matLabels, getCurrentBorders, getItemPrice, getBottomOpenings} from '../core/utilities'
import Section from './Section'
import Fraction from '../core/Fraction'
import MatPalette from './MatPalette'
import Currency from '../core/Currency'

const Matting = props => {

    const { title, onClose, subtitle } = props
    
    const project = useSelector( state => state.project.present )
    const { elements, artboardwidth, artboardheight, defaultReveal, projectMats } = project
    const { matProducts } = useSelector( state => state.resources )
    const dispatch = useDispatch() 

    const {top, left, bottom} = getCurrentBorders(elements, artboardwidth, artboardheight)
    
    const [activeMat, setActiveMat] = useState(-1)
    const [loadMatsFailure, setLoadMatsFailure] = useState(false)
    const [loading, setLoading] = useState(false)
    const [matOptionsOpen, setMatOptionsOpen] = useState(false)
    const [selectedBorderWidth, setSelectedBorderWidth] = useState(top)
    const [selectedTopBorderWidth, setSelectedTopBorderWidth] = useState(top)
    const [selectedSideBorderWidth, setSelectedSideBorderWidth] = useState(left)
    const [selectedBottomBorderWidth, setSelectedBottomBorderWidth] = useState(bottom)
    const [borderWidthError, setBorderWidthError] = useState(false)
    const [borderHasChanged, setBorderHasChanged] = useState(false)
    const [fineTuneBorders, setFineTuneBorders] = useState(false)
    const [activeTab, setActiveTab] = useState('matSelect')
    const [recentMats, setRecentMats] = useState([])

    const defaultRevealOptions = [
        { value: 0.125 * PPI, text: "1/8 inch" },
        { value: 0.25 * PPI, text: "1/4 inch" },
        { value: 0.375 * PPI, text: "3/8 inch" },
        { value: 0.5 * PPI, text: "1/2 inch" },
        { value: 0.625 * PPI, text: "5/8 inch" },
        { value: 0.75 * PPI, text: "3/4 inch" },
        { value: 0.875 * PPI, text: "7/8 inch" },
        { value: 1 * PPI, text: "1 inch" },
    ]

    const maxMatCount = 3
    const hasComplexShapes = elements.reduce( (isComplex, elem) => {
        isComplex = elem.shape instanceof Object
        return isComplex
    }, false )

    useEffect(() => {
        if ( ! matProducts.length ) {
            loadMatProducts('all')
        }
    }, [])

    function loadMatProducts(handle) {
        setLoading(true)
        dispatch( loadResources( 'matProducts', handle, (json, error) => {
            if ( error ) {
                setLoadMatsFailure(error)
            }
            setLoading(false)
        }) )
    }

    function selectMat(mat) {
        addRecentMat(mat)
        dispatch( updateMat( activeMat, mat ) )
    }

    function addRecentMat(mat) {
        const activeMatObject = projectMats[activeMat]
        const matNotInRecent = recentMats.map( m => m.id ).indexOf(activeMatObject.id) < 0
        if ( matNotInRecent ) {
            const 
                max = 6, 
                newRecentMats = [activeMatObject, ...recentMats]
                
            newRecentMats.splice(max, 1)
            setRecentMats(newRecentMats)
        }
    }

    function addMatLayer() {
        // event.stopPropagation()
        // event.nativeEvent.stopImmediatePropagation();

        let newElements = []

        elements.forEach( elem => {

            // if NOT a rect or oval opening, just add it
            if ( ! elem.shape || (elem.shape !== 'rect' && elem.shape !== 'oval') ) {
                newElements.push({
                    ...elem, 
                    image: null, 
                    depth: elem.depth > 1 ? elem.depth + 1 : elem.depth
                })
                return;
            }

            const isSource = elem.id === 'source-opening'
            let reveal

            /** If -NO- mat layers */
            if ( projectMats.length === 0 ) {
                // we need to update the size, create a new child, and move the image down to it
                reveal = 2.5 * PPI
                // update project dimensions
                dispatch( updateDimensions({ 
                    width: elem.width + reveal * 2,
                    height: elem.height + reveal * 2
                }) )
                // update element
                const updatedElement = {
                    ...elem,
                    width: elem.width + reveal * 2,
                    height: elem.height + reveal * 2,
                    image: null
                }
                // add new child
                const newChild = createOpening( elem.shape, {
                    image: elem.image,
                    parent: elem.id,
                    width: elem.width,
                    height: elem.height,
                    x: reveal,
                    y: reveal,
                    depth: elem.depth + 1,
                })
                // add to collection
                newElements.push(updatedElement, newChild)
            }
            /** IF there -ARE- mat layers */
            else {
                const isTopMat = elem.depth === 1  
                // if a TOP MAT opening
                if ( isTopMat ) {
                    reveal = defaultReveal
                    
                    // create new parent
                    const newParent = createOpening( elem.shape, {
                        parent: elem.parent,
                        width: elem.width + reveal * 2,
                        height: elem.height  + reveal * 2,
                        x: elem.x - reveal,
                        y: elem.y - reveal,
                        depth: elem.depth,
                    } )

                    // update element props
                    const updatedElem = {
                        ...elem,
                        x: reveal,
                        y: reveal,
                        parent: newParent.id,
                        depth: elem.depth + 1
                    }

                    // add the updated element and new parent
                    newElements.push(newParent, updatedElem)    
                }
                else {
                    // just update depth and push element
                    newElements.push({
                        ...elem,
                        depth: isSource ? elem.depth : elem.depth + 1,
                    })
                }
            }

        } )
        
        // default will just be the first mat in projectMats
        let mat = 'default'
        if ( projectMats.length === 0 ) {
            // if there are no existing project mats, get the default mat object
            mat = matProducts.find( mat => mat.id === window.Craft.defaultMatId )
            mat = mat ? mat : 'default'
        }
        
        setActiveMat( typeof activeMat === 'number' ? activeMat + 1 : 0 )
        dispatch( addMat(mat, newElements) )
    }

    function removeMatLayers(matlayerkey) {
        let sourceOpening = elements.find(elem => elem.id === 'source-opening')
        let updatedElements = []

        // reset active mat
        setActiveMat(-1)

        // populate updatedElements array
        elements.forEach( elem => {

            const isCutArt = ! ['rect', 'oval'].includes(elem.shape)
            
            // if source opening
            if ( elem.id === "source-opening" ) {
                return
            }
            // if its an element on the top mat
            else if ( elem.depth === 1 ) {
                // move image to source
                sourceOpening.image = elem.image
                // if its a cutart shape, we'll keep it
                if ( isCutArt ) {
                    updatedElements.push(elem)
                }
                // get children
                const childElements = elements.filter( __elem => __elem.parent === elem.id )
                // loop through each child and add to updatedElements
                childElements.forEach( child => {
                    updatedElements.push({
                        ...child,
                        parent: elem.parent,
                        x: elem.x + child.x,
                        y: elem.y + child.y,
                        depth: child.depth - 1
                    })
                } )
            }
            // if its an element deeper than the 2nd mat
            else if ( elem.depth > 2 ) {
                // update the depth
                updatedElements.push({
                    ...elem,
                    depth: elem.depth - 1
                })
            }

        })

        // remove mat
        dispatch( removeMat(matlayerkey) )
        // reset active element
        dispatch( setActiveElement(null) )
        // update project with new elements
        dispatch( updateProject({ elements: [sourceOpening, ...updatedElements] }) )
        
    }

    function removeAllMatLayers() {
        let sourceOpening = elements.find( elem => elem.id === 'source-opening' );
        const targetOpening = getBottomOpenings(project)[0]
        
        sourceOpening.image = targetOpening.image
        sourceOpening.width = targetOpening.width
        sourceOpening.height = targetOpening.height

        // transfer children of targetOpenging to sourceOpening
        const targetChildren = elements
            .filter( elem => elem.parent === targetOpening.id )
            .map( elem => (
                {
                    ...elem,
                    parent: 'source-opening',
                    depth: targetOpening.depth
                }
            ) )

            // update project with new size and elements
            dispatch( updateProject({ 
                artboardwidth: targetOpening.width,
                artboardheight: targetOpening.height,
                elements: [sourceOpening, ...targetChildren],
                projectMats: []
            }) )
            // reset active element
            dispatch( setActiveElement(null) )
    }

    function handleSubmitBorderWidth(event, data) {
        const minBorder = Math.min.apply( Math, [selectedTopBorderWidth, selectedSideBorderWidth, selectedBottomBorderWidth] )
        const minWidth = 2.5 * PPI

        // reset changed state
        setBorderHasChanged(false)

        // validate selected border width(s)
        if ( minBorder < minWidth ) {
            setBorderWidthError("Borders should not be smaller than 2.5 inches.")
            return
        } else {
            setBorderWidthError("")
        }

        // get the current borders and top mat elements
        const {left, top, right, bottom, topMatElements} = getCurrentBorders(elements, artboardwidth, artboardheight)

        // get the change in borders
        const topChange = selectedTopBorderWidth - top
        const leftChange = selectedSideBorderWidth - left
        const rightChange = selectedSideBorderWidth - right
        const bottomChange = selectedBottomBorderWidth - bottom

        // update each top mat element with new x, y values
        topMatElements.forEach( elem => {
            dispatch( updateElementProps( elem.id, {
                x: elem.x + leftChange,
                y: elem.y + topChange,
            }) )
        } )

        // update source opening dimensions
        const sourceOpening = elements.find( elem => elem.id === 'source-opening' )
        dispatch( updateElementProps('source-opening', {
            width: sourceOpening.width + leftChange + rightChange,
            height: sourceOpening.height + topChange + bottomChange
        }) )
    }

    function setUniversalBorderWidth(value) {
        value = parseFloat(value)
        setSelectedBorderWidth(value)
        setSelectedTopBorderWidth(value)
        setSelectedSideBorderWidth(value)
        setSelectedBottomBorderWidth(value)
        setBorderHasChanged(true)
    }

    return ( 
        <Section title={title} loading={loading && "Loading Matboard"} onClose={onClose}>

            <Section.Content>

            { subtitle && <p className="mb_1"><small>{subtitle}</small></p> }

            { ! hasComplexShapes &&
                <Menu fluid secondary pointing widths={ 2 } size="huge" className="mb_3">
                    <Menu.Item 
                        content="Layers"
                        icon="clone outline"
                        active={activeTab === 'matSelect'}
                        onClick={ () => setActiveTab('matSelect') }
                        />
                    <Menu.Item 
                        content="Borders"
                        icon="expand"
                        active={activeTab === 'borders'}
                        disabled={projectMats.length < 1}
                        onClick={ () => setActiveTab('borders') }
                        />
                </Menu>
            }

            { activeTab === 'matSelect' && <>
                <Segment vertical basic className="pt_0">

                    { ! hasComplexShapes && <>
                        <Header as="h3" size="tiny" textAlign="center">
                            Add up to {maxMatCount} matboard layers to surround your image
                        </Header>
                        <Button.Group fluid>
                            { ! hasComplexShapes && projectMats.length > 0 &&
                                <Popup
                                    on="click"
                                    position="top center"
                                    trigger={
                                        <Button basic color="grey"
                                            content="Remove all"
                                            icon="delete"
                                            disabled={matProducts.length === 0}
                                            style={{ maxWidth:145, paddingLeft:10, paddingRight:10 }}
                                            />
                                    }>
                                    <Header size="tiny">Are sure?</Header>
                                    <p><Icon name="exclamation circle" /> This will remove ALL matboard from your project.</p>
                                    <Button negative fluid size="mini" onClick={ () => removeAllMatLayers() }>Remove all mats</Button>
                                </Popup>
                            }
                            <Button basic primary
                                direction="below"
                                icon="add"
                                content={ projectMats.length < maxMatCount ? 'Add a mat' : `Max ${maxMatCount} mats` }
                                disabled={ projectMats.length >= maxMatCount }
                                onClick={ () => addMatLayer() }
                                />
                        </Button.Group>
                    </>}
                
                    <div className="ProjectMatsList mt_3">
                        { projectMats.map( (mat, i) => {
                            const matPrice = getItemPrice(mat.price, artboardwidth/PPI, artboardheight/PPI, window.Craft.pricing.matboard)
                            const matColorButton = (
                                <Button
                                    style={{margin:'1px 0', backgroundColor:'white', border:'1px solid #d0d1d1'}}
                                    onClick={ () => { setMatOptionsOpen(true); setActiveMat(i) } }
                                    >
                                    {mat.title} <span className="faded"><Currency value={matPrice} /></span>
                                    <Icon name="grid layout" className="ml_1"/>
                                    <Icon name="dropdown" fitted/>
                                </Button>
                            )
                            const removeMatButton = (
                                <Button
                                    style={{backgroundColor:'white', border:'1px solid #d0d1d1'}}
                                    icon="delete" 
                                    className="ml_nudge"
                                    onClick={ () => removeMatLayers(i) }
                                    />
                            )
                            return (
                                <div key={i} className="MatItem">
                                    <MatGraphic id={mat.sku} 
                                        color={mat.hex} 
                                        core={mat.core} 
                                        className="flex_shrink_0 fade_right" 
                                        style={{ zIndex: projectMats.length - i, width:'100%' }}
                                        />
                                    <div className="MatItem__actions">
                                        <Popup trigger={matColorButton}>
                                            Select mat color
                                        </Popup>
                                        { ! hasComplexShapes && projectMats.length > 1 && 
                                            <Popup trigger={removeMatButton}>
                                                Remove {matLabels[projectMats.length][i]}
                                            </Popup>
                                        }
                                    </div>
                                </div>
                            )
                        } ) }
                    </div>

                    { loadMatsFailure &&
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>Could not load mat products. Please try reloading the page.</p>
                            <Button size="tiny" icon="sync" content="Try again" onClick={ () => loadMatProducts('all') } />
                        </Message>
                    }

                </Segment>

                <Transition.Group animation="fade left" duration={200} >
                    { matOptionsOpen &&
                        <div className="position_absolute top_0 left_0 right_0 bottom_0 py_1 px_2 bg_white" style={{zIndex: 10}}>
                            <MatPalette
                                matProducts={matProducts}
                                projectMats={projectMats}
                                activeMat={activeMat}
                                onSelectMat={ selectMat }
                                onClose={ event => { event.stopPropagation(); setMatOptionsOpen(false) } }
                                />
                        </div>
                    }
                </Transition.Group>
            </>}

            { activeTab === 'borders' && <>

                <Segment basic vertical>

                    <Header as="h4" className="my_0">Border width <small className="faded">(inches)</small></Header>
                    <small className="display_block faded">The space between the inside of the frame and the artwork.</small>
                    
                    { ! fineTuneBorders && <>
                        <Grid columns={2} className="mt_0">
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column>
                                    <div className="ui fluid input">
                                        <input type="number" value={selectedBorderWidth/PPI} min={2.5} step={0.125}
                                            onChange={ event => setUniversalBorderWidth(event.target.value * PPI) }
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                padding: '0.25em',
                                                fontSize: '1.5rem',
                                            }}
                                            />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button fluid primary
                                        icon="check"
                                        basic={!borderHasChanged}
                                        content={ <>Apply <Fraction float={selectedBorderWidth / PPI} />&rdquo;</> }
                                        onClick={ handleSubmitBorderWidth }
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>}
                    
                    {/* { ! fineTuneBorders && 
                        <div className="display_flex align_items_center my_1" style={{fontSize:'1.5rem'}}>
                            <strong className="text_center white_space_no_wrap">2 &frac12;</strong>
                            <input type="range" className="width_100" 
                                value={selectedBorderWidth} 
                                min={2.5 * PPI} max={8 * PPI} step={0.25 * PPI}
                                style={{margin:'0 10px'}}
                                onChange={ event => setUniversalBorderWidth(event.target.value) }
                                />
                            <strong className="text_center white_space_no_wrap">8</strong>
                        </div>
                    } */}

                    <Button basic fluid 
                        content={ fineTuneBorders ? "Set uniform borders" : "Adjust individual borders"}
                        icon={ fineTuneBorders ? "caret up" : "unordered list"}
                        size="tiny"
                        className="mt_1"
                        onClick={ () => setFineTuneBorders( !fineTuneBorders ) } 
                        />

                    { fineTuneBorders &&
                        <Grid columns={2} className="mt_1">
                            <Grid.Row verticalAlign="middle" >
                                <Grid.Column>
                                    <Header as="h4">Top Border</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="ui fluid input">
                                        <input type="number" value={selectedTopBorderWidth/PPI} min={2.5} step={0.125}
                                            onChange={ event => setSelectedTopBorderWidth(parseFloat(event.target.value * PPI)) }
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                padding: '0.25em',
                                                fontSize: '1.5rem',
                                            }}
                                            />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle" >
                                <Grid.Column>
                                    <Header as="h4">Side borders</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="ui fluid input">
                                        <input type="number" value={selectedSideBorderWidth/PPI} min={2.5} step={0.125}
                                            onChange={ event => setSelectedSideBorderWidth(parseFloat(event.target.value * PPI)) }
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                padding: '0.25em',
                                                fontSize: '1.5rem',
                                            }}
                                            />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row verticalAlign="middle" >
                                <Grid.Column>
                                    <Header as="h4">Bottom border</Header>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="ui fluid input">
                                        <input type="number" value={selectedBottomBorderWidth/PPI} min={2.5} step={0.125}
                                            onChange={ event => setSelectedBottomBorderWidth(parseFloat(event.target.value * PPI)) }
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                padding: '0.25em',
                                                fontSize: '1.5rem',
                                            }}
                                            />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                    
                    { fineTuneBorders && 
                        <Button fluid basic primary
                            icon="check"
                            className="mt_1"
                            content="Apply custom borders"
                            onClick={ handleSubmitBorderWidth }
                            />
                    }
                    
                    { borderWidthError && 
                        <Message negative>
                            <Header size="tiny">{borderWidthError}</Header>
                        </Message>
                    }
                </Segment>
                <Segment basic vertical>
                    <Header as="h4" className="my_0">Default Reveal</Header>
                    <small className="display_block faded">
                        The mat width underneath another mat you wish to see. Change reveal size before you add a new mat layer.
                    </small>
                    <Dropdown button selection fluid
                        name="defaultReveal"
                        id="defaultReveal"
                        className="mt_1"
                        defaultValue={defaultReveal}
                        options={defaultRevealOptions}
                        onChange={(e, {value}) => dispatch( updateDefaultReveal( parseFloat(value) ) )}
                        />
                </Segment>

            </>}

            </Section.Content>

            <Section.Taskbar visible={matOptionsOpen}>
                <Popup
                    inverted
                    trigger={ <Button primary size="mini" icon="arrow left" onClick={ () => setMatOptionsOpen(false) } /> }
                    content="Back to mat layers"
                    />
                { projectMats[activeMat] && 
					<Label basic size="large" className="mr_half">
						<span className="faded">{matLabels[projectMats.length][activeMat]}:</span>
						<span className="border_radius_50 display_inline_block mx_nudge box_shadow" style={{ width:12, height:12, verticalAlign: -1, backgroundColor: projectMats[activeMat].hex }}></span>
						{projectMats[activeMat].title}
					</Label>
				}
                { recentMats.length > 0 && <>
                    <div className="display_flex align_items_center ml_auto">
                        { recentMats.map( (mat,m) => {
                            return (
                                <button key={m} className="naked_button border_lightest_grey border_rounded pa_half" title={mat.title} onClick={ () => selectMat(mat) }
                                    style={{ backgroundColor:mat.hex }}>
                                    <span className="display_none">{mat.title}</span>
                                </button>
                            )
                        } ) }
                    </div> 
                </>}
            </Section.Taskbar>

        </Section> 
    )

}

export default Matting