import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Icon, Dropdown } from 'semantic-ui-react'
import { setUserFormMode } from '../../reducks/app'

const UserActions = props => {

    const { fluid, item } = props
    const user = useSelector(state => state.user.meta)
    const dispatch = useDispatch()

    function onClickLogin() {
        dispatch(setUserFormMode('login'))
    }

    function logOut() {
        window.location = user.logoutUrl
    }

    if (user instanceof Object) {

        return (
            <Dropdown
                item={item}
                pointing="top right"
                trigger={<><Icon className="mr_nudge" name="user circle" /><span>{user.name}<Icon name="dropdown" /></span></>}
                icon={false}
                fluid={fluid}
            >
                <Dropdown.Menu>
                    {user.admin && <>
                        <Dropdown.Item as="a" href={window.Craft.cpUrl}>
                            <Icon name="chart bar outline" />
                            Admin Dashboard
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>}
                    {/* {user.shop && <>
                        <Dropdown.Item as="a" href={window.Craft.baseUrl + "/my-shop"}>
                            <Icon name="building" />
                            My Shop
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>} */}
                    <Dropdown.Item as="a" href={window.Craft.baseUrl + '/user/projects'}>
                        <Icon name="grid layout" />
                        My Wall
                    </Dropdown.Item>
                    <Dropdown.Item as="a" href={window.Craft.baseUrl + '/user/orders'}>
                        <Icon name="boxes" />
                        My Orders
                    </Dropdown.Item>
                    <Dropdown.Item as="a" href={window.Craft.baseUrl + '/user'}>
                        <Icon name="user circle outline" />
                        My Profile
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={logOut}>
                        <Icon name="log out" />
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )

    }
    else {

        return (
            <a href="/user/login" className={ item ? 'item' : '' } onClick={e => { e.preventDefault(); onClickLogin() }}>
                <Icon name="user circle" /> Sign in
            </a>
        )

    }
}

export default UserActions