import {sortDESC} from '../helpers'
import CloudinaryApi from './cloudinaryApi'
import {post, get} from './fetchKit'
import {normalizeImageData} from '../components/core/utilities'

export default class UserApi {

    static logIn(data) {
        return post('/actions/users/login', data)
    }

    static resetPassword(data) {
        return post('/actions/users/send-password-reset-email', data)
    }

    static saveUser(data) {
        return post('/actions/users/save-user', data)
    }

    static getCurrentUser() {
        return post('/api/current-user.json')
    }

    static async getUserMedia(user) {
        let media
        if ( user ) {
            media = await CloudinaryApi.assets({
                prefix: `user_uploads/user_${user.id}/uploads`,
                max_results: 500,
            })
            media.resources = media.resources.map( image => normalizeImageData({
                ...image, 
                thumbnail: image.secure_url && image.secure_url.split('/upload/').join('/upload/w_150/') 
            }, 'cloudinary') )
        }
        else {
            /** @deprecated grab locally stored media */
            // media = this.getLocalStorageMedia()
            media = { resources: [] }
        }

        media.resources.sort( sortDESC('created_at') )

        return media
    }

    /** 
     * @deprecated grab locally stored media 
     * */
    // static getLocalStorageMedia() {
    //     if ( storageAvailable('localStorage') ) {
    //         const localMedia = window.localStorage.getItem('wallcoreAnonymousUploads')
    //         if (localMedia) {
    //             return JSON.parse( localMedia )
    //         }
    //         else {
    //             return { resources: [] }
    //         }
    //     }
    //     else {
    //         return {
    //             error: { message: 'Please login or create an account to upload images.' }
    //         }
    //     }
    // }

    static async getUserArt(user) {
        if ( user ) {
            const response = await get('/api/current-user/art.json')
            return response
        }
        else {
            /** @deprecated get locally stored art */
            // return this.getLocalStorageArt()
            return { art: [] }
        }
    }

    /** 
     * @deprecated get locally stored art 
     * */
    // static getLocalStorageArt() {
    //     if ( storageAvailable('localStorage') ) {
    //         const localArt = window.localStorage.getItem('wallcoreLocalArt')
    //         if (localArt) {
    //             return JSON.parse( localArt )
    //         }
    //         else {
    //             return { art: [] }
    //         }
    //     }
    //     else {
    //         return {
    //             error: { message: 'Please login or create an account to use the art library.' }
    //         }
    //     }
    // }

    static getUserProjects(page) {
        return get(`/api/current-user/projects.json?page=${page}`)
    }

    static getUserTemplates(page) {
        return get(`/api/current-user/project-templates.json?page=${page}`)
    }

    static getSessionInfo() {
        return post(`/actions/users/session-info&dontExtendSession=1`)
    }

}
