import React, { useState } from 'react'
import {Button, Modal, Transition, Icon, Message} from 'semantic-ui-react'
import {useSelector, useDispatch} from 'react-redux'
import { setUserFormMode } from '../../reducks/app'
import {setFetchArt, setFetchUploads, updateUser } from '../../reducks/user'
import UserApi from '../../api/userApi'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ForgotPasswordForm from './ForgotPasswordForm'

const LoginRegister = props => {

    let cookies = {};
    window.document.cookie.split(';').forEach(function(cookie){
        if ( cookie.length && cookie.indexOf('=') > 0 ) {
            const key = cookie.split('=')[0].trim()
            const val = cookie.split('=')[1].trim()
            cookies[key] = val;
        }
    })

    const [loggingIn, setLoggingIn] = useState(false)
    const [resettingPassword, setResettingPassword] = useState(false)
    const [registering, setRegistering] = useState(false)
    const [registrationSuccess, setRegistrationSuccess] = useState(false)
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState(false)
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)
    const [userErrorMessage, setUserErrorMessage] = useState('')
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        loginName: '',
        password: '',
        rememberMe: true,
        username: '',
        'fields[noFeeFramer][]': cookies.preferredFramerId || ''
    })
    
    // const user = useSelector( state => state.user.meta )
    const userFormMode = useSelector( state => state.app.userFormMode )
    const project = useSelector( state => state.project.present )
    const dispatch = useDispatch()
    
    const loginMode = userFormMode === 'login'
    const forgotPasswordMode = userFormMode === 'forgotPassword'
    const registerMode = userFormMode === 'register'

    function handleLogIn(credentials) {

        setLoggingIn(true)

        UserApi.logIn( credentials )
            .then( json => {
                if ( json.success ) {
                    if ( json.csrfTokenValue ){
                        window.Craft.csrfToken = json.csrfTokenValue
                    }
                    dispatch( setUserFormMode( false ) )
                    updateUserData()
                }
                if ( json.errors || json.error ) {
                    setUserErrorMessage(json.errors || json.error)
                }
                setLoggingIn(false)
            } )

    }

    function handleForgotPassword(credentials) {
        setResettingPassword(true)
        UserApi.resetPassword(credentials)
            .then( json => {
                if ( json.success ) {
                    setResetPasswordSuccess(true)
                }
                if ( json.errors || json.error ) {
                    setForgotPasswordErrorMessage(json.errors || json.error)
                }
                setResettingPassword(false)
            } )
    }

    function updateUserData() {
        UserApi.getCurrentUser()
            .then( user => {
                if ( ! user.error ) {
                    dispatch( updateUser(user) )
                    dispatch( setFetchUploads(true) )
                    dispatch( setFetchArt(true) )
                }
                else {
                    console.log(user.error.code + ' | ' + user.error.message)
                    console.trace()
                }
            } )
    }

    function register(data) {

        console.log(data)

        setRegistering(true)

        UserApi.saveUser( data )
            .then( json => {
                if ( json.success ) {
                    setRegistrationSuccess(true)
                    dispatch( setUserFormMode('login') )
                }
                if ( json.errors || json.error ) {
                    setUserErrorMessage(json.errors || json.error)
                }
                setRegistering(false)
            } )

    }

    function userFormSubmit() {
        if ( loginMode ) {
            handleLogIn(formData)
        }
        if ( forgotPasswordMode ) {
            handleForgotPassword(formData)
        }
        if ( registerMode ) {
            register(formData)
            // if ( registrationSuccess ) {
            //     setRegistrationSuccess(false)
            //     dispatch( setUserFormMode(false) )
            // } else {
            //     register(formData)
            // }
        }
    }

    function changeInputValue({type, name, checked, value}) {
        let newFormData = { ...formData }
        if ( type === 'checkbox' ) {
            newFormData[name] = checked
         } else {
            newFormData[name] = value
        }
        setFormData(newFormData)
    }

    return (

        <Transition visible={ typeof userFormMode === 'string' } animation="fade down" duration={500} >

            <Modal open={ typeof userFormMode === 'string' } size="tiny" onClose={ () => { dispatch( setUserFormMode(false) ) } }>
                <Modal.Header>
                    { loginMode && 'Log in to your account' }
                    { forgotPasswordMode && 'Reset your password' }
                    { registerMode && 'Create an account' }
                </Modal.Header>
                <Modal.Content>
                    { loginMode &&
                        <LoginForm
                            data={formData}
                            error={userErrorMessage}
                            onChange={changeInputValue}
                            onSubmit={userFormSubmit}
                            notice={ registrationSuccess && (
                                <Message size="large" color="green">
                                    <strong>Thanks for signing up! Almost done...</strong>
                                    <br/>Please activate your account by using the link in the email we've sent you.
                                    { project.dateCreated && 
                                        ' You\'ll be able to get back to your project even if you navigate away from this page.'
                                    }
                                </Message>
                            ) }
                            onForgotPassword={ () => dispatch( setUserFormMode('forgotPassword') ) }
                            onRegister={ () => dispatch( setUserFormMode('register') ) } />
                    }
                    { forgotPasswordMode &&
                        <ForgotPasswordForm
                            data={formData}
                            error={forgotPasswordErrorMessage}
                            resetPasswordSuccess={resetPasswordSuccess}
                            onChange={changeInputValue}
                            onSubmit={userFormSubmit}
                            />
                    }
                    { registerMode &&
                        <RegisterForm
                            data={formData}
                            error={userErrorMessage}
                            success={registrationSuccess}
                            onChange={changeInputValue}
                            onSubmit={userFormSubmit}
                            onLogin={ () => dispatch( setUserFormMode('login') ) } />
                    }
                </Modal.Content>
                <Modal.Actions className="text_center">
                    { loginMode && <>
                        <Button basic primary
                            icon="asterisk"
                            content="Sign up"
                            onClick={ () => dispatch( setUserFormMode('register') ) } 
                            />
                        <Button primary 
                            loading={loggingIn}
                            icon="user circle"
                            content="Log in"
                            style={{margin: 0}}
                            onClick={userFormSubmit} 
                            />
                    </>}
                    { forgotPasswordMode && <>
                        <Button basic primary
                            icon="arrow left"
                            content="Back to login"
                            onClick={ () => dispatch( setUserFormMode('login') ) }
                            />
                        <Button primary 
                            loading={resettingPassword}
                            content="Submit"
                            style={{margin: 0}}
                            onClick={userFormSubmit} 
                            />
                    </>}
                    { registerMode && <>
                        <Button basic primary
                            onClick={ () => dispatch( setUserFormMode('login') ) } 
                            >
                            <Icon name="user circle"/> Log in
                        </Button>
                        <Button primary 
                            loading={registering} 
                            style={{margin: 0}} 
                            onClick={ userFormSubmit } 
                            >
                            <Icon name="asterisk"/> Create my account
                        </Button>
                    </>}
                </Modal.Actions>
            </Modal>

        </Transition>

    )
}

export default LoginRegister