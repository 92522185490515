import initialState from './_initialState';
import {deepCopyObject} from '../helpers'


/** -------------------------------------------
 * Action Creators
 ---------------------------------------------- */
export function setUserFormMode( mode ) {
    return { type: 'SET_USER_FORM_MODE', mode }
}

export function setEditImageMode( mode ) {
    return { type: 'SET_EDIT_IMAGE_MODE', mode }
}

export function setSetupMode( setupMode ) {
    return { type: 'SET_SETUP_MODE', setupMode }
}

export function setLockedMode( isLocked ) {
    return { type: 'SET_LOCKED_MODE', isLocked }
}

export function setMediaModal( open ) {
    return { type: 'SET_MEDIA_MODAL', open }
}

export function setSaveModal( open ) {
    return { type: 'SET_SAVE_MODAL', open }
}

export function setUserEntriesModal( open ) {
    return { type: 'SET_USER_ENTRIES_MODAL', open }
}

export function setPrintSheetModal( open ) {
    return { type: 'SET_PRINT_SHEET_MODAL', open }
}

export function toggleConfirmCartMode( confirmCartMode ) {
    return { type: 'TOGGLE_CONFIRM_CART_MODE', confirmCartMode }
}

export function setGrid( showGrid ) {
    return { type: 'SET_GRID', showGrid }
}
export function setRulers( showRulers ) {
    return { type: 'SET_RULERS', showRulers }
}
export function setFrame( showFrame ) {
    return { type: 'SET_FRAME', showFrame }
}

export function setSelectedLibraryImage( image ) {
    return { type: 'SET_SELECTED_LIBRARY_IMAGE', image }
}

export function setSelectedImages( images ) {
    return { type: 'SET_SELECTED_IMAGES', images }
}

export function addSelectedImage( image ) {
    return { type: 'ADD_SELECTED_IMAGE', image }
}

export function removeSelectedImage( imageId ) {
    return { type: 'REMOVE_SELECTED_IMAGE', imageId }
}

export function setFrameShop( frameShop ) {
    return { type: 'SET_FRAME_SHOP', frameShop }
}

export function addSnack(snack) {
    if ( ! snack.id ) {
        console.error(`Snack not added: Missing an 'id' property -->`, snack)
        return
    }
    return { type: 'ADD_SNACK', snack }
}

export function dismissSnack(id) {
    id = id instanceof Object ? id.id : id
    return { type: 'DISMISS_SNACK', id }
}

export function clearSnacks() {
    return { type: 'CLEAR_SNACKS' }
}

export function addFontFamily(family) {
    return { type: 'ADD_FONT_FAMILY', family }
}

export function addFontPreviews(previews) {
    return { type: 'ADD_FONT_PREVIEWS', previews }
}

export function setRequireShipping(require) {
    return { type: 'SET_REQUIRE_SHIPPING', require }
}

export function setFindFramerModal(visible) {
    return { type: 'SET_FIND_FRAMER_MODAL', visible }
}


/** -------------------------------------------
 * Reducers
 ---------------------------------------------- */
 export default function appReducer( state = initialState.app, action ) {
     
     // You MUST return a NEW state object for changes to be recognized
     let newState = deepCopyObject( state )
     
     switch(action.type) {
         
         case 'ADD_SNACK':
            const snackIndex = newState.snacks.findIndex( sk => sk.id === action.snack.id )
            console.log('snackIndex', snackIndex);
            if ( snackIndex < 0 ) {
                // add snack
                newState.snacks = [...state.snacks, action.snack]
            } else {
                // update snack
                let newSnacks = [...state.snacks]
                newSnacks.splice(snackIndex, 1, action.snack)
                console.log(newSnacks)
                newState.snacks = newSnacks
            }
            break

         case 'DISMISS_SNACK':
            newState.snacks = state.snacks.filter( snack => snack.id !== action.id )
            break

        case 'CLEAR_SNACKS':
            newState.snacks = []
            break

        case 'ADD_FONT_FAMILY':
            newState.fontFamilies = [...newState.fontFamilies, action.family]
            break;

        case 'ADD_FONT_PREVIEWS':
            newState.fontPreviews = [...newState.fontPreviews, ...action.previews]
            break;    

        case 'SET_USER_FORM_MODE':
            newState.userFormMode = action.mode
            break

        case 'SET_EDIT_IMAGE_MODE':
            newState.editImageMode = action.mode
            break

        case 'SET_SETUP_MODE':
            newState.setupMode = action.setupMode
            break

        case 'SET_LOCKED_MODE':
            newState.lockedMode = action.isLocked
            break

        case 'SET_MEDIA_MODAL':
            newState.mediaModalOpen = action.open
            break
        
        case 'SET_SAVE_MODAL':
            newState.saveModalOpen = action.open
            break
        
        case 'SET_USER_ENTRIES_MODAL':
            newState.userEntriesModal = action.open
            break
        
        case 'SET_PRINT_SHEET_MODAL':
            newState.printSheetModal = action.open
            break

        case 'TOGGLE_CONFIRM_CART_MODE':
            newState.confirmCartMode = action.confirmCartMode
            break

        case 'SET_SELECTED_LIBRARY_IMAGE':
            newState.selectedLibraryImage = action.image
            break
        
        case 'ADD_SELECTED_IMAGE':
            newState.selectedImages = [...newState.selectedImages, action.image]
            break
        
        case 'REMOVE_SELECTED_IMAGE':
            newState.selectedImages = newState.selectedImages.filter( img => img.id !== action.imageId )
            break
        
        case 'SET_SELECTED_IMAGES':
            newState.selectedImages = action.images
            break

        case 'SET_GRID':
            newState.showGrid = action.showGrid
            break

        case 'SET_RULERS':
            newState.showRulers = action.showRulers
            break

        case 'SET_FRAME':
            newState.showFrame = action.showFrame
            break
        
        case 'SET_FRAME_SHOP':
            newState.frameShop = action.frameShop
            break
        
        case 'SET_REQUIRE_SHIPPING':
            newState.requireShipping = action.require
            break
        
        case 'SET_FIND_FRAMER_MODAL':
            newState.findFramerModal = action.visible
            break

        default:
            return newState

    }

    return newState;

}
