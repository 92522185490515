import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Button, Icon, Responsive } from 'semantic-ui-react'
import StepMenu from './sidebar/StepMenu'
import Options from './sidebar/Options'
import Framing from './sidebar/Framing'
import Matting from './sidebar/Matting'
import Images from './sidebar/Images'
import AddToCart from './cart/AddToCart'
import { getItemIndexBy, getQueryObject, doScrollRef, getItemBy } from '../helpers'
import { setUserEntriesModal, setPrintSheetModal, setSaveModal } from '../reducks/app'
import ProjectsModal from './files/ProjectsModal'
import PrintSheetModal from './files/PrintSheetModal'
import SaveModal from './files/SaveModal'

class AppSidebar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activeSectionId: 'Images',
            lastActive: '',
        }
        this.sections = [
            {   
                id: "Images", 
                component: Images, buttonText: 'Images', icon: <Icon name="images" fitted size="large"/>, 
                props: { 
                    enableTaskbar: true, 
                    title: "Select your images", 
                    subtitle: "Drag images to place them into your project or select multiple images to place them in bulk.",
                    draggable: true
                } 
            },
            // {   
            //     id: "Framing",
            //     component: Framing, buttonText: 'Frame', icon: "glass",
            //     props: { title: "Select a frame" }
            // },
            {   
                id: "Matting",
                component: Matting, buttonText: 'Matboard', icon: "layers",
                props: { title: "Select matboard" }
            },
            {   
                id: "Options",
                component: Options, buttonText: 'Options', icon: <Icon name="tasks" fitted size="large"/>,
                props: { title: "Options" }
            },
        ]
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.changeSection = this.changeSection.bind(this)
        this.stepMenuRef = React.createRef()
        this.imagesView = null
    }

    componentDidMount() {
        const {activeSectionId, imagesView} = getQueryObject()
        if ( activeSectionId ) {
            this.setState({ activeSectionId })
        }
        if ( imagesView ) {
            this.imagesView = imagesView
        }
    }

    open(sectionId) {
        this.setState({ 
            activeSectionId: sectionId, 
            lastActive: this.state.activeSectionId 
        })
    }
    
    close() {
        this.setState({ 
            activeSectionId: '',
            lastActive: this.state.activeSectionId
        })
    }

    changeSection(sectionId) {
        
        this.open(sectionId)

        // if mobile, scroll section to top
        if ( 
            window.innerWidth < Responsive.onlyComputer.minWidth 
            && this.stepMenuRef.current
        ) {
            console.log(this.stepMenuRef)
            const boundingClientRect = this.stepMenuRef.current.getBoundingClientRect()
            console.log(boundingClientRect)
            doScrollRef(
                window.document.body.parentElement, 
                window.scrollY + boundingClientRect.top, 0
            )
        }
    }

    goTo(sectionIndex) {
        if ( sectionIndex > -1 ) {
            const section = this.sections[sectionIndex]
            this.setState({ 
                activeSectionId: section.id,
                lastActive: this.state.activeSectionId
            })
        }
    }

    render() {
        const { activeSectionId } = this.state;
        const { 
            visible,
            userEntriesModal,
            userTemplates,
            userProjects,
            printSheetModal,
            saveModalOpen,
            project,
            dispatch
        } = this.props;
        const activeIndex = getItemIndexBy('id', activeSectionId, this.sections)
        const activeSection = getItemBy('id', activeSectionId, this.sections)
        const prevSection = this.sections[activeIndex - 1]
        const nextSection = this.sections[activeIndex + 1]
        const isLastSection = activeIndex === this.sections.length - 1

        return (
        <div id="Sidebar" className="Sidebar"
            ref={ ref => this.sidebarRef = ref } 
            >

            { visible && 
                <div className="StepContent" style={{pointerEvents: activeSectionId ? 'all' : 'none'}}>
                    
                    { activeSection && activeSection.component && 
                        <activeSection.component 
                            onClose={ this.close } 
                            {...activeSection.props}
                            tab={this.imagesView} 
                            />
                    }

                    <StepMenu
                        ref={this.stepMenuRef}
                        buttons={this.sections} 
                        active={activeSectionId} 
                        sidebarVisible={visible} 
                        onToggle={this.changeSection}
                        />

                    <Responsive minWidth={Responsive.onlyComputer.minWidth}
                        className="Sidebar__navigation">
                        { activeIndex !== 0 && 
                            <Button basic inverted
                                className="flex_grow_0 flex_shrink_1 mr_half"
                                icon="arrow left"
                                content={prevSection.buttonText}
                                style={{whiteSpace:'nowrap'}}
                                onClick={ () => this.goTo(activeIndex - 1) }
                                />
                        }
                        { nextSection &&
                            <Button primary
                                className="flex_grow_1 flex_shrink_0 mr_0"
                                onClick={ () => this.goTo(activeIndex + 1) }
                                >
                                {nextSection.buttonText} <Icon name="arrow right" />
                            </Button>
                        }
                        { isLastSection && <>
                            <AddToCart fluid positive className="mr_0"/>
                        </>}
                    </Responsive>

                </div>
            }

            <ProjectsModal
                open={userEntriesModal}
                userTemplates={userTemplates}
                userProjects={userProjects}
                project={project}
                onClose={ () => dispatch( setUserEntriesModal(false) ) }
                dispatch={dispatch}
                />

            <PrintSheetModal
                open={printSheetModal}
                project={project}
                onClose={ () => dispatch( setPrintSheetModal(false) ) }
                />

            <SaveModal
                open={saveModalOpen}
                onClose={ () => dispatch( setSaveModal(false) ) }
                />

        </div>)
    }
}

function mapStateToProps(state) {
    return {
        project: state.project.present,
        userProjects: state.user.projects,
        userTemplates: state.user.projectTemplates,
        userEntriesModal: state.app.userEntriesModal,
        printSheetModal: state.app.printSheetModal,
        saveModalOpen: state.app.saveModalOpen,
        userUploads: state.user.uploads
    }
}

export default connect(mapStateToProps)(AppSidebar)
