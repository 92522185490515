import React from 'react'
import {PPI} from '../../data/constants'
import Fraction from '../core/Fraction'

const Rulers = props => {

    const { zoom, artboardwidth, artboardheight, offset } = props
    const widthInches = artboardwidth / PPI
    const heightInches = artboardheight / PPI
    const zoomAdjustedWidth = artboardwidth * zoom
    const zoomAdjustedHeight = artboardheight * zoom
    const rulersStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: Math.floor(zoomAdjustedWidth / -2),
        marginTop: Math.floor(zoomAdjustedHeight / -2),
        width: zoomAdjustedWidth,
        height: zoomAdjustedHeight,
        pointerEvents: "none",
    }
    const horzRule = {
        position:"absolute",
        top: -offset * zoom,
        left: 0,
        overflow: 'visible'
    }
    const vertRule = {
        position:"absolute",
        right: -offset * zoom,
        top: 0,
        overflow: 'visible'
    }
    const rulerFill = "#999"

    return (

        <div id="Rulers" style={rulersStyle}>

            <svg width={zoomAdjustedWidth} height={1} style={horzRule}>
                <rect x="0" y="-5" width="1" height="11" fill={rulerFill} />
                <rect x="0" y="0" width="100%" height="100%" fill={rulerFill}/>
                <rect x="100%" y="-5" width="1" height="11" fill={rulerFill} />
                <text x="50%" y="-10" fontSize="20" fill="#212b36" textAnchor="middle">
                    <Fraction float={ widthInches } /> in.
                </text>
            </svg>

            <svg height={zoomAdjustedHeight} width={1} style={vertRule}>
                <rect x="-5" y="0" width="11" height="1" fill={rulerFill} />
                <rect x="0" y="0" width="100%" height="100%" fill={rulerFill}/>
                <rect x="-5" y="100%" width="11" height="1" fill={rulerFill} />
                <text x="12" y="50%" fontSize="20" fill="#212b36">
                    <Fraction float={ heightInches } /> in.
                </text>
            </svg>

        </div>

    )

}

export default Rulers
