import React, { useEffect, useState } from 'react'
import CloudinaryUploader from '../core/CloudinaryUploader'
import { Message, Button, Header } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUserMedia, setFetchUploads } from '../../reducks/user'
import MediaList2 from './MediaList2'
import { addSelectedImage, setSelectedImages, removeSelectedImage, setUserFormMode } from '../../reducks/app'
import {blue} from '../core/colors'
import WCIcon from '../core/Icon'
// import CloudinaryApi from '../../api/cloudinaryApi'
// import { normalizeImageData } from '../core/utilities'

const UserUploads = props => {

	const { className, draggable, onLoadingStart, onLoadingEnd } = props

	const [uploadsError, setUploadsError] = useState(false)
	
	const { meta: user, uploads: userUploads, fetchUploads } = useSelector( state => state.user )
	const selectedImages = useSelector( state => state.app.selectedImages )
	const dispatch = useDispatch()

	const selectedImageIds = selectedImages.map( upload => upload.id )

	// on user change
	useEffect(() => {
		
		if ( fetchUploads ) {

			// const anonymousUploads = window.localStorage.getItem('wallcoreAnonymousUploads')
			// if ( user && anonymousUploads ) {
				
			// 	let anonymousUploadsJSON = null
			// 	try {
			// 		anonymousUploadsJSON = JSON.parse(anonymousUploads)
			// 	} catch(e) {
			// 		console.error(e)
			// 		getUserUploads()
			// 	}
	
			// 	if ( typeof onLoadingStart === 'function' ) {
			// 		onLoadingStart('Syncing previous uploads')
			// 	}
			// 	syncUploadsToUser(user.id, anonymousUploadsJSON.resources)
			// 		.then( () => {
			// 			getUserUploads()
			// 		} )
			// }
			// else {
				getUserUploads()
			// }

		}

	}, [fetchUploads])

	// async function syncUploadsToUser(userId, uploadsToSync) {

	// 	const requests = []

	// 	if ( uploadsToSync instanceof Object && uploadsToSync.forEach ) {
	// 		uploadsToSync.forEach( upload => {
	// 			let newUploadPath = upload.id.split('/')
	// 			newUploadPath[1] = `user_${userId}`
	// 			requests.push( CloudinaryApi.rename({
	// 				fromPublicId: upload.id,
	// 				toPublicId: newUploadPath.join('/'),
	// 			}) )
	// 		} )
	// 	}

	// 	return Promise.all(requests)
	// 		.then( responses => {
	// 			const syncedImages = []
	// 			responses.forEach( res => {
	// 				if ( res.data.error ) {
	// 					console.error(res.data.error)
	// 					return
	// 				}
	// 				syncedImages.push( normalizeImageData( res.data, 'cloudinary' ) )
	// 			} )
	// 			window.localStorage.removeItem('wallcoreAnonymousUploads')
	// 		} )
	// }

	function getUserUploads() {
		setUploadsError(false)
		if ( typeof onLoadingStart === 'function' ) onLoadingStart('Loading your images')
		
        dispatch( loadUserMedia( user, error => {
			if ( error ) {
				setUploadsError(error)
			}
			// loading end callback
			if ( typeof onLoadingEnd === 'function' ) onLoadingEnd()
		} ) )
		
		dispatch( setFetchUploads(false) )
	}

	function handleImageSelect(image) {
		if ( selectedImageIds.indexOf(image.id) < 0 ) {
			dispatch(addSelectedImage(image))
		} else {
			dispatch(removeSelectedImage(image.id))
		}
	}
	
	return (
		<div className={`UserUploads transition_all ${className}`}
			style={{ height: userUploads.length === 0 ? 'calc(100% - 60px)' : 'auto' }}
			>

			<div className="display_flex py_1 px_4 width_100 height_100 justify_content_center align_items_center flex_column"
				style={{ minHeight: 150 }}>
				<WCIcon name="pictures" size={60} fill={blue} />
				
				{ ! user && 
					<Header color="blue" textAlign="center" size="tiny">
						Please log in or create an account to save your uploads. 
					</Header>
				}
				<div>
					{ ! user && <>
						<Button primary fluid
							content="Create a Free account"
							className="mt_nudge"
							onClick={ () => dispatch( setUserFormMode('register') ) }
							/>
						<Button primary basic
							content="Login"
							className="mt_nudge"
							onClick={ () => dispatch( setUserFormMode('login') ) }
							/>
					</> }
					<CloudinaryUploader
						primary={!!user} basic={!user}
						allowMultiple
						onUploadComplete={uploads => dispatch(setSelectedImages(uploads))}
						content={ user ? "Click to upload" : "Upload now"}
						className="mt_nudge mr_0"
						disabled={ false }
						/>
				</div>
				{/* <small className="faded" style={{lineHeight:2}}><strong>JPG</strong> or <strong>PNG</strong>. Max <strong>10MB</strong></small> */}
			</div>

			{ uploadsError &&
				<Message negative onDismiss={() => setUploadsError(false)}>
					<Message.Header>Error</Message.Header>
					<Message.Content>
						{uploadsError}
						<Button fluid basic negative content="Try again" size="mini" icon="sync" 
							onClick={() => getUserUploads()} 
							/>
					</Message.Content>
				</Message>
			}

			{ userUploads.length > 0 && 
				<MediaList2
					media={userUploads}
					draggable={draggable}
					selectedImageIds={selectedImageIds}
					onSelectImage={ handleImageSelect }
					/>
			}

		</div>
	)
}

export default UserUploads