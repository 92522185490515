import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Portal } from 'semantic-ui-react';

const DragPreview = ({image, visible}) => {
    const [hidden, setHidden] = useState(true);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const className = classNames('position_fixed top_0 left_0 z_index_1000 pointer_events_none', {
        'opacity_0': hidden
    })

    useEffect(() => {
        const setFromEvent = e => {
            setPosition({ x: e.clientX, y: e.clientY })
            setHidden(e.clientX === 0 && e.clientY === 0)
        };
        window.addEventListener("mousemove", setFromEvent);
        return () => {
            window.removeEventListener("mousemove", setFromEvent);
        };
    }, []);

    return (
		<Portal open={visible}>
            <div className={className} style={{
                    transform: `translate3d( ${position.x}px, ${position.y}px, 0 )`
                }}>
                <img src={image.thumbnail || image.src} alt="drag preview" 
                    className="display_block box_shadow_strong width_100 height_auto" 
                    style={{
                        maxWidth: 150,
                        maxHeight: 150,
                        transform: `translate3d(-10px,-10px,0)`
                    }}
                    />
            </div>
		</Portal>
    )
}

export default DragPreview