import React, { useEffect } from 'react'
import {Image, Button, Segment, Icon, Header} from 'semantic-ui-react'

const Snack = props => {

    const { 
		description,
        color, 
        dismissAfter, 
        icon, 
        image, 
        loading, 
        message, 
        onDismiss, 
	} = props
	
	const canDismiss = props.canDismiss === undefined ? true : props.canDismiss

    useEffect(() => {
        let timeout
        if ( dismissAfter ) {
            timeout = setTimeout(onDismiss, dismissAfter)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    
	return (
		<Segment className="display_flex align_items_center" color={color || 'grey'}>
			{ (icon || loading) && 
				<div className="flex_grow_0 mr_half">
					<Icon fitted color={color || 'grey'} name={ loading ? 'cog' : icon } loading={loading} />					
				</div>
			}
			<div className="flex_grow_1">
				{ message && 
					<Header as="p" size="tiny" className="ma_0">
						<strong>{ message }</strong>
					</Header>
				}
				{ description && 
					<p className="mt_half" style={{lineHeight:1.2}}>{description}</p>
				}
			</div>
			{ image && 
				<Image src={image} size="tiny" className="flex_shrink_0 ml_half" />
			}
			{ ! dismissAfter && canDismiss &&
				<div className="flex_grow_0 flex_shrink_1 ml_half">
					<Button basic size="mini" content="OK" onClick={ onDismiss } />
				</div>
			}
		</Segment>
	)
}

export default Snack
