import React, { useRef, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useDrop} from 'react-dnd'
import { addElements, updateElementProps } from '../../reducks/project'
import { setActiveElement, setActiveDrawTool, setDrawToolActiveOn } from '../../reducks/tools'
import Element from './Element'
import Text from './Text'
import Graphic from './Graphic'
import VGroove from './VGroove'
import Deboss from './Deboss'
import DrawArea from './DrawArea'
import OpeningImage from './OpeningImage'
// import ElementToolset from './ElementToolset'
import OpeningToolset from './OpeningToolset'
import ImagePositioner from '../core/ImagePositioner'
import { createText, createDeepOpenings, getBackgroundColor, getCoreColor, getShape } from './element-helpers'
import {
    PPI, // LOW_RES_PRINT_PPI,
} from '../../data/constants'
import {
    layerHighlightColors, getCenteredImageCrop, // hasSufficientImageSize, 
} from '../core/utilities'
// import { setMediaModal, setEditImageMode, addSnack } from '../../reducks/app'
// import {getItemBy, nearestDivisor} from '../../helpers'
// import {getFraction} from '../core/Fraction'
import {blue} from '../core/colors'

const Opening = props => {
    
    // given props
    const {
        backgroundColor,
        component,
        depth,
        descendants,
        editable,
        height: openingHeight,
        id,
        image: openingImage,
        parent,
        shape,
        width: openingWidth,
        x, y,
        zoom,
    } = props

    // local state
    const [hovered, setHovered] = useState(false)

    // redux state
    const projectMats = useSelector( state => state.project.present.projectMats )
    const activeElement = useSelector( state => state.tools.activeElement )
    const drawToolActiveOn = useSelector( state => state.tools.drawToolActiveOn )
    const activeDrawTool = useSelector( state => state.tools.activeDrawTool )
    // const defaultReveal = useSelector( state => state.project.present.defaultReveal )
    const lockedMode = useSelector( state => state.app.lockedMode )
    const editImageMode = useSelector( state => state.app.editImageMode )
    const projectElements = useSelector( state => state.project.present.elements )
    const dispatch = useDispatch()

    // derived vars
    const activeElementObject = projectElements.find( el => el.id === activeElement )
    const parentObject = projectElements.find( el => el.id === parent )
    const parentWidth = parentObject ? parentObject.width : 0
    const parentHeight = parentObject ? parentObject.height : 0
    const drawMode = drawToolActiveOn === id
    const isActive = activeElement === id
    const isBottom = depth === projectMats.length
    const isSource = id === 'source-opening'
    const openingFill = isBottom && backgroundColor ? backgroundColor : getBackgroundColor( projectMats, depth )
    const coreColor = getCoreColor( projectMats, depth )
    const shapeEl = getShape( openingWidth, openingHeight, id, shape )

    // element refs
    const toolsetAnchor = useRef(null) 

    // drag-n-drop target
    const [{isDraggedOver}, dropRef] = useDrop({
        accept: ['IMAGE'],
        drop: (item, monitor) => {
            dispatch( updateElementProps( id, {
                image: {
                    ...item.image,
                    crop: getCenteredImageCrop(item.image, openingWidth, openingHeight)
                }
            } ) )
            if ( item.opening && openingImage ) {
                dispatch( updateElementProps( item.opening.id, {
                    image: {
                        ...openingImage,
                        crop: getCenteredImageCrop(openingImage, item.opening.width, item.opening.height)
                    }
                } ) )
            }

            // This is a DIRTY HACK to make sure the drag source completes its drag operation
            // without it, the drag source will sometimes remain in a "dragging" state
            // moral of the story: SVG is a pain in the ass to deal with mouse events.
            const click = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            window.dispatchEvent(click)
            // end dirty hack... :(

        },
        canDrop: (item, monitor) => {
            if ( item.opening ) {
                return item.opening.id !== id && !isSource && isBottom;
            }
            else {
                return true;
            }
        },
        collect: monitor => {
            return {
                isDraggedOver: monitor.isOver() && monitor.canDrop()
            }
        }
    })

    // componentDidUpdate(prevProps) {
    //     const { image, width, height, dispatch } = this.props
    //     if ( 
    //         // if a new image was added
    //         ( ! prevProps.image && image instanceof Object )
    //         // or image was changed
    //         || ( prevProps.image instanceof Object && image instanceof Object && prevProps.image.id !== image.id )
    //     ) {
    //         // check image source and if it can be printed in this opening
    //         if ( ( ! image.source || image.source === 'upload' ) && ! hasSufficientImageSize(image, { width: width/PPI, height: height/PPI }) ) {
    //             dispatch( addSnack({
    //                 id: 'image-error',
    //                 message: "Image is too small for this opening!",
    //                 description: `
    //                     For best image quality, this image should not be printed beyond a size of 
    //                     ${getFraction(nearestDivisor(image.width/LOW_RES_PRINT_PPI, 0.125))} x ${getFraction(nearestDivisor(image.height/LOW_RES_PRINT_PPI, 0.125))} inches. 
    //                     Use a higher resolution image for best results.
    //                 `,
    //                 color: "orange",
    //                 icon: "exclamation triangle",
    //                 image: image.thumbnail || image.src
    //             }) )
    //         }
    //     }
    // }

    const handleSelectArea = data => {
        const { x, y, width, height } = data
        let newElements = []

        switch(activeDrawTool) {

            case 'text':
                newElements.push( createText( 'Your text here', { x, y, width, height, depth, parent:id } ) )
                break

            case 'rect':
            case 'oval':
                const
                    shape = activeDrawTool,
                    layersToCut = projectMats.length - depth,
                    openingWidth = Math.max(width, PPI),
                    openingHeight = Math.max(height, PPI)

                const outermostOpening = { x, y, width: openingWidth, height: openingHeight, depth, parent: id }

                newElements = createDeepOpenings(shape, layersToCut, outermostOpening);
                break

            default:
                return

        }

        // add new elements
        dispatch( addElements( newElements ) )

        // set active element
        dispatch( setActiveElement( newElements[newElements.length - 1].id ) )

        // reset draw tools
        dispatch( setActiveDrawTool(null) )
        dispatch( setDrawToolActiveOn(null) )

    }

    const onMouseOver = event => {
        setHovered(true)
    }
    
    const onMouseOut = event => {
        setHovered(false)
    }

    const onResizeEnd = ({width, height, x, y}) => {
        if ( openingImage ) {
            // reset image position after opening resize
            dispatch( updateElementProps(id, { 
                image: { 
                    ...openingImage, 
                    crop: getCenteredImageCrop(openingImage, width, height)
                } 
            }) )
        }
    }

    return ( 
        <Element
            boundaries={null}
            component={component}
            depth={depth}
            canSelect={ ! lockedMode || (lockedMode && isBottom)}
            editable={editable}
            grid={null}
            height={openingHeight}
            id={id}
            isActive={isActive}
            isDraggedOver={isDraggedOver}
            lockedMode={lockedMode}
            onDeselect={ () => setHovered(false) }
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onResizeEnd={onResizeEnd}
            parentHeight={parentHeight}
            parentWidth={parentWidth}
            projectMats={projectMats}
            tools={ lockedMode && isBottom ?
                <OpeningToolset setImage="expanded" setBackground />
                : <OpeningToolset setImage setText setGraphic setOpening setBackground setPosition />
            }
            width={openingWidth}
            x={x} y={y} 
            zoom={zoom}
            >
            <defs>
                {shapeEl.path}
                <clipPath id={`${id}-clip`}>
                    {shapeEl.path}
                </clipPath>
                <pattern id={`${id}-active-pattern`} patternUnits="userSpaceOnUse" width={PPI/8} height={PPI/8}>
                    <rect className="show_in_preview" x="0" y="0" width="100%" height="100%" fill={openingFill} />
                    <circle className="hide_in_preview" cx="0" cy="0" r="2" fill={layerHighlightColors[depth]} strokeWidth="2" stroke="rgba(255,255,255,0.5)" opacity="0.6" />
                    <circle className="hide_in_preview" cx={PPI/8} cy="0" r="2" fill={layerHighlightColors[depth]} strokeWidth="2" stroke="rgba(255,255,255,0.5)" opacity="0.6" />
                    <circle className="hide_in_preview" cx={PPI/8} cy={PPI/8} r="2" fill={layerHighlightColors[depth]} strokeWidth="2" stroke="rgba(255,255,255,0.5)" opacity="0.6" />
                    <circle className="hide_in_preview" cx="0" cy={PPI/8} r="2" fill={layerHighlightColors[depth]} strokeWidth="2" stroke="rgba(255,255,255,0.5)" opacity="0.6" />
                </pattern>
                { ! isSource &&
                    <filter id={`${id}-blur`}>
                        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
                    </filter>
                }
            </defs>
            <g clipPath={`url(#${id}-clip)`}>

                { isBottom ?
                    <g className="show_in_preview">
                        <rect x="0" y="0" width={openingWidth} height={openingHeight} fill={ openingFill } />
                        { ! backgroundColor && <>
                            <line x1="20" x2={openingWidth - 20} y1="20" y2={openingHeight - 20} stroke={ isActive ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"} strokeWidth="6" strokeLinecap="round" strokeDasharray="0 12"/>
                            <line x1={openingWidth - 20} x2="20" y1="20" y2={openingHeight - 20} stroke={ isActive ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"} strokeWidth="6" strokeLinecap="round" strokeDasharray="0 12"/>
                        </>}
                    </g>
                    :
                    <rect
                        x="0" y="0"
                        width={openingWidth} height={openingHeight}
                        fill={ isActive ? `url(#${id}-active-pattern)` : openingFill }
                    />
                }

                { isBottom && 
                    <foreignObject x={0} y={0} width={openingWidth} height={openingHeight}>
                        { editable && <div ref={ toolsetAnchor }></div> }
                        <div ref={dropRef} style={{ width: openingWidth, height: openingHeight }} />
                    </foreignObject>
                }

                { openingImage &&
                    <OpeningImage
                        image={openingImage}
                        opening={{ id, width: openingWidth, height: openingHeight }}
                        isDraggedOver={isDraggedOver}
                        />
                }

                { ! isSource &&
                    <use className="opening-shadow hide_in_preview"
                        href={`#${id}-path`}
                        x="2" y="2"
                        fill="none"
                        stroke="black"
                        strokeWidth={5 / shapeEl.scale}
                        opacity="0.2"
                        filter={`url(#${id}-blur)`}
                        pointerEvents="none"
                        />
                }

                { descendants && descendants.map( (el) => {
                    let component = ''
                    switch(el.component) {
                        case 'Opening':
                            component = <Opening key={ el.id } {...el} zoom={zoom} />
                            break
                        case 'Text':
                            component = <Text key={ el.id } {...el} zoom={zoom} />
                            break
                        case 'Graphic':
                            component = <Graphic key={ el.id } {...el} zoom={zoom} />
                            break
                        case 'VGroove':
                            component = <VGroove key={ el.id } {...el} zoom={zoom} />
                            break
                        case 'Deboss':
                            component = <Deboss key={ el.id } {...el} zoom={zoom} />
                            break
                        default:
                    }
                    return component
                } ) }

                { drawMode &&
                    <foreignObject x={0} y={0} width={openingWidth} height={openingHeight}>
                        <DrawArea onSelectArea={ handleSelectArea } />
                    </foreignObject>
                }

            </g>

            { ! isSource && <>
                <use className="core-stroke-outer"
                    href={`#${id}-path`}
                    fill="none"
                    pointerEvents="none"
                    stroke="#00000022"
                    strokeWidth={6 / shapeEl.scale}
                    strokeDasharray="none"
                    />
                <use className="core-stroke-inner"
                    href={`#${id}-path`}
                    fill="none"
                    pointerEvents="none"
                    stroke={coreColor}
                    strokeWidth={4 / shapeEl.scale}
                    strokeDasharray="none"
                    />
                { (
                    ( lockedMode && ( hovered || isDraggedOver || isActive ) )
                    || ( !lockedMode && !isActive && ( hovered || isDraggedOver ) )
                ) &&
                    <use className="opening-selection-outline"
                        href={`#${id}-path`}
                        fill="none"
                        pointerEvents="none"
                        stroke={blue}
                        strokeWidth={6 / shapeEl.scale}
                        strokeDasharray={ 
                            hovered && ! isActive && ! isDraggedOver ? 
                                `${12/shapeEl.scale}` : `none`
                        }
                        />
                }
            </>}

            {/* { editable && isBottom && lockedMode &&
                <ElementToolset
                    open={ ! isDraggedOver && hovered && ! isActive }
                    context={toolsetAnchor}
                    tools={ <p>
                        { openingImage ?
                            `Click to edit or drag to a new opening`
                            : `Add an image by dragging it here`
                        }
                    </p> }
                    offset={{top: -15 }}
                    />
            } */}

            { openingImage && editImageMode && isActive &&
                <ImagePositioner
                    image={{
                        ...openingImage, 
                        width: openingImage.crop.width, 
                        height: openingImage.crop.height
                    }}
                    opening={activeElementObject}
                    />
            }

        </Element>
    )
}

export default Opening
