import React from 'react'
import { Image, Icon } from 'semantic-ui-react'
import classNames from 'classnames'
import { useDrag } from 'react-dnd'
import DragPreview from '../core/DragPreview'

const LibraryImage = props => {

    const {image, selected, className, onSelect, draggable, style} = props

    const [{isDragging}, dragRef] = useDrag({
        item: { 
            type: 'IMAGE', 
            image: props.image
        },
        canDrag: draggable || false,
        collect: (monitor, props) => {
            return {
                isDragging: monitor.isDragging()
            }
        }
    })

    const classes = classNames( 'LibraryImage', className, {
        'box_shadow_strong' : selected,
        'box_shadow' : ! selected,
        'draggable' : draggable,
        'opacity_5' : isDragging,
        'selected' : selected, 
    })

    return (
        <div ref={dragRef}
            className={classes}
            role="button"
            style={style}
            onMouseDown={ event => event.preventDefault() }
            onClick={ () => onSelect(image) }
            >
            <DragPreview visible={isDragging} image={image} />
            <Image fluid src={image.thumbnail || image.src} />
            <div className="LibraryImage__checkbox">
                { selected && 
                    <Icon fitted name="check" className="fade_up" />
                }
            </div>
        </div>
    )

}

export default LibraryImage