import React from 'react'
import { Icon } from 'semantic-ui-react'

const MatSwatch = ({ mat, isCurrent, onClick }) => {
    return (
		<button className="swatch mr_nudge mb_nudge"
			style={{ backgroundColor: mat.hex }}
			onClick={ onClick }
			title={mat.title}
			>
			{ isCurrent &&
				<Icon circular fitted name="check" className="fade_up duration_xshort" style={{backgroundColor:'white'}} />
			}
			<span className="display_none">{mat.title}</span>
		</button>
	)
}

export default MatSwatch;