import React, { useEffect } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import {setActiveElement} from '../reducks/tools'
import Canvas from './workspace/Canvas'
// import Controls from './workspace/Controls'
import Controls from './workspace/Controls'
import ProductPreviews from './core/ProductPreviews'
import { debounce } from '../helpers'

const Workspace = (props, ref) => {

    const { resetViewMethod, onZoom, zoom } = props

    const artboardwidth  = useSelector( state => state.project.present.artboardwidth )
    const artboardheight = useSelector( state => state.project.present.artboardheight )
    const activeFrame    = useSelector( state => state.project.present.activeFrame )
    const lockedMode     = useSelector( state => state.app.lockedMode )
    const showGrid       = useSelector( state => state.app.showGrid )
    const showRulers     = useSelector( state => state.app.showRulers )
    const showFrame      = useSelector( state => state.app.showFrame )
    const elements       = useSelector( state => state.project.present.elements )
    const activeElement  = useSelector( state => state.tools.activeElement )
    const dispatch       = useDispatch()
    
    // derived vars
    const activeElementObject = elements.find( elem => elem.id === activeElement )

    const resizeHandler = debounce( event => {
        if ( typeof resetViewMethod === 'function' ) { 
            resetViewMethod() 
        }
    }, 500 )

    useEffect(() => {
        window.addEventListener('resize', resizeHandler)
        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])
    
    useEffect( () => {
        if ( typeof resetViewMethod === 'function' ) 
            { resetViewMethod() }
    }, [artboardwidth, artboardheight, lockedMode])

    const onCanvasKeyDown = (event) => {

        if ( ! activeElementObject || lockedMode )
            {return}

        switch (event.key) {
            case 'Escape':
                dispatch( setActiveElement( null ) )
                break
            default:
                return
        }
    }

    return (
        <div className="Workspace" ref={ref}>

            <div className="position_relative width_100 height_100 overflow_scroll display_flex">
                <Canvas
                    artboardwidth={artboardwidth} 
                    artboardheight={artboardheight}
                    zoom={zoom}
                    showGrid={showGrid}
                    showFrame={showFrame}
                    showRulers={showRulers}
                    activeFrame={activeFrame}
                    onKeyDown={ onCanvasKeyDown }
                    onKeyUp={ null }
                    onBlur={ null }
                    updating={false}
                    lockedMode={lockedMode}
                    />
            </div>

            {/* <div className="position_absolute z_index_1" style={{top: '1rem', left: '1rem'}}>
                <ProductPreviews />
            </div> */}

            <div id="Workspace_top_right" className="position_absolute z_index_1 display_flex flex_column align_items_end" style={{top: '1rem', right: '1.5rem'}}>
                <Controls onZoom={onZoom} />
            </div>

      </div>
    )
}

export default React.forwardRef(Workspace)
