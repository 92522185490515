export default {
    resources: {
        cutArt: [],
        frameProducts: [],
        glass: null,
        graphics: [],
        matProducts: [],
        paper: null,
        fonts: [],
    },
    app: {
        confirmCartMode: false,
        fontFamilies: [],
        fontPreviews: [],
        frameShop: null,
        lockedMode: true,
        loginMode: true,
        mediaModalOpen: false,
        registerMode: false,
        requireShipping: false,
        saveModalOpen: false,
        selectedImages: [],
        selectedLibraryImage: null,
        setupMode: false,
        showFrame: true,
        showGrid: false,
        showRulers: false,
        snacks: [],
        updatingResources: false,
        userEntriesModal: false,
        userFormMode: false,
        zoom: 1,
    },
    cart: {
        lineItems: window.Craft.cart.lineItems,
        totalQty: window.Craft.cart.totalQty
    },
    tools: {
        activeDrawTool: null,
        activeElement: null,
        drawToolActiveOn: null,
        multiSelect: false,
    },
    user: {
        art: [],
        fetchArt: true,
        loadingProjects: false,
        meta: window.Craft.user || null,
        projects: [],
        projectTemplates: [],
        recentMats: [],
        uploads: [],
        fetchUploads: true,
        location: window.localStorage.getItem('wallcoreUserLocation'),
    },
    setup: {
        borders: { top: 3, side: 3, bottom: 3 },
        category: null,
        frameShops: [],
        gutters: 1,
        layout: 'horizontal',
        layoutView: 'custom',
        openings: [],
        selectedTemplate: null,
        templateCategories: window.Craft.defaultTemplateCategories,
        templateMaxPrice: 'unlimited',
        templates: [],
        templatesPagination: null,
    },
    price: {}
}
