import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Header, Icon, Button, Modal, Grid, Label } from 'semantic-ui-react'
import { setEditImageMode } from '../../reducks/app'
import { updateElementProps } from '../../reducks/project'
/*
 * https://github.com/DominicTobias/react-image-crop#props
 */
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"


class ImagePositioner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crop: {
                unit: '%',
                aspect: props.opening.width / props.opening.height
                // width: props.opening.width / props.image.width * 100,
                // height: props.opening.height / props.image.height * 100,
            }
        }
        this.saveImageCrop.bind(this)
    }

    componentDidMount() {
        const { opening, image } = this.props
        const { crop } = this.state

        this.setState({crop: {
            ...crop,
            x: image.crop ? image.crop.x / image.crop.width * -100 : 0,
            y: image.crop ? image.crop.y / image.crop.height * -100 : 0,
            width: image.crop ? opening.width / image.crop.width * 100 : 100,
            height: image.crop ? opening.height / image.crop.height * 100 : 100,
        }})
    }

    onCropChange(crop, percentCrop) {
        this.setState({crop: percentCrop})
    }

    onCropComplete(crop, percentCrop) {
        // console.log(this.state.crop)
    }

    saveImageCrop() {
        const { closeImagePositioner, setImagePosition, opening } = this.props
        const { crop: {x: percentCropX, y: percentCropY, width: percentCropWidth, height: percentCropHeight} } = this.state
        const newWidth = opening.width / ( percentCropWidth / 100 )
        const newHeight = opening.height / ( percentCropHeight / 100 )
        const imageProps = {
            width: newWidth,
            height: newHeight,
            x: newWidth * (percentCropX / -100),
            y: newHeight * (percentCropY / -100),
        }
        setImagePosition(opening, imageProps)
        closeImagePositioner()
    }

    getSelectionAddon(state) {
        return (
            <div
                className="width_100 height_100 position_absolute top_0 left_0 display_flex align_items_center justify_content_center"
                style={{border:'8px solid white', borderTopColor:'#ddd'}}
                >
                <Label size="large" color="black" icon="move" content="Drag me"/>
            </div>
        )
    }

    render() {
        const { opening, image, closeImagePositioner } = this.props
        const { crop } = this.state
        const cropStyle = {display:'block', maxWidth: 450}

        return (
            <Modal open={true} basic>
                <Modal.Content>
                    <Grid stackable columns={2}>
                        <Grid.Column>
                            <Header inverted icon='move' size="small" content='Drag the window over your image.' />
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button basic inverted circular onClick={closeImagePositioner}>
                                <Icon name='remove' /> Cancel
                            </Button>
                            <Button inverted circular onClick={() => this.saveImageCrop() }>
                                <Icon name='checkmark' /> Save
                            </Button>
                        </Grid.Column>
                    </Grid>
                    { image &&
                        <ReactCrop
                            src={image.src}
                            crop={crop}
                            keepSelection={true}
                            locked={false}
                            ruleOfThirds={true}
                            circularCrop={opening.shape === 'oval'}
                            className="mt_1 mx_auto"
                            style={cropStyle}
                            renderSelectionAddon={undefined}
                            onChange={this.onCropChange.bind(this)}
                            onComplete={this.onCropComplete.bind(this)}
                            />
                    }
                </Modal.Content>
            </Modal>
        )
    }
}

ImagePositioner.propTypes = {
    src: PropTypes.string,
    open: PropTypes.any,
    closeImagePositioner: PropTypes.func
}

function mapDispatchToProps(dispatch) {
    return {
        closeImagePositioner: () => dispatch(setEditImageMode(false)),
        setImagePosition: ({id, image}, crop ) => dispatch( updateElementProps( id, { image: {...image, crop} } ) )
    }
}

export default connect(undefined, mapDispatchToProps)(ImagePositioner)
