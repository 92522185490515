import React from 'react'
import {withRouter} from 'react-router-dom'
import { Image, Modal, Menu, Card, Button, Header, Icon } from 'semantic-ui-react'
import { loadUserProjects, loadUserTemplates } from '../../reducks/user'
import DateAndTime from '../core/DateAndTime'
import WCLoader from '../core/Loader'
import { setUserEntriesModal } from '../../reducks/app'

class ProjectsModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeLoadItem: 'projects',
            loadingProjects: false,
            loadingTemplates: false,
            projectsPagination: {
                current_page: 1
            },
            templatesPagination: {
                current_page: 1
            },
        }
        this.onSelectItem = this.onSelectItem.bind(this)
        this.getUserProjects = this.getUserProjects.bind(this)
        this.getUserTemplates = this.getUserTemplates.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
    }

    componentDidUpdate(prevProps) {
        if ( ! prevProps.open && this.props.open && this.props.userProjects.length === 0 ) {
            this.getUserProjects(this.state.projectsPagination.current_page)
        }
    }

    handleChangeTab(tab) {
        const { projectsPagination, templatesPagination } = this.state
        const { userProjects, userTemplates } = this.props
        if ( tab === 'projects' ) {
            this.setState({activeLoadItem: 'projects'})
            if ( userProjects.length === 0 ) {
                this.getUserProjects(projectsPagination.current_page)
            }
        }
        if ( tab === 'templates' ) {
            this.setState({activeLoadItem: 'templates'})
            if ( userTemplates.length === 0 ) {
                this.getUserTemplates(templatesPagination.current_page)
            }
        }
    }

    getUserProjects(page) {
        this.setState({loadingProjects: true})
        this.props.dispatch( loadUserProjects( page, json => {
            this.setState({
                loadingProjects: false, 
                projectsFetched: true,
                projectsPagination: json.meta.pagination
            })
        }) )
    }

    getUserTemplates(page) {
        this.setState({loadingTemplates: true})
        this.props.dispatch( loadUserTemplates( page, json => {
            this.setState({
                loadingTemplates: false, 
                templatesFetched: true,
                templatesPagination: json.meta.pagination
            })
        }) )
    }

    onSelectItem(entryObject) {
        const { dispatch, history } = this.props
        dispatch( setUserEntriesModal( false ) )
        history.push(`/designer/editor/${entryObject.id}`)
    }

    render() {
        const { open, userTemplates, userProjects, project } = this.props
        const { activeLoadItem, loadingProjects, loadingTemplates, projectsPagination, templatesPagination } = this.state

        return (
            <Modal open={ open } size="small" onClose={ this.props.onClose }>
                <Modal.Header>Load a saved design</Modal.Header>
                <Modal.Content scrolling>

                    <Menu pointing secondary>
                        <Menu.Item name='My Projects' active={activeLoadItem === 'projects'} onClick={() => this.handleChangeTab('projects') } />
                        <Menu.Item name='My Templates' active={activeLoadItem === 'templates'} onClick={() => this.handleChangeTab('templates') } />
                    </Menu>

                    { activeLoadItem === 'projects' && loadingProjects &&
                        <div className="width_100 display_flex align_items_center justify_content_center">
                            <WCLoader message="Loading Your Projects" />
                        </div>
                    }

                    { activeLoadItem === 'templates' && loadingTemplates &&
                        <div className="width_100 display_flex align_items_center justify_content_center">
                            <WCLoader message="Loading Your Templates" />
                        </div>
                    }

                    { activeLoadItem === 'projects' && ! loadingProjects && <>
                        { ! userProjects.length &&
                            <Header textAlign="center" size="large" className="my_3 icon">
                                <Icon name="folder open outline" />
                                <Header.Content>You dont have any saved projects yet.</Header.Content>
                                <Header.Subheader>After you save a project, you can find it here.</Header.Subheader>
                            </Header>
                        }
                        { userProjects.length > 0 && 
                            <Card.Group itemsPerRow={2} stackable style={{minHeight: 200}}>
                                { userProjects.map( entry => {
                                    const current = project instanceof Object && entry.id && entry.id === project.id
                                    return (
                                        <ItemCard key={`project-${entry.id}`} current={current}
                                            onSelect={ () => this.onSelectItem(entry) }
                                            type="Project"
                                            title={ entry.title }
                                            dateUpdated={ entry.dateUpdated }
                                            image={entry.thumbnail}
                                            />
                                    )
                                } ) }
                            </Card.Group>
                        }
                    </> }

                    { activeLoadItem === 'templates' && ! loadingTemplates && <>
                        { ! userTemplates.length &&
                            <Header textAlign="center" size="large" className="my_3 icon">
                                <Icon name="folder open outline" />
                                <Header.Content>You dont have any saved templates yet.</Header.Content>
                                <Header.Subheader>After you save a template, you can find it here.</Header.Subheader>
                            </Header>
                        }
                        { userTemplates.length > 0 && 
                            <Card.Group itemsPerRow={2} stackable style={{minHeight: 200}}>
                                { userTemplates.map( entry => {
                                    const current = project instanceof Object && entry.id && entry.id === project.id
                                    return (
                                        <ItemCard key={`template-${entry.id}`} current={current}
                                            onSelect={ () => this.onSelectItem(entry) }
                                            type="Template"
                                            title={ entry.title }
                                            dateUpdated={ entry.dateUpdated }
                                            image={entry.thumbnail}
                                            />
                                    )
                                } ) }
                            </Card.Group>
                        }
                    </> }
                        
                    { activeLoadItem === 'projects' && ! loadingProjects && projectsPagination.current_page < projectsPagination.total_pages &&
                        <p className="my_3 text_center">
                            <Button basic primary content="Load More"
                                onClick={() => this.getUserProjects(projectsPagination.current_page + 1)}
                                />
                        </p>
                    }
                    
                    { activeLoadItem === 'templates' && ! loadingTemplates && templatesPagination.current_page < templatesPagination.total_pages &&
                        <p className="my_3 text_center">
                            <Button basic primary content="Load More"
                                onClick={() => this.getUserTemplates(templatesPagination.current_page + 1)}
                                />
                        </p>
                    }

                </Modal.Content>
            </Modal>
        );
    }
}

export default withRouter(ProjectsModal)

const ItemCard = (props) => (
    <Card fluid onClick={ props.current ? null : props.onSelect }>
        <Card.Content>
            { props.image && 
                <Image src={props.image} floated="left" size="tiny" />
            }
            <div className="ui mini faded sub header mt_0">{props.type}</div>
            <Card.Header>
                {props.title}
                { props.current &&
                    <p className="ui sub header">{' '}(currently editing)</p>
                }
            </Card.Header>
            { props.dateUpdated &&
                <Card.Meta style={{clear:'both'}}>Last save: <DateAndTime value={props.dateUpdated} /></Card.Meta>
            }
        </Card.Content>
    </Card>
)

