import React from 'react'
import {connect} from 'react-redux'
import {Portal, Popup, Button} from 'semantic-ui-react'
import { removeElement, updateElementProps } from '../../reducks/project'
import { setActiveElement } from '../../reducks/tools'

class ElementToolset extends React.Component {

    deselectElement(event, data) {
        this.props.dispatch( setActiveElement(null) )
        if ( typeof this.props.onDeselect === 'function' ) {
            this.props.onDeselect()
        }
    }

    deleteElement(event, data) {
        this.props.dispatch( removeElement(this.props.activeElement) )
        this.props.dispatch( setActiveElement(null) )
    }

    toggleElementLock(event, data) {
        const { activeElement, activeElementObject, dispatch } = this.props
        if ( activeElementObject.editable ) {
            dispatch( updateElementProps(activeElement, {editable: false}) )
        } else {
            dispatch( updateElementProps(activeElement, {editable: true}) )
        }
    }

    render () {

        const { open, context, faded, tools, offset, title, confirm, remove, activeElementObject, projectType } = this.props

        let elementPosition
        if ( context && context.current ) {
            elementPosition = context.current.getBoundingClientRect()
        }

        const deleteButton = (
            <Button basic size="mini" title="Delete Element" content="Delete" icon="trash" className="mr_0 mt_nudge" style={{boxShadow:"none"}}/>
        )

        if ( ! context || ! context.current ) {
            return ''
        }

        return (
            <Portal open={open}>
                <div className={`${ faded ? 'faded' : '' } transition_all pointer left center bg_white pa_half border_rounded box_shadow_strong position_fixed z_index_1000`}
                    style={{
                        left: elementPosition.left + ( offset && offset.left ? offset.left : 0 ),
                        top: elementPosition.top + elementPosition.height/2 - ( offset && offset.top ? offset.top : 0 ),
                        transform: 'translateY(-50%) translateX(-100%)'
                    }}
                    >
                    <div className="ElementToolset fade_left_slight">

                        <div className="display_flex flex_column">

                            { title &&
                                <p className="text_center weight_bold uppercase mb_nudge" style={{ fontSize:14 }} >
                                    {title}
                                </p>
                            }

                            {tools}

                            { activeElementObject && projectType === 'projectTemplates' &&
                                <Button basic
                                    negative={ ! activeElementObject.editable }
                                    icon={ activeElementObject.editable ? 'lock open' : 'lock'}
                                    content={ activeElementObject.editable ? 'Unlocked' : 'Locked'}
                                    className="mr_0 mt_nudge"
                                    onClick={this.toggleElementLock.bind(this)}
                                    />
                            }

                            { confirm && <>
                                <Button positive
                                    title="Done Editing"
                                    icon="check"
                                    content="Done"
                                    className="mr_0 mt_nudge"
                                    onClick={this.deselectElement.bind(this)}
                                    />
                            </>}

                            { remove && <>
                                <Popup trigger={deleteButton} on='click' position="left center">
                                    <p>{`Are you sure?`}</p>
                                    <p><Button negative size="tiny" content="Yes, delete" onClick={ this.deleteElement.bind(this) }/></p>
                                </Popup>
                                </>}

                        </div>

                    </div>
                </div>
            </Portal>
        )
    }
}

function mapStateToProps(state) {
    const {activeElement} = state.tools
    const {present: project} = state.project
    return {
        activeElement,
        activeElementObject: project.elements.find(el => el.id === activeElement ),
        projectType: project.type
    }
}

export default connect(mapStateToProps)(ElementToolset);
