import React, { useState, useEffect } from 'react'
import { Modal, Form, Header, Input, Button } from 'semantic-ui-react'
import ProjectPreview from '../core/ProjectPreview'
import { useSelector, useDispatch } from 'react-redux'
import { setAsTemplate, setProjectTitle } from '../../reducks/project'
import { setSaveModal } from '../../reducks/app'
import { saveProject } from '../core/utilities'

const SaveModal = props => {
	const titleInputRef = React.createRef()
	// props
	const { open, onClose } = props
	const userId = useSelector(state => state.user.meta && state.user.meta.id )
	const userIsAdmin = useSelector(state => state.user.meta && state.user.meta.admin )
	const project = useSelector(state => state.project.present)
	const dispatch = useDispatch()
	// state
	const [generatingPreview, setGeneratingPreview] = useState(false)
	const [localProjectTitle, setLocalProjectTitle] = useState(project.title)
	const [localProjectDescription, setLocalProjectDescription] = useState(project.description)
	// derived values
	const templateMode = project.type === 'projectTemplates'
	
	function handleSave() {
		dispatch( setSaveModal(false) )
		saveProject(project, null, userId)
	}

	useEffect(() => {
		setLocalProjectTitle(project.title)
	}, [project.title])
	
	useEffect(() => {
		setLocalProjectDescription(project.description)
	}, [project.description])

	return (
		<Modal open={ open } size="mini" onClose={ onClose }>
			<Modal.Header>Save As</Modal.Header>
			<Modal.Content className="text_center">

				<Form>

					<ProjectPreview centered size="small"
						loadingStart={() => setGeneratingPreview(true) }
						loadingComplete={() => setGeneratingPreview(false) }
						/>

					<Header as="label" htmlFor="projectTitle" 
						size="small" color="blue" 
						className="mt_1 display_block"
						>
						Give your { templateMode ? 'Template' : 'Project' } a name
					</Header>
					<Input fluid id="projectTitle" type="text" name="projectTitle" value={localProjectTitle} className="mt_half"
						ref={ titleInputRef }
						onChange={ (event, data) => setLocalProjectTitle(data.value) }
						onBlur={ (event) => {
							dispatch( setProjectTitle(localProjectTitle) )
						} }
						onFocus={ (event) => { event.target.select() }}
						/>

					{ userIsAdmin && <>

						<Form.Checkbox toggle id="save-as-template" label={<label><strong>Save as a Template</strong></label>}
							className="mt_1"
							checked={project.type === 'projectTemplates'}
							onChange={ (e, data) => dispatch( setAsTemplate( data.checked ) ) }
							/>

						{ ! templateMode &&
							<p className="mt_half"><strong className="color_primary">Pro tip:</strong> A template allows you to create new projects from this design.</p>
						}

						{ templateMode &&
							<Form.TextArea className="mt_half" name="projectDescription" placeholder="Optional description" rows="3"
								value={localProjectDescription}
								onChange={ (event, data) => setLocalProjectDescription(data.value) }
								onBlur={ (event) => dispatch( setLocalProjectDescription(localProjectDescription) ) }
								/>
						}

					</>}

				</Form>

			</Modal.Content>
			<Modal.Actions>
				<Button basic
					onClick={ onClose } >
					Cancel
				</Button>
				<Button primary basic={templateMode}
					loading={generatingPreview}
					disabled={generatingPreview || ! localProjectTitle}
					onClick={ handleSave }>
					{'Save ' + (templateMode ? 'Template' : 'to my Wall') }
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

export default SaveModal