import React, { Component } from 'react';
import { Button, Icon, Segment, Header, Dropdown, Grid, Responsive } from 'semantic-ui-react'
// import {debounce} from '../../helpers'
import PodApi from '../../api/podApi'
import {storageAvailable} from '../../helpers'

const inputStyle = {
    border: 0,
    fontSize: '1.5rem',
    fontFamily: 'inherit',
}

export default class FilterBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingCategories: false,
            loadingOrientations: false,
            loadingImageTypes: false,
            loadingPublishers: false,
            loadingArtists: false,
            filtersChanged: false,
            artists: [],
            artistValue: '',
            artistSearchQuery: '',
            categories: window.Craft.podCategories || [],
            categoriesValue: [],
            imageTypes: window.Craft.podImageTypes || [],
            imageTypeValue: '',
            orientations: window.Craft.podOrientations || [],
            orientationValue: '',
            publishers: window.Craft.podPublishers || [],
            publisherValue: '',
            searchValue: '',
            showMoreFilters: false
        }

        this.storageAvailable = storageAvailable('localStorage')
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this)
        this.getFilterCount = this.getFilterCount.bind(this)
        this.handleClearFilters = this.handleClearFilters.bind(this)
    }

    componentDidMount() {

        if ( this.searchInputRef ) {
            this.searchInputRef.focus()
        }

        const { categories, orientations, imageTypes, publishers } = this.state

        // if ( ! artists.length ) {
        //     this.setState({ loadingArtists: true })
        //     const getArtists = this.makeRequest( 'artists' )
        // }

        if ( ! categories.length ) {
            this.setState({ loadingCategories: true })
            this.makeRequest( 'categories', { key: 'name', value: 'id', text: 'name' } )
                    .then( () => {
                        let newState = {
                            loadingCategories: false,
                        }
                        if ( this.props.query.categories ) {
                            newState.categoriesValue = this.props.query.categories.split(',').map(cat => parseInt(cat))
                        }
                        this.setState(newState)
                    } )
        }
        else {
            if ( this.props.query.categories ) {
                this.setState({ categoriesValue: this.props.query.categories.split(',').map(cat => parseInt(cat)) })
            }
        }

        if ( ! orientations.length ) {
            this.setState({ loadingOrientations: true })
            this.makeRequest( 'orientations' )
                    .then( () => this.setState({ loadingOrientations: false }) )
        }

        if ( ! imageTypes.length ) {
            this.setState({ loadingImageTypes: true })
            this.makeRequest( 'imageTypes', { key: 'name', value: 'id', text: 'name' } )
                    .then( () => this.setState({ loadingImageTypes: false }) )
        }

        if ( ! publishers.length ) {
            this.setState({ loadingPublishers: true })
            this.makeRequest( 'publishers', { key: 'name', value: 'id', text: 'name' } )
                    .then( () => this.setState({ loadingPublishers: false }) )
        }

    }

    async makeRequest(handle, dataMap) {

        if ( this.storageAvailable ) {
            const pod_data_exp = window.localStorage.getItem(`pod_${handle}_exp`)
            const pod_data = window.localStorage.getItem(`pod_${handle}`)
            const currentTime = new Date().getTime()
            if ( pod_data && currentTime < pod_data_exp ) {
                this.setState(JSON.parse(pod_data))
                this.setState({loading: false})
                return new Promise( (resolve, reject) => {
                    resolve()
                })
            }
        }

        const json = await PodApi.getData(handle)

        if ( json && json.data && json.data.map ) {

            // update state with json results
            const newState = {}
            newState[handle] = json.data.map( item => {
                return dataMap ?
                    { key: item[dataMap.key], value: item[dataMap.value], text: item[dataMap.text] }
                    : { key: item, value: item, text: item }
            } )
            this.setState(newState)

            // store results
            if ( this.storageAvailable ) {
                window.localStorage.setItem(`pod_${handle}`, JSON.stringify(newState))
                window.localStorage.setItem(`pod_${handle}_exp`, new Date().getTime() + 60 * 60 * 1000)
            }
        }

        return json
    }

    handleSearchChange(event) {
        this.setState({
            searchValue: event.target.value,
            filtersChanged: true
        })
    }

    handleDropdownChange( event, {id, value, limit} ) {
        let newState = {
            filtersChanged: true
        }
        if ( limit ) {
            if ( value.length <= limit ) {
                newState[id] = value
            }
        }
        else {
            newState[id] = value
        }
        this.setState(newState)
    }

    handleArtistSearchChange(e, { searchQuery }) {
        this.setState({
            artistSearchQuery: searchQuery,
            filtersChanged: true
        })
    }

    sendFilters() {
        const { artistValue, searchValue, categoriesValue, orientationValue, imageTypeValue, publisherValue } = this.state
        let filters = {}
        if ( searchValue && typeof searchValue === 'string' ) { filters.srch_text = searchValue }
        if ( artistValue && typeof artistValue === 'string' ) { filters.artist = artistValue }
        if ( parseInt(categoriesValue[0]) > -1 ) { filters.cat0 = parseInt(categoriesValue[0]) }
        if ( parseInt(categoriesValue[1]) > -1 ) { filters.cat1 = parseInt(categoriesValue[1]) }
        if ( parseInt(categoriesValue[2]) > -1 ) { filters.cat2 = parseInt(categoriesValue[2]) }
        if ( parseInt(categoriesValue[3]) > -1 ) { filters.cat3 = parseInt(categoriesValue[3]) }
        if ( parseInt(categoriesValue[4]) > -1 ) { filters.cat4 = parseInt(categoriesValue[4]) }
        if ( orientationValue && typeof orientationValue === 'string' ) { filters.orientation = orientationValue }
        if ( parseInt(imageTypeValue) > -1 ) { filters.image_type = parseInt(imageTypeValue) }
        if ( parseInt(publisherValue) > -1 ) { filters.publisher_id = parseInt(publisherValue) }

        this.setState({ filtersChanged: false })

        this.props.onApplyFilters(filters)
    }

    handleSearchKeyPress(event) {
        if (event.key === "Enter") {
            this.sendFilters()
        }
    }

    handleClearFilters(event) {
        this.setState({
            categoriesValue: [],
            orientationValue: '',
            imageTypeValue: '',
            publisherValue: '',
        })
    }

    getFilterCount() {
        const { categoriesValue, artistValue, orientationValue, imageTypeValue, publisherValue } = this.state
        let filterCount = 0

        filterCount += categoriesValue.length
        if ( artistValue ) { filterCount++ }
        if ( orientationValue ) { filterCount++ }
        if ( parseInt(imageTypeValue) > -1 ) { filterCount++ }
        if ( parseInt(publisherValue) > -1 ) { filterCount++ }

        return filterCount
    }

    render() {

        const {
            orientations,
            imageTypes,
            publishers,
            categories,
            categoriesValue,
            searchValue,
            loadingCategories,
            loadingOrientations,
            loadingImageTypes,
            loadingPublishers,
            orientationValue,
            imageTypeValue,
            publisherValue,
            filtersChanged,
            showMoreFilters
        } = this.state

        const { searching } = this.props
        const filterCount = this.getFilterCount()
        const submitButtonProps = {
            color: ! searching && filtersChanged ? 'purple' : undefined,
            icon: "search",
            loading: searching,
            disabled: searching || ! filtersChanged,
            content: searching ? 'Searching' : 'Find Art',
            onClick: this.sendFilters.bind(this)
        }

        return (
            <div className={`text_center width_100 ${this.props.className}`}>

                <Segment basic className="mx_auto mb_0 display_flex align_items_center" style={{maxWidth: 600, border:0, background:'white', borderRadius: 5}}>
                    <input ref={ ref => this.searchInputRef = ref }
                        placeholder='Search...'
                        value={searchValue}
                        className="flex_grow_1 mr_1"
                        style={inputStyle}
                        onFocus={ (event) => { event.target.select() }}
                        onKeyPress={this.handleSearchKeyPress}
                        onChange={this.handleSearchChange} />
                    <Responsive as={Button} minWidth={Responsive.onlyComputer.minWidth}
                        {...submitButtonProps} 
                        />
                </Segment>

                <div className="mx_auto" style={{maxWidth:600}}>
                    <Responsive as={Button} maxWidth={Responsive.onlyTablet.maxWidth}
                        fluid
                        color="purple"
                        className="mt_half mx_0"
                        icon={submitButtonProps.icon}
                        loading={submitButtonProps.loading}
                        content={submitButtonProps.content}
                        onClick={submitButtonProps.onClick}
                        />

                </div>

                <Button basic color="black" size="tiny"
                    icon={ showMoreFilters ? "caret up" : "caret down" }
                    content={ showMoreFilters ? "Hide" : "Advanced Search Options" }
                    className="mx_auto mt_1"
                    onClick={ () => this.setState({ showMoreFilters: !showMoreFilters }) }
                    />
                    
                { filterCount > 0 &&
                    <Button basic
                        color="black"
                        size="tiny"
                        className="ml_half"
                        onClick={this.handleClearFilters}
                        >
                        {filterCount} active {filterCount > 1 ? 'filters' : 'filter'} <span className="mx_half">|</span> <Icon name="delete" /> Clear
                    </Button>
                }

                { showMoreFilters && <>

                    <Grid stackable columns={4} className="mt_1">

                        {/*<Header size="small">Find an artist:</Header>
                        <Label size="large" color="blue" className="mb_nudge">
                        <Icon name="user"/>
                        Monet, Claude
                        {' '}{' '}<Icon fitted name="delete"/>
                        </Label>
                        <Dropdown fluid selection search
                        id="artistValue"
                        placeholder="Enter an artist name"
                        disabled={loadingData}
                        loading={loadingData}
                        onSearchChange={this.handleArtistSearchChange.bind(this)}
                        searchQuery={artistSearchQuery}
                        onChange={this.handleDropdownChange.bind(this)}
                        options={artists}
                        />*/}

                        <Grid.Column>
                            <Header size="small">
                                Categories:
                            </Header>
                            <Dropdown
                                id="categoriesValue"
                                value={categoriesValue}
                                limit={5}
                                fluid multiple selection clearable
                                placeholder={ loadingCategories ? 'Loading' : 'Select categories'}
                                loading={loadingCategories}
                                options={categories}
                                onChange={this.handleDropdownChange.bind(this)}
                                />
                            <Header sub className="mt_nudge faded">Choose up to 5</Header>
                        </Grid.Column>

                        <Grid.Column>
                            <Header size="small">
                                Orientation:
                            </Header>
                            <Dropdown
                                id='orientationValue'
                                value={orientationValue}
                                fluid clearable selection
                                placeholder={ loadingOrientations ? 'Loading' : 'Choose an orientation'}
                                loading={loadingOrientations}
                                options={orientations}
                                onChange={this.handleDropdownChange.bind(this)}
                                />
                        </Grid.Column>

                        <Grid.Column>
                            <Header size="small">
                                Image Types:
                            </Header>
                            <Dropdown
                                id='imageTypeValue'
                                value={imageTypeValue}
                                fluid clearable selection
                                placeholder={ loadingImageTypes ? 'Loading' : 'Choose image type'}
                                loading={loadingImageTypes}
                                options={imageTypes}
                                onChange={this.handleDropdownChange.bind(this)}
                                />
                        </Grid.Column>

                        <Grid.Column>
                            <Header size="small">
                                Publishers:
                            </Header>
                            <Dropdown
                                id='publisherValue'
                                value={publisherValue}
                                fluid clearable selection
                                placeholder={ loadingPublishers ? 'Loading' : 'Choose a publisher'}
                                loading={loadingPublishers}
                                options={publishers}
                                onChange={this.handleDropdownChange.bind(this)}
                                />
                        </Grid.Column>

                    </Grid>

                    <Button className="mt_1" {...submitButtonProps} />

                </>}

            </div>
        )
    }
}
