import React from 'react'
import Section from '../sidebar/Section'
import { Card, Button, Responsive } from 'semantic-ui-react'
import { setOpenings } from '../../reducks/setup'
import { useDispatch } from 'react-redux'
import { defaultOpening } from './vars'
import { doScrollRef } from '../../helpers'
import { getFraction } from '../core/Fraction'

const documents = [
	{ title: 'Diploma', width: 11, height: 8.5, image: '//placehold.it/150x150' },
	{ title: 'PhD Diploma', width: 14, height: 11, image: '//placehold.it/150x150' },
	{ title: 'Letter', width: 8.5, height: 11, image: '//placehold.it/150x150' },
	{ title: 'Legal', width: 11, height: 14, image: '//placehold.it/150x150' },
	{ title: 'Picture - Small', width: 4, height: 6, image: '//placehold.it/150x150' },
	{ title: 'Picture - Medium', width: 7, height: 5, image: '//placehold.it/150x150' },
	{ title: 'Picture - Large', width: 9, height: 6, image: '//placehold.it/150x150' },
	{ title: 'Poster - Small', width: 14, height: 20, image: '//placehold.it/150x150' },
	{ title: 'Poster - Large', width: 18, height: 24, image: '//placehold.it/150x150' },
	{ title: 'Poster - XL', width: 24, height: 36, image: '//placehold.it/150x150' },
	{ title: 'A1', width: 23.4, height: 33.1, image: '//placehold.it/150x150' },
	{ title: 'A2', width: 16.5, height: 23.4, image: '//placehold.it/150x150' },
	{ title: 'A3', width: 11.7, height: 16.5, image: '//placehold.it/150x150' },
	{ title: 'A4', width: 8.3, height: 11.7, image: '//placehold.it/150x150' },
	{ title: 'A5', width: 5.8, height: 8.3, image: '//placehold.it/150x150' },
]

const CommonDocuments = props => {

	const dispatch = useDispatch()

	function selectDoc(doc) {
		if ( window.innerWidth < Responsive.onlyComputer.minWidth ) {
			doScrollRef( window.document.body.parentElement, 0, 0 )
		}
		dispatch( setOpenings([
			{
				...defaultOpening,
				width: doc.width,
				height: doc.height,
			}
		]) )
	}

	return (
		<Section title="Choose from common sizes">
			<Section.Content>
				{ documents.map( (doc,d) => (
					<Button key={d} fluid basic color="black" 
						className="mb_1 justify_content_space_between"
						style={{display:'flex'}}
						onClick={ event => selectDoc(doc) }
						>
						{doc.title}
						&nbsp;&nbsp;&nbsp;
						<span className="faded">{getFraction(doc.width)} &times; {getFraction(doc.height)}&rdquo;</span>
					</Button>
				) ) }
				<Card.Group itemsPerRow={2}>
				</Card.Group>
			</Section.Content>
		</Section>
	)
}

export default CommonDocuments