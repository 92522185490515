import React from 'react'
import {Link} from 'react-router-dom'
import WCIcon from '../core/Icon'
import { Popup } from 'semantic-ui-react'
import { white } from '../core/colors'

const StepButton = (props) => {

    const { 
        id, 
        active, 
        icon, 
        buttonText, 
        href, 
        link, 
        style, 
        iconSize, 
        divided, 
        popup,
        onClick,
    } = props
    let className = props.className || ''
    let iconEl = typeof icon === 'string' ? <WCIcon name={icon} size={ iconSize || "21px" } fill={ active ? white : 'rgba(255,255,255,0.6)' } /> : icon
    
    const content = (<>
        {iconEl}
        <span className="display_inline_block mt_nudge">{buttonText}</span>
    </>)
    let renderElement = null

    if ( link ) {
        renderElement = <Link id={id} to={link} className={`StepButton ${className}`} style={style}>{content}</Link>
    }
    else if ( href ) {
        renderElement = <a id={id} href={href} className={`StepButton ${className}`} style={style}>{content}</a>
    } 
    else {
        renderElement = <div id={id}
            className={`StepButton ${className} ${ active ? 'is_active' : '' } ${ divided ? 'is_divided' : '' }`}
            onClick={ onClick ? (event) => onClick(event, props) : undefined }
            style={style}
            >
            {content}
        </div>
    }

    if ( popup ) {
        renderElement = (
            <Popup
                on="hover"
                position="right center"
                trigger={ renderElement }
                >
                {popup}
            </Popup>
        )
    }

    return (<>
        {renderElement}
        { divided && 
            <div className="flex_shrink_0" style={{height: 2, border: 0, background:'#fff', margin: '0.5rem 5px'}} />
        }
    </>)
}

export default StepButton