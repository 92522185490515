import React, {Component} from 'react'

export default class WCIcon extends Component {

    constructor(props) {
        super(props)
        this.svg.bind(this)
    }

    logo(fill) {
        return <svg className="logo" width="415" height="512" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 415 512">
            <path fill={fill} d="M0,64,64,0V368H255.19v64H0ZM80,0V352h64V64H415.06l-64-64ZM415,80H159v64H351V513l64-64ZM335,512V160H271V448H0l64,64Z" />
        </svg>
    }

    cog(fill) {
        return <svg className="cog" width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path fill={fill} d="M7.02,0 L6.02,2.38 C5.82,2.44 5.64,2.54 5.46,2.64 L3.08,1.64 L1.64,3.08 L2.64,5.46 C2.54,5.66 2.46,5.82 2.38,6.02 L0,7.02 L0,9.02 L2.38,10.02 C2.46,10.22 2.54,10.38 2.64,10.58 L1.64,12.96 L3.08,14.4 L5.46,13.4 C5.64,13.48 5.82,13.58 6.02,13.66 L7.02,16.04 L9.02,16.04 L10.02,13.66 C10.2,13.58 10.4,13.5 10.58,13.4 L12.96,14.4 L14.4,12.96 L13.4,10.58 C13.48,10.4 13.58,10.2 13.66,10.02 L16.04,9.02 L16.04,7.02 L13.66,6.02 C13.6,5.84 13.5,5.64 13.4,5.46 L14.4,3.08 L12.96,1.64 L10.58,2.64 C10.4,2.56 10.2,2.46 10.02,2.38 L9.02,8.8817842e-16 L7.02,0 Z M8.02,5 C9.68,5 11.02,6.34 11.02,8 C11.02,9.66 9.68,11 8.02,11 C6.36,11 5.02,9.66 5.02,8 C5.02,6.34 6.36,5 8.02,5 Z" ></path>
        </svg>
    }

    xacto(fill) {
        return <svg className="xacto" width="28px" height="25px" viewBox="0 0 28 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path transform="translate(-68.000000, -7.000000)" fill={fill} d="M88.5154375,17.8416054 L86.433841,19.9232019 L86.433841,25.4249729 L70,29.490116 L84.517163,13.68374 C83.7441521,12.3642092 83.7663123,11.1020142 84.6958095,10.1296816 C85.327434,9.46894897 86.3441657,8.42625387 87.7475103,7 L89.0765786,8.30772002 C87.6790489,9.72806404 86.6675543,10.7653883 86.0436005,11.4180967 C85.7528033,11.7222951 85.7407428,12.0830849 86.1136154,12.7206182 C87.992065,14.6556972 89.2720305,15.9630774 89.9504725,16.6397346 C90.2894956,16.9778658 90.7096995,16.9890561 91.4476138,16.552252 L93.9718196,14.1930405 L95.2449848,15.5552461 L92.5813229,18.0447982 L92.5016533,18.0938212 C91.0439104,18.9908129 89.6673977,18.9908129 88.6337729,17.9599074 C88.5961183,17.9223518 88.5566733,17.882918 88.5154375,17.8416054 Z M87.2061031,16.5140651 C86.7954131,16.0947314 86.3220553,15.6096606 85.7859265,15.0587503 L75.5585508,26.1943797 L84.569289,23.9654461 L84.569289,19.1508792 L87.2061031,16.5140651 Z M68.2333984,30.94707 L94.8046875,30.94707 L94.8046875,31.94707 L68.2333984,31.94707 L68.2333984,30.94707 Z" ></path>
        </svg>
    }

    layers(fill) {
        return <svg className="layers" width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g fill={fill} fillRule="nonzero">
                <path d="M0.567023313,6.74360913 L10.0174119,11.6480521 C10.1119157,11.746141 10.2064196,11.746141 10.3954274,11.746141 C10.5844352,11.746141 10.6789391,11.746141 10.7734429,11.6480521 L20.2238315,6.74360913 C20.601847,6.54743141 20.7908548,6.25316483 20.7908548,5.86080939 C20.7908548,5.46845395 20.601847,5.17418737 20.2238315,4.97800965 L10.7734429,0.0735666451 C10.4899313,-0.024522215 10.2064196,-0.024522215 9.92290797,0.0735666451 L0.472519427,4.97800965 C0.189007771,5.17418737 0,5.46845395 0,5.86080939 C0,6.25316483 0.189007771,6.54743141 0.567023313,6.74360913 Z M10.3954274,3.8038544 L14.301588,5.86080939 L10.3954274,7.91776438 L6.4892668,5.86080939 L10.3954274,3.8038544 Z" ></path>
                <path d="M19.5189422,14.2251226 L10.4132153,18.9380808 L1.30748845,14.2251226 C0.83323184,14.0287493 0.26412391,14.2251226 0.0744212662,14.6178691 C-0.115281377,15.0106156 0.0744212662,15.697922 0.453826553,15.8942953 L9.93895872,20.8036267 C10.1286614,21 10.2235127,21 10.4132153,21 C10.602918,21 10.6977693,21 10.7926206,20.9018134 L20.2777528,15.9924819 C20.7520094,15.7961087 20.941712,15.2069889 20.6571581,14.7160557 C20.4674554,14.2251226 19.8983475,14.0287493 19.5189422,14.2251226 Z"></path>
                <path d="M19.5189422,9.80384622 L10.4132153,14.5168044 L1.30748845,9.80384622 C0.83323184,9.60747296 0.26412391,9.80384622 0.0744212662,10.1965927 C-0.115281377,10.6875259 0.0744212662,11.2766457 0.453826553,11.4730189 L9.93895872,16.3823504 C10.1286614,16.5787236 10.2235127,16.5787236 10.4132153,16.5787236 C10.602918,16.5787236 10.6977693,16.5787236 10.7926206,16.480537 L20.2777528,11.5712055 C20.7520094,11.3748323 20.941712,10.7857125 20.6571581,10.2947794 C20.4674554,9.80384622 19.8983475,9.60747296 19.5189422,9.80384622 Z"></path>
            </g>
        </svg>
    }

    text(fill) {
        return <svg className="text" width="21px" height="15px" viewBox="0 0 21 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path fill={fill} transform="translate(-72.000000, -15.000000)" d="M83.96,17.88 L79.74,17.88 L79.74,29.02 L76.18,29.02 L76.18,17.88 L72,17.88 L72,15 L83.96,15 L83.96,17.88 Z M91.74,26.04 L92.42,28.44 C91.64,28.9 90.58,29.18 89.54,29.18 C87.46,29.18 85.96,28 85.96,25.76 L85.96,21 L84.46,21 L84.46,18.74 L85.96,18.74 L85.96,15.74 L89.4,15.74 L89.4,18.72 L92.22,18.72 L92.22,21 L89.4,21 L89.4,25.22 C89.4,26.06 89.76,26.42 90.4,26.4 C90.76,26.4 91.22,26.26 91.74,26.04 Z"></path>
        </svg>
    }

    image(fill) {
        return <svg className="image" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path fill={fill} d="M0,0 L0,16 L16,16 L16,0 L0,0 Z M2,2 L14,2 L14,8 L12,6 L10,8 L14,12 L14,14 L12,14 L4,6 L2,8 L2,2 Z"></path>
        </svg>
    }

    frame(fill) {
        return <svg className="frame" width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path fill={fill} transform="translate(-70.000000, -15.000000)" d="M75,22 L75,20 L88,20 L88,22 L77,22 L77,33 L75,33 L75,22 Z M74,15 L88,15 L88,19 L74,19 L74,33 L70,33 L70,15 L74,15 Z"></path>
        </svg>
    }

    glass(fill) {
        return <svg className="glass" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g stroke={fill}>
                <rect strokeWidth="2" fill="none" x="1" y="1" width="18" height="18"></rect>
                <path d="M5.5,14.5 L14.5,5.5" strokeLinecap="round"></path>
                <path d="M9,15 L15,9" strokeLinecap="round"></path>
                <path d="M5,11 L11,5" strokeLinecap="round"></path>
            </g> 
        </svg>
    }

    select(fill) {
        return (<svg className="select" width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <path d="M3.5 19h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 15c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={fill}></path>
            <path d="M3.5 12h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 8c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={fill}></path>
            <path d="M18.5 11c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={fill}></path>
            <path d="M3.5 5h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM1.5 1c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={fill}></path>
            <path d="M11.5 4c-0.276 0-0.5-0.224-0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2c-0.276 0-0.5 0.224-0.5 0.5s-0.224 0.5-0.5 0.5-0.5-0.224-0.5-0.5c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.276-0.224 0.5-0.5 0.5z" fill={fill}></path>
            <path d="M17.5 5h-2c-0.827 0-1.5-0.673-1.5-1.5v-2c0-0.827 0.673-1.5 1.5-1.5h2c0.827 0 1.5 0.673 1.5 1.5v2c0 0.827-0.673 1.5-1.5 1.5zM15.5 1c-0.276 0-0.5 0.224-0.5 0.5v2c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5-0.224 0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5h-2z" fill={fill}></path>
            <path d="M13 20c-0.198 0-0.386-0.119-0.464-0.314l-1.697-4.242-2.963 3.386c-0.137 0.157-0.357 0.212-0.552 0.139s-0.324-0.26-0.324-0.468v-15c0-0.198 0.117-0.377 0.298-0.457s0.392-0.046 0.539 0.087l11 10c0.153 0.139 0.205 0.358 0.13 0.55s-0.26 0.32-0.466 0.32h-4.261l1.726 4.314c0.103 0.256-0.022 0.547-0.279 0.65l-2.5 1c-0.061 0.024-0.124 0.036-0.186 0.036zM11 14c0.028 0 0.056 0.002 0.084 0.007 0.172 0.029 0.315 0.146 0.38 0.307l1.814 4.536 1.572-0.629-1.814-4.536c-0.062-0.154-0.043-0.329 0.050-0.466s0.248-0.22 0.414-0.22h3.707l-9.207-8.37v12.539l2.624-2.999c0.096-0.109 0.233-0.171 0.376-0.171z" fill={fill}></path>
        </svg>)
    }

    pictures(fill) {
        return (<svg className="pictures" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
            <path fill={fill} d="M887.456 628.32l-42.112 157.248-1.184 0.32-46.016 171.744-297.472-79.68-3.552 0.928-313.216-83.904-0.544-2.048-83.36-22.368 15.776-58.912 1.024 0.288 154.528-576.704-1.024-0.288 16.288-60.704 547.36 146.688 0.512-1.792 150.752 40.384-98.304 366.72 0.544 2.080zM908.224 303.936l-576.736-154.528-113.856 424.96 576.672 154.496 113.92-424.928zM391.488 428.544l38.208 65.44 99.424-93.6 50.656 88.512 133.92-118.048 33.504 273.888-442.304-118.592 86.592-97.6zM420.064 364.16c-25.12-6.752-40.064-32.608-33.312-57.728 6.72-25.152 32.576-40.096 57.728-33.344 25.12 6.752 40.064 32.608 33.312 57.728-6.72 25.152-32.576 40.096-57.728 33.344zM692.48 143.328l-13.248 3.552-121.44-32.544 179.136-48 27.712 103.392-70.080-18.784-2.080-7.616zM115.744 297.888l39.744 148.32-32.544 121.408-84.16-314.176 181.376-48.608-18.784 70.080-85.632 22.976zM225.856 951.552l-32.576-121.536 243.104 65.152-210.528 56.384z"></path>
        </svg>)
    }

    picture(fill) {
        return (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
            <path fill={fill} d="M626.272 347.424l-100.608 151.424-73.12-73.696-73.152 118.304-54.848-54.304-59.392 118.848h466.304l-105.184-260.576zM128 160v736h736v-736h-736zM800 672h-608v-448h608v448zM335.712 418.272c26.528 0 48-21.472 48-48s-21.472-48-48-48c-26.496 0-48 21.472-48 48s21.504 48 48 48z"></path>
        </svg>)
    }

    svg(name, fill, size, fluid) {
        if ( ! this[name] || 'svg' === name )
            return ''

        const style = fluid ? 
        {
            maxWidth: size, width: '100%', height: 'auto'
        } : {
            width: size, height: 'auto'
        }
        return <span className="Icon display_inline_block" style={style}>{ this[name](fill) }</span>
    }

    render() {
    
        const { name, fill, size, fluid } = this.props
        return this.svg(name, fill, size, fluid)
        
    }

}
