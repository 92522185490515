import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import CloudinaryUploader from '../core/CloudinaryUploader'
import { Button, Message, Menu, Image, Segment, Popup, Header, Responsive } from 'semantic-ui-react'
import {updateUserMedia, loadUserMedia, loadUserArt} from '../../reducks/user'
import WCLoader from '../core/Loader'
import {getItemBy, storageAvailable} from '../../helpers'
import MediaList from './MediaList'
import CloudinaryApi from '../../api/cloudinaryApi'

class MediaLibrary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingUploads: false,
            loadingUploadsError: false,
            loadingArt: false,
            loadingArtError: false,
            selectedImages: props.preSelection || [],
            activeLibrarySection: 'uploads',
            uploadVisibleCount: 12,
            artVisibleCount: 12,
            manageUploadsMode: false,
            deleting: false,
            deleteUploadsError: '',
            deleteConfirm: false,
        }

        this.toggleManageMode = this.toggleManageMode.bind(this)
        this.selectImage = this.selectImage.bind(this)
        this.clearSelected = this.clearSelected.bind(this)
        this.getUserUploads = this.getUserUploads.bind(this)
    }

    componentDidMount() {
        if ( this.props.userUploads.length === 0 ) {
            this.getUserUploads()
        }
        if ( this.props.userArt.length === 0 ) {
            this.getUserArt()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ( ! prevProps.user && this.props.user ) {
            this.getUserUploads()
            this.getUserArt()
        }
    }

    getUserUploads(){
        this.setState({
            loadingUploads: true,
            loadingUploadsError: false
        })
        this.props.dispatch( loadUserMedia( this.props.user, error => {
            if ( error ) {
                this.setState({
                    loadingUploadsError: error
                })
            }
            this.setState({loadingUploads: false})
        } ) )
    }

    getUserArt(){
        this.setState({
            loadingArt: true,
            loadingArtError: false
        })
        this.props.dispatch( loadUserArt( this.props.user, error => {
            if ( error ) {
                this.setState({
                    loadingArtError: error
                })
            }
            this.setState({loadingArt: false})
        } ) )
    }

    selectImage(image) {

        const { selectedImages, manageUploadsMode } = this.state
        const { maxSelection } = this.props

        // check if image already exists in selection
        const { key: imageIndex } = getItemBy('id', image.id, selectedImages, 'withKey')

        // make a copy of selection
        let newSelectedImages = [...selectedImages]

        // if image exists, remove it
        if ( imageIndex > -1 ) {
            newSelectedImages = newSelectedImages.filter( selectedImage => selectedImage.id !== image.id )
        }
            // otherwise add it
            else {
                // if the allowable # of images is already met, remove first selection
                if ( ! manageUploadsMode && selectedImages.length === maxSelection ) {
                    newSelectedImages.shift()
                }
                // add image
                newSelectedImages.push(image)
            }

        // update state
        this.setState({
            selectedImages: newSelectedImages
        })

        if ( ! manageUploadsMode && typeof this.props.onChangeSelection === 'function' ) {
            this.props.onChangeSelection(newSelectedImages)
        }
    }

    clearSelected() {
        this.setState({ selectedImages: [] })
        if ( typeof this.props.onChangeSelection === 'function' ) {
            this.props.onChangeSelection([])
        }
    }

    async deleteSelectedUploads() {
        const { userUploads, user } = this.props
        const { selectedImages } = this.state

        this.setState({
            deleting: true,
            deleteConfirm: false
        })

        let newUploads = userUploads.filter( upload => {
            return selectedImages.map(image => image.id).indexOf( upload.id ) < 0
        } )

        if ( user ) {
            // delete from Cloudinary
            const response = await CloudinaryApi.deleteAssets({
                public_ids: selectedImages.map( image => image.id ).join()
            })
            if ( response.deleted ) {
                this.props.dispatch( updateUserMedia( newUploads ) )
                this.clearSelected()
            }
            if ( response.error ) {
                this.setState({deleteUploadsError: response.error})
            }
        }
        else {
            // update localStorage
            if ( storageAvailable('localStorage') ) {
                window.localStorage.setItem('wallcoreAnonymousUploads', JSON.stringify({resources: newUploads}))
                this.props.dispatch( updateUserMedia( newUploads ) )
                this.clearSelected()
            }
        }

        this.setState({ deleting: false })

    }

    onUploadComplete(uploads) {
        if ( this.props.selectOnUpload ) {
            this.setState({
                selectedImages: uploads
            })
            if ( typeof this.props.onChangeSelection === 'function' ) {
                this.props.onChangeSelection(uploads)
            }
        }
    }

    toggleManageMode() {
        this.clearSelected()
        this.setState({manageUploadsMode: ! this.state.manageUploadsMode})
    }

    render() {

        const {
            loadingUploads,
            loadingUploadsError,
            loadingArt,
            loadingArtError,
            selectedImages,
            activeLibrarySection,
            uploadVisibleCount,
            artVisibleCount,
            manageUploadsMode,
            deleting,
            deleteUploadsError,
            deleteConfirm,
        } = this.state
        const {
            userUploads,
            userArt,
            maxSelection,
            inline,
            draggable,
            mobile,
            tablet, 
        } = this.props
        const selectedImageSize = this.props.selectedImageSize || 'medium'
        const columns = this.props.columns || 4
        const showSelection = this.props.showSelection || this.props.showSelection === undefined
        const uploadsActive = activeLibrarySection === 'uploads'
        const artActive = activeLibrarySection === 'shopart'
        const className = this.props.className || ''

        const commonListProps = {
            columns,
            draggable,
            inline,
            onSelectImage: this.selectImage,
            selectedImages,
        }

        const userUploadsListProps = {
            ...commonListProps,
            manageMode: manageUploadsMode,
            media: userUploads,
            visibleCount: uploadVisibleCount,
            onLoadMore: () => this.setState({ uploadVisibleCount: uploadVisibleCount + 12 }),
        }

        const userArtListProps = {
            ...commonListProps,
            media: userArt,
            visibleCount: artVisibleCount,
            onLoadMore: () => this.setState({ artVisibleCount: artVisibleCount + 12 }),
        }

        const userUploadsActions = <>
            <Button
                icon={ manageUploadsMode ? "arrow left" : "check square outline" }
                content={ manageUploadsMode ? undefined : "Manage" }
                onClick={ this.toggleManageMode }
                />
            { manageUploadsMode && selectedImages.length > 0 && <>
                <Popup 
                    open={deleteConfirm} 
                    on="click"
                    trigger={<Button icon="trash" content="Delete" loading={deleting} />}
                    onOpen={ () => this.setState({deleteConfirm: true}) }
                    onClose={ () => this.setState({deleteConfirm: false}) }
                    >
                    <Header size="tiny" color="red">{`Are you sure you want to delete the selected images?`}</Header>
                    <Button negative content="Yes, delete" onClick={ this.deleteSelectedUploads.bind(this) } />
                </Popup>
                <Button icon="delete" content="Clear" 
                    onClick={() => this.clearSelected()} 
                    />
            </>}
        </>

        const uploadButton = (
            <CloudinaryUploader
                allowMultiple={typeof maxSelection === 'undefined' || maxSelection > 1}
                onUploadComplete={this.onUploadComplete.bind(this)}
                icon="cloud upload"
                content="Upload New"
                disabled={ loadingUploads }
                />
        )

        return (
        <div className={`MediaLibrary ${className}`}>

            { showSelection && ! manageUploadsMode && selectedImages.length > 0 &&
                <Segment basic textAlign="center">
                    <Image.Group size={selectedImageSize} className="mb_half">
                        { selectedImages.map( image => <Image key={image.id} src={image.thumbnail || image.src} className="box_shadow"/> ) }
                    </Image.Group>
                    <Button basic icon="delete" content="Deselect All" onClick={ () => this.clearSelected() } />
                </Segment>
            }

            <Menu secondary pointing widths={2} size="large">
                <Menu.Item content="My Uploads"
                    icon="camera retro"
                    active={uploadsActive} 
                    onClick={() => this.setState({activeLibrarySection: 'uploads'})}
                    />
                <Menu.Item content="My Art"
                    icon="images"
                    active={artActive} 
                    onClick={() => this.setState({activeLibrarySection: 'shopart'})}
                    />
            </Menu>

            <Segment basic vertical textAlign="center">

                { uploadsActive && <>

                    <div className="mb_2">
                        { manageUploadsMode &&
                            <Header size="small">Manage Uploads</Header>
                        }
                        <Responsive as={Button.Group} basic vertical fluid maxWidth={399}>    
                            { ! manageUploadsMode &&
                                uploadButton
                            }
                            { userUploads.length > 0 && <>
                                { userUploadsActions }
                            </>}
                        </Responsive>
                        <Responsive as={Button.Group} basic minWidth={400}>
                            { ! manageUploadsMode &&
                                uploadButton
                            }
                            { userUploads.length > 0 && <>
                                { userUploadsActions }
                            </>}
                        </Responsive>
                    </div>

                    { loadingUploads &&
                        <WCLoader message="Loading previous uploads"/>
                    }

                    { loadingUploadsError &&
                        <Message negative onDismiss={() => this.setState({loadingUploadsError: ""})}>
                            <Message.Header>{loadingUploadsError}</Message.Header>
                            <Message.Content>
                                <p>There was an error loading your media</p>
                                <Button fluid basic negative content="Try Reload" size="mini" icon="sync" onClick={() => { this.getUserUploads() }} />
                            </Message.Content>
                        </Message>
                    }
                    
                    { deleteUploadsError &&
                        <Message negative onDismiss={() => this.setState({deleteUploadsError: ""})}>
                            <Message.Header>Error deleting your uploads</Message.Header>
                            <Message.Content>
                                <p><code>{deleteUploadsError}</code></p>
                            </Message.Content>
                        </Message>
                    }

                    { userUploads.length > 0 && <>
                        <Responsive as={MediaList} maxWidth={Responsive.onlyMobile.maxWidth}
                            {...userUploadsListProps}
                            columns={ mobile || columns }
                            />
                        <Responsive as={MediaList} {...Responsive.onlyTablet}
                            {...userUploadsListProps}
                            columns={ tablet || columns }
                            />
                        <Responsive as={MediaList} minWidth={Responsive.onlyComputer.minWidth}
                            {...userUploadsListProps}
                            />
                    </> }

                </>}

                { artActive && <>

                    <Button.Group basic className="mb_2">
                        <Button as={Link} to="/designer/shop-art" icon="search" content="Shop for art" />
                        { userArt.length > 0 &&
                            <Button
                                content="Manage"
                                as={Link}
                                to="/designer/shop-art?mode=myLibrary"
                                icon="check square outline"
                                />
                        }
                    </Button.Group>

                    { loadingArt &&
                        <WCLoader message="Loading saved art"/>
                    }

                    { loadingArtError &&
                        <Message negative>
                            <Message.Header>{loadingArtError}</Message.Header>
                            <Message.Content>
                                <p>There was an error loading your art</p>
                                <Button fluid basic negative content="Try Reload" size="mini" icon="sync" onClick={() => { this.getUserArt() }} />
                            </Message.Content>
                        </Message>
                    }

                    { userArt.length > 0 && <>

                        <MediaList 
                           {...userArtListProps}
                            />

                    </>}

                </>}

            </Segment>

        </div>
        )
    }
}

MediaLibrary.propTypes = {
    user: PropTypes.object,
    userUploads: PropTypes.array,
    userArt: PropTypes.array,
}

function mapStateToProps(state) {
    return {
        user: state.user.meta,
        userUploads: state.user.uploads,
        userArt: state.user.art,
    }
}

export default connect(mapStateToProps)(MediaLibrary)
