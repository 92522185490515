import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StepButton from './StepButton'
import { setActiveElement } from '../../reducks/tools'
import { getBottomOpenings } from '../core/utilities'

const StepMenu = (props, ref) => {
 
    const { onToggle, sidebarVisible, active, buttons} = props
    const lockedMode = useSelector( state => state.app.lockedMode )
    const project = useSelector( state => state.project.present )
    const activeElement = useSelector( state => state.tools.activeElement )
    const dispatch = useDispatch()
    
    useEffect(() => {
        if ( ! lockedMode && ! activeElement ) {
            const bottomOpenings = getBottomOpenings(project)
            if ( bottomOpenings[0] ) {
                dispatch( setActiveElement( bottomOpenings[0].id ) )
            } else {
                dispatch( setActiveElement( project.elements[0] ) )
            }
        }
    }, [lockedMode])

    const onClickButton = (event, data) => {
        onToggle(data.id)
    }

    return (
        <div id="Steps" className="Steps" ref={ref}>

            { buttons.map( (btn, i) => {
                if ( btn.noRender ){
                    return '';
                }
                const isActive = sidebarVisible && active === btn.id
                return  <StepButton
                            key={btn.id}
                            id={btn.id}
                            icon={btn.icon}
                            buttonText={btn.buttonText || btn.title}
                            active={isActive}
                            divided={btn.divided}
                            onClick={ btn.onClick || onClickButton } />
            } ) }

        </div>
    )
}
export default React.forwardRef(StepMenu)