import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Header, Button, Icon, Popup, Input } from 'semantic-ui-react'
import Library from '../core/Library'
import { createGraphic, createOpening, createText, elementHasChildren, createDeepOpenings } from './element-helpers'
import { addElement, addElements, updateElementProps } from '../../reducks/project'
import { setActiveElement, setActiveDrawTool, setDrawToolActiveOn } from '../../reducks/tools'
import {PPI, HALF_IN_PX} from '../../data/constants'
import {getItemBy} from '../../helpers'
import { setGrid, setMediaModal, setEditImageMode } from '../../reducks/app'
import { CompactPicker } from 'react-color'

class OpeningToolset extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cutArtLibraryOpen: false
        }
        this.cutOpenings.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    selectGraphic(graphic) {
        const { activeElementObject, activeIsSource, defaultReveal, dispatch } = this.props
        const { id: activeId, width: activeWidth, height: activeHeight } = activeElementObject
        const twoInches = PPI*2
        const reveal = activeIsSource ? defaultReveal * 2 : defaultReveal
        const newElementWidth =  activeWidth - (reveal * 2)
        const newElementHeight =  activeHeight - (reveal * 2)

        this.setState({
            libraryOpen: false
        })
        const newGraphic = createGraphic( graphic.url, {
            x: reveal, y: reveal,
            width: Math.min( twoInches, newElementWidth ), height: Math.min( twoInches, newElementHeight ),
            parent: activeId,
        } )
        dispatch( addElement( newGraphic ) )
        dispatch( setActiveElement( newGraphic.id ) )
    }

    onSelectCutArt(cutArt) {
        const { activeElementObject, activeIsSource, defaultReveal, dispatch } = this.props
        const { image, depth, id:activeId } = activeElementObject
        const reveal = activeIsSource ? defaultReveal * 2 : defaultReveal

        if ( image ) {
            dispatch( updateElementProps( activeId, {image: null} ) )
        }
        const newCutArt = createOpening( 'cutart', {
            x: reveal, y: reveal,
            width: cutArt.width,
            height: cutArt.height,
            depth: depth + 1,
            shape: cutArt,
            parent: activeId
        } )
        dispatch( addElement( newCutArt ) )
        dispatch( setActiveElement( newCutArt.id ) )
    }

    removeImage() {
        const { activeElementObject, dispatch } = this.props
        const { id } = activeElementObject
        dispatch( updateElementProps( id, { image: null } ) )
    }

    openMediaModal() {
        this.props.dispatch( setMediaModal(true) )
    }

    openImagePositioner() {
        this.props.dispatch( setEditImageMode(true) )
    }

    cancelDraw() {
        const { dispatch } = this.props
        dispatch( setActiveDrawTool(null) )
        dispatch( setDrawToolActiveOn(null) )
        dispatch( setGrid(false) )
    }

    selectText() {
        // const { dispatch, activeElement } = this.props
        const { dispatch, activeElementObject, activeElement } = this.props
        const { id, width, height, depth, backgroundColor } = activeElementObject
        const textElement = createText( 'Enter text here', {
            x: HALF_IN_PX,
            width: width - PPI,
            parent: id,
            depth
        } )
        textElement.height = parseInt(textElement.fontSize * 1.5)
        textElement.y = height/2 - textElement.height/2
        dispatch( addElement( textElement ) )
        dispatch( setActiveElement( textElement.id ) )
        if ( ! backgroundColor ) {
            dispatch( updateElementProps( activeElement, { backgroundColor: '#ffffff' } ) )
        }

        // dispatch( setActiveDrawTool('text') )
        // dispatch( setDrawToolActiveOn(activeElement) )
        // dispatch( setGrid(true) )
    }

    selectBgColor(color) {
        const { dispatch, activeElement } = this.props
        dispatch( updateElementProps( activeElement, { backgroundColor: color.hex } ) )
    }

    clearBgColor() {
        const { dispatch, activeElement } = this.props
        dispatch( updateElementProps( activeElement, { backgroundColor: '' } ) )
    }

    selectRectangle(event, data) {
        this.cutOpenings('rect')
    }

    selectOval(event, data) {
        this.cutOpenings('oval')
    }

    cutOpenings(shape) {
        const { dispatch, elements, activeElementObject, activeIsSource, defaultReveal, projectMats } = this.props
        const { id, image, width, height, depth } = activeElementObject
        const adjustedReveal = activeIsSource ? PPI * 2 : defaultReveal

        if ( image ) {
            dispatch( updateElementProps( id, {image: null} ) )
        }

        if ( elementHasChildren(activeElementObject, elements) )
        {
            dispatch( setActiveDrawTool(shape) )
            dispatch( setDrawToolActiveOn(id) )
            dispatch( setGrid(true) )
        }
        else
        {
            const outerOpening = {
                x: adjustedReveal,
                y:  adjustedReveal,
                width: width - (adjustedReveal * 2),
                height: height - (adjustedReveal * 2),
                depth,
                parent: id
            }
            const rectOpenings = createDeepOpenings(shape, projectMats.length - depth, outerOpening)

            dispatch( addElements(rectOpenings) )
            dispatch( setActiveElement( rectOpenings[rectOpenings.length - 1].id ) )
        }
    }

    handleInputChange(event, {id: elementProp}) {
        const { activeElementObject, activeElementParent, dispatch } = this.props
        const {id: elementid} = activeElementObject
        let pixelValue = parseFloat(event.target.value) * PPI
        let newHeight, newWidth, change

        if ( elementProp === 'element_top_border' ) {
            change = activeElementObject.y - pixelValue
            newHeight = activeElementObject.height + change
            dispatch( updateElementProps( elementid, { y: pixelValue, height: newHeight } ) )
        }
        else if ( elementProp === 'element_bottom_border' ) {
            newHeight = activeElementParent.height - (activeElementObject.y + pixelValue)
            dispatch( updateElementProps( elementid, { height: newHeight } ) )
        }
        else if ( elementProp === 'element_left_border' ) {
            change = activeElementObject.x - pixelValue
            newWidth = activeElementObject.width + change
            dispatch( updateElementProps( elementid, { x: pixelValue, width: newWidth } ) )
        }
        else if ( elementProp === 'element_right_border' ) {
            newWidth = activeElementParent.width - (activeElementObject.x + pixelValue)
            dispatch( updateElementProps( elementid, { width: newWidth } ) )
        }
        else if ( elementProp === 'element_width' ) {
            dispatch( updateElementProps( elementid, { width: event.target.value * PPI } ) )
        }
        else if ( elementProp === 'element_height' ) {
            dispatch( updateElementProps( elementid, { height: event.target.value * PPI } ) )
        }
        else {
            dispatch( updateElementProps( elementid, { [elementProp]: pixelValue } ) )
        }
    }

    render() {
        const {
            cutArtLibraryOpen
        } = this.state
        const {
            activeDrawTool,
            activeIsSource,
            activeElementObject,
            activeElementParent,
            projectMats,
            setImage,
            setText,
            setGraphic,
            setOpening,
            setBackground,
            setPosition
        } = this.props
        const { image, depth, backgroundColor, width, height, x, y } = activeElementObject
        const activeIsBottom    = depth === projectMats.length;
        const canAddOpening     = setOpening && ! activeIsBottom
        const canAddImage       = setImage && activeIsBottom
        const canAddText        = setText && activeIsBottom
        const canAddGraphic     = setGraphic && activeIsBottom
        const canSetBackground  = setBackground && activeIsBottom
        const canSetPosition    = setPosition

        const cancelDrawButton  = <Button negative icon="close" content="Cancel" onClick={this.cancelDraw.bind(this)}/>
        const addImageButton    = <Button icon="image" content="Add image" onClick={this.openMediaModal.bind(this)} />
        const editImageButton   = (
            <Button
                style={{ backgroundImage: image ? `url(${image.src})` : '', backgroundSize: 'cover', backgroundPosition: 'center', backgroundBlendMode: 'multiply' }}>
                <Icon name="image" className="mr_nudge"/>
                Image
                <Icon name="dropdown" className="ml_nudge mr_0"/>
            </Button>
        )
        const manageImageButtons= <>
            <Button icon="sync" content="Replace" onClick={this.openMediaModal.bind(this)} />
            <Button icon="crop" content="Crop" onClick={this.openImagePositioner.bind(this)} />
            <Button icon="delete" content="Remove" onClick={this.removeImage.bind(this)} />
        </>
        const addTextButton     = <Button icon="font" content="Text" onClick={this.selectText.bind(this)} />
        const addGraphicButton  = <Button icon="paw" content="Graphic" />
        const addOpeningButton  = <Button icon="square outline" content="Opening" />
        const addRectButton     = <Button icon="square outline" content="Rectangle" onClick={this.selectRectangle.bind(this)} />
        const addOvalButton     = <Button icon="circle outline" content="Oval" onClick={this.selectOval.bind(this)} />
        const addCutArtButton   = <Button icon="star outline" content="Cut art" onClick={ () => this.setState({ cutArtLibraryOpen:true }) } />
        const addColorButton    = (
            <Button>
                <Icon name="tint"/>
                Background
                { backgroundColor &&
                    <span className="display_inline_block border_white ml_half" style={{width:10, height:10, backgroundColor}}></span>
                }
            </Button>
        )
        const removeBgButton    = <Button fluid size="mini" icon="close" content="Remove Color" title="Remove color" className="my_nudge" onClick={this.clearBgColor.bind(this)}/>
        const dimensionsButton  = <Button icon="arrows alternate" content="Position" className="mr_0"/>

        return (
            <Button.Group vertical basic>

            { canAddImage && <>

                { image ?
                    ( setImage === 'expanded' ?
                        manageImageButtons
                        :
                        <Popup trigger={editImageButton} on='click' wide position="top center">
                            <Button.Group size="tiny" basic vertical>
                                {manageImageButtons}
                            </Button.Group>
                        </Popup>
                    )
                    :
                    addImageButton
                }

            </>}

            { (canAddGraphic || canAddText ) && <>

                { activeDrawTool === 'text' ? cancelDrawButton : addTextButton }

                <Library resource="graphics"
                    trigger={addGraphicButton}
                    title="Select a graphic"
                    onSelectItem={this.selectGraphic.bind(this)} />
            </>}

            { canSetBackground && <>
                <Popup inverted trigger={addColorButton} on='click' wide position="left center">
                    <CompactPicker color={backgroundColor} onChange={this.selectBgColor.bind(this)} />
                    { removeBgButton }
                </Popup>
            </>}

            { canAddOpening && <>

                <Popup trigger={addOpeningButton} on='click' wide position="left center">
                    <Button.Group size="tiny" basic vertical>
                        { activeDrawTool === 'rect' ? cancelDrawButton : addRectButton }
                        { activeDrawTool === 'oval' ? cancelDrawButton : addOvalButton }
                        { addCutArtButton }
                    </Button.Group>
                </Popup>
                
                <Library resource="cutArt"
                    open={cutArtLibraryOpen}
                    onClose={ () => this.setState({cutArtLibraryOpen: false}) }
                    title="Select Cut Art"
                    tool="cutart_opening"
                    onSelectItem={this.onSelectCutArt.bind(this)} />

            </>}

            { canSetPosition &&

                <Popup on="click" trigger={dimensionsButton} wide position="top center">
                    <form className="ui form" style={{width:200}}>
                        <Header dividing size="tiny">Dimensions</Header>
                        <div className="two fields">
                            <div className="field">
                                <Input type="number" value={width/PPI} min={0} step={9/PPI}
                                    id="element_width"
                                    onChange={ this.handleInputChange }
                                    />
                                <label>Width</label>
                            </div>
                            <div className="field">
                                <Input type="number" value={height/PPI} min={0} step={9/PPI}
                                    id="element_height"
                                    onChange={ this.handleInputChange }
                                    />
                                <label>Height</label>
                            </div>
                        </div>
                        { ! activeIsSource && <>
                            <Header dividing size="tiny">Borders</Header>
                            <div className="two fields mb_0">
                                <div className="field">
                                    <Input type="number" value={x/PPI} min={0} step={9/PPI} 
                                        id='element_left_border'
                                        onChange={ this.handleInputChange }
                                        />
                                    <label>Left</label>
                                </div>
                                <div className="field">
                                    <Input type="number" value={(activeElementParent.width - (x + width)) / PPI} min={0} step={9/PPI} 
                                        id='element_right_border'
                                        onChange={ this.handleInputChange }
                                        />
                                    <label>Right</label>
                                </div>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <Input type="number" value={y/PPI} min={0} step={9/PPI} 
                                        id='element_top_border'
                                        onChange={ this.handleInputChange }
                                        />
                                    <label>Top</label>
                                </div>
                                <div className="field">
                                    <Input type="number" value={(activeElementParent.height - (y + height)) / PPI} min={0} step={9/PPI}
                                        id='element_bottom_border'
                                        onChange={ this.handleInputChange }
                                        />
                                    <label>Bottom</label>
                                </div>
                            </div>
                            </>}
                        </form>
                    </Popup>

            }

        </Button.Group>
        )
    }
}

OpeningToolset.propTypes = {
    elements: PropTypes.array,
    activeElement: PropTypes.string,
    activeElementObject: PropTypes.object,
    activeIsSource: PropTypes.bool,
    defaultReveal: PropTypes.number,
    projectMats: PropTypes.array,
    activeDrawTool: PropTypes.string,
}

function mapStateToProps(state) {

    const { elements } = state.project.present
    const { activeElement } = state.tools
    let activeElementObject = getItemBy('id', activeElement, elements) || {}
    let activeElementParent = getItemBy('id', activeElementObject.parent, elements) || {}

    return {
        elements,
        activeElement,
        activeElementObject,
        activeElementParent,
        activeIsSource: activeElement === 'source-opening',
        defaultReveal: state.project.present.defaultReveal,
        projectMats: state.project.present.projectMats,
        activeDrawTool: state.tools.activeDrawTool,
    }
}

export default connect(mapStateToProps)(OpeningToolset)
