import React from 'react'
import { Message, Header } from 'semantic-ui-react';
import ErrorMessage from './ErrorMessage';

const ForgotPasswordForm = props => {

    const { data: formData } = props

	return (
        <form className="ui form" method="post" acceptCharset="UTF-8" 
            onSubmit={ event => { event.preventDefault(); props.onSubmit() } }
            >
            
			{ props.resetPasswordSuccess && 
                <Message positive>
                    <Message.Header>Password reset email sent</Message.Header>
                    <Message.Content>Follow the link in your email to reset your password.</Message.Content>
                </Message>
            }

            <Header>Enter your username or email and we'll send you a password reset link via email.</Header>

            <div className="field">
                <label htmlFor="loginName">Username or email</label>
                <input id="loginName" type="text" name="loginName" value={formData.loginName} 
                    onChange={ event => props.onChange({ name: 'loginName', type: 'text', value: event.target.value }) } 
                    />
            </div>

            { props.error &&
                <ErrorMessage error={props.error} />
            }

            <input type="submit" value="Submit" style={{display:"none"}} />

        </form>
	)
}

export default ForgotPasswordForm