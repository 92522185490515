import React, {useState} from 'react'
import { Icon, Label, Dimmer, Loader } from 'semantic-ui-react'
import GalleryCardPreview from './GalleryCardPreview'

const GalleryCard = (props) => {

    const [preview, setPreview] = useState(false)
    const {
        image, 
        inLibrary, 
        syncing, 
        onDeselectImage, 
        onSelectImage, 
        canFeature, 
        featured, 
        onToggleFeature
    } = props

    function showPreview() {
        setPreview(true)
    }

    function hidePreview() {
        setPreview(false)
    }
    
    function handleSelectImage(event) {
        event.stopPropagation()
        onSelectImage(image)
    }

    function handleDeselectImage(event) {
        event.stopPropagation()
        onDeselectImage(image)
    }

    function handleToggleFeature(event) {
        event.stopPropagation()
        onToggleFeature(image, featured)
    }

    return (
        <Dimmer.Dimmable dimmed={syncing} 
            as="button"
            className="gallery_card hover_raise naked_button width_100" 
            style={{minHeight: 60}}
            onClick={ showPreview }
            >

            <img src={image.src.replace( / /g, encodeURIComponent(' ') )} 
                alt={image.id} 
                className="display_block max_width_100 height_auto box_shadow" 
                />

            { inLibrary &&
                <Label icon="folder"
                    content="In Library"
                    attached="bottom"
                    color='green' 
                />
            }
            { canFeature && featured &&
                <Label icon="star"
                    corner="left"
                    color="blue" 
                />
            }

            <Icon inverted size="huge" name="search"/>

            <Dimmer active={syncing}>
                <Loader />
            </Dimmer>

            <GalleryCardPreview 
                open={preview}
                card={{image, inLibrary, syncing, canFeature, featured}}
                onClose={ () => hidePreview() }
                onSelectImage={ handleSelectImage }
                onDeselectImage={ handleDeselectImage }
                onToggle={ handleToggleFeature }
                />

        </Dimmer.Dimmable>
    )
}

export default GalleryCard