import React from 'react'
import {PPI, EIGHTH_IN_PX} from '../../data/constants'

const fillLite = "rgba(56, 185, 255, 0.2)"
const fillMed = "rgba(56, 185, 255, 0.6)"
const fillDark = "rgba(56, 185, 255, 1)"

const Grid = () => {
    return (
            <svg style={{ width: '100%', height: '100%' }}>
                <defs>
                    <pattern id="grid-pattern" patternUnits="userSpaceOnUse" width={PPI} height={PPI}>
                        <rect x={0} y={EIGHTH_IN_PX * 0} width="100%" height="1" fill={fillDark}/>
                        <rect x={0} y={EIGHTH_IN_PX * 1} width="100%" height="1" fill={fillLite}/>
                        <rect x={0} y={EIGHTH_IN_PX * 2} width="100%" height="1" fill={fillLite}/>
                        <rect x={0} y={EIGHTH_IN_PX * 3} width="100%" height="1" fill={fillLite}/>
                        <rect x={0} y={EIGHTH_IN_PX * 4} width="100%" height="1" fill={fillMed}/>
                        <rect x={0} y={EIGHTH_IN_PX * 5} width="100%" height="1" fill={fillLite}/>
                        <rect x={0} y={EIGHTH_IN_PX * 6} width="100%" height="1" fill={fillLite}/>
                        <rect x={0} y={EIGHTH_IN_PX * 7} width="100%" height="1" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 0} y={0} width="1" height="100%" fill={fillDark}/>
                        <rect x={EIGHTH_IN_PX * 1} y={0} width="1" height="100%" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 2} y={0} width="1" height="100%" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 3} y={0} width="1" height="100%" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 4} y={0} width="1" height="100%" fill={fillMed}/>
                        <rect x={EIGHTH_IN_PX * 5} y={0} width="1" height="100%" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 6} y={0} width="1" height="100%" fill={fillLite}/>
                        <rect x={EIGHTH_IN_PX * 7} y={0} width="1" height="100%" fill={fillLite}/>
                    </pattern>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#grid-pattern)" />
            </svg>
        )
}

export default Grid
