import React, {useState, useEffect, useRef} from 'react';
import { setActiveElement } from '../../reducks/tools'
import { updateElementProps } from '../../reducks/project'
import ElementTransformer from './ElementTransformer'
import ElementToolset from './ElementToolset'
import {EIGHTH_IN_PX, PPI} from '../../data/constants'
import {nearestDivisor, getBrowserFeatures} from '../../helpers'
import {layerHighlightColors} from '../core/utilities'
import Fraction from '../core/Fraction'
import { useSelector, useDispatch } from 'react-redux';
import { Responsive } from 'semantic-ui-react';

const Element = (props, ref) => {

    const svgPositionRef = useRef(null)

    // state
    const [hovered, setHovered] = useState(false)
    const [resizing, setResizing] = useState(false)
    const [x, setX] = useState(props.x)
    const [y, setY] = useState(props.y)
    const [width, setWidth] = useState(props.width)
    const [height, setHeight] = useState(props.height)
    const [dragging, setDragging] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    // redux state
    const projectType = useSelector( state => state.project.present.type )
    const dispatch = useDispatch()

    // component props
    const { 
        boundaries,
        canSelect,
        children,
        component, 
        depth, 
        editable, 
        grid,
        id, 
        isActive, 
        // isDraggedOver,
        lockedMode, 
        onDeselect,
        onDragStop,
        onMouseOut,
        onMouseOver,
        onResizeEnd,
        parentWidth,
        parentHeight,
        shape,
        tools, 
        zoom, 
    } = props
    
    // derived vars
    const defaultGrid = [EIGHTH_IN_PX, EIGHTH_IN_PX]
    const isSource = 'source-opening' === id
    const elementTitle  = <>
        <Fraction float={width / PPI} />{' '}{' '}&times;{' '}{' '}<Fraction float={height / PPI} />{' '}&rdquo;<br/>{getElementLabel()}
    </>
    const showTransformer = (
        component === 'Opening' ?
            !lockedMode && isActive
            : isActive || hovered
    )

    // effects
    useEffect( () => { setX(props.x) }, [props.x] )
    useEffect( () => { setY(props.y) }, [props.y] )

    // methods
    const handleClick = (event) => {
        event.stopPropagation();

        if (
            isMobile
            || ( canSelect !== undefined && ! canSelect )
            || ( projectType === 'projects' && ! editable )
        ) 
        { return }

        if ( ! isActive ) {
            dispatch( setActiveElement( id ) )
        }
    }

    const handleMouseOver = event => {
        event.stopPropagation()

        // if ( lockedMode || ! editable ) return
        if ( projectType === 'projects' && ! editable ) 
            return

        if ( ! hovered ) {
            setHovered(true)
        }

        if ( typeof onMouseOver === 'function' ) {
            onMouseOver(event)
        }
    }

    const handleMouseOut = event => {
        event.stopPropagation()

        // if ( lockedMode || ! editable ) return
        if ( projectType === 'projects' && ! editable ) 
            return

        if ( hovered ) {
            setHovered(false)
        }

        if ( typeof onMouseOut === 'function' ) {
            onMouseOut(event)
        }
    }

    const handleDragStart = ( event, data ) => {
        event.stopPropagation()

        if ( lockedMode || ! editable ) 
            {return}

        setDragging(true)
    }

    const handleDrag = ( event, {deltaX, deltaY} ) => {
        event.stopPropagation()

        if ( lockedMode || ! editable ) 
            {return}

        const divisorX = grid ? grid[0] : defaultGrid[0]
        const divisorY = grid ? grid[1] : defaultGrid[1]
        const newX = x + nearestDivisor( deltaX / zoom, divisorX )
        const newY = y + nearestDivisor( deltaY / zoom, divisorY )

        let _x, _y

        if ( boundaries ) {

            // keep the element within prescribed boundaries

            const { xMin, xMax, yMin, yMax } = boundaries
            if ( newX < xMin || newX > xMax ) {
                _x = x
            }
            else {
                _x = newX
            }
            if ( newY < yMin || newY > yMax ) {
                _y = y
            }
            else {
                _y = newY
            }
        }
        else {

            // default to containing the element within it's parent

            if ( newX < 0 ) {
                _x = 0
            }
            else if ( newX > parentWidth - width ) {
                _x = x
            }
            else {
                _x = newX
            }

            if ( newY < 0 ) {
                _y = 0
            }
            else if ( newY > parentHeight - height ) {
                _y = y
            }
            else {
                _y = newY
            }
        }

        setX(_x)
        setY(_y)
    }

    const handleDragStop = ( event, {deltaX, deltaY} ) => {
        event.stopPropagation()

        if ( lockedMode || ! editable ) 
            {return}

        if ( deltaX !== 0 || deltaY !== 0 ){
            dispatch( updateElementProps( id, { x, y } ) )
        }

        setDragging(false)

        if ( typeof onDragStop === 'function' ) {
            onDragStop({ x, y })
        }
    }

    const handleResizeStart = ( event, data ) => {
        setResizing(true)
    }

    const handleResize = ( event, data, direction ) => {
        if ( lockedMode || ! editable )
            {return}

        const divisorX = grid ? grid[0] : defaultGrid[0]
        const divisorY = grid ? grid[1] : defaultGrid[1]
        const deltaX = nearestDivisor(data.deltaX / zoom, divisorX)
        const deltaY = nearestDivisor(data.deltaY / zoom, divisorY)

        let newWidth = width,
            newHeight = height,
            newX = x,
            newY = y

        if ( 'N' === direction )
        {
            newHeight = height - deltaY
            newY = y + deltaY
        }
        if ( 'S' === direction )
        {
            newHeight = height + deltaY
        }
        if ( 'E' === direction )
        {
            newWidth = width + deltaX
        }
        if ( 'W' === direction )
        {
            newWidth = width - deltaX
            newX = x + deltaX
        }
        if ( 'NE' === direction )
        {
            newWidth = width + deltaX
            newHeight = height - deltaY
            newY = y + deltaY
        }
        if ( 'SE' === direction )
        {
            newWidth = width + deltaX
            newHeight = height + deltaY
        }
        if ( 'NW' === direction )
        {
            newWidth = width - deltaX
            newHeight = height - deltaY
            newX = x + deltaX
            newY = y + deltaY
        }
        if ( 'SW' === direction )
        {
            newWidth = width - deltaX
            newHeight = height + deltaY
            newX = x + deltaX
        }

        setWidth(newWidth)
        setHeight(newHeight)
        setX(newX)
        setY(newY)
        
        if ( typeof onResizeEnd === 'function' ) {
            onResizeEnd({ width: newWidth, height: newHeight, x: newX, y: newY })
        }
    }

    const handleResizeStop = ( event, data, direction ) => {
        event.stopPropagation()

        if ( lockedMode || ! editable ) 
            {return}

        dispatch( updateElementProps( id, { width, height, x, y } ) )
        setResizing(false)
    }

    function getElementLabel() {
        let label = ''

        if ( depth === 0 ) {
            label = 'Top mat'
        }
        else {
            if ( component === 'Opening' ) {
                if ( typeof shape === 'object' ) {
                    label = 'Cut Art'
                } else {
                    label = 'Opening'
                }
            }
            else {
                label = component
            }
        }
        return label
    }

    const handleResponsiveUpdate = (event, {width}) => {
        if ( 
            width < Responsive.onlyComputer.minWidth
            && getBrowserFeatures('touchevents')
        ) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    return (
        <Responsive as="svg" fireOnMount onUpdate={handleResponsiveUpdate}
            ref={ref}
            id={id}
            className={`Element cursor_pointer`}
            width={width} height={height}
            x={x} y={y}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            >

            <svg x="0" y="0" width="100%" height="100%" pointerEvents="none" 
                ref={ svgPositionRef }></svg>

            { resizing && component === 'Opening' ?
                <>
                    <rect x="0" y="0" width="100%" height="100%" fill={layerHighlightColors[depth]} opacity="0.3" pointerEvents="none" />
                    <g opacity="0.3">{children}</g>
                </>
                :
                children
            }

            { ! isSource && showTransformer &&
                <ElementTransformer
                    width={width}
                    height={height}
                    x={x}
                    y={y}
                    isActive={isActive}
                    canTransform={ ! lockedMode && isActive }
                    color={ editable ? layerHighlightColors[depth] : 'red'}
                    zoom={zoom}
                    onResizeStart={ handleResizeStart }
                    onResize={ handleResize }
                    onResizeStop={ handleResizeStop }
                    onDragStart={ handleDragStart }
                    onDrag={ handleDrag }
                    onDragStop={ handleDragStop }
                    />
            }

            <ElementToolset
                confirm
                remove={ ! lockedMode && ! isSource }
                open={isActive}
                faded={dragging || resizing}
                context={svgPositionRef}
                tools={tools}
                title={elementTitle}
                offset={{ left: -15 }}
                onDeselect={ () => { 
                    setHovered(false);
                    if ( typeof onDeselect === 'function' ) {
                        onDeselect()
                    }
                } }
                />

        </Responsive>
    )
}

export default React.forwardRef(Element)