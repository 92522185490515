import {serializeObject} from '../helpers'

const __parseResponseAsJson = async (response) => {
    if (response.status !== 200) {
        console.log(response)
        console.trace()
    }

    const responseText = await response.text()
    
    try {
        const data = JSON.parse(responseText)
        return data
    }
        catch(error) {
            console.log(error, 'text : ('+typeof response+') : ', response)
            console.trace()
            return {error}
        }
}

/**
 * POST url encoded data to a url and accept JSON in return
 * Used primarily for sending data to Craft CMS
 * @param url {string} - URL of the endpoint
 * @param bodyParams {object} - plain object of body parameters.
 */
export const post = async (url, bodyParams) => {

    const method = "POST"
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json"
    }
    const body = serializeObject({
        ...bodyParams, 
        [window.Craft.csrfTokenName]: window.Craft.csrfToken
    })

    let response = await fetch(url, { method, headers, body }).catch( err => console.error(err) )

    return __parseResponseAsJson(response)

}

/**
 * Perform a GET request to a url and receive JSON in return
 * @param url {string} - URL of the endpoint
 */
export const get = async (url) => {
    const response = await fetch(url).catch( err => console.error(err) )
    return __parseResponseAsJson(response)
}
